// This file is for System Logs. The related CSS file is Syslognew.css

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LinearGradient, Stop, ReferenceArea, ReferenceLine } from 'recharts';
import './Syslognew.css';
import { utils, writeFile } from 'xlsx';

const Syslogtest = ({ searchQuery, dataSource, host, start, end, logType, environment, onZoom, onLogTypeChange, onSearchChange }) => {
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSyslogDetails, setShowSyslogDetails] = useState(false);
    const [showAuthlogDetails, setShowAuthlogDetails] = useState(false);
    const [showApplicationlogDetails, setShowApplicationlogDetails] = useState(false);
    const [inputError, setInputError] = useState(null);
    const [logVolumes, setLogVolumes] = useState([]);
    const [timeframe, setTimeframe] = useState('hours'); // Default timeframe
    const [selectedTimestamp, setSelectedTimestamp] = useState(null);
    const [selectedGraphTimestamp, setSelectedGraphTimestamp] = useState(null);
    const [isDemoMode, setIsDemoMode] = useState(false);
    const logRef = useRef(null);
    const [zoomStart, setZoomStart] = useState(null);
    const [zoomEnd, setZoomEnd] = useState(null);
    const [isZooming, setIsZooming] = useState(false);
    const [brushActive, setBrushActive] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'timestamp', direction: 'desc' });
    const [selectedLevels, setSelectedLevels] = useState([]);
    const [syslogsContentFilter, setSyslogsContentFilter] = useState('');
    const [authlogsContentFilter, setAuthlogsContentFilter] = useState('');
    const [applogsContentFilter, setApplogsContentFilter] = useState('');
    const [activeSection, setActiveSection] = useState(null);
    const [collapsedSections, setCollapsedSections] = useState({
        syslogs: false,
        authlogs: false,
        applogs: false
    });
    const [collapsedFilters, setCollapsedFilters] = useState({
        syslogs: false,
        authlogs: false,
        applogs: false
    });
    const [showTooltip, setShowTooltip] = useState(false);
    const [showLevelFilter, setShowLevelFilter] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [syslogsDropdownOpen, setSyslogsDropdownOpen] = useState(false);
    const [authlogsDropdownOpen, setAuthlogsDropdownOpen] = useState(false);
    const [applogsDropdownOpen, setApplogsDropdownOpen] = useState(false);

    // Generate dummy log volumes data
    const generateDummyLogVolumes = () => {
        const now = new Date();
        const data = [];
        const logsPerHour = Math.floor(180 / 12); // Evenly distribute 180 logs across 12 hours
        
        // Adjust base volume and variation based on log type
        let baseVolume, variation;
        switch (logType) {
            case 'informational':
                baseVolume = Math.floor(logsPerHour * 0.6); // 60% of logs are informational
                variation = Math.floor(logsPerHour * 0.2);
                break;
            case 'error':
                baseVolume = Math.floor(logsPerHour * 0.3); // 30% of logs are errors
                variation = Math.floor(logsPerHour * 0.1);
                break;
            case 'critical':
                baseVolume = Math.floor(logsPerHour * 0.1); // 10% of logs are critical
                variation = Math.floor(logsPerHour * 0.05);
                break;
            default: // 'all'
                baseVolume = logsPerHour;
                variation = Math.floor(logsPerHour * 0.3);
        }

        for (let i = 11; i >= 0; i--) {
            const timestamp = new Date(now.getTime() - i * 3600000);
            data.push({
                timestamp: timestamp.getTime(),
                count: Math.floor(Math.random() * variation) + baseVolume
            });
        }
        return data;
    };

    // Generate dummy logs
    const generateDummyLogs = () => {
        const logLevels = {
            all: ['info', 'warning', 'error', 'critical', 'notice', 'debug', 'exception'],
            informational: ['info', 'notice', 'debug', 'warning'],
            error: ['error', 'exception'],
            critical: ['critical']
        };
        
        const syslogMessages = {
            info: ['System startup completed successfully', 'Service health check passed', 'Backup completed successfully', 'System resources optimized'],
            warning: ['CPU usage exceeded 80%', 'Memory utilization at 75%', 'Disk space usage warning', 'Network latency increased'],
            error: ['Network interface eth0 down', 'Database connection failed', 'Service restart failed', 'Process terminated unexpectedly'],
            critical: ['System crash detected', 'Security breach detected', 'Critical resource exhaustion', 'Hardware failure detected'],
            notice: ['Configuration updated', 'Scheduled maintenance started', 'System update available', 'Service state changed'],
            debug: ['Debug mode enabled', 'Cache cleared', 'Connection pool refreshed', 'Resource cleanup completed'],
            exception: ['Unhandled exception in service', 'Stack overflow detected', 'Memory leak detected', 'Deadlock detected']
        };

        const authlogMessages = {
            info: ['User login successful', 'Password changed successfully', 'Session started', 'User logged out'],
            warning: ['Multiple failed login attempts', 'Password expiring soon', 'Session timeout warning', 'Unusual login pattern detected'],
            error: ['Authentication failed', 'Invalid credentials provided', 'Session token expired', 'Access denied'],
            critical: ['Brute force attack detected', 'Unauthorized root access attempt', 'Security policy violation', 'Multiple security breaches detected'],
            notice: ['New user account created', 'User permissions updated', 'Account lockout lifted', 'Security policy updated'],
            debug: ['Auth token generated', 'Session validation', 'Permission check completed', 'Access log rotated'],
            exception: ['Authentication service error', 'Token validation failed', 'Permission check failed', 'Security module crash']
        };

        const applogMessages = {
            info: ['Application started', 'Database connection established', 'Cache refreshed', 'API endpoint accessed'],
            warning: ['High latency detected', 'Low memory warning', 'API rate limit approaching', 'Cache miss rate increased'],
            error: ['Database query failed', 'API endpoint error', 'Service dependency timeout', 'Transaction rollback'],
            critical: ['Application crash detected', 'Data corruption found', 'Critical service failure', 'Unrecoverable error'],
            notice: ['New version deployed', 'Configuration reload', 'Maintenance mode enabled', 'Feature flag updated'],
            debug: ['Request processing details', 'Cache operation trace', 'Database query execution', 'API response time'],
            exception: ['Unhandled API exception', 'Database connection lost', 'Service integration failed', 'Internal server error']
        };

        const now = new Date();
        const dummyLogs = [];
        const selectedLevels = logLevels[logType] || logLevels.all;
        const totalLogs = 180; // Keep total logs under 200
        
        // Generate logs for each hour in the past 12 hours
        for (let hour = 11; hour >= 0; hour--) {
            const baseTimestamp = new Date(now.getTime() - hour * 3600000);
            const logsPerHour = Math.floor(totalLogs / 12); // Distribute logs evenly

            for (let i = 0; i < logsPerHour; i++) {
                // Add some random minutes to spread logs within the hour
                const timestamp = new Date(baseTimestamp.getTime() + Math.random() * 3600000);
                
                // Determine log type (syslog, authlog, applog) with equal distribution
                const logType = i % 3 === 0 ? 'syslog' : i % 3 === 1 ? 'auth.log' : 'application.log';
                
                // Select level based on current logType filter
                const level = selectedLevels[Math.floor(Math.random() * selectedLevels.length)];
                
                // Select appropriate message based on log type and level
                let messageArray;
                if (logType === 'syslog') {
                    messageArray = syslogMessages[level] || syslogMessages.info;
                } else if (logType === 'auth.log') {
                    messageArray = authlogMessages[level] || authlogMessages.info;
                } else {
                    messageArray = applogMessages[level] || applogMessages.info;
                }

                const content = messageArray[Math.floor(Math.random() * messageArray.length)];

                dummyLogs.push({
                    timestamp: timestamp.toISOString(),
                    level,
                    content,
                    filename: logType,
                    host: host || 'sample-host'
                });
            }
        }

        return dummyLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc', borderRadius: '5px' }}>
                    <p>{`Time: ${label}`}</p>
                    <p>{`Value: ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };

    const processLogVolumes = (volumes, start, end, markers) => {
        const interval = (new Date(end) - new Date(start)) / markers; // Calculate uniform intervals
        const processedData = [];

        for (let i = 0; i < markers; i++) {
            const timestamp = new Date(new Date(start).getTime() + i * interval);
            const matchingVolume = volumes.find((v) =>
                new Date(v.timestamp).getTime() >= timestamp.getTime() &&
                new Date(v.timestamp).getTime() < timestamp.getTime() + interval
            );

            processedData.push({
                timestamp: timestamp.getTime(),
                count: matchingVolume ? matchingVolume.count : 0 // Use 0 if no matching volume
            });
        }

        return processedData;
    };

    const handleTimeframeChange = (newTimeframe) => {
        setTimeframe(newTimeframe);

        // Reprocess data when the timeframe changes
        const markers = newTimeframe === 'hours' ? 12 : newTimeframe === 'days' ? 7 : 30; // Adjust markers dynamically
        const processedData = processLogVolumes(logVolumes, start, end, markers);
        setLogVolumes(processedData);
    };

    const loadDemoData = () => {
            setLoading(true);
        setTimeout(() => {
            const dummyLogsData = generateDummyLogs();
            const dummyVolumesData = generateDummyLogVolumes();
            setLogs(dummyLogsData);
            setLogVolumes(dummyVolumesData);
            setError(null);
            setIsDemoMode(true);
            setLoading(false);
        }, 1000);
    };

    const closeDemoData = () => {
        setLogs([]);
        setLogVolumes([]);
        setIsDemoMode(false);
        setError('No logs available');
    };

    useEffect(() => {
        // Reset demo mode when any selection changes
        if (isDemoMode) {
            closeDemoData();
        }
    }, [dataSource, host, environment]); // Reset when any selection changes

    useEffect(() => {
        if (!dataSource || !host || !start || !end) {
            console.log('Missing required parameters:', { dataSource, host, start, end });
            return;
        }

        const fetchData = async () => {
            if (isDemoMode) return; // Skip fetching if in demo mode

            setLoading(true);
            setError(null);

            try {
            const url = `https://infia.enviseer.com/api/logs?cluster=${dataSource}&host=${encodeURIComponent(host)}&start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`;
            function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            const token = getCookie('token');

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                if (!Array.isArray(data) || data.length === 0) {
                    setError('No logs available');
                    return;
                }
                setLogs(data);
                // You would also fetch and set log volumes here
            } catch (error) {
                console.error('Error loading data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const fetchLogVolumes = async () => {
            setLoading(true);
            setError(null);

            const volumeUrl = `https://infia.enviseer.com/api/logs/volume?cluster=${dataSource}&host=${encodeURIComponent(host)}&start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`;
            //console.log('Fetching log volumes from URL:', volumeUrl);

            function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }

            const token = getCookie('token');
            try {
                const response = await fetch(volumeUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Using Authorization header with Bearer token
                        'Accept': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const volumes = await response.json();
                //console.log('Fetched log volumes:', volumes);
                // Transform data for the chart
                // Transform and process log volumes for the selected timeframe
                const markers = timeframe === 'hours' ? 12 : timeframe === 'days' ? 7 : 30; // Adjust markers
                const processedData = processLogVolumes(volumes, start, end, markers);
                setLogVolumes(processedData); // Update the state with processed data
                //setLogVolumes(volumes);

            } catch (error) {
                console.error('Error fetching log volumes:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchLogVolumes();

        fetchData();
    }, [dataSource, host, start, end, isDemoMode]);

    const escapeSpecialChars = (query) => {
        return query.replace(/([.+^${}()|[\]\\])/g, '\\$1');
    };

    const parseBooleanQuery = (query) => {
        try {
            const escapedQuery = escapeSpecialChars(query);
            const terms = escapedQuery.match(/("[^"]+"|[^,\s]+|,\s*)/g) || [];
            return terms.map(term => term.trim().replace(/"/g, ''));
        } catch (error) {
            setInputError('Malformed query input. Please check your query syntax.');
            return [];
        }
    };

    // Add this helper function for level-based colors
    const getLevelColors = (level) => {
        switch(level) {
            case 'critical':
                return {
                    bg: 'rgba(153, 27, 27, 0.05)',
                    text: '#991B1B'
                };
            case 'error':
            case 'exception':
                return {
                    bg: 'rgba(220, 38, 38, 0.05)',
                    text: '#DC2626'
                };
            case 'warning':
                return {
                    bg: 'rgba(217, 119, 6, 0.05)',
                    text: '#D97706'
                };
            default:
                return {
                    bg: 'transparent',
                    text: '#2B3674'
                };
        }
    };

    // Update the highlightTerms function for subtler highlighting
    const highlightTerms = (content, query) => {
        const terms = parseBooleanQuery(query);
        let highlightedContent = content;
        terms.forEach(term => {
            if (!['AND', 'OR', 'NOT'].includes(term.toUpperCase()) && !term.includes(',')) {
                const regexPattern = term.replace(/\*/g, '.*').replace(/\?/g, '.');
                const regex = new RegExp(`(${regexPattern})`, 'gi');
                highlightedContent = highlightedContent.replace(regex, '<span style="background-color: rgba(104, 37, 236, 0.1); padding: 2px 4px; border-radius: 4px;">$1</span>');
            }
        });
        return highlightedContent;
    };

    const applyBooleanLogic = (content, query) => {
        const terms = parseBooleanQuery(query);
        let result = true;
        let operator = 'AND';

        terms.forEach(term => {
            if (['AND', 'OR', 'NOT'].includes(term.toUpperCase())) {
                operator = term.toUpperCase();
            } else {
                const regexPattern = term.replace(/\*/g, '.*').replace(/\?/g, '.');
                const regex = new RegExp(regexPattern, 'i');
                const isMatch = regex.test(content);
                if (operator === 'AND') {
                    result = result && isMatch;
                } else if (operator === 'OR') {
                    result = result || isMatch;
                } else if (operator === 'NOT') {
                    result = result && !isMatch;
                }
            }
        });

        return result;
    };

    //csv
    const exportToCsv = (logs, filename) => {
        const header = 'Timestamp,Level,Content\n';
        const csvContent = logs.map(log => {
            const timestamp = new Date(log.timestamp).toLocaleString();
            const level = log.level || 'null';
            const content = log.content.replace(/,/g, ';').replace(/\n/g, ' ');
            return `"${timestamp}","${level}","${content}"`;
        }).join('\n');
        
        const blob = new Blob([header + csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const exportToExcel = (logs, filename) => {
        const header = ['Timestamp', 'Level', 'Content'];
        const data = logs.map(log => [
            new Date(log.timestamp).toLocaleString(),
            log.level || 'null',
            log.content
        ]);
        
        const ws = utils.aoa_to_sheet([header, ...data]);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Logs');
        writeFile(wb, `${filename}.xlsx`);
    };

    const exportToJSON = (logs, filename) => {
        const data = JSON.stringify(logs, null, 2);
        const blob = new Blob([data], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${filename}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const filterLogs = (logs, logType, searchQuery, selectedTimestamp, timeWindow) => {
        if (!logs || !Array.isArray(logs)) {
            return [];
        }

        return logs
            .filter(log => {
                // First filter by log type
                if (logType === 'all') return true;
                if (logType === 'informational') {
                    return ['info', 'notice', 'debug', 'warning', null].includes(log.level);
                } else if (logType === 'error') {
                    return ['error', 'exception'].includes(log.level);
                } else if (logType === 'critical') {
                    return log.level === 'critical';
                }
                return true;
            })
            .filter(log => {
                // Then filter by search query if present
                if (!searchQuery) return true;
                const content = log.content.toLowerCase();
                return applyBooleanLogic(content, searchQuery);
            })
            .filter(log => {
                // Filter by time window or single timestamp
                if (timeWindow) {
                    const logTime = new Date(log.timestamp).getTime();
                    return logTime >= timeWindow.start && logTime <= timeWindow.end;
                } else if (selectedTimestamp) {
                    const logTime = new Date(log.timestamp).getTime();
                    const selectedTime = new Date(selectedTimestamp).getTime();
                    return logTime >= selectedTime && logTime < selectedTime + 3600000;
                }
                return true;
            })
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    };

    const filteredLogs = useMemo(() => 
        filterLogs(
            logs, 
            logType, 
            searchQuery, 
            selectedGraphTimestamp,
            brushActive ? { start: zoomStart, end: zoomEnd } : null
        ), 
        [logs, logType, searchQuery, selectedGraphTimestamp, brushActive, zoomStart, zoomEnd]
    );

    const syslogs = useMemo(() => filteredLogs.filter(log => log.filename.includes('syslog') || log.filename.includes('System')), [filteredLogs]);

    const authlogs = useMemo(() => filteredLogs.filter(log => log.filename.includes('auth.log') || log.filename.includes('Security')), [filteredLogs]);

    const applicationlogs = useMemo(() =>
        filteredLogs.filter(log =>
            !(log.filename.includes('syslog') || log.filename.includes('System') || log.filename.includes('auth.log') || log.filename.includes('Security'))
        ),
        [filteredLogs]);

        const handleToggle = () => {
            setShowApplicationlogDetails(!showApplicationlogDetails);
            setTimeout(() => {
                if (logRef.current) {
                    logRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }, 100); // Delay to ensure UI updates before scrolling
        };

    const toggleSyslogDetails = () => setShowSyslogDetails(!showSyslogDetails);
    const toggleAuthlogDetails = () => setShowAuthlogDetails(!showAuthlogDetails);
    const toggleApplicationlogDetails = () => handleToggle();

    const sortData = (data, config) => {
        if (!config.key) return data;

        return [...data].sort((a, b) => {
            if (config.key === 'timestamp') {
                return config.direction === 'asc' 
                    ? new Date(a.timestamp) - new Date(b.timestamp)
                    : new Date(b.timestamp) - new Date(a.timestamp);
            }
            if (a[config.key] < b[config.key]) return config.direction === 'asc' ? -1 : 1;
            if (a[config.key] > b[config.key]) return config.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    // Function to toggle section collapse
    const toggleSection = (section) => {
        setCollapsedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    // Function to toggle filter collapse
    const toggleFilter = (section) => {
        setCollapsedFilters(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const renderLogs = (logs, showDetails, toggleDetails, contentFilter, setContentFilter) => {
        const section = logs === syslogs ? 'syslogs' : logs === authlogs ? 'authlogs' : 'applogs';
        const sectionName = logs === syslogs ? 'Syslogs' : logs === authlogs ? 'Authlogs' : 'Applogs';
        
        // Apply filters and sorting as before
        let filteredByContent = logs;
        if (contentFilter) {
            filteredByContent = logs.filter(log => 
                log.content.toLowerCase().includes(contentFilter.toLowerCase())
            );
        }

        let filteredByLevel = filteredByContent;
        if (selectedLevels.length > 0) {
            filteredByLevel = filteredByContent.filter(log => 
                selectedLevels.includes(log.level || 'null')
            );
        }

        const sortedLogs = sortData(filteredByLevel, sortConfig);
        const uniqueLevels = [...new Set(logs.map(log => log.level || 'null'))];

        return (
            <div className="log-card" style={{
                background: '#ffffff',
                borderRadius: '12px',
                overflow: 'hidden',
                marginBottom: '24px',
                boxShadow: '0 2px 8px rgba(104, 37, 236, 0.1)',
                border: activeSection === section ? '2px solid #6825EC' : '2px solid transparent',
                transition: 'all 0.2s ease'
            }}>
                <div style={{
                    padding: '16px 24px',
                    borderBottom: '1px solid #E0E6EF',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                        <h2 style={{ 
                            margin: 0, 
                            fontSize: '18px', 
                            color: '#1b2559',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px' 
                        }}>
                            {sectionName}
                            <span style={{
                                fontSize: '14px',
                                color: '#6825EC',
                                fontWeight: '600',
                                background: 'rgba(104, 37, 236, 0.15)',
                                padding: '4px 12px',
                                borderRadius: '20px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                height: '24px',
                                boxShadow: '0 2px 4px rgba(104, 37, 236, 0.1)'
                            }}>
                                {sortedLogs.length}
                            </span>
                        </h2>
                    </div>
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        {/* Export dropdown */}
                        <div className="dropdown" style={{ position: 'relative' }}>
                            <button
                                style={{
                                    padding: '8px 16px',
                                    background: '#F8FAFC',
                                    border: '1px solid #E2E8F0',
                                    borderRadius: '8px',
                                    color: '#1E293B',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px'
                                }}
                                onClick={(e) => {
                                    const dropdown = e.currentTarget.nextElementSibling;
                                    dropdown.style.display = dropdown.style.display === 'none' ? 'block' : 'none';
                                }}
                            >
                                <i className="bi bi-download"></i>
                                Export
                            </button>
                            <div style={{
                                display: 'none',
                                position: 'absolute',
                                top: '100%',
                                right: '0',
                                background: '#FFFFFF',
                                border: '1px solid #E2E8F0',
                                borderRadius: '8px',
                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                                zIndex: 10,
                                marginTop: '4px',
                                minWidth: '120px'
                            }}>
                                {[
                                    { format: 'Excel', icon: 'file-earmark-excel', fn: () => exportToExcel(sortedLogs, `${sectionName.toLowerCase()}`) },
                                    { format: 'CSV', icon: 'file-earmark-text', fn: () => exportToCsv(sortedLogs, `${sectionName.toLowerCase()}.csv`) },
                                    { format: 'JSON', icon: 'file-earmark-code', fn: () => exportToJSON(sortedLogs, `${sectionName.toLowerCase()}`) }
                                ].map(({ format, icon, fn }) => (
                                    <button
                                        key={format}
                                        onClick={fn}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            padding: '8px 16px',
                                            width: '100%',
                                            border: 'none',
                                            background: 'none',
                                            cursor: 'pointer',
                                            color: '#1E293B',
                                            fontSize: '14px',
                                            textAlign: 'left',
                                            ':hover': {
                                                background: '#F8FAFC'
                                            }
                                        }}
                                    >
                                        <i className={`bi bi-${icon}`}></i>
                                        {format}
                                    </button>
                                ))}
                            </div>
                        </div>
                        {/* Collapse section button */}
                        <button
                            onClick={() => toggleSection(section)}
                            style={{
                                padding: '8px',
                                background: 'transparent',
                                border: '1px solid #E2E8F0',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                color: '#64748B',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                transition: 'all 0.2s ease'
                            }}
                            title={collapsedSections[section] ? "Expand section" : "Collapse section"}
                        >
                            <i className={`bi bi-chevron-${collapsedSections[section] ? 'down' : 'up'}`}></i>
                        </button>
                    </div>
                </div>

                {!collapsedSections[section] && (
                    <div className="log-table-container" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                        <div style={{ padding: '0px'}}>
                            <div style={{ 
                                display: 'flex', 
                                gap: '16px', 
                                alignItems: 'stretch'
                            }}>
                                {/* Filter and Search Row */}
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '16px',
                                    marginBottom: '16px'
                                }}>
                                    {/* Level Filter Dropdown */}
                                    <div className="level-filter-container">
                                        <button 
                                            className="level-filter-button"
                                            onClick={() => {
                                                // Use the appropriate setter based on the section
                                                const setDropdownOpen = 
                                                    section === 'syslogs' ? setSyslogsDropdownOpen :
                                                    section === 'authlogs' ? setAuthlogsDropdownOpen :
                                                    setApplogsDropdownOpen;
                                                const isOpen = 
                                                    section === 'syslogs' ? syslogsDropdownOpen :
                                                    section === 'authlogs' ? authlogsDropdownOpen :
                                                    applogsDropdownOpen;
                                                setDropdownOpen(!isOpen);
                                            }}
                                        >
                                            <span>Level</span>
                                            {selectedLevels.length > 0 && (
                                                <span className="selected-count">{selectedLevels.length}</span>
                                            )}
                                        </button>

                                        {/* Use the appropriate state for dropdown visibility */}
                                        {(section === 'syslogs' ? syslogsDropdownOpen :
                                          section === 'authlogs' ? authlogsDropdownOpen :
                                          applogsDropdownOpen) && (
                                            <div className="level-filter-dropdown">
                                                <div className="level-filter-header">
                                                    <div className="filter-action-buttons">
                                                        <button 
                                                            className="filter-action-button select-all"
                                                            onClick={() => setSelectedLevels([...uniqueLevels])}
                                                            title="Select All"
                                                        >
                                                            <i className="bi bi-check2-all"></i>
                                                            Select All
                                                        </button>
                                                        {selectedLevels.length > 0 && (
                                                            <button 
                                                                className="filter-action-button clear-all"
                                                                onClick={() => setSelectedLevels([])}
                                                                title="Clear All"
                                                            >
                                                                <i className="bi bi-x-lg"></i>
                                                                Clear All
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="level-options">
                                                    {uniqueLevels.map(level => (
                                                        <div key={level} className="level-option">
                                                            <div className="level-option-content">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedLevels.includes(level)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setSelectedLevels([...selectedLevels, level]);
                                                                        } else {
                                                                            setSelectedLevels(selectedLevels.filter(l => l !== level));
                                                                        }
                                                                    }}
                                                                />
                                                                <div className={`level-badge ${level.toLowerCase()}`}>
                                                                    {level}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Search Input */}
                                    <div className="syslog-search-container" style={{ flex: 1 }}>
                                        <i className="bi bi-search" style={{ 
                                            color: '#6825EC',
                                            fontSize: '16px',
                                            marginRight: '12px'
                                        }}></i>
                                        <input
                                            className="syslog-search-input"
                                            type="text"
                                            value={contentFilter}
                                            onChange={(e) => setContentFilter(e.target.value)}
                                            placeholder={`Search in ${sectionName.toLowerCase()}...`}
                                        />
                                        {contentFilter && (
                                            <button
                                                onClick={() => setContentFilter('')}
                                                style={{
                                                    background: 'rgba(104, 37, 236, 0.1)',
                                                    border: 'none',
                                                    padding: '6px 12px',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    color: '#6825EC',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '4px',
                                                    fontSize: '12px',
                                                    fontWeight: '500'
                                                }}
                                            >
                                                <i className="bi bi-x"></i>
                                                Clear
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Table section */}
                        <table className="log-table" style={{ width: '100%', borderCollapse: 'separate', borderSpacing: 0 }}>
                        <thead>
                                <tr style={{ 
                                    background: '#F8FAFC',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                                }}>
                                    <th style={{ 
                                        padding: '16px', 
                                        borderBottom: '1px solid #E2E8F0',   
                                        color: '#1E293B',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}>
                                        <div style={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            gap: '4px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            setSortConfig({
                                                key: 'timestamp',
                                                direction: sortConfig.key === 'timestamp' && sortConfig.direction === 'asc' ? 'desc' : 'asc'
                                            });
                                        }}>
                                            Timestamp
                                            {sortConfig.key === 'timestamp' && (
                                                <i className={`bi bi-arrow-${sortConfig.direction === 'asc' ? 'up' : 'down'}`} 
                                                   style={{ fontSize: '12px', color: '#6825EC' }}></i>
                                            )}
                                        </div>
                                    </th>
                                    <th style={{ 
                                        padding: '16px', 
                                        borderBottom: '1px solid #E2E8F0',
                                        color: '#1E293B',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}>
                                        Level
                                    </th>
                                    <th style={{ 
                                        padding: '16px',
                                        borderBottom: '1px solid #E2E8F0',
                                        color: '#1E293B',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}>
                                        Content
                                    </th>
                                    <th style={{ 
                                        width: '60px',
                                        borderBottom: '1px solid #E2E8F0',
                                        padding: '16px'
                                    }}></th>
                            </tr>
                        </thead>
                            <tbody>
                                {sortedLogs.map((log, index) => (
                                    <tr key={index} style={{
                                        backgroundColor: '#FFFFFF',
                                        transition: 'all 0.2s ease'
                                    }}>
                                        <td style={{ 
                                            padding: '12px 16px', 
                                            fontSize: '14px', 
                                            borderBottom: '1px solid #eee',
                                            color: '#1E293B'
                                        }}>
                                            {new Date(log.timestamp).toLocaleString()}
                                        </td>
                                        <td style={{ 
                                            padding: '12px 16px', 
                                            fontSize: '14px', 
                                            borderBottom: '1px solid #eee'
                                        }}>
                                            <div className={`level-badge ${log.level.toLowerCase()}`}>
                                                {log.level}
                                            </div>
                                        </td>
                                        <td style={{ 
                                            padding: '12px 16px', 
                                            fontSize: '14px', 
                                            borderBottom: '1px solid #eee',
                                            color: '#1E293B',
                                            lineHeight: '1.5'
                                        }} 
                                        dangerouslySetInnerHTML={{ 
                                            __html: highlightTerms(log.content, contentFilter || searchQuery) 
                                        }}>
                                        </td>
                                        <td style={{ 
                                            padding: '12px 16px', 
                                            fontSize: '14px', 
                                            borderBottom: '1px solid #eee',
                                            textAlign: 'center'
                                        }}>
                                            <button
                                                onClick={(event) => {
                                                    navigator.clipboard.writeText(log.content);
                                                    
                                                    // Show a tooltip
                                                    const tooltip = document.createElement('div');
                                                    tooltip.textContent = 'Copied!';
                                                    tooltip.style.position = 'fixed';
                                                    tooltip.style.background = '#1b2559';
                                                    tooltip.style.color = '#fff';
                                                    tooltip.style.padding = '4px 8px';
                                                    tooltip.style.borderRadius = '4px';
                                                    tooltip.style.fontSize = '12px';
                                                    tooltip.style.zIndex = '1000';
                                                    document.body.appendChild(tooltip);
                                                    
                                                    const rect = event.currentTarget.getBoundingClientRect();
                                                    tooltip.style.top = `${rect.top - 30}px`;
                                                    tooltip.style.left = `${rect.left}px`;
                                                    
                                                    setTimeout(() => {
                                                        document.body.removeChild(tooltip);
                                                    }, 2000);
                                                }}
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    padding: '4px',
                                                    cursor: 'pointer',
                                                    color: '#94A3B8',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: '4px',
                                                    transition: 'all 0.2s ease'
                                                }}
                                                onMouseEnter={(e) => {
                                                    e.target.style.background = '#F1F5F9';
                                                    e.target.style.color = '#64748B';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.background = 'none';
                                                    e.target.style.color = '#94A3B8';
                                                }}
                                                title="Copy content"
                                            >
                                                <i className="bi bi-clipboard"></i>
                                            </button>
                                        </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                )}
            </div>
        );
    };

    // Add a useEffect to handle closing dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            const filterContainers = document.querySelectorAll('.level-filter-container');
            let clickedInsideFilter = false;
            
            filterContainers.forEach(container => {
                if (container.contains(event.target)) {
                    clickedInsideFilter = true;
                }
            });

            if (!clickedInsideFilter) {
                setSyslogsDropdownOpen(false);
                setAuthlogsDropdownOpen(false);
                setApplogsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (error || (isDemoMode && (!logs.length || !logVolumes.length))) {
        return (
            <div className="logs-collection-paused">
                <div className="logs-paused-icon">
                    <i className="bi bi-pause-circle"></i>
                </div>
                <h2>Logs Collection Paused</h2>
                <p>Log collection is currently paused for this service or application.</p>
                <p className="secondary-text">This may be due to scheduled maintenance, high resource usage, or<br/>an administrative decision to reduce log volume.</p>
                <div className="logs-action-buttons">
                    <button className="check-status-btn">
                        <i className="bi bi-info-circle"></i> Check Status
                    </button>
                    <button className="demo-data-btn" onClick={loadDemoData}>
                        <i className="bi bi-display"></i> Load Demo Data
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="log-table-container">
            {loading ? (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading logs...</p>
                </div>
            ) : (
                <>
                    {isDemoMode && (
                        <div className="demo-mode-banner">
                            <span>Demo Mode</span>
                            <button className="close-demo-btn" onClick={closeDemoData}>
                                <i className="bi bi-x-circle"></i> Close Demo
                            </button>
                        </div>
                    )}

                    {/* Search bar and log type selector in one row */}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '24px',
                        marginBottom: '24px',
                        background: '#FFFFFF',
                        padding: '20px',
                        borderRadius: '12px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)'
                    }}>
                        {/* Left side: Search with info button */}
                        <div style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            flex: '1'
                        }}>
                            <div className="syslog-search-container">
                                <i className="bi bi-search" style={{ 
                                    color: '#6825EC',
                                    fontSize: '18px',
                                    marginRight: '12px'
                                }}></i>
                                <input
                                    className="syslog-search-input"
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => onSearchChange(e)}
                                    placeholder="Search across all logs..."
                                />
                                {searchQuery && (
                                    <button
                                        onClick={() => onSearchChange({ target: { value: '' } })}
                                        style={{
                                            background: 'rgba(104, 37, 236, 0.1)',
                                            border: 'none',
                                            padding: '6px 12px',
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            color: '#6825EC',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '4px',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            marginRight: '12px'
                                        }}
                                    >
                                        <i className="bi bi-x"></i>
                                        Clear
                                    </button>
                                )}
                                <div style={{ position: 'relative' }}>
                                    <button
                                        style={{
                                            background: showTooltip ? 'rgba(104, 37, 236, 0.1)' : 'transparent',
                                            border: 'none',
                                            color: '#6825EC',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            padding: '8px',
                                            borderRadius: '8px',
                                            transition: 'all 0.2s ease'
                                        }}
                                        onMouseEnter={() => setShowTooltip(true)}
                                        onMouseLeave={() => setShowTooltip(false)}
                                    >
                                        <i className="bi bi-info" style={{ fontSize: '16px' }}></i>
                                    </button>
                                    {showTooltip && (
                                        <div style={{
                                            position: 'absolute',
                                            top: 'calc(100% + 10px)',
                                            right: '0',
                                            background: '#FFFFFF',
                                            padding: '16px 20px',
                                            borderRadius: '12px',
                                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                            border: '1px solid #E2E8F0',
                                            width: '320px',
                                            zIndex: 1000,
                                            color: '#1E293B',
                                            fontSize: '14px',
                                            lineHeight: '1.6'
                                        }}>
                                            <div style={{ fontWeight: '600', marginBottom: '12px', color: '#1b2559' }}>Search Syntax Guide</div>
                                            <p style={{ margin: '0 0 12px 0' }}>
                                                <strong>AND:</strong> Use to include multiple terms<br/>
                                                Example: 'error AND database'
                                            </p>
                                            <p style={{ margin: '0 0 12px 0' }}>
                                                <strong>OR:</strong> Use to include either of the terms<br/>
                                                Example: 'error OR warning'
                                            </p>
                                            <p style={{ margin: '0' }}>
                                                <strong>NOT:</strong> Use to exclude terms<br/>
                                                Example: 'error NOT database'
                                            </p>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-6px',
                                                right: '10px',
                                                transform: 'rotate(45deg)',
                                                width: '12px',
                                                height: '12px',
                                                background: '#FFFFFF',
                                                border: '1px solid #E2E8F0',
                                                borderRight: 'none',
                                                borderBottom: 'none'
                                            }}></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Right side: Log type selector */}
                        <div style={{
                            display: 'flex',
                            gap: '8px'
                        }}>
                            {[
                                { type: 'all', label: 'All Logs' },
                                { type: 'informational', label: 'Informational' },
                                { type: 'error', label: 'Error' },
                                { type: 'critical', label: 'Critical' }
                            ].map(({ type, label }) => (
                                <button
                                    key={type}
                                    onClick={() => onLogTypeChange(type)}
                                    style={{
                                        padding: '10px 20px',
                                        borderRadius: '20px',
                                        border: 'none',
                                        background: type === logType ? 'rgba(104, 37, 236, 0.1)' : 'transparent',
                                        color: type === logType ? '#6825EC' : '#64748B',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        transition: 'all 0.2s ease',
                                        boxShadow: type === logType ? '0 2px 4px rgba(104, 37, 236, 0.1)' : 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '6px'
                                    }}
                                    onMouseEnter={(e) => {
                                        if (type !== logType) {
                                            e.target.style.background = 'rgba(104, 37, 236, 0.05)';
                                            e.target.style.color = '#6825EC';
                                        }
                                    }}
                                    onMouseLeave={(e) => {
                                        if (type !== logType) {
                                            e.target.style.background = 'transparent';
                                            e.target.style.color = '#64748B';
                                        }
                                    }}
                                >
                                    {label}
                                    <span style={{
                                        fontSize: '12px',
                                        background: type === logType ? '#6825EC' : '#E2E8F0',
                                        color: type === logType ? '#FFFFFF' : '#64748B',
                                        padding: '2px 8px',
                                        borderRadius: '12px',
                                        transition: 'all 0.2s ease'
                                    }}>
                                        {type === 'all' ? filteredLogs.length :
                                         type === 'informational' ? filteredLogs.filter(log => ['info', 'notice', 'debug', 'warning'].includes(log.level)).length :
                                         type === 'error' ? filteredLogs.filter(log => ['error', 'exception'].includes(log.level)).length :
                                         filteredLogs.filter(log => log.level === 'critical').length}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="chart-container" style={{ 
                        width: '100%', 
                        height: '400px', 
                        marginBottom: '15px', 
                        padding: '30px',
                        background: 'white',
                        borderRadius: '16px',
                        boxShadow: '0 4px 20px rgba(104, 37, 236, 0.05)',
                    }}>
                        <div style={{ 
                            marginBottom: '20px', 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            padding: '0 10px'
                        }}>
                            <div style={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: '12px' 
                            }}>
                                <span style={{ 
                                    fontSize: '18px', 
                                    fontWeight: '600', 
                                    color: '#1b2559',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px'
                                }}>
                                    <i className="bi bi-bar-chart-line" style={{ color: '#6825EC' }}></i>
                                    {filteredLogs.length} logs found
                                </span>
                                {(selectedGraphTimestamp || brushActive) && (
                                    <span style={{ 
                                        color: '#6825EC', 
                                        fontSize: '14px',
                                        padding: '4px 12px',
                                        background: 'rgba(104, 37, 236, 0.1)',
                                        borderRadius: '20px'
                                    }}>
                                        {brushActive 
                                            ? `Showing logs from ${new Date(zoomStart).toLocaleTimeString()} to ${new Date(zoomEnd).toLocaleTimeString()}`
                                            : `Showing logs for ${new Date(selectedGraphTimestamp).toLocaleTimeString()}`
                                        }
                                    </span>
                                )}
                            </div>
                            {(selectedGraphTimestamp || brushActive) && (
                                <button
                                    onClick={() => {
                                        setSelectedGraphTimestamp(null);
                                        setBrushActive(false);
                                        setZoomStart(null);
                                        setZoomEnd(null);
                                    }}
                                    style={{
                                        padding: '8px 16px',
                                        background: 'transparent',
                                        color: '#6825EC',
                                        border: '1px solid #6825EC',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        transition: 'all 0.2s ease',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '6px'
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.style.background = '#6825EC';
                                        e.target.style.color = '#ffffff';
                                    }}
                                    onMouseOut={(e) => {
                                        e.target.style.background = 'transparent';
                                        e.target.style.color = '#6825EC';
                                    }}
                                >
                                    <i className="bi bi-x-circle"></i>
                                    Clear Selection
                                </button>
                            )}
                    </div>
                    <ResponsiveContainer width="100%" height={320}>
                            <AreaChart 
                                data={logVolumes}
                            onClick={(e) => {
                                    if (!brushActive && e && e.activePayload) {
                                        const clickedTimestamp = e.activePayload[0].payload.timestamp;
                                        setSelectedGraphTimestamp(prevTimestamp => 
                                            prevTimestamp === clickedTimestamp ? null : clickedTimestamp
                                        );
                                    }
                                }}
                                onMouseDown={(e) => {
                                    if (e && e.activeLabel) {
                                        setIsZooming(true);
                                        setZoomStart(e.activeLabel);
                                    }
                                }}
                                onMouseMove={(e) => {
                                    if (isZooming && e && e.activeLabel) {
                                        setZoomEnd(e.activeLabel);
                                    }
                                }}
                                onMouseUp={() => {
                                    if (isZooming && zoomStart && zoomEnd) {
                                        const start = Math.min(zoomStart, zoomEnd);
                                        const end = Math.max(zoomStart, zoomEnd);
                                        setBrushActive(true);
                                        onZoom(new Date(start), new Date(end));
                                    }
                                    setIsZooming(false);
                                }}
                                style={{ cursor: isZooming ? 'col-resize' : 'pointer' }}
                            >
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#6825EC" stopOpacity={0.3} />
                                        <stop offset="95%" stopColor="#6825EC" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="selectionGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#6825EC" stopOpacity={0.08} />
                                    <stop offset="100%" stopColor="#6825EC" stopOpacity={0.08} />
                                </linearGradient>
                            </defs>
                                <CartesianGrid 
                                    strokeDasharray="3 3" 
                                    vertical={false}
                                    stroke="#E2E8F0"
                                />
                            <XAxis
                                dataKey="timestamp"
                                tickFormatter={(timestamp) => {
                                    const date = new Date(timestamp);
                                        return date.toLocaleTimeString([], { 
                                            hour: '2-digit', 
                                            minute: '2-digit' 
                                        });
                                    }}
                                    stroke="#94A3B8"
                                    tick={{ fontSize: 12 }}
                                    axisLine={{ stroke: '#E2E8F0' }}
                                    tickLine={{ stroke: '#E2E8F0' }}
                            />
                            <YAxis 
                                allowDecimals={false} 
                                    stroke="#94A3B8"
                                    tick={{ fontSize: 12 }}
                                    axisLine={{ stroke: '#E2E8F0' }}
                                    tickLine={{ stroke: '#E2E8F0' }}
                                />
                                <Tooltip
                                    content={({ active, payload }) => {
                                if (active && payload && payload.length) {
                                    const { timestamp, count } = payload[0].payload;
                                    return (
                                        <div style={{
                                                    background: '#FFFFFF',
                                                    border: '1px solid #E2E8F0',
                                                    borderRadius: '8px',
                                                    padding: '12px',
                                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                                                }}>
                                                    <p style={{ 
                                                        margin: '0 0 8px 0',
                                                        color: '#1b2559',
                                                        fontWeight: '600'
                                                    }}>
                                                        {new Date(timestamp).toLocaleString()}
                                                    </p>
                                                    <p style={{ 
                                                        margin: '0',
                                                        color: '#6825EC',
                                                        fontWeight: '500'
                                                    }}>
                                                        {count} logs
                                                    </p>
                                        </div>
                                    );
                                }
                                return null;
                                    }}
                                />
                            <Area
                                type="monotone"
                                dataKey="count"
                                    stroke="#6825EC"
                                    strokeWidth={2}
                                fill="url(#colorUv)"
                                    activeDot={{
                                        r: 6,
                                        fill: '#6825EC',
                                        stroke: '#FFFFFF',
                                        strokeWidth: 2
                                    }}
                                />
                                {isZooming && zoomStart && zoomEnd && (
                                    <>
                                        <ReferenceArea
                                            x1={Math.min(zoomStart, zoomEnd)}
                                            x2={Math.max(zoomStart, zoomEnd)}
                                            fill="url(#selectionGradient)"
                                            fillOpacity={1}
                                            stroke="#6825EC"
                                            strokeWidth={1}
                                            strokeDasharray="4 4"
                                        />
                                        <ReferenceLine
                                            x={Math.min(zoomStart, zoomEnd)}
                                            stroke="#6825EC"
                                            strokeWidth={2}
                                            strokeDasharray="4 4"
                                        />
                                        <ReferenceLine
                                            x={Math.max(zoomStart, zoomEnd)}
                                            stroke="#6825EC"
                                            strokeWidth={2}
                                            strokeDasharray="4 4"
                                        />
                                    </>
                                )}
                                {brushActive && (
                                    <>
                                        <ReferenceArea
                                            x1={zoomStart}
                                            x2={zoomEnd}
                                            fill="url(#selectionGradient)"
                                            fillOpacity={1}
                                            stroke="#6825EC"
                                            strokeWidth={1}
                                            strokeDasharray="4 4"
                                        />
                                        <ReferenceLine
                                            x={zoomStart}
                                            stroke="#6825EC"
                                            strokeWidth={2}
                                            strokeDasharray="4 4"
                                        />
                                        <ReferenceLine
                                            x={zoomEnd}
                                            stroke="#6825EC"
                                            strokeWidth={2}
                                            strokeDasharray="4 4"
                                        />
                                    </>
                                )}
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            {renderLogs(syslogs, showSyslogDetails, toggleSyslogDetails, syslogsContentFilter, setSyslogsContentFilter)}
            {renderLogs(authlogs, showAuthlogDetails, toggleAuthlogDetails, authlogsContentFilter, setAuthlogsContentFilter)}
            {renderLogs(applicationlogs, showApplicationlogDetails, toggleApplicationlogDetails, applogsContentFilter, setApplogsContentFilter)}
                </>
            )}
        </div>
    );

};

export default Syslogtest;



