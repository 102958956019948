import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebarnew';
import Dashboard from './components/Dashboard';
import Metrices from './components/Metrices';
import Landing from './components/Landing';
import Logs from './components/Logs';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './components/HomePage';
import EnviDetect from './components/EnviDetect';
import { BrowserRouter as Router, Route, Navigate, Routes, useLocation } from 'react-router-dom';

// New Component to handle location and content
function AppContent({ toggleSidebar, handleLogout, handleSelect, selectedPage, sidebarOpen, setSidebarOpen, setSelectedPage, selectedMetric, setSelectedMetric }) {
    const location = useLocation(); // Get the current route
    const [isIframeVisible, setIframeVisible] = useState(false);

    const toggleIframe = () => {
        setIframeVisible(!isIframeVisible);
    };
    useEffect(() => {
        // Collapse the sidebar when on the homepage
        if (location.pathname === '/') {
            setSidebarOpen(false);
        }
    }, [location.pathname, setSidebarOpen]);

    return (
        <>
            {location.pathname === '/dashboard' && (
                <>
                    <Header toggleSidebar={toggleSidebar} onLogout={handleLogout} selectedPage={selectedPage} sidebarOpen={sidebarOpen}/>
                    <Sidebar
                        onSelect={handleSelect}
                        sidebarOpen={sidebarOpen}
                        toggleSidebar={toggleSidebar}
                        selectedPage={selectedPage}
                    />
                </>
            )}


<iframe
                    src="https://infia.enviseer.com/envibuddy/envistate/"
                    width="90%"
                    height="70%"
                    style={{
                        maxHeight: '700px',
                        maxWidth: '400px',
                        position: 'fixed',
                        right: '20px',
                        bottom: isIframeVisible ? '80px' : '-100%', // Slide in/out effect
                        zIndex: 999,
                        display: isIframeVisible ? 'block' : 'none', // Toggle visibility
                        border: '2px solid #ddd',
                        borderRadius: '16px',
                        boxShadow: '0 4px 12px rgba(128, 0, 128, 0.3), 0 0 20px rgba(128, 0, 128, 0.2)',
                        backgroundColor: '#fff',
                        transition: 'bottom 0.3s ease',
                    }}
                    title="Embedded Website"
                ></iframe>

                {/* Toggle button */}
                <button
                    id="toggleButton"
                    onClick={toggleIframe}
                    style={{
                        position: 'fixed',
                        right: '20px',
                        bottom: '20px',
                        backgroundColor: isIframeVisible ? '#fff' : 'white',
                        color: isIframeVisible ? '#895CE8' : 'white',
                        border: '2px solid #895CE8',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '60px',
                        height: '60px',
                        fontSize: '24px',
                        boxShadow: '0 4px 12px rgba(128, 0, 128, 0.3), 0 0 20px rgba(128, 0, 128, 0.2)',
                        transition: 'all 0.3s ease',
                    }}
                >
                    {isIframeVisible ? (
                        <i className="bi bi-x" style={{ fontSize: '24px', color: '#895CE8' }}></i>
                    ) : (
                        <i className="bi bi-chat-dots" style={{ fontSize: '24px', color: 'white' }}></i>
                    )}
                </button>

<main
  id="main"
  className={`main ${location.pathname === '/dashboard' ? (sidebarOpen ? 'sidebar-open' : 'sidebar-collapsed') : ''}`}
  style={location.pathname === '/' ? { margin: '0' } : {}}
>
                <Routes>
                    <Route path="/" element={<HomePage handleLogout={handleLogout} />} />
                    <Route
                        path="/dashboard"
                        element={
                            selectedPage === 'analytics' ? (
                                <Landing sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                            ) : selectedPage === 'dashboard' ? (
                                <Dashboard sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                            ) : selectedPage === 'enviDetect' ? (
                                <EnviDetect sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                            ) : selectedPage === 'metrics' ? (
                                <Metrices selectedMetric={selectedMetric} sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : selectedPage === 'logs' ? (
                                <Logs sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : null
                        }
                    />
                    <Route path="/login" element={<Navigate to="/" />} />
                </Routes>
            </main>
        </>
    );
}

function App() {
    const [selectedPage, setSelectedPage] = useState('analytics'); // Default to 'dashboard'
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [selectedMetric, setSelectedMetric] = useState('CPU');
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state

    useEffect(() => {
        // Check if the token is present to handle authentication
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        // Update a CSS variable based on sidebar state
        document.documentElement.style.setProperty('--sidebar-width', sidebarOpen ? '225px' : '80px');
    }, [sidebarOpen]);

    const handleSelect = (page, metric) => {
        setSelectedPage(page);
        setSelectedMetric(metric);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLogin = (token) => {
        // Save token and update auth state
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        // Remove token and update auth state
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
    };

    if (!isAuthenticated) {
        return (
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/login" element={<Login onLogin={handleLogin} />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                </div>
            </Router>
        );
    }

    return (
        <Router>
            <AppContent
                toggleSidebar={toggleSidebar}
                handleLogout={handleLogout}
                handleSelect={handleSelect}
                selectedPage={selectedPage}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                setSelectedPage={setSelectedPage}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
            />
        </Router>
    );
}

export default App;
