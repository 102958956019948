import React from 'react';
import './Logo.css';

function Logo({ sidebarOpen }) {  // Receive sidebarOpen as a prop
    return (
        <div className="logo-container">
            <a href="/dashboard" className="logo">
                <img 
                src = {sidebarOpen ? "/Logo2.png" : "/Logo.png"}
                alt="Enviseer Logo" className="logo-img" />
                {sidebarOpen && <span className="logo-text">ENVISEER</span>}
            </a>
        </div>
    );
}

export default Logo;
