import React, { useEffect, useState, useCallback } from "react";
import styles from "./APMMonitoring.module.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Traces from "./Traces";
import Metrics from "./Metrics";
import APMLogs from "./APMLogs";
import CustomDropdown from "./CustomDropdown";

const BarChart = ({ data, title }) => {
  return (
    <div className={styles.barchartContainer}>
      <h3 className={styles.chartTitle}>{title}</h3>
      <div className={styles.barChart}>
        {data.map(({ cluster, enviscore }) => (
          <div key={cluster} className={styles.barContainer}>
            <div
              className={styles.bar}
              style={{ height: `${enviscore / 10}px` }}
            ></div>
            <div className={styles.barLabel}>{cluster}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const APMMonitoring = ({sidebarOpen, clustersWithServers}) => {
      const [isMounted, setIsMounted] = useState(false);
      const [error, setError] = useState(null);
      const [loading, setLoading] = useState(false);
      const [selectedDataSource, setSelectedDataSource] = useState(
        clustersWithServers && Object.keys(clustersWithServers).length > 0
          ? Object.keys(clustersWithServers)[0]
          : null // Provide a fallback if clustersWithServers is empty or undefined
      );
      const [services, setServices] = useState([]);
      const [startTime, setStartTime] = useState(null);
      const [endTime, setEndTime] = useState(null);
      const [lastDuration, setLastDuration] = useState('5 minutes');
       const [errorMessage, setErrorMessage] = useState('');
      const [selectedServices, setSelectedServices] = useState('');
      const [refreshKey, setRefreshKey] = useState(0);
      // Add new state for managing tabs
      const [activeTab, setActiveTab] = useState('traces');


      const dataSources = Object.keys(clustersWithServers ?? {});
      ;


       useEffect(() => {
          const currentTime = new Date();
          const tenMinutesAgo = new Date(currentTime.getTime() - 5 * 60000);
          setEndTime(currentTime);
          setStartTime(tenMinutesAgo);
          fetchServices(selectedDataSource);
      }, []);
  

      const fetchServices = useCallback( async(clusterName) => {
            
        function getCookie(name) {
              const value = `; ${document.cookie}`;
              const parts = value.split(`; ${name}=`);
              if (parts.length === 2) return parts.pop().split(';').shift();
          }
          
          const token = getCookie('token');
          const url = `https://infia.enviseer.com/api/apm/services`;
        setLoading(true);
        setError(null); // Reset error state
        const payload = {
            cluster_name: clusterName,
          };

      try {
          const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`, // Adding the Authorization header
                  'Accept': 'application/json'
              },
              body: JSON.stringify(payload),
          });
          if (!response.ok) {
              if (response.status === 401) {
                  localStorage.clear();
                   onLogout();
              } else {
                  throw new Error(`HTTP error! Status: ${response.status}`);
              }
          }
          const data = await response.json();
          setServices(Array.isArray(data.services) ? data.services : []);
          setSelectedServices(Array.isArray(data.services) ? data.services[0] : '')
      } catch (error) {
          console.error("Failed to fetch clusters:", error);
          setError(error.message);
          setServices('');
      } finally {
          setLoading(false);
      }
  }, []);

      const handleDataSourceChange = (event) => {
        const selected = event.target.value;
        setSelectedDataSource(selected);
        setSelectedServices('');
        fetchServices(selected);
    };

    const handleStartTimeChange = (selectedStartTime) => {
        const currentTime = new Date();
    
        if (selectedStartTime > currentTime) {
            setErrorMessage('Start time cannot be in the future. Setting start and end times to the current time.');
            setStartTime(currentTime);
            setEndTime(currentTime);
            return;
        }
    
        setStartTime(selectedStartTime);
        
        if (!endTime || selectedStartTime > endTime) {
            setEndTime(selectedStartTime);
        }
    
        setErrorMessage('');
    };
    
    

    const handleEndTimeChange = (selectedEndTime) => {

        const currentTime = new Date();
      
        if (!startTime) {
          setErrorMessage('Please set a start time first.');
          return;
        }
      
        // Normalize dates for accurate comparison (removes time component)
        const startDateOnly = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
        const endDateOnly = new Date(selectedEndTime.getFullYear(), selectedEndTime.getMonth(), selectedEndTime.getDate());
      
        if (endDateOnly < startDateOnly) {
          setErrorMessage('End date should be later than or equal to the start date.');
          return;
        }
      
        // Same date, check time comparison
        if (endDateOnly.getTime() === startDateOnly.getTime() && selectedEndTime <= startTime) {
          setErrorMessage('End time should be later than start time.');
          return;
        }
      
        if (selectedEndTime > currentTime) {
          setErrorMessage('End time cannot be in the future. Setting end time to the current time.');
          setEndTime(currentTime);
        } else {
          setErrorMessage('');  // Clear any existing error messages
          setEndTime(selectedEndTime);
        }
      };

  const filterPassedTime = (time) => {
      const currentTime = new Date();
      const selectedTime = new Date(time);
      return currentTime.getTime() >= selectedTime.getTime();
  };
  
  const handleLastDurationChange = (event) => {
      const selectedDuration = event.target.value;
      setLastDuration(selectedDuration);

      const currentTime = new Date();
      let newStartTime = null;
      let newEndTime = currentTime;

      switch (selectedDuration) {
          case '5 minutes':
              newStartTime = new Date(currentTime.getTime() - 5 * 60000);
              break;
          case '10 minutes':
              newStartTime = new Date(currentTime.getTime() - 10 * 60000);
              break;
          case '30 minutes':
              newStartTime = new Date(currentTime.getTime() - 30 * 60000);
              break;
          case '1 hour':
              newStartTime = new Date(currentTime.getTime() - 60 * 60000);
              break;
          case '2 hours':
              newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
              break;
          case '4 hours':
              newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
              break;
          case '6 hours':
              newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
              break;
          case '12 hours':
              newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
              break;
          case '1 day':
              newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
              break;
          case '3 days':
              newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
              break;
          case '1 week':
              newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
              break;
          case 'custom':
              newStartTime = null;
              newEndTime = null;
              break;
          default:
              newStartTime = startTime;
              newEndTime = endTime;
              break;
      }

      setStartTime(newStartTime);
      setEndTime(newEndTime);
  };


  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return <div>Loading...</div>;

  const handleRefresh = () => {
    // Get the current time - Reassigned in the case of custom
    let currentTime = new Date();

    // Calculate the new start time based on the selected duration
    let newStartTime = null;

    switch (lastDuration) {
        case '5 minutes':
            newStartTime = new Date(currentTime.getTime() - 5 * 60000);
            break;
        case '10 minutes':
            newStartTime = new Date(currentTime.getTime() - 10 * 60000);
            break;
        case '30 minutes':
            newStartTime = new Date(currentTime.getTime() - 30 * 60000);
            break;
        case '1 hour':
            newStartTime = new Date(currentTime.getTime() - 60 * 60000);
            break;
        case '2 hours':
            newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
            break;
        case '4 hours':
            newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
            break;
        case '6 hours':
            newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
            break;
        case '12 hours':
            newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
            break;
        case '1 day':
            newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
            break;
        case '3 days':
            newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
            break;
        case '1 week':
            newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
            break;
        case 'custom':
            // If custom is selected, don't update startTime or endTime
            newStartTime = startTime;
            currentTime = endTime;
            break;
        default:
            newStartTime = startTime;
            currentTime = endTime;
            break;
    }

    // Update the state with the new times
    setStartTime(newStartTime);
    setEndTime(currentTime);

    // Increment refreshKey to trigger re-fetching of data
    setRefreshKey(prevKey => prevKey + 1);
};


  return (
    <div className={styles.apmDashboard}>
      <div className="dropdowns" style={{ width: sidebarOpen ? '90%' : '96%' }}>
                    <div className="dropdown-contents">

                        <div className="dropdown">
                            <CustomDropdown
                                label="Clusters"
                                options={Array.isArray(dataSources) && dataSources.map(source => ({ value: source, label: source }))}
                                value={selectedDataSource}
                                onChange={(event) => handleDataSourceChange(event)}
                            />
                        </div>


                        <div className="dropdown">
                            <CustomDropdown
                                label="Duration"
                                options={[
                                    { value: '5 minutes', label: '5 minutes' },
                                    { value: '10 minutes', label: '10 minutes' },
                                    { value: '30 minutes', label: '30 minutes' },
                                    { value: '1 hour', label: '1 hour' },
                                    { value: '2 hours', label: '2 hours' },
                                    { value: '4 hours', label: '4 hours' },
                                    { value: '6 hours', label: '6 hours' },
                                    { value: '12 hours', label: '12 hours' },
                                    { value: '1 day', label: '1 day' },
                                    { value: '3 days', label: '3 days' },
                                    { value: '1 week', label: '1 week' },
                                    { value: 'custom', label: 'custom' }
                                ]}
                                value={lastDuration}
                                onChange={(event) => handleLastDurationChange(event)}
                            />
                        </div>

                        {lastDuration === 'custom' && (
                            <>
                                <div className="dropdown">
                                    <DatePicker
                                        selected={startTime}
                                        onChange={handleStartTimeChange}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        timeFormat="HH:mm:ss"
                                        timeCaption="Time"
                                        placeholderText="Select start time"
                                        className={`react-datepicker__input-container ${startTime ? 'selected' : ''}`}
                                        timeIntervals={5}
                                        maxDate={new Date()}
                                        filterTime={filterPassedTime}
                                        dayClassName={(date) => {
                                            if (date.getTime() > new Date().getTime()) {
                                                return 'react-datepicker__day--disabled';
                                            }
                                            return '';
                                        }}
                                        timeClassName={(time) => {
                                            if (new Date(time).getTime() > new Date().getTime()) {
                                                return 'react-datepicker__time-list-item--disabled';
                                            }
                                            return '';
                                        }}
                                    />
                                </div>
                                <div className="dropdown">
                                    <DatePicker
                                        selected={endTime}
                                        onChange={handleEndTimeChange}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        timeFormat="HH:mm:ss"
                                        timeCaption="Time"
                                        placeholderText="Select end time"
                                        className={`react-datepicker__input-container ${endTime ? 'selected' : ''}`}
                                        timeIntervals={5}
                                        maxDate={new Date()}
                                        filterTime={filterPassedTime}
                                        dayClassName={(date) => {
                                            if (date.getTime() > new Date().getTime()) {
                                                return 'react-datepicker__day--disabled';
                                            }
                                            return '';
                                        }}
                                        timeClassName={(time) => {
                                            if (new Date(time).getTime() > new Date().getTime()) {
                                                return 'react-datepicker__time-list-item--disabled';
                                            }
                                            return '';
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        <div>
                            <button 
                                onClick={handleRefresh} 
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#6825EC',
                                    border: 'none',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    transition: 'all 0.2s ease',
                                    boxShadow: '0 2px 4px rgba(104, 37, 236, 0.2)'
                                }}
                            >
                                <i 
                                    className="bi bi-arrow-clockwise" 
                                    style={{ 
                                        color: '#FFFFFF',
                                        fontSize: '20px'
                                    }}
                                />
                            </button>
                        </div>

                    </div>
      </div>
      
      {/* Add Tabs UI */}
      <div className={styles.tabsContainer}>
        <div 
          className={`${styles.tab} ${activeTab === 'traces' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('traces')}
        >
          Traces
        </div>
        <div 
          className={`${styles.tab} ${activeTab === 'metrics' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('metrics')}
        >
          Metrics
        </div>
        <div 
          className={`${styles.tab} ${activeTab === 'logs' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('logs')}
        >
          Logs
        </div>
      </div>
      
      {/* Content based on selected tab */}
      {activeTab === 'traces' && (
        <div className={styles.tracesContainer}>
          <div className={styles.tracesBox}>
            <Traces 
              key={refreshKey}
              selectedDataSource={selectedDataSource}
              services={services||[]}
              startTime={startTime}
              endTime={endTime}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
            />
          </div>
        </div>
      )}
      
      {activeTab === 'metrics' && (
        <div className={styles.metricsContainer}>
          <div className={styles.metricsBox}>
            <Metrics
              key={refreshKey}
              selectedDataSource={selectedDataSource}
              services={services||[]}
              startTime={startTime}
              endTime={endTime}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
            />
          </div>
        </div>
      )}

      {activeTab === 'logs' && (
        <div className={styles.logsContainer}>
          <div className={styles.logsBox}>
            <APMLogs
              key={refreshKey}
              selectedDataSource={selectedDataSource}
              services={services||[]}
              startTime={startTime}
              endTime={endTime}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default APMMonitoring;
