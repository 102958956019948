import React, { useState, useEffect, useCallback, useRef } from "react";
import "./Alerts.css";
import { Bar } from "react-chartjs-2";
import CustomDropdown from "./CustomDropdown";
import { SearchableDropdown } from "./features/utils";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from "chart.js";
import MultiSelectorFilter from './features/MultiSelectorFilter';
import RootCauseAnalysis from './RootCauseAnalysis';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const Alerts = ({ sidebarOpen, onLogout }) => {
  const [activeTab, setActiveTab] = useState("All Alerts");
  const [dataSources, setDataSources] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [lastDuration, setLastDuration] = useState('1 day');
  const [showPopup, setShowPopup] = useState(false);
  const [editData, setEditData] = useState({
    alertName: "",
    integration: "",
    metric: "",
    operator: "",
    condition: "",
    clusterName: "",
    serverName: "",
    severity: ""
  });
  const [methodData, setMethodData] = useState({
    alertName: "",
    integration: "",
    metric: "",
    operator: "",
    condition: "",
    clusterName: "",
    serverName: "",
    severity: ""
  });
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filter, setFilter] = useState({ severity: "", server: "", metric: "", integration: "", alertName: "" });
  const [methods, setMethods] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [alertData, setAlertData] = useState([]);
  const [alertCount, setAlertCount] = useState([]);
  const [methodFilter, setMethodFilter] = useState({
    cluster: "",
    server: "",
    metric: "",
    integration: "",
    severity: "",
    status: "",
    alertName: ""
});
  const [integration, setIntegration] = useState("");
const [operator, setOperator] = useState("");
const [loading, setLoading] = useState("");
const [error, setError] = useState("");
const [servers, setServers] = useState([]);
const [metrics, setMetrics] = useState([]);
const [currentStep, setCurrentStep] = useState("methodInfo"); // Current tab/step
const [progress, setProgress] = useState(0); // Progress bar percentage
const [isSubmitted, setIsSubmitted] = useState(false); // Success message
const [isHistoryPopupOpen, setIsHistoryPopupOpen] = useState(false);
const [refreshKey, setRefreshKey] = useState(0);
const [selectedLevels, setSelectedLevels] = useState([]);
const [selectedSeverities, setSelectedSeverities] = useState([]);
const [selectedMetrics, setSelectedMetrics] = useState([]);
const [selectedIntegrations, setSelectedIntegrations] = useState([]);
const [selectedStatuses, setSelectedStatuses] = useState([]);

// Update the dropdown states to have separate states for alerts and methods
const [dropdownStates, setDropdownStates] = useState({
    // Alert section dropdowns
    alertSeverity: false,
    alertMetric: false,
    alertIntegration: false,
    // Method section dropdowns
    methodSeverity: false,
    methodMetric: false,
    methodIntegration: false,
    methodStatus: false
});

const dropdownRefs = {
    // Alert section refs
    alertSeverity: useRef(null),
    alertMetric: useRef(null),
    alertIntegration: useRef(null),
    // Method section refs
    methodSeverity: useRef(null),
    methodMetric: useRef(null),
    methodIntegration: useRef(null),
    methodStatus: useRef(null)
};

// Add this with other state variables at the top of the component
const [searchQuery, setSearchQuery] = useState('');
const [selectedTableServers, setSelectedTableServers] = useState([]);
const [selectedTableMetrics, setSelectedTableMetrics] = useState([]);
const [selectedTableIntegrations, setSelectedTableIntegrations] = useState([]);
const [tableSearchQuery, setTableSearchQuery] = useState('');

// RCA related states
const [showRcaModal, setShowRcaModal] = useState(false);
const [selectedAlertId, setSelectedAlertId] = useState(null);

// Add these state variables at the top with other useState declarations
const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Add selectedTableSeverities state
  const [selectedTableSeverities, setSelectedTableSeverities] = useState([]);

useEffect(() => {
          fetchClusters();
      }, []);
useEffect(() => {
            const currentTime = new Date();
            const tenMinutesAgo = new Date(currentTime.getTime() - 5 * 60000);
            setEndTime(currentTime);
            setStartTime(tenMinutesAgo);
        }, []);

useEffect(() => {
    const handleClickOutside = (event) => {
        Object.entries(dropdownRefs).forEach(([key, ref]) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setDropdownStates(prev => ({ ...prev, [key]: false }));
            }
        });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
        }, []);

  const handleDataSourceChange = (event) => {
    const selected = event.target.value;
    setSelectedDataSource(selected);
};

const handleStartTimeChange = (selectedStartTime) => {
  const currentTime = new Date();

  if (selectedStartTime > currentTime) {
      setErrorMessage('Start time cannot be in the future. Setting start and end times to the current time.');
      setStartTime(currentTime);
      setEndTime(currentTime);
      return;
  }

  setStartTime(selectedStartTime);
  
  if (!endTime || selectedStartTime > endTime) {
      setEndTime(selectedStartTime);
  }

  setErrorMessage('');
};



const handleEndTimeChange = (selectedEndTime) => {

  const currentTime = new Date();

  if (!startTime) {
    setErrorMessage('Please set a start time first.');
    return;
  }

  // Normalize dates for accurate comparison (removes time component)
  const startDateOnly = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
  const endDateOnly = new Date(selectedEndTime.getFullYear(), selectedEndTime.getMonth(), selectedEndTime.getDate());

  if (endDateOnly < startDateOnly) {
    setErrorMessage('End date should be later than or equal to the start date.');
    return;
  }

  // Same date, check time comparison
  if (endDateOnly.getTime() === startDateOnly.getTime() && selectedEndTime <= startTime) {
    setErrorMessage('End time should be later than start time.');
    return;
  }

  if (selectedEndTime > currentTime) {
    setErrorMessage('End time cannot be in the future. Setting end time to the current time.');
    setEndTime(currentTime);
  } else {
    setErrorMessage('');  // Clear any existing error messages
    setEndTime(selectedEndTime);
  }
};

const filterPassedTime = (time) => {
  const currentTime = new Date();
  const selectedTime = new Date(time);
  return currentTime.getTime() >= selectedTime.getTime();
};

  const handleLastDurationChange = (event) => {
    const selectedDuration = event.target.value;
    setLastDuration(selectedDuration);

    const currentTime = new Date();
    let newStartTime = null;
    let newEndTime = currentTime;

    switch (selectedDuration) {
        case '5 minutes':
            newStartTime = new Date(currentTime.getTime() - 5 * 60000);
            break;
        case '10 minutes':
            newStartTime = new Date(currentTime.getTime() - 10 * 60000);
            break;
        case '30 minutes':
            newStartTime = new Date(currentTime.getTime() - 30 * 60000);
            break;
        case '1 hour':
            newStartTime = new Date(currentTime.getTime() - 60 * 60000);
            break;
        case '2 hours':
            newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
            break;
        case '4 hours':
            newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
            break;
        case '6 hours':
            newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
            break;
        case '12 hours':
            newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
            break;
        case '1 day':
            newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
            break;
        case '3 days':
            newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
            break;
        case '1 week':
            newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
            break;
        case 'custom':
            newStartTime = null;
            newEndTime = null;
            break;
        default:
            newStartTime = startTime;
            newEndTime = endTime;
            break;
    }

    setStartTime(newStartTime);
    setEndTime(newEndTime);
};

  const handleRefresh = () => {
    // Get the current time - Reassigned in the case of custom
    let currentTime = new Date();

    // Calculate the new start time based on the selected duration
    let newStartTime = null;

    switch (lastDuration) {
        case '5 minutes':
            newStartTime = new Date(currentTime.getTime() - 5 * 60000);
            break;
        case '10 minutes':
            newStartTime = new Date(currentTime.getTime() - 10 * 60000);
            break;
        case '30 minutes':
            newStartTime = new Date(currentTime.getTime() - 30 * 60000);
            break;
        case '1 hour':
            newStartTime = new Date(currentTime.getTime() - 60 * 60000);
            break;
        case '2 hours':
            newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
            break;
        case '4 hours':
            newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
            break;
        case '6 hours':
            newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
            break;
        case '12 hours':
            newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
            break;
        case '1 day':
            newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
            break;
        case '3 days':
            newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
            break;
        case '1 week':
            newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
            break;
        case 'custom':
            // If custom is selected, don't update startTime or endTime
            newStartTime = startTime;
            currentTime = endTime;
            break;
        default:
            newStartTime = startTime;
            currentTime = endTime;
            break;
    }

    // Update the state with the new times
    setStartTime(newStartTime);
    setEndTime(currentTime);

    // Increment refreshKey to trigger re-fetching of data
    setRefreshKey(prevKey => prevKey + 1);
};

const handleTabClick = (step) => {
  setCurrentStep(step);
  if (step === "methodInfo") setProgress(0);
  else if (step === "condition") setProgress(33);
  else if (step === "integration") setProgress(66);
};

const handleNext = () => {
  if (currentStep === "methodInfo") {
    if (!methodData.alertName || !methodData.clusterName || !methodData.serverName.length) {
      alert("Please fill in all fields in this step before proceeding.");
      return;
    }
    setCurrentStep("condition");
    setProgress(33);
  } else if (currentStep === "condition") {
    if (!methodData.metric || !methodData.operator || !methodData.condition || !methodData.severity) {
      alert("Please fill in all fields in this step before proceeding.");
      return;
    }
    setCurrentStep("integration");
    setProgress(66);
  }
};



// Cancel action
const handleCancel = () => {
  setCurrentStep("methodInfo");
  setProgress(0);
  setIsSubmitted(false);
  setShowPopup(false);
  setIsPopupOpen(false);
  setMethodData({
    alertName: "",
    integration: "",
    metric: "",
    operator: "",
    condition: "",
    clusterName: "",
    serverName: [],
    severity: ""
  });
};
 
  const severity = ["Critical", "High", "Low"];
  const operators = ["<", ">=", "==", ">"];
  const statusA = ['Enable', 'Disable'];
 

  const fetchAlerts = useCallback(async () => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
    const token = getCookie('token');
    //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

  
    if (!token) {
      localStorage.clear();
      onLogout();
    }
  
    const url = "https://infia.enviseer.com/api/alerts/past";
    const payload = { cluster: selectedDataSource };
  
    setLoading(true);
    setError(null);

    setAlertData([]);
    setFilter({ severity: "", server: "", metric: "", integration: "", alertName: "" });

  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      let data = await response.json();
      
      // Ensure data is always an array
      if (!data || !Array.isArray(data)) {
        data = []; // Prevent null-related errors
      }
  
      const transformedMethods = data.map((item) => ({
        id: item.id,
        alertId: item.alert_id,
        clusterName: item.cluster,
        alertName: item.message,
        condition: `${item.metric_name} = ${item.current_value}`,
        severity: item.severity,
        integration: item.target_channels ? item.target_channels.join(", ") : "N/A",
        serverName: item.host || "Unknown",
        metric: item.metric_name,
        triggered_at: item.triggered_at,
      }));
  
      setAlertData(transformedMethods);
    } catch (error) {
      console.error("Failed to fetch alerts:", error);
      setError(error.message);
      setAlertData([]); // Ensure it's reset
    } finally {
      setLoading(false);
    }
  }, [selectedDataSource]);
  
  
  const fetchMethods = useCallback(async () => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
    const token = getCookie('token');
    //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

  
    if (!token) {
      localStorage.clear();
      onLogout();
    }
  
    const url = "https://infia.enviseer.com/api/alerts/query";
    const payload = { cluster: selectedDataSource };
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      let data = await response.json();
      
      if (!data || !Array.isArray(data)) {
        data = []; // Prevent null-related errors
      }
  
      const transformedMethods = data.map((item) => ({
        id: item.id,
        clusterId: item.cluster,
        clusterName: item.cluster,
        alertName: item.message,
        condition: `${item.metric_name} ${item.operator} ${item.threshold}`,
        severity: item.severity,
        integration: item.target_channels ? item.target_channels.join(", ") : "N/A",
        serverName: item.servers && item.servers.length > 0 ? item.servers.join(", ") : "No Servers",
        metric: item.metric_name,
        updated_at: item.updated_at,
        status: item.enabled ? "Enable" : "Disable",
      }));
  
      setMethods(transformedMethods);
    } catch (error) {
      console.error("Failed to fetch methods:", error);
      setError(error.message);
      setMethods([]); // Ensure it's reset
    } finally {
      setLoading(false);
    }
  }, [selectedDataSource]);
  
  
  const fetchAlertsCount = useCallback(async () => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
    const token = getCookie('token');
    //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

  
    if (!token) {
      localStorage.clear();
      onLogout();
    }
  
    const url = "https://infia.enviseer.com/api/alerts/daily-summary";
    const payload = { cluster: selectedDataSource, host: '*' };
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      let data = await response.json();
  
      if (!data || typeof data !== 'object') {
        data = {}; // Prevent null-related errors
      }
  
      processAlertData(data);
      setAlertCount(data);
    } catch (error) {
      console.error("Failed to fetch alertCount:", error);
      setError(error.message);
      setAlertCount(null); // Ensure it's reset
      processAlertData(null);
    } finally {
      setLoading(false);
    }
  }, [selectedDataSource]);
  

  const processAlertData = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      setChartData({
        labels: [],
        datasets: [],
      });
      return;
    }
  
    const last30Days = getLast30Days();
    const alertMap = {};
  
    // Initialize structure with default values
    last30Days.forEach((date) => {
      alertMap[date] = { Moderate: 0, High: 0, Critical: 0 };
    });
  
    // Populate alertMap with actual data
    data.forEach((item) => {
      if (!item || !item.alert_date || !item.severity || typeof item.alert_count !== "number") {
        return; // Skip invalid entries
      }
  
      const date = item.alert_date.split("T")[0];
      const severity = item.severity.toLowerCase();
  
      if (alertMap[date]) {
        if (severity === "low" || severity === "") {
          alertMap[date].Moderate += item.alert_count;
        } else if (severity === "high") {
          alertMap[date].High += item.alert_count;
        } else if (severity === "critical") {
          alertMap[date].Critical += item.alert_count;
        }
      }
    });
  
    // Convert to chart-friendly format
    const moderateAlerts = last30Days.map((date) => alertMap[date].Moderate);
    const highAlerts = last30Days.map((date) => alertMap[date].High);
    const criticalAlerts = last30Days.map((date) => alertMap[date].Critical);
  
    setChartData({
      labels: last30Days,
      datasets: [
        {
          label: "Moderate Alerts",
          data: moderateAlerts,
          backgroundColor: "rgba(253, 224, 71, 0.85)",
          hoverBackgroundColor: "rgba(253, 224, 71, 1)",
          borderRadius: 6,
          borderSkipped: false,
          barPercentage: 0.7,
          categoryPercentage: 0.8
        },
        {
          label: "High Alerts",
          data: highAlerts,
          backgroundColor: "rgba(249, 115, 22, 0.85)",
          hoverBackgroundColor: "rgba(249, 115, 22, 1)",
          borderRadius: 6,
          borderSkipped: false,
          barPercentage: 0.7,
          categoryPercentage: 0.8
        },
        {
          label: "Critical Alerts",
          data: criticalAlerts,
          backgroundColor: "rgba(239, 68, 68, 0.85)",
          hoverBackgroundColor: "rgba(239, 68, 68, 1)",
          borderRadius: 6,
          borderSkipped: false,
          barPercentage: 0.7,
          categoryPercentage: 0.8
        },
      ],
    });
  };
  const processAlertHistoryData = () => {
    if (!alertCount || !Array.isArray(alertCount) || alertCount.length === 0) {
      return []; // Return an empty array if alertCount is null/invalid
    }
  
    const alertMap = {};
  
    alertCount.forEach((item) => {
      if (!item || !item.alert_date || !item.severity || typeof item.alert_count !== "number") {
        return; // Skip invalid entries
      }
  
      const date = item.alert_date?.split("T")[0];
      const severity = item.severity.toLowerCase();
  
      if (!alertMap[date]) {
        alertMap[date] = { date, criticalAlerts: 0, highAlerts: 0, moderateAlerts: 0 };
      }
  
      if (severity === "low" || severity === "") {
        alertMap[date].moderateAlerts += item.alert_count;
      } else if (severity === "high") {
        alertMap[date].highAlerts += item.alert_count;
      } else if (severity === "critical") {
        alertMap[date].criticalAlerts += item.alert_count;
      }
    });
  
    // Convert object to sorted array (latest first)
    return Object.values(alertMap).sort((a, b) => new Date(b.date) - new Date(a.date));
  };
  


   useEffect(() => {
          function getCookie(name) {
              const value = `; ${document.cookie}`;
              const parts = value.split(`; ${name}=`);
              if (parts.length === 2) return parts.pop().split(';').shift();
          }
          const token = getCookie('token');
          //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

    if (!token) {
              localStorage.clear();
              onLogout();
          }
    if (activeTab === 'All Alerts') {
            fetchAlertsCount();
            fetchAlerts();
    } else {
            fetchMethods("*");
          }
          
          
      }, [activeTab, selectedDataSource]);
  

      const handleClusterSelect = (cluster) => {
        setMethodData({ ...methodData, clusterName: cluster, serverName: [] });
        if (cluster) {
          fetchServers(cluster);
        } else {
          setServers([]);
          setMethodData({ ...methodData, serverName: [] });
        }
      };
      const handleServerSelect = (server) => {
        // Create serverOptions from servers state
        const serverOptions = servers.map(server => ({ 
          label: server, 
          value: server 
        }));
        
        if (server === "All") {
          const allServerValues = serverOptions
            .filter((option) => option.value !== "All")
            .map((option) => option.value);
          setMethodData({ ...methodData, serverName: allServerValues });
        } else if (!methodData.serverName.includes(server)) {
          setMethodData({ ...methodData, serverName: [...methodData.serverName, server] });
        }
        
        // Use methodData.clusterName and the selected server for fetching metrics
        if (methodData.clusterName && server && server !== "All") {
          fetchMetrics(server);
        }
      };

      // Also update the fetchMetrics function to accept a server parameter
      const fetchMetrics = async (serverName) => {
        function getCookie(name) {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
        }
        const token = getCookie('token');
        //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

        try {
          setLoading(true);
          const response = await fetch(
            `https://infia.enviseer.com/api/clusters/${methodData.clusterName}/hosts/${serverName}/metrics`,
            {
              headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json',
              },
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();
          setMetrics(data.metrics || []);
        } catch (err) {
          setError('Failed to fetch metrics');
        } finally {
          setLoading(false);
        }
      };

      const handleSeveritySelect = (severity) => {
        setMethodData(prevState => ({ ...prevState, severity: severity }));
      };
      
      
  
      const fetchClusters = useCallback(async () => {
          function getCookie(name) {
                  const value = `; ${document.cookie}`;
                  const parts = value.split(`; ${name}=`);
                  if (parts.length === 2) return parts.pop().split(';').shift();
              }
              const token = getCookie('token');
              //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

              const username = getCookie('username');
              //const username = 'oyeiamgroot@gmail.com';
              const email = decodeURIComponent(username);
              const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
              //console.log("Cluster", url);
          setLoading(true);
          setError(null); // Reset error state
  
          try {
              const response = await fetch(url, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`, // Adding the Authorization header
                      'Accept': 'application/json'
                  }
              });
              if (!response.ok) {
                  if (response.status === 401) {
                      localStorage.clear();
                      onLogout();
                  } else {
                      throw new Error(`HTTP error! Status: ${response.status}`);
                  }
              }
              const data = await response.json();
             if (data.length > 0) {
              setDataSources(data);
              setSelectedDataSource(data[0])
              setServers([]); // Clear servers when clusters are fetched;
             } 
          } catch (error) {
              //console.log("Response Status:", error);
              setDataSources([]);
              if (dataSources.length === 0) {
        alert('Please contact your administrator. No clusters available.');
              }
              console.error("Failed to fetch clusters:", error);
              setError(error.message);
          } finally {
              setLoading(false);
          }
      }, []);

      useEffect(() => {
        fetchClusters();
    }, [fetchClusters]);

      const fetchServers = useCallback(
        async (clusterId) => {
          function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        const token = getCookie('token');
        //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

        const username = getCookie('username');
        const email = decodeURIComponent(username);
          const url = `https://infia.enviseer.com/api/clusters/${clusterId}/hostnames`;
          setLoading(true);
          setError(null);
    
          try {
            const response = await fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
              },
            });
    
            if (!response.ok) {
              if (response.status === 401) {
                localStorage.clear();
                onLogout();
              } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
            }
    
            const data = await response.json();
            setServers(["All", ...data]); // Add "All" as the first option
          } catch (error) {
            console.error("Failed to fetch servers:", error);
            setError(error.message);
          } finally {
            setLoading(false);
          }
        },
        []
      );


      
    


  const handleEdit = (method) => {
    setEditData(method);
    fetchServers(method.clusterName);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setEditData(null);
  };

  const handleSaveChanges = async (e, method = null) => {
    if (e) e.preventDefault(); // Prevent default form submission if event exists
  
    const dataToSave = method ? method : editData;
    
    // Ensure required fields are filled
    if (!dataToSave.clusterName || !dataToSave.serverName || !dataToSave.metric || !dataToSave.condition || !dataToSave.severity || !dataToSave.integration) {
      alert("Please fill in all required fields.");
      return;
    }

    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
  
    const token = getCookie('token');
    //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

  
    const [metric, operator, threshold] = dataToSave.condition.split(" ");

    const payload = {
      alert_id: dataToSave.id,
      cluster: dataToSave.clusterName,
      metric_name: dataToSave.metric,
      operator: operator || ">=", // Default operator if missing
      threshold: parseFloat(threshold) || 0, // Ensure threshold is a number
      message: dataToSave.alertName,
      target_channels: dataToSave.integration.split(", "), // Handle multiple integrations
      severity: dataToSave.severity,
      servers: dataToSave.serverName.split(", "), // Handle multiple servers
      enabled: dataToSave.status.toLowerCase() === "enable", // Convert status to boolean
    };
  
    //console.log("Submitting updated alert:", payload); // Debugging
  
    try {
      const response = await fetch("https://infia.enviseer.com/api/alerts", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        alert(data.message || "Alert updated successfully!");
        setShowPopup(false);
        fetchMethods(); // Refresh the alerts list
      } else {
        alert(`Failed to update alert: ${data.message || "Unknown error"}`);
      }
    } catch (error) {
      alert(`Error updating alert: ${error.message}`);
    }
  };
  

  const handleCreateAlert = async (e) => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const token = getCookie('token');
  //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

    if (!token) {
      localStorage.clear();
      onLogout();
  }
    e.preventDefault();

    if (!methodData.clusterName || !methodData.serverName || !methodData.metric || !methodData.condition || !methodData.severity || !methodData.integration || !methodData.alertName) {
      alert("Please fill in all required fields.");
      return;
    }
    // Validation to ensure required fields are filled
  
    // Prepare the payload
    const payload = {
      cluster: methodData.clusterName,
      metric_name: methodData.metric,
      operator: methodData.operator,
      threshold: Number(methodData.condition),
      message: methodData.alertName,
      target_channels: [methodData.integration],
      severity: methodData.severity,
      servers: methodData.serverName,
      enabled: true,
    };
  
    try {
      const response = await fetch("https://infia.enviseer.com/api/alerts", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        setProgress(100);
        setIsSubmitted(true);
        setTimeout(() => {
        setProgress(0);
        setCurrentStep('methodInfo');
        setIsSubmitted(false);
        setShowPopup(false);
        }, 3000);
        fetchMethods();
        // Reset form fields after successful submission
        setMethodData({
          alertName: "",
          integration: "",
          metric: "",
          operator: "",
          condition: "",
          clusterName: "",
          serverName: "",
          severity: ""
        });
      } else {
        const errorData = await response.json();
        alert(`Failed to create alert: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };
  
  const handleDelete = async (alertId, cluster) => {
    // Confirm deletion action
    if (!window.confirm("Are you sure you want to delete this alert?")) {
      return;
    }
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const token = getCookie('token');
  //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDAxMzk2MDYsImV4cCI6MTc0MDE0MzIwNn0.BPNpcPXzM0EjKH7dUcPVQoNpToQXFuaLf8x80qo9rZQ';

    if (!token) {
      localStorage.clear();
      onLogout();
  }
  
    const payload = {
      alert_id: alertId,
      cluster: cluster,
    };
  
    try {
      const response = await fetch("https://infia.enviseer.com/api/alerts", {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        fetchMethods();
        alert(data.message || "Alert deleted successfully!");
        // Optionally, update the UI to reflect the deletion
      } else {
        const errorData = await response.json();
        alert(`Failed to delete alert: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };
  

  const getLast30Days = () => {
    const dates = [];
    const today = new Date();
    for (let i = 29; i >= 0; i--) {
      const date = new Date();
      date.setDate(today.getDate() - i);
      dates.push(date.toISOString().split("T")[0]); // Format as YYYY-MM-DD
    }
    return dates;
  };


  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.9)',
        titleFont: { 
          size: 13, 
          weight: 'bold',
          family: "'Inter', sans-serif"
        },
        bodyFont: { 
          size: 12,
          family: "'Inter', sans-serif"
        },
        padding: 12,
        cornerRadius: 8,
        displayColors: true,
        usePointStyle: true,
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw || 0}`,
        },
      },
      legend: {
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded',
          padding: 16,
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 12,
            family: "'Inter', sans-serif"
          }
        }
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { 
          display: false 
        },
        border: {
          display: false
        },
        ticks: {
          font: {
            size: 10,
            family: "'Inter', sans-serif"
          },
          color: '#6B7280',
          padding: 5,
          maxRotation: 45,
          minRotation: 45,
          callback: function (val, index) {
            // Show fewer labels to prevent overcrowding
            return index % 5 === 0 ? this.getLabelForValue(val).slice(8) : "";
          },
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: 10, // Set a reasonable max value
        grid: { 
          display: true,
          color: 'rgba(229, 231, 235, 0.5)',
          drawBorder: false
        },
        border: {
          display: false
        },
        ticks: {
          font: {
            size: 10,
            family: "'Inter', sans-serif"
          },
          color: '#6B7280',
          padding: 5,
          stepSize: 2,
          count: 6 // Limit the number of ticks
        }
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    layout: {
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      }
    },
    elements: {
      bar: {
        borderWidth: 0,
        borderRadius: 4
      }
    }
  };

  // Update the getFilteredData function to properly handle all filters
  const getFilteredData = (data) => {
    if (!data || !Array.isArray(data)) return [];
    
    return data.filter(alert => {
      // Search by alert name or ID (case-insensitive)
      const searchTerm = tableSearchQuery.toLowerCase().trim();
      const searchMatch = !searchTerm || 
        (alert.alertName && alert.alertName.toLowerCase().includes(searchTerm)) ||
        (alert.id && alert.id.toString().toLowerCase().includes(searchTerm));

      // Filter by server (exact match from dropdown)
        const serverMatch = selectedTableServers.length === 0 || 
        (alert.serverName && selectedTableServers.includes(alert.serverName));

      // Filter by metric (exact match from dropdown)
        const metricMatch = selectedTableMetrics.length === 0 || 
        (alert.metric && selectedTableMetrics.includes(alert.metric));

      // Filter by integration (exact match from dropdown)
        const integrationMatch = selectedTableIntegrations.length === 0 || 
        (alert.integration && selectedTableIntegrations.includes(alert.integration));

      // Filter by severity (exact match from dropdown)
      const severityMatch = selectedTableSeverities.length === 0 ||
        (alert.severity && selectedTableSeverities.includes(alert.severity));

      // All conditions must match
      return searchMatch && serverMatch && metricMatch && integrationMatch && severityMatch;
    });
};

  // Add useEffect to reset filters when data source changes
  useEffect(() => {
    // Reset all filters when data source changes
    setSelectedTableSeverities([]);
    setSelectedTableServers([]);
    setSelectedTableMetrics([]);
    setSelectedTableIntegrations([]);
    setTableSearchQuery('');
    setSortConfig({ key: null, direction: 'asc' });
  }, [selectedDataSource]);

  // Use the function to filter both alertData and methods
  const filteredData = getFilteredData(alertData, filter);
  const filteredMethods = getFilteredData(methods, methodFilter, true);

  const [selectedServers, setSelectedServers] = useState([]);
  const serverOptions = [ 
    ...servers.map((server) => ({ label: server, value: server })),
  ];

 
const handleServerRemove = (server) => {
  setMethodData({ ...methodData, serverName: methodData.serverName.filter((s) => s !== server) });
};

  const integrations = [
  { name: "email", icon: "./iconEmail.png", active: true },
  // { name: "whatsApp", icon: "./iconWhatsapp.png", active: true },
  // { name: "slack", icon: "./iconSlack.png", active: false },
];

const integrationA = ['email'];

  
const handleSelectIntegration = (integration) => {
  if (integration.active) {
    setMethodData({ ...methodData, integration: integration.name });
  }
};

  const renderContent = () => {
    switch (currentStep) {
      case "methodInfo":
        // Create serverOptions from servers state
        const serverOptions = servers.map(server => ({ 
          label: server, 
          value: server 
        }));
        
        return (
          <div style={{ 
            padding: "30px", 
            display: "flex", 
            flexDirection: "column", 
            paddingBottom: "60px", 
            scrollbarWidth: "thin",
            maxWidth: "600px",
            margin: "0 auto"
          }}>
            <h3 style={{
              color: "#2B3674",
              textAlign: "center",
              marginBottom: "20px",
              fontWeight: "bold",
              fontSize: "20px"
            }}>
              Method Information
            </h3>
            <p style={{
              color: "#6B7A99",
              textAlign: "center",
              marginBottom: "30px",
              fontSize: "15px",
              lineHeight: "1.5"
            }}>
              Outline the method details to build an actionable alert system.
            </p>
            
            <div style={{
              display: "grid", 
              gridTemplateColumns: "120px 1fr", 
              gap: "24px",
              alignItems: "center",
              marginBottom: "24px"
            }}>
              <label style={{
                color: "#2B3674",
                fontWeight: "600",
                fontSize: "15px"
              }}>
                Message:
              </label>
              <input
                type="text"
                placeholder="Alert Message"
                value={methodData.alertName}
                onChange={(e) => setMethodData({ ...methodData, alertName: e.target.value })}
                style={{
                  padding: "12px 16px",
                  borderRadius: "8px",
                  border: "1px solid #E2E8F0",
                  fontSize: "14px",
                  width: "100%",
                  transition: "border-color 0.2s ease",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                  outline: "none"
                }}
              />
            </div>

            <div style={{
              display: "grid", 
              gridTemplateColumns: "120px 1fr", 
              gap: "24px",
              alignItems: "center", 
              marginBottom: "24px"
            }}>
              <label style={{
                color: "#2B3674",
                fontWeight: "600",
                fontSize: "15px"
              }}>
                Cluster:
              </label>
              <SearchableDropdown
                options={dataSources}
                placeholder="Select Cluster"
                onSelect={handleClusterSelect}
                selectedOption={methodData.clusterName}
                style={{
                  padding: "12px 16px",
                  borderRadius: "8px",
                  border: "1px solid #E2E8F0",
                  fontSize: "14px",
                  width: "100%",
                  backgroundColor: "white",
                  transition: "border-color 0.2s ease",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                  outline: "none"
                }}
              />
            </div>

            <div style={{
              display: "grid", 
              gridTemplateColumns: "120px 1fr", 
              gap: "24px",
              alignItems: "center",
              marginBottom: "24px"
            }}>
              <label style={{
                color: "#2B3674",
                fontWeight: "600",
                fontSize: "15px"
              }}>
                Server:
              </label>
              <SearchableDropdown
                options={serverOptions.map((option) => option.label)}
                placeholder="Select Server"
                onSelect={handleServerSelect}
                selectedOption={methodData.serverName}
                style={{
                  padding: "12px 16px",
                  borderRadius: "8px",
                  border: "1px solid #E2E8F0",
                  fontSize: "14px",
                  width: "100%",
                  backgroundColor: "white",
                  transition: "border-color 0.2s ease",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                  outline: "none"
                }}
              />
            </div>
            
            {methodData.serverName && methodData.serverName.length > 0 && (
              <div style={{
                marginTop: "8px",
                marginBottom: "24px",
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                paddingLeft: "144px" // Align with input fields
              }}>
                {methodData.serverName.map((server) => (
                  <div
                    key={server}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      padding: "6px 12px",
                      borderRadius: "6px",
                      backgroundColor: "#F5F5FF",
                      border: "1px solid #E2E8F0",
                      fontSize: "13px",
                      color: "#4A578C",
                    }}
                  >
                    {server}
                    <span
                      onClick={() => handleServerRemove(server)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "18px",
                        height: "18px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 77, 79, 0.1)",
                        color: "#FF4D4F",
                        fontSize: "12px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        marginLeft: "2px"
                      }}
                    >
                      ✕
                    </span>
                  </div>
                ))}
              </div>
            )}

            <div style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "16px",
              position: "absolute",
              bottom: "20px",
              left: "20px",
              right: "20px"
            }}>
              <button
                onClick={handleCancel}
                style={{
                  padding: "10px 24px",
                  backgroundColor: "#FF4D4F",
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                  boxShadow: "0 2px 4px rgba(255, 77, 79, 0.2)"
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleNext}
                style={{
                  padding: "10px 24px",
                  backgroundColor: "#6825EC",
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                  boxShadow: "0 2px 4px rgba(104, 37, 236, 0.2)"
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case "condition":
        return (
          <div style={{ padding: "30px", display: "flex", flexDirection: "column", paddingBottom: "60px", position: "relative", maxWidth: "600px", margin: "0 auto" }}>
            {/* Close button at top right */}
            <button
              onClick={handleCancel}
              style={{
                position: "absolute",
                top: "15px",
                right: "15px",
                background: "none",
                border: "none",
                fontSize: "20px",
                color: "#6B7A99",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                transition: "background-color 0.2s ease"
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(0,0,0,0.05)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              ×
            </button>
            
            <h3
              style={{
                color: "#2B3674",
                textAlign: "center",
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Method Condition
            </h3>
            <p
              style={{
                color: "#6B7A99",
                textAlign: "center",
                marginBottom: "30px",
                fontSize: "15px",
                lineHeight: "1.5"
              }}
            >
              Define the criteria to trigger your alerts.
            </p>
            
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginBottom: "30px" }}>
              {/* First row - metric selection */}
              <div style={{ width: "100%" }}>
                <SearchableDropdown
                  options={metrics}
                  placeholder="Select Metric"
                  onSelect={(metric) => setMethodData({ ...methodData, metric })}
                  selectedOption={methodData.metric}
                  style={{
                    padding: "12px 16px",
                    borderRadius: "8px",
                    border: "1px solid #E2E8F0",
                    fontSize: "14px",
                    width: "100%",
                    backgroundColor: "white",
                    transition: "border-color 0.2s ease",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                    outline: "none"
                  }}
                />
              </div>
              
              {/* Second row - operator */}
              <div style={{ width: "100%" }}>
                <SearchableDropdown
                  options={operators}
                  placeholder="Operator"
                  onSelect={(operator) => setMethodData({ ...methodData, operator })}
                  selectedOption={methodData.operator}
                  style={{
                    padding: "12px 16px",
                    borderRadius: "8px",
                    border: "1px solid #E2E8F0",
                    fontSize: "14px",
                    width: "100%",
                    backgroundColor: "white",
                    transition: "border-color 0.2s ease",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                    outline: "none"
                  }}
                />
              </div>
              
              {/* Third row - value */}
              <div style={{ width: "100%" }}>
                <input
                  type="number"
                  placeholder="Value"
                  value={methodData.condition}
                  onChange={(e) => setMethodData({ ...methodData, condition: e.target.value })}
                  style={{
                    padding: "12px 16px",
                    borderRadius: "8px",
                    border: "1px solid #E2E8F0",
                    fontSize: "14px",
                    width: "100%",
                    transition: "border-color 0.2s ease",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                    outline: "none"
                  }}
                />
              </div>
            </div>
            
            <div style={{ display: "grid", gridTemplateColumns: "120px 1fr", gap: "24px", alignItems: "center", marginBottom: "24px" }}>
              <label style={{ color: "#2B3674", fontWeight: "600", fontSize: "15px" }}>
                Severity:
              </label>
              <SearchableDropdown
                options={severity}
                placeholder="Select severity"
                onSelect={handleSeveritySelect}
                selectedOption={methodData.severity}
                style={{
                  padding: "12px 16px",
                  borderRadius: "8px",
                  border: "1px solid #E2E8F0",
                  fontSize: "14px",
                  width: "100%",
                  backgroundColor: "white",
                  transition: "border-color 0.2s ease",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                  outline: "none"
                }}
              />
            </div>
            
            <div style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
              position: "absolute",
              bottom: "20px",
              right: "20px"
            }}>
              <button
                onClick={handleNext}
                style={{
                  padding: "10px 24px",
                  backgroundColor: "#6825EC",
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                  boxShadow: "0 2px 4px rgba(104, 37, 236, 0.2)"
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case "integration":
        return (
          <div style={{ padding: "30px", display: "flex", flexDirection: "column", paddingBottom: "60px", position: "relative", maxWidth: "600px", margin: "0 auto" }}>
            {/* Close button at top right */}
            <button
              onClick={handleCancel}
              style={{
                position: "absolute",
                top: "15px",
                right: "15px",
                background: "none",
                border: "none",
                fontSize: "20px",
                color: "#6B7A99",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                transition: "background-color 0.2s ease"
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(0,0,0,0.05)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              ×
            </button>
            
            <h3
              style={{
                color: "#2B3674",
                textAlign: "center",
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Method Integration
            </h3>
            <p
              style={{
                color: "#6B7A99",
                textAlign: "center",
                marginBottom: "30px",
                fontSize: "15px",
                lineHeight: "1.5"
              }}
            >
              Stay informed through your preferred channels
            </p>
      
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {integrations.map((integration) => (
                <div
                  key={integration.name}
                  style={{
                    border: "1px solid #E2E8F0",
                    borderRadius: "8px",
                    padding: "20px",
                    width: "120px",
                    textAlign: "center",
                    backgroundColor:
                      methodData.integration === integration.name
                        ? "rgba(104, 37, 236, 0.05)"
                        : "#fff",
                    opacity: integration.active ? 1 : 0.6,
                    cursor: integration.active ? "pointer" : "not-allowed",
                    transition: "all 0.3s ease",
                    boxShadow: methodData.integration === integration.name 
                      ? "0 4px 6px -1px rgba(104, 37, 236, 0.1)"
                      : "0 1px 2px rgba(0, 0, 0, 0.05)",
                  }}
                  onClick={() => handleSelectIntegration(integration)}
                >
                  <img
                    src={integration.icon}
                    alt={integration.name}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginBottom: "10px",
                    }}
                  />
                  <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    {integration.name}
                  </div>
                  <button
                    style={{
                      backgroundColor:
                        methodData.integration === integration.name
                          ? "#6825EC"
                          : integration.active
                          ? "#4A578C"
                          : "#ccc",
                      color: "white",
                      padding: "5px 10px",
                      border: "none",
                      borderRadius: "4px",
                      fontSize: "14px",
                      cursor: integration.active ? "pointer" : "not-allowed",
                    }}
                    disabled={!integration.active}
                  >
                    {methodData.integration === integration.name ? "Connected" : "Connect"}
                  </button>
                </div>
              ))}
            </div>
        
            <div style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
              position: "absolute",
              bottom: "20px",
              right: "20px"
            }}>
              <button
                onClick={handleCreateAlert}
                style={{
                  padding: "10px 24px",
                  backgroundColor: "#6825EC",
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                  boxShadow: "0 2px 4px rgba(104, 37, 236, 0.2)"
                }}
              >
                Create
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  
  const isDataAvailable =
  chartData &&
  chartData.datasets &&
  chartData.datasets.some((dataset) =>
    dataset.data.some((value) => value > 0)
  );

  //const isDataAvailable = false;

  const handleSeeAllClick = async () => {
    setIsHistoryPopupOpen(true);
    
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const token = getCookie('token');

    if (!token) {
      localStorage.clear();
      onLogout();
    }

    const url = "https://infia.enviseer.com/api/alerts/daily-summary";
    const payload = { cluster: selectedDataSource, host: '*' };

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      let data = await response.json();
      setAlertCount(data);
    } catch (error) {
      console.error("Failed to fetch alertCount:", error);
      setError(error.message);
      setAlertCount(null);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseHistoryPopup = () => {
    setIsHistoryPopupOpen(false);
  };


  const labels = getLast30Days();
  const datasets = [
    {
      label: "Moderate Alerts",
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 3)),
      type: "Moderate",
    },
    {
      label: "High Alerts",
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 5)),
      type: "High",
    },
    {
      label: "Critical Alerts",
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 7)),
      type: "Critical",
    },
  ];
  const dateWiseAlerts = labels.map((date, index) => {
    const criticalAlerts = datasets[2].data[index] || 0;
    const highAlerts = datasets[1].data[index] || 0;
    const lowAlerts = datasets[0].data[index] || 0;

    return {
      date,
      criticalAlerts,
      highAlerts,
      lowAlerts,
    };
  });

   

//console.log("wwwweeeeeeeeeeeee",selectedDataSource);

const toggleStatus = (method) => {
  const newStatus = method.status.toLowerCase() === "enable" ? "disable" : "enable";
  const updatedMethod = { ...method, status: newStatus };
  setEditData(updatedMethod);
  handleSaveChanges(null, updatedMethod); // Pass updated method explicitly
};

const severityLevels = ['Critical', 'High', 'Low'];

// Add these utility functions before the render
const formatToSentenceCase = (str) => {
    if (!str) return '';
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

const formatCondition = (condition) => {
    if (!condition) return '';
    return condition
        .split(' ')
        .map(word => {
            if (word.includes('_')) {
                return formatToSentenceCase(word);
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
};

const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
    }
    setSortConfig({ key, direction });
};

const getSortedData = (data) => {
    if (!sortConfig.key) return data;
    
    return [...data].sort((a, b) => {
        let aVal = a[sortConfig.key];
        let bVal = b[sortConfig.key];
        
      // Special handling for different columns
      if (sortConfig.key === 'triggeredat') {
        aVal = new Date(a.triggered_at).getTime();
        bVal = new Date(b.triggered_at).getTime();
      } else if (sortConfig.key === 'alertname') {
        aVal = a.alertName?.toLowerCase() || '';
        bVal = b.alertName?.toLowerCase() || '';
      } else if (sortConfig.key === 'alertid') {
        aVal = parseInt(a.id);
        bVal = parseInt(b.id);
        }
        
        if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
    });
};

// After other fetch functions like handleRefresh, add the fetchRcaData function

const fetchRcaData = async (alertId, cluster, host) => {
  setRcaLoading(true);
  setRcaError(null);
  try {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const token = getCookie('token');

    const response = await fetch("https://infia.enviseer.com/api/alerts/rca", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        alert_id: alertId,
        cluster: cluster,
        host: host
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setRcaData(data);
  } catch (error) {
    console.error("Error fetching RCA data:", error);
    setRcaError(error.message || "An error occurred while fetching RCA data");
  } finally {
    setRcaLoading(false);
  }
};

// Add handler for the RCA icon click
const handleRcaClick = (alert) => {
  setRcaData(null);
  setShowRcaModal(true);
  fetchRcaData(alert.id, alert.cluster, alert.serverName);
};

// Function to handle closing the RCA modal
const handleCloseRcaModal = () => {
  setShowRcaModal(false);
  setSelectedAlertId(null);
};

// Function to handle opening the RCA modal
const handleOpenRcaModal = (alert) => {
  setSelectedAlertId({
    id: alert.id, 
      cluster: selectedDataSource,  // Using selectedDataSource for the cluster
      host: alert.serverName
  });
  setShowRcaModal(true);
};

  return (
    <div className={`alerts-container ${sidebarOpen ? "sidebar-open" : ""}`}>
      <div className="dropdowns" style={{ width: sidebarOpen ? '90%' : '96%' }}>
                    <div className="dropdown-contents">
                        <div className="dropdown">
                            <CustomDropdown
              label="Clusters"
                                options={Array.isArray(dataSources) && dataSources.map(source => ({ value: source, label: source }))}
                                value={selectedDataSource}
                                onChange={(event) => handleDataSourceChange(event)}
                            />
                        </div>
                    </div>
      </div>

      <div className="tabs">
        <button
          className={activeTab === "All Alerts" ? "active" : ""}
          onClick={() => setActiveTab("All Alerts")}
        >
          All Alerts
        </button>
        <button
          className={activeTab === "Create Alert" ? "active" : ""}
          onClick={() => setActiveTab("Create Alert")}
        >
          Alert Methods
        </button>
      </div>

      {activeTab === "All Alerts" && (
        <div className="all-alerts">
          <div
  className="create-method-tab"
  onClick={() => setIsPopupOpen(true)}
  style={{
              position: "absolute",
              top: "135px",
              right: "40px",
              width: "220px",
              padding: "12px 20px",
              background: "linear-gradient(135deg, #6825EC, #4A378C)",
              borderRadius: "25px",
    textAlign: "center",
              color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
              boxShadow: "0px 4px 20px rgba(74, 55, 140, 0.2)",
              border: "none",
              transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
  }}
  onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-2px) scale(1.02)";
              e.currentTarget.style.boxShadow = "0px 8px 25px rgba(74, 55, 140, 0.3)";
  }}
  onMouseLeave={(e) => {
              e.currentTarget.style.transform = "translateY(0) scale(1)";
              e.currentTarget.style.boxShadow = "0px 4px 20px rgba(74, 55, 140, 0.2)";
  }}
>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4V20M4 12H20" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Create New Method
</div>

<div className="chart-container">
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
              padding: '0 10px'
            }}>
              <h2 style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                color: '#1F2937',
                margin: 0,
                position: 'relative',
                paddingBottom: '8px'
              }}>
                Alerts Count for Last 30 Days
                <div style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '40%',
                  height: '2px',
                  background: 'linear-gradient(to right, #6825EC, transparent)',
                  borderRadius: '2px'
                }}></div>
              </h2>
              <button 
                onClick={handleSeeAllClick}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                  padding: '8px 16px',
                  backgroundColor: '#F3F4F6',
                  border: '1px solid #E5E7EB',
                  borderRadius: '8px',
                  color: '#4B5563',
                  fontSize: '0.875rem',
                  fontWeight: '500',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#E5E7EB';
                  e.currentTarget.style.borderColor = '#D1D5DB';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#F3F4F6';
                  e.currentTarget.style.borderColor = '#E5E7EB';
                }}
              >
                <i className="bi bi-clock-history"></i>
                View History
              </button>
      </div>

            {/* Rest of the chart container content */}
      {isDataAvailable ? (
        <div style={{ 
            height: '350px', 
            position: 'relative',
            borderRadius: '12px',
            padding: '20px',
            overflow: 'hidden'
          }}>
            <Bar
              data={chartData}
              options={chartOptions}
              style={{ maxHeight: '310px' }}
            />
          </div>
      ) : (
        <div style={{ 
            height: '350px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            padding: '20px',
            boxShadow: '0 2px 8px rgba(104, 37, 236, 0.1)'
          }}>
            <p style={{ 
              textAlign: "center", 
              color: "#6B7280",
              fontSize: "16px",
              fontWeight: "500"
            }}>
              No Alert Data Available
            </p>
          </div>
      )}

      {/* History Popup */}
      {isHistoryPopupOpen && (
              <div className="historypopup-overlay" style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                backdropFilter: 'blur(4px)'
              }}>
                <div className="historypopup-content" style={{
                  backgroundColor: 'white',
                  borderRadius: '16px',
                  padding: '24px',
                  width: '90%',
                  maxWidth: '800px',
                  maxHeight: '80vh',
                  overflow: 'hidden',
                  boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '24px',
                    borderBottom: '1px solid #E5E7EB',
                    paddingBottom: '16px'
                  }}>
                    <div>
                      <h3 style={{
                        fontSize: '1.5rem',
                        fontWeight: '600',
                        color: '#1F2937',
                        margin: '0 0 4px 0'
                      }}>Alert History</h3>
                      <p style={{
                        fontSize: '0.875rem',
                        color: '#6B7280',
                        margin: 0
                      }}>Daily summary of alerts by severity level</p>
                    </div>
                    <button 
                      onClick={handleCloseHistoryPopup}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#6B7280',
                        cursor: 'pointer',
                        padding: '8px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'all 0.2s'
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#F3F4F6'
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent'
                      }}
                    >
                      <i className="bi bi-x" style={{ fontSize: '24px' }}></i>
          </button>
                  </div>

          {loading ? (
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '40px'
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        color: '#6B7280'
                      }}>
                        <i className="bi bi-arrow-repeat loading-spinner" style={{
                          fontSize: '24px',
                          animation: 'spin 1s linear infinite'
                        }}></i>
                        Loading Data...
                      </div>
                    </div>
          ) : error ? (
                    <div style={{
                      padding: '20px',
                      backgroundColor: '#FEE2E2',
                      borderRadius: '8px',
                      color: '#DC2626',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px'
                    }}>
                      <i className="bi bi-exclamation-circle"></i>
                      {error}
                    </div>
                  ) : (
                    <div style={{ overflowY: 'auto', maxHeight: 'calc(80vh - 100px)' }}>
                      <table style={{
                        width: '100%',
                        borderCollapse: 'separate',
                        borderSpacing: '0',
                        backgroundColor: 'white'
                      }}>
              <thead>
                          <tr style={{
                            backgroundColor: '#6825EC',
                            position: 'sticky',
                            top: 0,
                            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                          }}>
                            <th style={{
                              padding: '16px 24px',
                              textAlign: 'center',
                              color: 'white',
                              fontWeight: '600',
                              fontSize: '0.875rem',
                              letterSpacing: '0.025em'
                            }}>Date</th>
                            <th style={{
                              padding: '16px 24px',
                              textAlign: 'center',
                              color: 'white',
                              fontWeight: '600',
                              fontSize: '0.875rem',
                              letterSpacing: '0.025em'
                            }}>Critical</th>
                            <th style={{
                              padding: '16px 24px',
                              textAlign: 'center',
                              color: 'white',
                              fontWeight: '600',
                              fontSize: '0.875rem',
                              letterSpacing: '0.025em'
                            }}>High</th>
                            <th style={{
                              padding: '16px 24px',
                              textAlign: 'center',
                              color: 'white',
                              fontWeight: '600',
                              fontSize: '0.875rem',
                              letterSpacing: '0.025em'
                            }}>Moderate</th>
                </tr>
              </thead>
                        <tbody>
                          {alertCount && alertCount.length > 0 ? (
                            processAlertHistoryData().map((alert, index) => (
                              <tr 
                                key={alert.date}
                                style={{
                                  backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F8FAFC',
                                  transition: 'background-color 0.2s'
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor = '#F1F5F9'
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#FFFFFF' : '#F8FAFC'
                                }}
                              >
                                <td style={{
                                  padding: '14px 24px',
                                  color: '#1F2937',
                                  borderBottom: '1px solid #E5E7EB',
                                  fontSize: '0.875rem',
                                  textAlign: 'center'
                                }}>{new Date(alert.date).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric'
                                })}</td>
                                <td style={{
                                  padding: '14px 24px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #E5E7EB',
                                  color: alert.criticalAlerts > 0 ? '#DC2626' : '#6B7280',
                                  fontSize: '0.875rem',
                                  fontWeight: alert.criticalAlerts > 0 ? '600' : '400'
                                }}>{alert.criticalAlerts}</td>
                                <td style={{
                                  padding: '14px 24px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #E5E7EB',
                                  color: alert.highAlerts > 0 ? '#EA580C' : '#6B7280',
                                  fontSize: '0.875rem',
                                  fontWeight: alert.highAlerts > 0 ? '600' : '400'
                                }}>{alert.highAlerts}</td>
                                <td style={{
                                  padding: '14px 24px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #E5E7EB',
                                  color: alert.moderateAlerts > 0 ? '#CA8A04' : '#6B7280',
                                  fontSize: '0.875rem',
                                  fontWeight: alert.moderateAlerts > 0 ? '600' : '400'
                                }}>{alert.moderateAlerts}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                              <td 
                                colSpan="4" 
                                style={{
                                  textAlign: 'center',
                                  padding: '40px',
                                  color: '#6B7280'
                                }}
                              >
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  gap: '12px'
                                }}>
                                  <i className="bi bi-inbox" style={{ fontSize: '24px' }}></i>
                                  No Alert Data Available
                                </div>
                              </td>
                  </tr>
                )}
              </tbody>
            </table>
                    </div>
          )}
        </div>
      </div>
    )}
    </div>

        <div className="alertTable">
            <div className="filter-container">
                <div className="filter-group">
                <MultiSelectorFilter
                  label="Severity"
                  options={[
                    { value: 'Critical', label: 'Critical' },
                    { value: 'High', label: 'High' },
                    { value: 'Low', label: 'Low' }
                  ]}
                  selectedOptions={selectedTableSeverities}
                  setSelectedOptions={setSelectedTableSeverities}
                />

                    <MultiSelectorFilter
                        label="Server"
                  options={Array.from(new Set(alertData?.map(alert => alert.serverName).filter(Boolean) || [])).map(server => ({
                            value: server,
                            label: server
                        }))}
                        selectedOptions={selectedTableServers}
                        setSelectedOptions={setSelectedTableServers}
                    />

                    <MultiSelectorFilter
                        label="Metric"
                  options={Array.from(new Set(alertData?.map(alert => alert.metric).filter(Boolean) || [])).map(metric => ({
                            value: metric,
                            label: formatToSentenceCase(metric)
                        }))}
                        selectedOptions={selectedTableMetrics}
                        setSelectedOptions={setSelectedTableMetrics}
                    />

                    <MultiSelectorFilter
                        label="Integration"
                  options={Array.from(new Set(alertData?.map(alert => alert.integration).filter(Boolean) || [])).map(integration => ({
                            value: integration,
                            label: integration
                        }))}
                        selectedOptions={selectedTableIntegrations}
                        setSelectedOptions={setSelectedTableIntegrations}
                    />

                    <div className="syslog-search-container">
                        <i className="bi bi-search"></i>
                        <input
                            className="syslog-search-input"
                            type="text"
                            value={tableSearchQuery}
                            onChange={(e) => setTableSearchQuery(e.target.value)}
                            placeholder="Search alerts by name/id"
                        />
                        {tableSearchQuery && (
                            <button
                                onClick={() => setTableSearchQuery('')}
                                className="clear-search-button"
                            >
                                <i className="bi bi-x"></i>
                                Clear
                            </button>
                        )}
                    </div>
                </div>
            </div>

    <table>
      <thead>
        <tr>
                  {[
                    { key: 'severity', label: 'Severity' },
                    { key: 'alertname', label: 'Alert Name' },
                    { key: 'alertid', label: 'Alert ID' },
                    { key: 'triggeredat', label: 'Triggered At' },
                    { key: 'server', label: 'Server' },
                    { key: 'metric', label: 'Metric' },
                    { key: 'condition', label: 'Condition' },
                    { key: 'integration', label: 'Integration' },
                    { key: 'rca', label: 'RCA', sortable: false }
                  ].map((column) => (
                    <th
                      key={column.key}
                      onClick={() => !column.sortable === false ? handleSort(column.key) : null}
                      className={sortConfig.key === column.key ? 'sorted' : ''}
                      style={{ cursor: column.sortable === false ? 'default' : 'pointer' }}
                    >
                      {column.label}
                      {column.sortable !== false && (
                                    <i className={`bi ${
                          sortConfig.key === column.key
                                            ? sortConfig.direction === 'asc' 
                                                ? 'bi-arrow-up' 
                                                : 'bi-arrow-down'
                                            : 'bi-arrow-down-up'
                                    }`} />
                                )}
          </th>
                        ))}
        </tr>
      </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="8" style={{ textAlign: 'center', padding: '40px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
                                    <i className="bi bi-arrow-repeat loading-spinner" />
                                    Loading Data...
                                </div>
                            </td>
                        </tr>
                    ) : filteredData && filteredData.length > 0 ? (
                        getSortedData(filteredData).map((alert) => (
          <tr key={alert.id}>
                                <td>
                                    <div className={`severity-badge ${alert.severity.toLowerCase()}`}>
                          <i className={`bi ${alert.severity.toLowerCase() === "critical" ? "bi-exclamation-triangle-fill" :
                                            alert.severity.toLowerCase() === "high" ? "bi-exclamation-circle-fill" :
                                            "bi-info-circle-fill"
                                        }`} />
                                        {alert.severity}
                                    </div>
            </td>
                      <td style={{ fontWeight: '600' }}>{formatToSentenceCase(alert.alertName)}</td>
                                <td><span className="alert-id">#{alert.id}</span></td>
                                <td>
                                    <div className="timestamp">
                                        <i className="bi bi-clock" />
                                        {new Date(alert.triggered_at).toLocaleString()}
                                    </div>
                                </td>
                                <td>
                                    <div className="server-cell">
                                        <i className="bi bi-hdd-rack server-icon" />
                                        {alert.serverName}
                                    </div>
                                </td>
                                <td>
                                    <div className="metric-cell">
                                        <span className="metric-icon">
                            <i className={`bi ${alert.metric.toLowerCase().includes('cpu') ? 'bi-cpu' :
                                                alert.metric.toLowerCase().includes('memory') ? 'bi-memory' :
                                                alert.metric.toLowerCase().includes('disk') ? 'bi-hdd' :
                                                alert.metric.toLowerCase().includes('network') ? 'bi-diagram-3' :
                                                'bi-graph-up'
                                            }`} />
                                        </span>
                                        {formatToSentenceCase(alert.metric)}
                                    </div>
                                </td>
                                <td>
                                    <span className="condition-cell">{formatCondition(alert.condition)}</span>
                                </td>
                                <td>
                                    <div className="integration-badge">
                          <i className={`bi ${alert.integration.toLowerCase().includes('email') ? 'bi-envelope' :
                                            alert.integration.toLowerCase().includes('slack') ? 'bi-slack' :
                                            alert.integration.toLowerCase().includes('whatsapp') ? 'bi-whatsapp' :
                                            'bi-bell'
                                        }`} />
                                        {alert.integration}
                                    </div>
                                </td>
                                <td>
                                    <div className="rca-icon" onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenRcaModal(alert);
                                    }}>
                                        <i className="bi bi-search-heart" title="Root Cause Analysis"></i>
                                    </div>
            </td>
          </tr>
                        ))
        ) : (
          <tr>
                            <td colSpan="8" style={{ textAlign: 'center', padding: '40px', color: '#6B7280' }}>
                                <i className="bi bi-inbox" style={{ fontSize: '24px', marginBottom: '8px', display: 'block' }} />
              No data available
            </td>
          </tr>
        )}
      </tbody>
    </table>
      </div>



        </div>
      )}

      {activeTab === "Create Alert" && (
        <div >
        <div
  className="create-method-tab"
  onClick={() => setIsPopupOpen(true)}
  style={{
              position: "absolute",
              top: "135px",
              right: "40px",
              width: "220px",
              padding: "12px 20px",
              background: "linear-gradient(135deg, #6825EC, #4A378C)",
              borderRadius: "25px",
    textAlign: "center",
              color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
              boxShadow: "0px 4px 20px rgba(74, 55, 140, 0.2)",
              border: "none",
              transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
  }}
  onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-2px) scale(1.02)";
              e.currentTarget.style.boxShadow = "0px 8px 25px rgba(74, 55, 140, 0.3)";
  }}
  onMouseLeave={(e) => {
              e.currentTarget.style.transform = "translateY(0) scale(1)";
              e.currentTarget.style.boxShadow = "0px 4px 20px rgba(74, 55, 140, 0.2)";
  }}
>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4V20M4 12H20" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Create New Method
</div>

          <h3 style={{ color: "#2B3674" }}>All Methods</h3>

      <div className="methods-filter-container">
          <div className="methods-filter-group">
              <div className="methods-search-container">
                  <i className="bi bi-search"></i>
  <input
                      className="methods-search-input"
    type="text"
    value={methodFilter.alertName}
    onChange={(e) => setMethodFilter((prev) => ({ ...prev, alertName: e.target.value }))}
                      placeholder="Search alerts by name/id"
                  />
                  {methodFilter.alertName && (
                      <button
                          onClick={() => setMethodFilter((prev) => ({ ...prev, alertName: '' }))}
                          className="clear-search-button"
                      >
                          <i className="bi bi-x"></i>
                          Clear
                      </button>
                  )}
              </div>

              <div className="filter-group">
                  <MultiSelectorFilter
                      label="Severity"
                      options={[
                          { value: 'Critical', label: 'Critical' },
                          { value: 'High', label: 'High' },
                          { value: 'Low', label: 'Low' }
                      ]}
                      selectedOptions={selectedSeverities}
                      setSelectedOptions={setSelectedSeverities}
                  />

                  <MultiSelectorFilter
                      label="Metric"
                      options={Array.from(new Set(methods.map(method => method.metric))).map(metric => ({
                          value: metric,
                          label: formatToSentenceCase(metric)
                      }))}
                      selectedOptions={selectedMetrics}
                      setSelectedOptions={setSelectedMetrics}
                  />

                  <MultiSelectorFilter
                      label="Integration"
                      options={Array.from(new Set(methods.map(method => method.integration))).map(integration => ({
                          value: integration,
                          label: integration
                      }))}
                      selectedOptions={selectedIntegrations}
                      setSelectedOptions={setSelectedIntegrations}
                  />

                  <MultiSelectorFilter
                      label="Status"
                      options={[
                          { value: 'Enable', label: 'Enabled' },
                          { value: 'Disable', label: 'Disabled' }
                      ]}
                      selectedOptions={selectedStatuses}
                      setSelectedOptions={setSelectedStatuses}
                  />
              </div>
          </div>
      </div>

      <div className="methods-table-container">
          <table className="methods-table">
              <thead>
                  <tr>
                      {[
                          'Severity',
                          'Alert Name',
                          'Alert ID',
                          'Server',
                          'Metric',
                          'Condition',
                          'Integration',
                          'Last Updated',
                          'Status',
                          'Actions'
                      ].map((header) => (
                          <th
                              key={header}
                              onClick={() => handleSort(header.toLowerCase().replace(' ', ''))}
                              className={sortConfig.key === header.toLowerCase().replace(' ', '') ? 'sorted' : ''}
                          >
                              {header}
                      <i className={`bi ${sortConfig.key === header.toLowerCase().replace(' ', '')
                                      ? sortConfig.direction === 'asc'
                                          ? 'bi-arrow-up'
                                          : 'bi-arrow-down'
                                      : 'bi-arrow-down-up'
                              }`} />
      </th>
                      ))}
    </tr>
  </thead>
              <tbody>
                  {getSortedData(filteredMethods).map((method) => (
      <tr key={method.id}>
                          <td>
                              <div className={`severity-badge ${method.severity.toLowerCase()}`}>
                        <i className={`bi ${method.severity === "Critical" ? "bi-exclamation-triangle-fill" :
                                      method.severity === "High" ? "bi-exclamation-circle-fill" :
                                      "bi-info-circle-fill"
                                  }`} />
          {method.severity}
                              </div>
        </td>
        <td>
                              <span className="method-name">{formatToSentenceCase(method.alertName)}</span>
        </td>
                          <td>
                              <span className="method-id">#{method.id}</span>
</td>
                          <td>
                              <div className="server-cell">
                                  <i className="bi bi-hdd-rack server-icon" />
                                  {method.serverName}
  </div>
                          </td>
                          <td>
                              <div className="method-metric">
                                  <span className="method-metric-icon">
                          <i className={`bi ${method.metric.toLowerCase().includes('cpu') ? 'bi-cpu' :
                                          method.metric.toLowerCase().includes('memory') ? 'bi-memory' :
                                          method.metric.toLowerCase().includes('disk') ? 'bi-hdd' :
                                          method.metric.toLowerCase().includes('network') ? 'bi-diagram-3' :
                                          'bi-graph-up'
                                      }`} />
  </span>
                                  {formatToSentenceCase(method.metric)}
                              </div>
</td>
                          <td>
                              <span className="method-condition">{formatCondition(method.condition)}</span>
                          </td>
                          <td>
                              <div className="method-integration">
                        <i className={`bi ${method.integration.toLowerCase().includes('email') ? 'bi-envelope' :
                                      method.integration.toLowerCase().includes('slack') ? 'bi-slack' :
                                      method.integration.toLowerCase().includes('whatsapp') ? 'bi-whatsapp' :
                                      'bi-bell'
                                  }`} />
                                  {method.integration}
                              </div>
                          </td>
                          <td>
                              <div className="timestamp">
                                  <i className="bi bi-clock" />
                                  {new Date(method.updated_at).toLocaleString()}
                              </div>
                          </td>
                          <td>
                              <div className={`method-status ${method.status === 'Enable' ? 'enabled' : 'disabled'}`}>
                                  <i className={`bi ${method.status === 'Enable' ? 'bi-check-circle-fill' : 'bi-x-circle-fill'}`} />
                                  {method.status === 'Enable' ? 'Enabled' : 'Disabled'}
                              </div>
                          </td>
                          <td>
                              <div className="method-actions">
                                  <button className="method-action-btn" onClick={() => handleEdit(method)} title="Edit">
                                      <i className="bi bi-pencil-fill" />
          </button>
                                  <button className="method-action-btn" onClick={() => toggleStatus(method)} title="Toggle Status">
                                      <i className={`bi ${method.status === 'Enable' ? 'bi-toggle-on' : 'bi-toggle-off'}`} />
                                  </button>
                              </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>
      </div>

      {showPopup && (
  <div className="popup" onClick={handleClosePopup}>
              <div className="popup-content" onClick={(e) => e.stopPropagation()} style={{
                backgroundColor: '#FFFFFF',
                borderRadius: '16px',
                padding: '32px',
                width: '90%',
                maxWidth: '800px',
                maxHeight: '85vh',
                overflow: 'auto',
                boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
              }}>
                <h3 style={{
                  fontSize: '24px',
                  fontWeight: '700',
                  color: '#1F2937',
                  marginBottom: '24px',
                  borderBottom: '2px solid #E5E7EB',
                  paddingBottom: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px'
                }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5V19M5 12H19" stroke="#6825EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  Create New Method
                </h3>

                <form onSubmit={handleSaveChanges} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                  {/* Method Information Section */}
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div className="form-group" style={{ marginBottom: '16px' }}>
                      <label style={{
                        display: 'block',
                        color: '#4B5563',
                        fontSize: '14px',
                        fontWeight: '500',
                        marginBottom: '8px'
                      }}>
                        Alert Name
                      </label>
          <input
            type="text"
            value={editData.alertName}
            onChange={(e) => setEditData({ ...editData, alertName: e.target.value })}
                        style={{
                          width: '100%',
                          padding: '10px 16px',
                          borderRadius: '8px',
                          border: '1px solid #D1D5DB',
                          fontSize: '16px',
                          transition: 'all 0.2s',
                          outline: 'none',
                        }}
                        onFocus={(e) => e.target.style.borderColor = '#6825EC'}
                        onBlur={(e) => e.target.style.borderColor = '#D1D5DB'}
          />
        </div>

                    <div style={{ 
                      display: 'grid', 
                      gridTemplateColumns: '1fr 1fr', 
                      gap: '20px',
                      marginBottom: '16px'
                    }}>
        {/* Cluster Selection */}
        <div className="form-group">
                        <label style={{
                          display: 'block',
                          color: '#4B5563',
                          fontSize: '14px',
                          fontWeight: '500',
                          marginBottom: '8px'
                        }}>
                          Cluster
              </label>
              <SearchableDropdown
                options={dataSources}
                placeholder="Select Cluster"
                onSelect={(value) => {
                  if (editData.clusterName !== value) {
                    handleClusterSelect(value);
                    setEditData((prev) => ({
                      ...prev,
                                previousCluster: prev.clusterName,
                      clusterName: value,
                    }));
                  }
                }}
                          selectedOption={editData.clusterName}
              />
        </div>

        {/* Server Selection */}
        <div className="form-group">
                        <label style={{
                          display: 'block',
                          color: '#4B5563',
                          fontSize: '14px',
                          fontWeight: '500',
                          marginBottom: '8px'
                        }}>
                          Server
              </label>
              <SearchableDropdown
                options={serverOptions.map((option) => option.label)}
                placeholder="Select Server"
                onSelect={(value) => setEditData({ ...editData, serverName: value })}
                          selectedOption={editData.serverName}
              />
        </div>
        </div>

                    {/* Condition Section */}
                    <div style={{ 
                      display: 'grid', 
                      gridTemplateColumns: '2fr 1fr 1fr', 
                      gap: '20px',
                      marginBottom: '16px'
                    }}>
        <div className="form-group">
                        <label style={{
                          display: 'block',
                          color: '#4B5563',
                          fontSize: '14px',
                          fontWeight: '500',
                          marginBottom: '8px'
                        }}>
                          Metric
              </label>
          <SearchableDropdown
                options={metrics}
                placeholder="Select Metric"
                onSelect={(value) => setEditData({ ...editData, metric: value })}
                selectedOption={editData.metric}
              />
        </div>

                      <div className="form-group">
                        <label style={{
                          display: 'block',
                          color: '#4B5563',
                          fontSize: '14px',
                          fontWeight: '500',
                          marginBottom: '8px'
                        }}>
                          Operator
              </label>
          <SearchableDropdown
                options={operators}
                placeholder="Operator"
                onSelect={(value) => setEditData({ ...editData, condition: `${editData.metric} ${value} ${editData.threshold || ''}` })}
                          selectedOption={editData.condition.split(" ")[1]}
                          saved={operator}
              />
        </div>

        <div className="form-group">
                        <label style={{
                          display: 'block',
                          color: '#4B5563',
                          fontSize: '14px',
                          fontWeight: '500',
                          marginBottom: '8px'
                        }}>
                          Threshold
                        </label>
          <input
                type="number"
                placeholder="Value"
                value={editData.condition.split(" ")[2] || ""}
          onChange={(e) => setEditData({ ...editData, condition: `${editData.metric} ${editData.condition.split(" ")[1] || ">"} ${e.target.value}` })}
                          style={{
                            width: '100%',
                            padding: '10px 16px',
                            borderRadius: '8px',
                            border: '1px solid #D1D5DB',
                            fontSize: '16px',
                            transition: 'all 0.2s',
                            outline: 'none',
                          }}
                          onFocus={(e) => e.target.style.borderColor = '#6825EC'}
                          onBlur={(e) => e.target.style.borderColor = '#D1D5DB'}
                        />
                      </div>
                    </div>

                    {/* Integration and Severity Section */}
                    <div style={{ 
                      display: 'grid', 
                      gridTemplateColumns: '1fr 1fr', 
                      gap: '20px',
                      marginBottom: '16px'
                    }}>
        <div className="form-group">
                        <label style={{
                          display: 'block',
                          color: '#4B5563',
                          fontSize: '14px',
                          fontWeight: '500',
                          marginBottom: '8px'
                        }}>
                          Integration
                        </label>
          <SearchableDropdown
                options={integrationA}
                          placeholder="Select Integration"
                onSelect={(value) => setEditData({ ...editData, integration: value })}
                          selectedOption={editData.integration}
              />
        </div>

        <div className="form-group">
                        <label style={{
                          display: 'block',
                          color: '#4B5563',
                          fontSize: '14px',
                          fontWeight: '500',
                          marginBottom: '8px'
                        }}>
                          Severity
              </label>
              <SearchableDropdown
                options={severity}
                          placeholder="Select Severity"
                onSelect={(value) => setEditData({ ...editData, severity: value })}
                          selectedOption={editData.severity}
              />
        </div>
        </div>

                    {/* Status Section */}
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label style={{
                        display: 'block',
                        color: '#4B5563',
                        fontSize: '14px',
                        fontWeight: '500',
                        marginBottom: '8px'
                      }}>
                        Status
                      </label>
          <SearchableDropdown
                options={statusA}
                        placeholder="Select Status"
                onSelect={(value) => setEditData({ ...editData, status: value })}
                        selectedOption={editData.status}
              />
        </div>
                  </div>

                  {/* Action Buttons */}
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '16px',
                    marginTop: '32px',
                    borderTop: '1px solid #E5E7EB',
                    paddingTop: '24px'
                  }}>
              <button
                type="button"
                onClick={handleClosePopup}
                style={{
                        padding: '12px 24px',
                        borderRadius: '8px',
                        border: '1px solid #E5E7EB',
                        backgroundColor: '#FFFFFF',
                        color: '#4B5563',
                        fontSize: '16px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#F3F4F6';
                        e.target.style.borderColor = '#D1D5DB';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#FFFFFF';
                        e.target.style.borderColor = '#E5E7EB';
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                style={{
                        padding: '12px 32px',
                        borderRadius: '8px',
                        border: 'none',
                        background: 'linear-gradient(135deg, #6825EC, #4A378C)',
                        color: '#FFFFFF',
                        fontSize: '16px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        boxShadow: '0 4px 6px -1px rgba(74, 55, 140, 0.1), 0 2px 4px -1px rgba(74, 55, 140, 0.06)',
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.transform = 'translateY(-1px)';
                        e.target.style.boxShadow = '0 6px 8px -1px rgba(74, 55, 140, 0.2), 0 4px 6px -1px rgba(74, 55, 140, 0.1)';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.transform = 'translateY(0)';
                        e.target.style.boxShadow = '0 4px 6px -1px rgba(74, 55, 140, 0.1), 0 2px 4px -1px rgba(74, 55, 140, 0.06)';
                      }}
                    >
                      Create Method
              </button>
            </div>
      </form>
    </div>
  </div>
)}


        </div>
      )}
      {isPopupOpen && (
  <div
    className="popup-overlay"
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(15, 23, 42, 0.75)",
      backdropFilter: "blur(8px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 999,
      animation: "fadeIn 0.3s ease-out",
    }}
  >
    <div
      className="popup-content"
      style={{
        width: "700px",
        minHeight: "450px",
        backgroundColor: "#ffffff",
        borderRadius: "16px",
        boxShadow: "0 10px 25px -5px rgba(104, 37, 236, 0.3), 0 8px 10px -6px rgba(104, 37, 236, 0.2)",
        position: "relative",
        display: "flex",
        overflow: "hidden",
        border: "1px solid rgba(104, 37, 236, 0.1)",
        animation: "slideUp 0.4s ease-out",
      }}
    >
      {/* Left Tabs */}
      {!isSubmitted && (
        <div
          style={{
            flex: 1,
            minWidth: "180px",
            background: "linear-gradient(135deg, #f5f3ff 0%, #ede9fe 100%)",
            padding: "20px 10px",
            borderRadius: "16px 0 0 16px",
            boxShadow: "inset -1px 0 0 rgba(104, 37, 236, 0.1)",
          }}
        >
          <h4
            style={{
              color: "#4338ca",
              fontWeight: "bold",
              fontSize: "16px",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            Step {currentStep === "methodInfo" ? "1" : currentStep === "condition" ? "2" : "3"} of 3
          </h4>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li
              onClick={() => handleTabClick("methodInfo")}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
                padding: "14px",
                margin: "8px 0",
                borderRadius: "12px",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor:
                  currentStep === "methodInfo"
                    ? "rgba(104, 37, 236, 0.1)"
                    : methodData.alertName && methodData.clusterName && methodData.serverName.length
                    ? "rgba(16, 185, 129, 0.1)"
                    : "transparent",
                color:
                  currentStep === "methodInfo"
                    ? "#6825EC"
                    : methodData.alertName && methodData.clusterName && methodData.serverName.length
                    ? "#10b981"
                    : "#4b5563",
                transition: "all 0.3s ease",
                boxShadow: currentStep === "methodInfo" ? "0 4px 6px -1px rgba(104, 37, 236, 0.1)" : "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px",
                  height: "24px",
                  backgroundColor:
                    currentStep === "methodInfo"
                      ? "#6825EC"
                      : methodData.alertName && methodData.clusterName && methodData.serverName.length
                      ? "#10b981"
                      : "#e5e7eb",
                  borderRadius: "50%",
                  transition: "all 0.3s ease",
                  color: "#ffffff",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                1
              </div>
              Method Info
            </li>
            <li
              onClick={() => handleTabClick("condition")}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
                padding: "14px",
                margin: "8px 0",
                borderRadius: "12px",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor:
                  currentStep === "condition"
                    ? "rgba(104, 37, 236, 0.1)"
                    : methodData.severity && methodData.metric && methodData.operator && methodData.condition
                    ? "rgba(16, 185, 129, 0.1)"
                    : "transparent",
                color:
                  currentStep === "condition"
                    ? "#6825EC"
                    : methodData.severity && methodData.metric && methodData.operator && methodData.condition
                    ? "#10b981"
                    : "#4b5563",
                transition: "all 0.3s ease",
                boxShadow: currentStep === "condition" ? "0 4px 6px -1px rgba(104, 37, 236, 0.1)" : "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px",
                  height: "24px",
                  backgroundColor:
                    currentStep === "condition"
                      ? "#6825EC"
                      : methodData.severity && methodData.metric && methodData.operator && methodData.condition
                      ? "#10b981"
                      : "#e5e7eb",
                  borderRadius: "50%",
                  transition: "all 0.3s ease",
                  color: "#ffffff",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                2
              </div>
              Condition
            </li>
            <li
              onClick={() => handleTabClick("integration")}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
                padding: "14px",
                margin: "8px 0",
                borderRadius: "12px",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor:
                  currentStep === "integration"
                    ? "rgba(104, 37, 236, 0.1)"
                    : methodData.integration
                    ? "rgba(16, 185, 129, 0.1)"
                    : "transparent",
                color:
                  currentStep === "integration"
                    ? "#6825EC"
                    : methodData.integration
                    ? "#10b981"
                    : "#4b5563",
                transition: "all 0.3s ease",
                boxShadow: currentStep === "integration" ? "0 4px 6px -1px rgba(104, 37, 236, 0.1)" : "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px",
                  height: "24px",
                  backgroundColor:
                    currentStep === "integration"
                      ? "#6825EC"
                      : methodData.integration
                      ? "#10b981"
                      : "#e5e7eb",
                  borderRadius: "50%",
                  transition: "all 0.3s ease",
                  color: "#ffffff",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                3
              </div>
              Integration
            </li>
          </ul>

          {/* Progress visualization */}
          <div style={{ marginTop: "40px", padding: "0 14px" }}>
            <div style={{ 
              position: "relative", 
              height: "4px", 
              backgroundColor: "#e5e7eb", 
              borderRadius: "2px",
              overflow: "hidden",
              marginBottom: "10px"
            }}>
              <div style={{ 
                position: "absolute", 
                top: 0, 
                left: 0, 
                height: "100%", 
                width: `${progress}%`, 
                background: "linear-gradient(90deg, #6825EC 0%, #9061F9 100%)",
                borderRadius: "2px",
                transition: "width 0.5s ease-out"
              }} />
            </div>
            <div style={{ 
              display: "flex", 
              justifyContent: "space-between", 
              fontSize: "12px", 
              color: "#6B7280" 
            }}>
              <span>Start</span>
              <span>Complete</span>
            </div>
          </div>
        </div>
      )}

      {/* Right Content */}
      {isSubmitted ? (
        <div 
          style={{
            display: "flex", 
            flexDirection: "column",
            justifyContent: "center", 
            width: "100%", 
            alignItems: "center",
            padding: "40px",
            background: "linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%)"
          }}
        >
          <div style={{
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            backgroundColor: "rgba(16, 185, 129, 0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24px"
          }}>
            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 6L9 17L4 12" stroke="#10b981" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <h3
            style={{
              color: "#10b981",
              fontWeight: "bold",
              fontSize: "24px",
              marginBottom: "16px",
            }}
          >
            Alert Created Successfully!
          </h3>
          <div
            style={{
              marginBottom: "32px",
              fontSize: "16px",
              color: "#4b5563",
              textAlign: "center",
              maxWidth: "400px"
            }}
          >
            Your alert has been successfully created and is now active. You'll receive notifications based on your selected integration method.
          </div>
          <button
            onClick={() => {
              setIsPopupOpen(false);
              setIsSubmitted(false);
            }}
            style={{
              marginTop: "20px",
              padding: "12px 24px",
              backgroundColor: "#6825EC",
              color: "white",
              border: "none",
              borderRadius: "8px",
              fontWeight: "600",
              fontSize: "16px",
              cursor: "pointer",
              transition: "all 0.2s ease",
              boxShadow: "0 4px 6px -1px rgba(104, 37, 236, 0.2)",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#5a1ecc";
              e.currentTarget.style.transform = "translateY(-2px)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#6825EC";
              e.currentTarget.style.transform = "translateY(0)";
            }}
          >
            Close
          </button>
        </div>
      ) : (
        <div 
          style={{ 
            flex: 3, 
            minWidth: "470px", 
            padding: "20px",
            background: "linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%)"
          }}
        >
          {/* Progress Bar */}
          <div style={{
            backgroundColor: "rgba(104, 37, 236, 0.1)",
            borderRadius: "8px",
            marginBottom: "20px",
            overflow: "hidden",
            height: "6px"
          }}>
            <div
              style={{
                height: "100%",
                width: `${progress}%`,
                background: "linear-gradient(90deg, #6825EC 0%, #9061F9 100%)",
                borderRadius: "8px",
                transition: "width 0.5s ease-out",
              }}
            />
          </div>
          {renderContent()}
        </div>
      )}
    </div>
  </div>
)}

{/* Add keyframe animations */}
<style jsx>{`
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
`}</style>

{/* RCA Modal - Now using the separate component */}
<RootCauseAnalysis 
  isOpen={showRcaModal} 
  onClose={handleCloseRcaModal} 
  alertId={selectedAlertId} 
/>

    </div>
  );
};

export default Alerts;
