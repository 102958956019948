import React, { useState, useRef, useCallback } from 'react';
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Position,
  ReactFlowProvider
} from 'reactflow';
import 'reactflow/dist/style.css';
import './ITPA.css';
import {
  StartNode,
  SshNode,
  FtpUploadNode,
  FtpDownloadNode,
  ServerActionNode,
  TerminateNode,
  TelnetNode,
  DatabaseQueryNode,
  DatabaseBackupNode,
  NetworkScanNode,
  NetworkMonitorNode,
  CompressNode,
  ScpNode,
  TlsNode,
  KillSqlNode,
  TablesNode,
  NetworkSecurityNode,
  NetworkDiagnosticsNode
} from './nodes/CustomNodes';

const nodeTypes = {
  start: StartNode,
  ssh: SshNode,
  telnet: TelnetNode,
  ftpUpload: FtpUploadNode,
  ftpDownload: FtpDownloadNode,
  serverAction: ServerActionNode,
  dbQuery: DatabaseQueryNode,
  dbBackup: DatabaseBackupNode,
  networkScan: NetworkScanNode,
  networkMonitor: NetworkMonitorNode,
  compress: CompressNode,
  scp: ScpNode,
  tls: TlsNode,
  killSql: KillSqlNode,
  tables: TablesNode,
  networkSecurity: NetworkSecurityNode,
  networkDiagnostics: NetworkDiagnosticsNode,
  terminate: TerminateNode
};

const taskInfo = {
  ftpUpload: {
    title: 'FTP Upload',
    description: 'Upload files to remote server via FTP/SFTP',
    icon: 'bi-upload',
    fields: {
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', default: '21' },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password', required: true },
      sourcePath: { type: 'text', label: 'Source Path', required: true },
      destPath: { type: 'text', label: 'Destination Path', required: true },
      protocol: { type: 'select', label: 'Protocol', options: ['FTP', 'SFTP'], default: 'FTP' }
    }
  },
  ftpDownload: {
    title: 'FTP Download',
    description: 'Download files from remote server via FTP/SFTP',
    icon: 'bi-download',
    fields: {
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', default: '21' },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password', required: true },
      sourcePath: { type: 'text', label: 'Source Path', required: true },
      destPath: { type: 'text', label: 'Destination Path', required: true },
      protocol: { type: 'select', label: 'Protocol', options: ['FTP', 'SFTP'], default: 'FTP' }
    }
  },
  ssh: {
    title: 'SSH Command',
    description: 'Execute commands on remote server via SSH',
    icon: 'bi-terminal',
    fields: {
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', default: '22' },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password' },
      privateKey: { type: 'textarea', label: 'Private Key' },
      command: { type: 'textarea', label: 'Command', required: true }
    }
  },
  telnet: {
    title: 'Telnet Command',
    description: 'Execute commands via Telnet connection',
    icon: 'bi-terminal-plus',
    fields: {
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', default: '23' },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password', required: true },
      command: { type: 'textarea', label: 'Command', required: true },
      timeout: { type: 'number', label: 'Timeout (seconds)', default: '30' }
    }
  },
  serverAction: {
    title: 'Server Action',
    description: 'Perform server management actions',
    icon: 'bi-pc-display',
    fields: {
      action: { 
        type: 'select', 
        label: 'Action', 
        options: ['start', 'stop', 'restart', 'status'],
        required: true 
      },
      target: { type: 'text', label: 'Target Server', required: true },
      timeout: { type: 'number', label: 'Timeout (seconds)', default: '30' }
    }
  },
  dbQuery: {
    title: 'Database Query',
    description: 'Execute SQL queries on databases',
    icon: 'bi-database',
    fields: {
      type: {
        type: 'select',
        label: 'Database Type',
        options: ['MySQL', 'PostgreSQL', 'MongoDB', 'Oracle', 'SQL Server'],
        required: true
      },
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', required: true },
      database: { type: 'text', label: 'Database Name', required: true },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password', required: true },
      query: { type: 'textarea', label: 'SQL Query', required: true }
    }
  },
  dbBackup: {
    title: 'Database Backup',
    description: 'Create database backups',
    icon: 'bi-database-fill-check',
    fields: {
      type: {
        type: 'select',
        label: 'Database Type',
        options: ['MySQL', 'PostgreSQL', 'MongoDB', 'Oracle', 'SQL Server'],
        required: true
      },
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', required: true },
      database: { type: 'text', label: 'Database Name', required: true },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password', required: true },
      backupPath: { type: 'text', label: 'Backup Path', required: true },
      compression: { type: 'select', label: 'Compression', options: ['None', 'GZIP', 'ZIP'], default: 'GZIP' }
    }
  },
  networkScan: {
    title: 'Network Scan',
    description: 'Scan network for devices and services',
    icon: 'bi-radar',
    fields: {
      target: { type: 'text', label: 'Target IP/Range', required: true },
      scanType: {
        type: 'select',
        label: 'Scan Type',
        options: ['Port Scan', 'Service Detection', 'Host Discovery'],
        required: true
      },
      ports: { type: 'text', label: 'Ports', placeholder: 'e.g. 80,443,8080 or 1-1000' },
      timeout: { type: 'number', label: 'Timeout (seconds)', default: '30' }
    }
  },
  networkMonitor: {
    title: 'Network Monitor',
    description: 'Monitor network performance and availability',
    icon: 'bi-graph-up',
    fields: {
      target: { type: 'text', label: 'Target Host/IP', required: true },
      type: {
        type: 'select',
        label: 'Monitor Type',
        options: ['Ping', 'Traceroute', 'Bandwidth', 'Latency'],
        required: true
      },
      interval: { type: 'number', label: 'Interval (seconds)', default: '60' },
      threshold: { type: 'number', label: 'Alert Threshold', default: '1000' }
    }
  },
  compress: {
    title: 'Compress Files',
    description: 'Compress files into an archive',
    icon: 'bi-file-zip',
    fields: {
      sourcePath: { type: 'text', label: 'Source Path', required: true },
      outputPath: { type: 'text', label: 'Output Path', required: true },
      format: { 
        type: 'select', 
        label: 'Format', 
        options: ['zip', 'tar.gz', 'tar.bz2', '7z', 'rar'],
        required: true 
      },
      level: {
        type: 'select',
        label: 'Compression Level',
        options: ['Fastest', 'Fast', 'Normal', 'Maximum', 'Ultra'],
        default: 'Normal'
      },
      password: { type: 'password', label: 'Password (Optional)' },
      excludePattern: { type: 'text', label: 'Exclude Pattern', placeholder: '*.tmp,*.log' }
    }
  },
  scp: {
    title: 'SCP Transfer',
    description: 'Secure file transfer using SCP protocol',
    icon: 'bi-shield-lock',
    fields: {
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', default: '22' },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password' },
      privateKey: { type: 'textarea', label: 'Private Key' },
      sourcePath: { type: 'text', label: 'Source Path', required: true },
      destPath: { type: 'text', label: 'Destination Path', required: true },
      recursive: { type: 'checkbox', label: 'Recursive Transfer', default: false }
    }
  },
  tls: {
    title: 'TLS Certificate',
    description: 'Manage TLS certificates and configurations',
    icon: 'bi-shield-check',
    fields: {
      action: {
        type: 'select',
        label: 'Action',
        options: ['Generate', 'Renew', 'Revoke', 'Validate'],
        required: true
      },
      domain: { type: 'text', label: 'Domain', required: true },
      provider: {
        type: 'select',
        label: 'Certificate Provider',
        options: ['Let\'s Encrypt', 'DigiCert', 'GlobalSign', 'Custom'],
        required: true
      },
      validity: { type: 'number', label: 'Validity (days)', default: '365' },
      keySize: {
        type: 'select',
        label: 'Key Size',
        options: ['2048', '4096', '8192'],
        default: '2048'
      }
    }
  },
  killSql: {
    title: 'Kill SQL Query',
    description: 'Terminate long-running database queries',
    icon: 'bi-stop-circle',
    fields: {
      type: {
        type: 'select',
        label: 'Database Type',
        options: ['MySQL', 'PostgreSQL', 'MongoDB', 'Oracle', 'SQL Server'],
        required: true
      },
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', required: true },
      database: { type: 'text', label: 'Database Name', required: true },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password', required: true },
      queryId: { type: 'text', label: 'Query ID', required: true },
      force: { type: 'checkbox', label: 'Force Kill', default: false }
    }
  },
  tables: {
    title: 'Table Operations',
    description: 'Perform database table operations',
    icon: 'bi-table',
    fields: {
      type: {
        type: 'select',
        label: 'Database Type',
        options: ['MySQL', 'PostgreSQL', 'MongoDB', 'Oracle', 'SQL Server'],
        required: true
      },
      host: { type: 'text', label: 'Host', required: true },
      port: { type: 'number', label: 'Port', required: true },
      database: { type: 'text', label: 'Database Name', required: true },
      username: { type: 'text', label: 'Username', required: true },
      password: { type: 'password', label: 'Password', required: true },
      operation: {
        type: 'select',
        label: 'Operation',
        options: ['Create', 'Drop', 'Truncate', 'Optimize', 'Analyze'],
        required: true
      },
      tableName: { type: 'text', label: 'Table Name', required: true },
      backup: { type: 'checkbox', label: 'Backup Before Operation', default: true }
    }
  },
  networkSecurity: {
    title: 'Network Security',
    description: 'Network security and firewall operations',
    icon: 'bi-shield-lock-fill',
    fields: {
      action: {
        type: 'select',
        label: 'Action',
        options: ['Block IP', 'Unblock IP', 'Update Rules', 'Audit Logs'],
        required: true
      },
      target: { type: 'text', label: 'Target IP/Range', required: true },
      protocol: {
        type: 'select',
        label: 'Protocol',
        options: ['TCP', 'UDP', 'ICMP', 'All'],
        default: 'All'
      },
      port: { type: 'text', label: 'Port(s)', placeholder: 'e.g. 80,443 or 1-1000' },
      duration: { type: 'number', label: 'Duration (hours)', default: '24' }
    }
  },
  networkDiagnostics: {
    title: 'Network Diagnostics',
    description: 'Advanced network diagnostic tools',
    icon: 'bi-tools',
    fields: {
      tool: {
        type: 'select',
        label: 'Diagnostic Tool',
        options: ['Packet Capture', 'DNS Lookup', 'Route Trace', 'Bandwidth Test'],
        required: true
      },
      target: { type: 'text', label: 'Target Host/IP', required: true },
      duration: { type: 'number', label: 'Duration (seconds)', default: '30' },
      output: { type: 'text', label: 'Output File', required: true },
      options: { type: 'textarea', label: 'Additional Options', placeholder: 'Tool-specific options' }
    }
  }
};

const ITPA = ({ sidebarOpen, onLogout }) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(null);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onInit = useCallback(
    (instance) => {
      setReactFlowInstance(instance);
    },
    []
  );

  const onDragStart = (event, item) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(item));
    event.dataTransfer.effectAllowed = 'move';
    event.currentTarget.classList.add('dragging');
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    setIsDraggingOver(true);
  };

  const onDragLeave = () => {
    setIsDraggingOver(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const data = event.dataTransfer.getData('application/reactflow');
    if (!data || !reactFlowInstance) return;

    const item = JSON.parse(data);
    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    const newNode = {
      id: `${item.type}-${Date.now()}`,
      type: item.type,
      position,
      data: { 
        label: item.name,
        icon: item.icon,
        tag: item.type,
        config: {}
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Top,
      draggable: true,
    };

    setNodes((nds) => nds.concat(newNode));
    setSelectedNode(newNode);
  };

  const onNodeClick = (event, node) => {
    setSelectedNode(node);
  };

  const onDeleteNode = (nodeId) => {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    setEdges((eds) => eds.filter((edge) => 
      edge.source !== nodeId && edge.target !== nodeId
    ));
  };

  const onConfigChange = (nodeId, config) => {
    setNodes((nds) => 
      nds.map((node) => 
        node.id === nodeId 
          ? { ...node, data: { ...node.data, config } }
          : node
      )
    );
  };

  const showTaskInfo = (taskType) => {
    setShowInfoModal(taskType);
  };
  
  return (
    <div className="itpa-container">
      <div className="itpa-workspace">
        <div className="task-palette">
          <div className="task-palette-header">
            <h3>Task Palette</h3>
            <p>Drag tasks to the canvas</p>
          </div>
          
          <div className="task-category">
            <h4>Remote Access</h4>
            <div className="task-grid">
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'ssh', name: 'SSH Command', icon: 'bi-terminal' })}
                  className="task-item"
                >
                  <i className="bi bi-terminal"></i>
                  <span>SSH Command</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('ssh')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'telnet', name: 'Telnet Command', icon: 'bi-terminal-plus' })}
                  className="task-item"
                >
                  <i className="bi bi-terminal-plus"></i>
                  <span>Telnet Command</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('telnet')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="task-category">
            <h4>File Operations</h4>
            <div className="task-grid">
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'ftpUpload', name: 'FTP Upload', icon: 'bi-upload' })}
                  className="task-item"
                >
                  <i className="bi bi-upload"></i>
                  <span>FTP Upload</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('ftpUpload')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'ftpDownload', name: 'FTP Download', icon: 'bi-download' })}
                  className="task-item"
                >
                  <i className="bi bi-download"></i>
                  <span>FTP Download</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('ftpDownload')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'scp', name: 'SCP Transfer', icon: 'bi-shield-lock' })}
                  className="task-item"
                >
                  <i className="bi bi-shield-lock"></i>
                  <span>SCP Transfer</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('scp')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'compress', name: 'Compress Files', icon: 'bi-file-zip' })}
                  className="task-item"
                >
                  <i className="bi bi-file-zip"></i>
                  <span>Compress Files</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('compress')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="task-category">
            <h4>Security</h4>
            <div className="task-grid">
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'tls', name: 'TLS Certificate', icon: 'bi-shield-check' })}
                  className="task-item"
                >
                  <i className="bi bi-shield-check"></i>
                  <span>TLS Certificate</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('tls')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="task-category">
            <h4>Database</h4>
            <div className="task-grid">
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'dbQuery', name: 'Database Query', icon: 'bi-database' })}
                  className="task-item"
                >
                  <i className="bi bi-database"></i>
                  <span>Database Query</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('dbQuery')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'dbBackup', name: 'Database Backup', icon: 'bi-database-fill-check' })}
                  className="task-item"
                >
                  <i className="bi bi-database-fill-check"></i>
                  <span>Database Backup</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('dbBackup')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'killSql', name: 'Kill SQL Query', icon: 'bi-stop-circle' })}
                  className="task-item"
                >
                  <i className="bi bi-stop-circle"></i>
                  <span>Kill SQL Query</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('killSql')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'tables', name: 'Table Operations', icon: 'bi-table' })}
                  className="task-item"
                >
                  <i className="bi bi-table"></i>
                  <span>Table Operations</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('tables')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="task-category">
            <h4>Network</h4>
            <div className="task-grid">
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'networkScan', name: 'Network Scan', icon: 'bi-radar' })}
                  className="task-item"
                >
                  <i className="bi bi-radar"></i>
                  <span>Network Scan</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('networkScan')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'networkMonitor', name: 'Network Monitor', icon: 'bi-graph-up' })}
                  className="task-item"
                >
                  <i className="bi bi-graph-up"></i>
                  <span>Network Monitor</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('networkMonitor')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'networkSecurity', name: 'Network Security', icon: 'bi-shield-lock-fill' })}
                  className="task-item"
                >
                  <i className="bi bi-shield-lock-fill"></i>
                  <span>Network Security</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('networkSecurity')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
              <div className="task-item-wrapper">
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, { type: 'networkDiagnostics', name: 'Network Diagnostics', icon: 'bi-tools' })}
                  className="task-item"
                >
                  <i className="bi bi-tools"></i>
                  <span>Network Diagnostics</span>
                </div>
                <button 
                  className="info-button"
                  onClick={() => showTaskInfo('networkDiagnostics')}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <ReactFlowProvider>
          <div 
            className={`workflow-canvas ${isDraggingOver ? 'drag-over' : ''}`}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={onInit}
              nodeTypes={nodeTypes}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onNodeClick={onNodeClick}
              fitView
              snapToGrid
              snapGrid={[15, 15]}
            >
              <Background />
              <Controls />
            </ReactFlow>
          </div>
        </ReactFlowProvider>

        {selectedNode && (
          <div className="node-config-panel">
            <div className="panel-header">
              <h3>{taskInfo[selectedNode.data.tag]?.title || selectedNode.data.label}</h3>
              <button 
                className="delete-button"
                onClick={() => onDeleteNode(selectedNode.id)}
              >
                <i className="bi bi-trash"></i>
              </button>
            </div>
            <div className="panel-content">
              {taskInfo[selectedNode.data.tag]?.fields && 
                Object.entries(taskInfo[selectedNode.data.tag].fields).map(([key, field]) => (
                  <div key={key} className="form-field">
                    <label>{field.label}</label>
                    {field.type === 'select' ? (
                      <select
                        value={selectedNode.data.config[key] || field.default || ''}
                        onChange={(e) => onConfigChange(selectedNode.id, {
                          ...selectedNode.data.config,
                          [key]: e.target.value
                        })}
                        required={field.required}
                      >
                        <option value="">Select {field.label}</option>
                        {field.options.map((opt) => (
                          <option key={opt} value={opt}>{opt}</option>
                        ))}
                      </select>
                    ) : field.type === 'textarea' ? (
                      <textarea
                        value={selectedNode.data.config[key] || field.default || ''}
                        onChange={(e) => onConfigChange(selectedNode.id, {
                          ...selectedNode.data.config,
                          [key]: e.target.value
                        })}
                        required={field.required}
                      />
                    ) : (
                      <input
                        type={field.type}
                        value={selectedNode.data.config[key] || field.default || ''}
                        onChange={(e) => onConfigChange(selectedNode.id, {
                          ...selectedNode.data.config,
                          [key]: e.target.value
                        })}
                        required={field.required}
                      />
                    )}
                  </div>
                ))
              }
            </div>
          </div>
        )}

        {showInfoModal && (
          <div className="info-modal">
            <div className="modal-content">
              <div className="modal-header">
                <h3>
                  <i className={`bi ${taskInfo[showInfoModal].icon || 'bi-info-circle'}`}></i>
                  {taskInfo[showInfoModal].title}
                </h3>
                <button onClick={() => setShowInfoModal(null)}>
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-section">
                  <p>{taskInfo[showInfoModal].description}</p>
                </div>
                
                {Object.keys(taskInfo[showInfoModal].fields).length > 0 && (
                  <div className="modal-section">
                    <h4>
                      <i className="bi bi-list-check"></i>
                      Configuration Fields
                    </h4>
                    <div className="field-list">
                      {Object.entries(taskInfo[showInfoModal].fields).map(([key, field]) => (
                        <div key={key} className="field-item">
                          <label>{field.label}</label>
                          <span>
                            {field.type === 'select' ? (
                              'Select from: ' + field.options.join(', ')
                            ) : field.type === 'textarea' ? (
                              'Multi-line text'
                            ) : (
                              field.type.charAt(0).toUpperCase() + field.type.slice(1)
                            )}
                            {field.required && ' (Required)'}
                            {field.default && ` (Default: ${field.default})`}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ITPA; 