import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Hover.css';

const GraphOptionsDropdown = ({ selectedOption, onChange, options, handleExpand }) => {
	const [tooltipVisible, setTooltipVisible] = useState(false);
	return (
		<div className="graph-options-dropdown" style={dropdownStyle}>
			<Select
				value={selectedOption}
				onChange={onChange}
				options={options}
				placeholder="Select Option"
				styles={customStyles}
			/>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{handleExpand && (
					<button className="btn btn-outline-secondary expand-button" type="button" id="expandButton" onClick={handleExpand} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}
						style={{ background: '#1b2559', borderColor: '#007bff', color: '#fff', important: true }} >
						<i className="bi bi-box-arrow-up-right"></i>
					</button>
				)}
				{tooltipVisible && (
					<div className="button-tooltip">View Anomalies</div>)}
			</div>
		</div>
	);
};

GraphOptionsDropdown.propTypes = {
	selectedOption: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onButtonClick: PropTypes.func
};

const dropdownStyle = {
	display: 'flex',
	flexDirection: 'row',
	gap: '10px',
	fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif',
	fontSize: '14px',
	fontWeight: '600',
	marginBottom: '10px',
	width: 'auto'
}

const customStyles = {
	control: (base, state) => ({
	  ...base,
	  minHeight: '40px',
	  minWidth: '150px',
	  borderColor: state.isFocused ? '#1b2559' : 'grey',
	  boxShadow: state.isFocused ? '0 0 0 1px #1b2559' : 'none',
	}),
	valueContainer: (base) => ({
	  ...base,
	  padding: '0 8px'
	}),
	input: (base) => ({
	  ...base,
	  color: 'black',
	}),
	placeholder: (base) => ({
	  ...base,
	  color: 'black',
	}),
	dropdownIndicator: (base) => ({
	  ...base,
	  color: 'black'
	}),
	clearIndicator: (base) => ({
	  ...base,
	  color: 'red'
	}),
	option: (base, state) => ({
	  ...base,
	  backgroundColor: state.isSelected ? '#1b2559' : 'white',
	  color: state.isSelected ? 'white' : 'black',
	})
  };
  
export default GraphOptionsDropdown;


