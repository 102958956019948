import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Dropdownnew.css';
import './Scores.css';
import './Graph.css';
import './Health.css';
import LazyGraph from './features/LazyGraph';
import Graph from './features/Graph';
import MultiGraph from './features/MultiGraph';
import { formatDateTime } from './features/utils';
import Anomaly from './Anomaly';
//import MetricChart from './MetricChart';
import GraphOptionsDropdown from './features/GraphOptionsDropdown';
import CustomDropdown from './CustomDropdown';
import ZoomControls from './features/ZoomControls';


const options = [
    // { value: 'Basic Alert', label: 'Basic Alert' },
    { value: 'Collective Insight', label: 'Collective Insight' }
];

function Dropdownlatest({ sidebarOpen, navigate, onLogout }) {
    const localDataSource = localStorage.getItem('DataSource') || '';
    const localServer = localStorage.getItem('Server') || '';

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataSources, setDataSources] = useState([]);
    const [servers, setServers] = useState([]);

    //const [selectedDataSource, setSelectedDataSource] = useState(localDataSource || '100');
    const [selectedDataSource, setSelectedDataSource] = useState(localDataSource || '');
    const [selectedServer, setSelectedServer] = useState(localServer || '');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [lastDuration, setLastDuration] = useState('6 hours');
    const [selectedOptions, setSelectedOptions] = useState(
        Array(8).fill(options[0])
    );
    const [refreshKey, setRefreshKey] = useState(0);
    const [anomalyCount, setAnomalyCount] = useState({ basic: 0, strict: 0 });
    const [metrics, setMetrics] = useState([]);

     const [zoomHistory, setZoomHistory] = useState([]); // Store zoom levels

    useEffect(() => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3MzQzNjQyODgsImV4cCI6MTczNDM2Nzg4OH0.v5LU-SRonKGebuo40R7Nugb8na-sNDdEQoxxD5jltcU';

        const fetchMetrics = async () => {
          try {
            setLoading(true);
            const response = await fetch(
              `https://infia.enviseer.com/api/clusters/${selectedDataSource}/hosts/${selectedServer}/metrics`,
              {
                headers: {
                  'Authorization': `Bearer ${token}`, // Adding the Authorization header
                  'Accept': 'application/json',
                },
              }
            );
    
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            setMetrics(data.metrics || []);
          } catch (err) {
            setError('Failed to fetch metrics');
          } finally {
            setLoading(false);
          }
        };
    
        fetchMetrics();
      }, [selectedDataSource,selectedServer]);

    useEffect(() => {
        const currentTime = new Date();
        const fiveMinutesAgo = new Date(currentTime.getTime() - 6 * 60 * 60000);
        setEndTime(currentTime);
        setStartTime(fiveMinutesAgo);
    }, []);

    const fetchClusters = useCallback(async () => {

        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        const username = getCookie('username');
        const email = decodeURIComponent(username);
        const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
        //console.log("Cluster", url);
        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            setDataSources(data);
            const storedDataSource = localStorage.getItem('DataSource');
        if (storedDataSource && data.includes(storedDataSource)) {
            setSelectedDataSource(storedDataSource);
        } else if (data.length > 0) {
            setSelectedDataSource(data[0]);
            localStorage.setItem('DataSource', data[0]);
        }

        // Fetch servers only after setting the data source
        fetchServers(storedDataSource || data[0]);
        } catch (error) {
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchServers = useCallback(async (dataSource) => {
        const url = `https://infia.enviseer.com/api/clusters/${dataSource}/hostnames`;
        //console.log(url);
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
        {data&&        setServers(data);

        // Check local storage for previously selected server
        const storedServer = localStorage.getItem('Server');
        if (storedServer && data.includes(storedServer)) {
            setSelectedServer(storedServer);
        } else if (data.length > 0) {
            setSelectedServer(data[0]);
            localStorage.setItem('Server', data[0]);
        }}
        } catch (error) {
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchClusters();
    }, [fetchClusters]);

    // ✅ Fetch servers only when `selectedDataSource` is set
    useEffect(() => {
        if (selectedDataSource) {
            fetchServers(selectedDataSource);
        }
    }, [selectedDataSource, fetchServers]);

    // ✅ Handles Cluster Selection Change
    const handleDataSourceChange = (event) => {
        const selected = event.target.value; // Get selected value from event
        //console.log("Changing Data Source to:", selected); 
    
        setSelectedDataSource(selected);
        localStorage.setItem('DataSource', selected);
    
        // Ensure fetchServers runs AFTER state update
        fetchServers(selected)
            .then(() => {
                    setSelectedServer('');
            })
            .catch(error => {
                console.error("Error fetching servers:", error);
            });
    
        setRefreshKey(prev => prev + 1);
    };
    
    
    const handleServerChange = (event) => {
        const selected = event.target.value;
        setSelectedServer(selected);
        localStorage.setItem('Server', selected);
        setRefreshKey(prevKey => prevKey + 1);
    };
    

    const handleStartTimeChange = (selectedStartTime) => {
        const currentTime = new Date();
    
        if (selectedStartTime > currentTime) {
            setErrorMessage('Start time cannot be in the future. Setting start and end times to the current time.');
            setStartTime(currentTime);
            setEndTime(currentTime);
            return;
        }
    
        setStartTime(selectedStartTime);
        
        if (!endTime || selectedStartTime > endTime) {
            setEndTime(selectedStartTime);
        }
    
        setErrorMessage('');
    };
    
    

    const handleEndTimeChange = (selectedEndTime) => {

            //console.log("wwwwwwwwwwwwwwwwwwwwww",selectedEndTime);
        const currentTime = new Date();
      
        if (!startTime) {
          setErrorMessage('Please set a start time first.');
          return;
        }
      
        // Normalize dates for accurate comparison (removes time component)
        const startDateOnly = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
        const endDateOnly = new Date(selectedEndTime.getFullYear(), selectedEndTime.getMonth(), selectedEndTime.getDate());
      
        if (endDateOnly < startDateOnly) {
          setErrorMessage('End date should be later than or equal to the start date.');
          return;
        }
      
        // Same date, check time comparison
        if (endDateOnly.getTime() === startDateOnly.getTime() && selectedEndTime <= startTime) {
          setErrorMessage('End time should be later than start time.');
          return;
        }
      
        if (selectedEndTime > currentTime) {
          setErrorMessage('End time cannot be in the future. Setting end time to the current time.');
          setEndTime(currentTime);
        } else {
          setErrorMessage('');  // Clear any existing error messages
          setEndTime(selectedEndTime);
        }
      };
      

    const filterPassedTime = (time) => {
        const currentTime = new Date();
        const selectedTime = new Date(time);
        return currentTime.getTime() >= selectedTime.getTime();
    };


    const handleLastDurationChange = (event) => {
        const selectedDuration = event.target.value;
        setLastDuration(selectedDuration);

        const currentTime = new Date();
        let newStartTime = null;
        let newEndTime = currentTime;

        switch (selectedDuration) {
            case '5 minutes':
                newStartTime = new Date(currentTime.getTime() - 5 * 60000);
                break;
            case '10 minutes':
                newStartTime = new Date(currentTime.getTime() - 10 * 60000);
                break;
            case '30 minutes':
                newStartTime = new Date(currentTime.getTime() - 30 * 60000);
                break;
            case '1 hour':
                newStartTime = new Date(currentTime.getTime() - 60 * 60000);
                break;
            case '2 hours':
                newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
                break;
            case '4 hours':
                newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
                break;
            case '6 hours':
                newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
                break;
            case '12 hours':
                newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
                break;
            case '1 day':
                newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
                break;
            case '3 days':
                newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
                break;
            case '1 week':
                newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
                break;
            case 'custom':
                newStartTime = null;
                newEndTime = null;
                break;
            default:
                newStartTime = startTime;
                newEndTime = endTime;
                break;
        }

        setStartTime(newStartTime);
        setEndTime(newEndTime);
    };

    const dismissErrorMessage = () => {
        setErrorMessage('');
    };

    const handleDropdownChange = (index, selectedOption) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = selectedOption;
        setSelectedOptions(newSelectedOptions);
    };
    const updateAnomalyCount = (count) => {
        setAnomalyCount(count);
    };
    const updateAvgScore = (Score) => {

    };
    
    const handleRefresh = () => {
        // Get the current time - Reassigned in the case of custom
        let currentTime = new Date();

        // Calculate the new start time based on the selected duration
        let newStartTime = null;

        switch (lastDuration) {
            case '5 minutes':
                newStartTime = new Date(currentTime.getTime() - 5 * 60000);
                break;
            case '10 minutes':
                newStartTime = new Date(currentTime.getTime() - 10 * 60000);
                break;
            case '30 minutes':
                newStartTime = new Date(currentTime.getTime() - 30 * 60000);
                break;
            case '1 hour':
                newStartTime = new Date(currentTime.getTime() - 60 * 60000);
                break;
            case '2 hours':
                newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
                break;
            case '4 hours':
                newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
                break;
            case '6 hours':
                newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
                break;
            case '12 hours':
                newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
                break;
            case '1 day':
                newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
                break;
            case '3 days':
                newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
                break;
            case '1 week':
                newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
                break;
            case 'custom':
                // If custom is selected, don't update startTime or endTime
                newStartTime = startTime;
                currentTime = endTime;
                break;
            default:
                newStartTime = startTime;
                currentTime = endTime;
                break;
        }

        // Update the state with the new times
        setStartTime(newStartTime);
        setEndTime(currentTime);

        // Increment refreshKey to trigger re-fetching of data
        setRefreshKey(prevKey => prevKey + 1);
    };

    const handleclick = () => {
        if (dataSources.length === 0) {
            alert('Please contact your administrator. No clusters available.');
        }
    };

    const onZoom = (newStartDate, newEndDate) => {
        const timeDifference = (newEndDate - newStartDate) / (1000 * 60); // Convert milliseconds to minutes
    
        if (timeDifference < 5) {
            alert("Please select at least a 5-minute range.");
            return; // Prevent setting the time range
        }
        setZoomHistory((prevHistory) => [
            ...prevHistory,
            { start: startTime, end: endTime }
        ]);

    
        setStartTime(newStartDate);
        setEndTime(newEndDate);
        setLastDuration('custom');
        
    };
       const handleUndoZoom = () => {
    if (zoomHistory.length >= 1) {
      const prevZoom = zoomHistory[zoomHistory.length - 1];
      setZoomHistory(zoomHistory.slice(0, zoomHistory.length - 1)); // Remove last zoom
      setStartTime(prevZoom.start);
      setEndTime(prevZoom.end);
      setRefreshKey((prevKey) => prevKey + 1); // Refresh graph
    }
  };


  // Handle Reset Zoom (Full Reset)
  const handleResetZoom = () => {
    if (zoomHistory.length >= 1) {
        const prevZoom = zoomHistory[0];
        setZoomHistory([]); // Remove last zoom
        setStartTime(prevZoom.start);
        setEndTime(prevZoom.end);
        setRefreshKey((prevKey) => prevKey + 1); // Refresh graph
      }
  };

    return (
        <div>
            <div className="dashboard">

                <div className="dropdowns" style={{ width: sidebarOpen ? '90%' : '96%', justifyContent:'space-between' }}>
                    <div className="dropdown-contents">

                        <div className="dropdown">
                        <CustomDropdown
                    label="Clusters"
                    options={dataSources.length > 0 ? dataSources.map(source => ({ value: source, label: source })) : []}
                    value={selectedDataSource || ''}
                    onChange={handleDataSourceChange}
                />
                        </div>

                        <div className="dropdown">
                        <CustomDropdown
                    label="Servers"
                    options={servers.length > 0 ? servers.map(server => ({ value: server, label: server })) : []}
                    value={selectedServer || ''}
                    onChange={handleServerChange}
                    disabled={servers.length === 0} // ✅ Disable if no servers available
                />
                        </div>

                        <div className="dropdown">
                            <CustomDropdown
                                label="Duration"
                                options={[
                                    { value: '5 minutes', label: '5 minutes' },
                                    { value: '10 minutes', label: '10 minutes' },
                                    { value: '30 minutes', label: '30 minutes' },
                                    { value: '1 hour', label: '1 hour' },
                                    { value: '2 hours', label: '2 hours' },
                                    { value: '4 hours', label: '4 hours' },
                                    { value: '6 hours', label: '6 hours' },
                                    { value: '12 hours', label: '12 hours' },
                                    { value: '1 day', label: '1 day' },
                                    { value: '3 days', label: '3 days' },
                                    { value: '1 week', label: '1 week' },
                                    { value: 'custom', label: 'custom' }
                                ]}
                                value={lastDuration}
                                onChange={(event) => handleLastDurationChange(event)}
                            />
                        </div>

                        {lastDuration === 'custom' && (
                            <>
                                <div className="dropdown">
                                    <DatePicker
                                        selected={startTime}
                                        onChange={handleStartTimeChange}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        timeFormat="HH:mm:ss"
                                        timeCaption="Time"
                                        placeholderText="Select start time"
                                        className={`react-datepicker__input-container ${startTime ? 'selected' : ''}`}
                                        timeIntervals={5}
                                        maxDate={new Date()}
                                        filterTime={filterPassedTime}
                                        dayClassName={(date) => {
                                            if (date.getTime() > new Date().getTime()) {
                                                return 'react-datepicker__day--disabled';
                                            }
                                            return '';
                                        }}
                                        timeClassName={(time) => {
                                            if (new Date(time).getTime() > new Date().getTime()) {
                                                return 'react-datepicker__time-list-item--disabled';
                                            }
                                            return '';
                                        }}
                                    />
                                </div>
                                <div className="dropdown">
                                    <DatePicker
                                        selected={endTime}
                                        onChange={handleEndTimeChange}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        timeFormat="HH:mm:ss"
                                        timeCaption="Time"
                                        placeholderText="Select end time"
                                        className={`react-datepicker__input-container ${endTime ? 'selected' : ''}`}
                                        timeIntervals={5}
                                        maxDate={new Date()}
                                        filterTime={filterPassedTime}
                                        dayClassName={(date) => {
                                            if (date.getTime() > new Date().getTime()) {
                                                return 'react-datepicker__day--disabled';
                                            }
                                            return '';
                                        }}
                                        timeClassName={(time) => {
                                            if (new Date(time).getTime() > new Date().getTime()) {
                                                return 'react-datepicker__time-list-item--disabled';
                                            }
                                            return '';
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        <div>
                            <button onClick={handleRefresh} className="refresh-button">
                                <i className="bi bi-arrow-clockwise"></i>
                            </button>
                        </div>
                        <ZoomControls 
        handleUndoZoom={handleUndoZoom} 
        handleResetZoom={handleResetZoom} 
        zoomHistory = {zoomHistory}
      />

                        {errorMessage && (
                            <div className="error-message-container">
                                <p>
                                    <i className="bi bi-exclamation-circle-fill" style={{ marginRight: '8px' }}></i>
                                    {errorMessage}
                                    <button onClick={dismissErrorMessage} style={{ background: 'none', border: 'none', color: '#B71C1C', cursor: 'pointer', marginLeft: '8px' }}>
                                        <i className="bi bi-x-circle-fill"></i>
                                    </button>
                                </p>
                            </div>
                        )}

                    </div>
                </div>

                <Anomaly
                    key={refreshKey}
                    selectedDataSource={selectedDataSource}
                    selectedServer={selectedServer}
                    startTime={startTime}
                    endTime={endTime}
                    navigate={navigate}
                    onZoom = {onZoom}
                />
                {/* <div className="graphs">
                {metrics.filter(metric => metric !== 'uptime_seconds').map((metric) => (
        <div className="graph-total" key={metric}>
          <p>{metric.replace('_', ' ').toUpperCase()}</p>
          <div className="graph">
          <LazyGraph
    key={`${metric}-${refreshKey}`}
    dataSource={selectedDataSource}
    server={selectedServer}
    startDate={startTime}
    endDate={endTime}
    metricName={metric}
    label={metric.replace('_', ' ').toUpperCase()}
    borderColor="rgba(122, 38, 230, 1)"
    backgroundColor="rgba(122, 38, 230, 0.3)"
    onZoom={onZoom}
/>

          </div>
        </div>
      ))}
    </div> */}
            </div>
        </div >
    );
}

export default Dropdownlatest;

