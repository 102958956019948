import React, { useState } from 'react';
import styles from './BarChart.module.css'; // Importing CSS module

const getGradientColor = (value) => {
  if (value >= 8) return 'linear-gradient(to right, #32CD32, #228B22)'; // Green gradient
  if (value >= 6 && value < 8) return 'linear-gradient(to right, #FFD700, #FFA500)'; // Yellow gradient
  return 'linear-gradient(to right, #FF6347, #FF4500)'; // Red gradient
};

const getScoreColor = (value) => {
  if (value >= 8) return '#228B22'; // Green
  if (value >= 6 && value < 8) return '#FFA500'; // Yellow
  return '#FF4500'; // Red
};

const BarChart = ({ clusters, updateSelectedCluster, selectedDataSource, dataType }) => {
  const [activeFilter, setActiveFilter] = useState('all');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState('none');

  const handleFilterClick = (filterName) => {
    setActiveFilter(filterName);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'highToLow' ? 'lowToHigh' : 'highToLow'));
  };

  const sortClusters = (clusters) => {
    if (sortOrder === 'lowToHigh') {
      return [...clusters].sort((a, b) => a.enviscore - b.enviscore);
    } else if (sortOrder === 'highToLow') {
      return [...clusters].sort((a, b) => b.enviscore - a.enviscore);
    }
    return clusters;
  };

  const filteredClusters = sortClusters(
    activeFilter === 'critical'
      ? clusters.filter(({ enviscore }) => enviscore < 6)
      : activeFilter === 'moderate'
      ? clusters.filter(({ enviscore }) => enviscore >= 6 && enviscore < 8)
      : activeFilter === 'optimized'
      ? clusters.filter(({ enviscore }) => enviscore >= 8)
      : clusters
  );

  return (
    <div className={styles.barchartContainer}>
      <div className={styles.barChartHeaders}>
        {dataType === 'clusters' ? <h3>Applications</h3> : <h3>Hosts</h3>}
        <div className={styles.filterButton} onClick={toggleFilters}>
          <span style={{ marginRight: '8px' }}>Filters</span>
          {filtersVisible ? (
            <i className="bi bi-caret-up-square"></i>
          ) : (
            <i className="bi bi-caret-down-square"></i>
          )}
        </div>
      </div>

      <div className={styles.filtersContainer}>
        {filtersVisible && (
          <div className={`${styles.filters} ${filtersVisible ? styles.active : ''}`}>
            <div
              className={`${styles.filterTab} ${activeFilter === 'all' ? styles.active : ''}`}
              onClick={() => handleFilterClick('all')}
            >
              All
            </div>
            <div
              className={`${styles.filterTab} ${activeFilter === 'critical' ? styles.active : ''}`}
              onClick={() => handleFilterClick('critical')}
            >
              Critical
            </div>
            <div
              className={`${styles.filterTab} ${activeFilter === 'moderate' ? styles.active : ''}`}
              onClick={() => handleFilterClick('moderate')}
            >
              Moderate
            </div>
            <div
              className={`${styles.filterTab} ${activeFilter === 'optimized' ? styles.active : ''}`}
              onClick={() => handleFilterClick('optimized')}
            >
              Optimized
            </div>
          </div>
        )}
      </div>

      <div className={styles.sortHeader}>
        {dataType === 'clusters' ? <div>Total Applications: {filteredClusters.length}</div> : <div>Total Hosts: {filteredClusters.length}</div>}
        <div onClick={toggleSortOrder}>
          {dataType === 'clusters' ? <span>EnviScore</span> : <span>EnviVital</span>}
          {sortOrder === 'highToLow' ? (
            <i className="bi bi-arrow-down" style={{ marginLeft: '5px', cursor: 'pointer' }}></i>
          ) : (
            <i className="bi bi-arrow-up" style={{ marginLeft: '5px', cursor: 'pointer' }}></i>
          )}
        </div>
      </div>

      <div className={styles.chartHorizontal}>
        {Array.isArray(filteredClusters) &&
          filteredClusters.map(({ cluster, enviscore }) => (
            <div
              key={cluster}
              className={`${styles.barHorizontalContainer} ${selectedDataSource === cluster ? styles.selected : ''}`}
              onClick={() => updateSelectedCluster(cluster)}
            >
              <div className={styles.clusterLabel}>{cluster}</div>
              <div className={styles.barBackground}>
                <div
                  className={styles.barHorizontal}
                  style={{
                    width: `${enviscore * 10}%`,
                    background: getGradientColor(enviscore),
                  }}
                ></div>
              </div>
              <div
                className={styles.valueLabel}
                style={{ color: getScoreColor(enviscore) }}
              >
                {dataType === 'clusters' ? enviscore.toFixed(2) : enviscore}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BarChart;
