// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expand-button {
    background: #1b2559;
    border-color: #007bff;
    color: #fff;
    transition: width 0.5s ease;
    width: 50px;
    cursor: pointer;
    border-radius: 25px;
}

.expand-button:hover {
    width: 70px;
}

.button-tooltip {
    position: absolute;
    top: 100%; 
    left: 50%; 
    transform: translate(10%, 15%);
    background-color: #333;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: none;
    text-align: center;
    min-width: 125px;
}

.expand-button:hover + .button-tooltip {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/features/Hover.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;IACX,2BAA2B;IAC3B,WAAW;IACX,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,8BAA8B;IAC9B,sBAAsB;IACtB,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,qCAAqC;IACrC,aAAa;IACb,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".expand-button {\n    background: #1b2559;\n    border-color: #007bff;\n    color: #fff;\n    transition: width 0.5s ease;\n    width: 50px;\n    cursor: pointer;\n    border-radius: 25px;\n}\n\n.expand-button:hover {\n    width: 70px;\n}\n\n.button-tooltip {\n    position: absolute;\n    top: 100%; \n    left: 50%; \n    transform: translate(10%, 15%);\n    background-color: #333;\n    color: #ffffff;\n    padding: 5px 10px;\n    border-radius: 25px;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n    display: none;\n    text-align: center;\n    min-width: 125px;\n}\n\n.expand-button:hover + .button-tooltip {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
