import React, { useState, useEffect } from "react";
import "./correlation.css";
import { FaInfoCircle, FaExclamationTriangle, FaServer, FaGlobe, FaDatabase, FaSearch, FaWrench, FaChartLine, FaExclamationCircle, FaCheckCircle, FaCalendarAlt, FaClock } from 'react-icons/fa';
import incidentData from './data/dummyIncidentData';
import FlowDiagram from "./features/flowDiagram";

// Function to convert Markdown to HTML
const convertMarkdownToHtml = (markdown) => {
  if (!markdown) return '';
  
  // Handle bold
  let html = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  
  // Handle italics
  html = html.replace(/\*(.*?)\*/g, '<em>$1</em>');
  
  // Handle code blocks
  html = html.replace(/`(.*?)`/g, '<code>$1</code>');
  
  // Handle lists
  html = html.replace(/- (.*?)(\n|$)/g, '<li>$1</li>');
  
  // Handle headings
  html = html.replace(/\n\*\*(.*?):\*\*/g, '<h4>$1:</h4>');
  
  // Handle paragraphs
  html = html.split('\n\n').map(para => {
    // Skip if already a heading or list
    if (para.startsWith('<h') || para.includes('<li>')) {
      return para;
    }
    return `<p>${para}</p>`;
  }).join('');
  
  // Wrap lists
  if (html.includes('<li>')) {
    html = html.replace(/<li>(.*?)<\/li>/g, '<ul><li>$1</li></ul>');
    html = html.replace(/<\/ul><ul>/g, '');
  }
  
  return html;
};

const Correlation = () => {
  const [incidents, setIncidents] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const [eventTimestamps, setEventTimestamps] = useState({});
  const [alertTimestamps, setAlertTimestamps] = useState({});
  const [searchText, setSearchText] = useState("");  // Restored searchText state
  const [popupVisible, setPopupVisible] = useState(false);
  const [alertsCountMap, setAlertsCountMap] = useState({});

  useEffect(() => {
    const fetchIncidents = async () => {
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3NDM0MzAyMDEsImV4cCI6MTc0MzQzMzgwMX0.eMuy5kw2wZiErPvZsIoQTizH8HoA4R9THjSSTRZ0CbU';
      try {
        // Fetch incidents
        const incidentsResponse = await fetch(
          "https://infia.enviseer.com/api/alert/get-buckets",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cluster: "100" }),
          }
        );

        if (!incidentsResponse.ok) {
          throw new Error(`HTTP error! status: ${incidentsResponse.status}`);
        }

        //const incidentsData = await incidentsResponse.json();
        const incidentsData ={
          "buckets": [
              {
                  "id": 1,
                  "name": "100-test_server-stage-win",
                  "app": "100",
                  "server": "test_server",
                  "region": "ap-south-1",
                  "env": "staging",
                  "os": "windows",
                  "is_closed": true
              },
              {
                  "id": 2,
                  "name": "100-infia-prod-linux",
                  "app": "100",
                  "server": "infia",
                  "region": "ap-south-1",
                  "env": "production",
                  "os": "linux",
                  "is_closed": true
              },
              {
                  "id": 3,
                  "name": "100-envibuddy-dev-linux",
                  "app": "100",
                  "server": "Envibuddy-server",
                  "region": "ap-south-2",
                  "env": "development",
                  "os": "linux",
                  "is_closed": true
              },
              {
                  "id": 4,
                  "name": "100-postgres-test",
                  "app": "100",
                  "server": "Postgres",
                  "region": "ap-south-2",
                  "env": "testing",
                  "os": "macos",
                  "is_closed": true
              },
              {
                  "id": 5,
                  "name": "100-test_server-stage-win-2",
                  "app": "100",
                  "server": "test_server",
                  "region": "ap-south-1",
                  "env": "staging",
                  "os": "windows",
                  "is_closed": true
              },
              {
                  "id": 6,
                  "name": "100-infia-prod-linux-2",
                  "app": "100",
                  "server": "infia",
                  "region": "ap-south-2",
                  "env": "production",
                  "os": "linux",
                  "is_closed": true
              },
              {
                  "id": 7,
                  "name": "100-envibuddy-dev-linux-2",
                  "app": "100",
                  "server": "Envibuddy-server",
                  "region": "ap-south-2",
                  "env": "development",
                  "os": "linux",
                  "is_closed": true
              },
              {
                  "id": 8,
                  "name": "100-postgres-test-2",
                  "app": "100",
                  "server": "Postgres",
                  "region": "ap-south-1",
                  "env": "testing",
                  "os": "macos",
                  "is_closed": true
              },
              {
                  "id": 9,
                  "name": "100-test_server-dev-win",
                  "app": "100",
                  "server": "test_server",
                  "region": "ap-south-2",
                  "env": "development",
                  "os": "windows",
                  "is_closed": true
              },
              {
                  "id": 10,
                  "name": "100-infia-prod-linux-3",
                  "app": "100",
                  "server": "infia",
                  "region": "ap-south-1",
                  "env": "production",
                  "os": "linux",
                  "is_closed": true
              },
          ]
      }
;      

        const getEnvironmentText = () => {
          return Math.random() < 0.5 ? 'staging' : 'production';
        };

        const mappedIncidents = incidentsData.buckets.map((bucket) => {
          // Ensure environment is either staging or production
          const environment = bucket.env === 'development' || bucket.env === 'testing' ? 'staging' : 
                            bucket.env === 'production' ? 'production' : 'staging';
          
          // Format OS to be lowercase
          const os = bucket.os.toLowerCase();

          return {
            id: bucket.id,
            alert_name: `${bucket.app}-${bucket.server.toLowerCase()}-${environment}-${os}`,
            host: bucket.server,
            region: bucket.region,
            dataSource: bucket.app,
            status: bucket.is_closed ? "Resolved" : "Open",
            description: `Environment: ${environment}, OS: ${os}`,
            env: environment,  // Add this for proper environment tracking
            os: os  // Add this for proper OS tracking
          };
        });

        setIncidents(mappedIncidents);

        mappedIncidents && setSelectedIncident(mappedIncidents[0]);


        // Fetch alerts for each bucket
        const alertCounts = {};
        await Promise.all(
          mappedIncidents.map(async (incident) => {
            const alerts = await fetchAlertsByBucketId(incident.id, token);
            alertCounts[incident.id] = alerts.length;
          })
        );

        setAlertsCountMap(alertCounts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchIncidents();
  }, []);


  const jsonData = {
    "alerts": [
      {
        "id": 1,
        "bucket_id": 5,
        "description": "metric=cpu_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T23:23:06.863407Z",
        "updated_at": "2025-04-01T23:23:06.863407Z"
      },
      {
        "id": 2,
        "bucket_id": 3,
        "description": "metric=network_latency, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T23:06:11.863407Z",
        "updated_at": "2025-04-01T23:06:11.863407Z"
      },
      {
        "id": 3,
        "bucket_id": 6,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T23:38:35.863407Z",
        "updated_at": "2025-03-31T23:38:35.863407Z"
      },
      {
        "id": 4,
        "bucket_id": 3,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T01:36:47.863407Z",
        "updated_at": "2025-04-01T01:36:47.863407Z"
      },
      {
        "id": 5,
        "bucket_id": 1,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T22:45:58.863407Z",
        "updated_at": "2025-04-01T22:45:58.863407Z"
      },
      {
        "id": 6,
        "bucket_id": 6,
        "description": "metric=memory_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T15:06:19.863407Z",
        "updated_at": "2025-04-01T15:06:19.863407Z"
      },
      {
        "id": 7,
        "bucket_id": 5,
        "description": "metric=network_latency, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T21:32:01.863407Z",
        "updated_at": "2025-03-31T21:32:01.863407Z"
      },
      {
        "id": 8,
        "bucket_id": 3,
        "description": "metric=disk_space, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T14:05:53.863407Z",
        "updated_at": "2025-03-31T14:05:53.863407Z"
      },
      {
        "id": 9,
        "bucket_id": 10,
        "description": "metric=cpu_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T05:01:56.863407Z",
        "updated_at": "2025-04-01T05:01:56.863407Z"
      },
      {
        "id": 10,
        "bucket_id": 4,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T17:33:21.863407Z",
        "updated_at": "2025-03-31T17:33:21.863407Z"
      },
      {
        "id": 11,
        "bucket_id": 9,
        "description": "metric=cpu_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T01:51:29.863407Z",
        "updated_at": "2025-04-01T01:51:29.863407Z"
      },
      {
        "id": 12,
        "bucket_id": 1,
        "description": "metric=cpu_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T08:47:56.863407Z",
        "updated_at": "2025-03-31T08:47:56.863407Z"
      },
      {
        "id": 13,
        "bucket_id": 1,
        "description": "metric=cpu_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T15:56:00.863407Z",
        "updated_at": "2025-04-01T15:56:00.863407Z"
      },
      {
        "id": 14,
        "bucket_id": 3,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T09:09:00.863407Z",
        "updated_at": "2025-04-01T09:09:00.863407Z"
      },
      {
        "id": 15,
        "bucket_id": 9,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T17:48:01.863407Z",
        "updated_at": "2025-04-01T17:48:01.863407Z"
      },
      {
        "id": 16,
        "bucket_id": 5,
        "description": "metric=memory_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T22:48:23.863407Z",
        "updated_at": "2025-04-01T22:48:23.863407Z"
      },
      {
        "id": 17,
        "bucket_id": 3,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T17:52:49.863407Z",
        "updated_at": "2025-03-31T17:52:49.863407Z"
      },
      {
        "id": 18,
        "bucket_id": 6,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T18:27:02.863407Z",
        "updated_at": "2025-03-31T18:27:02.863407Z"
      },
      {
        "id": 19,
        "bucket_id": 7,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T15:24:40.863407Z",
        "updated_at": "2025-04-01T15:24:40.863407Z"
      },
      {
        "id": 20,
        "bucket_id": 4,
        "description": "metric=memory_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T16:46:40.863407Z",
        "updated_at": "2025-03-31T16:46:40.863407Z"
      },
      {
        "id": 21,
        "bucket_id": 9,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T19:54:12.863407Z",
        "updated_at": "2025-03-31T19:54:12.863407Z"
      },
      {
        "id": 22,
        "bucket_id": 10,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T02:47:47.863407Z",
        "updated_at": "2025-04-01T02:47:47.863407Z"
      },
      {
        "id": 23,
        "bucket_id": 8,
        "description": "metric=network_latency, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T09:48:27.863407Z",
        "updated_at": "2025-04-01T09:48:27.863407Z"
      },
      {
        "id": 24,
        "bucket_id": 10,
        "description": "metric=cpu_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T17:06:01.863407Z",
        "updated_at": "2025-03-31T17:06:01.863407Z"
      },
      {
        "id": 25,
        "bucket_id": 3,
        "description": "metric=memory_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T22:36:41.863407Z",
        "updated_at": "2025-03-31T22:36:41.863407Z"
      },
      {
        "id": 26,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T15:23:13.863407Z",
        "updated_at": "2025-04-01T15:23:13.863407Z"
      },
      {
        "id": 27,
        "bucket_id": 5,
        "description": "metric=memory_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T22:29:58.863407Z",
        "updated_at": "2025-03-31T22:29:58.863407Z"
      },
      {
        "id": 28,
        "bucket_id": 10,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T21:11:01.863407Z",
        "updated_at": "2025-04-01T21:11:01.863407Z"
      },
      {
        "id": 29,
        "bucket_id": 1,
        "description": "metric=cpu_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T22:28:20.863407Z",
        "updated_at": "2025-04-01T22:28:20.863407Z"
      },
      {
        "id": 30,
        "bucket_id": 2,
        "description": "metric=network_latency, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T05:42:44.863407Z",
        "updated_at": "2025-03-31T05:42:44.863407Z"
      },
      {
        "id": 31,
        "bucket_id": 3,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T06:51:47.863407Z",
        "updated_at": "2025-04-01T06:51:47.863407Z"
      },
      {
        "id": 32,
        "bucket_id": 4,
        "description": "metric=cpu_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T08:01:43.863407Z",
        "updated_at": "2025-04-01T08:01:43.863407Z"
      },
      {
        "id": 33,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T21:12:54.863407Z",
        "updated_at": "2025-04-01T21:12:54.863407Z"
      },
      {
        "id": 34,
        "bucket_id": 1,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T18:07:56.863407Z",
        "updated_at": "2025-03-31T18:07:56.863407Z"
      },
      {
        "id": 35,
        "bucket_id": 2,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T17:16:48.863407Z",
        "updated_at": "2025-04-01T17:16:48.863407Z"
      },
      {
        "id": 36,
        "bucket_id": 10,
        "description": "metric=network_latency, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T05:48:51.863407Z",
        "updated_at": "2025-03-31T05:48:51.863407Z"
      },
      {
        "id": 37,
        "bucket_id": 10,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T06:06:14.863407Z",
        "updated_at": "2025-04-01T06:06:14.863407Z"
      },
      {
        "id": 38,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T13:56:18.863407Z",
        "updated_at": "2025-04-01T13:56:18.863407Z"
      },
      {
        "id": 39,
        "bucket_id": 5,
        "description": "metric=memory_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T23:15:11.863407Z",
        "updated_at": "2025-03-31T23:15:11.863407Z"
      },
      {
        "id": 40,
        "bucket_id": 4,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T18:49:16.863407Z",
        "updated_at": "2025-04-01T18:49:16.863407Z"
      },
      {
        "id": 41,
        "bucket_id": 4,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T02:15:47.863407Z",
        "updated_at": "2025-04-01T02:15:47.863407Z"
      },
      {
        "id": 42,
        "bucket_id": 5,
        "description": "metric=disk_space, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T04:39:16.863407Z",
        "updated_at": "2025-04-01T04:39:16.863407Z"
      },
      {
        "id": 43,
        "bucket_id": 10,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T03:50:55.863407Z",
        "updated_at": "2025-04-01T03:50:55.863407Z"
      },
      {
        "id": 44,
        "bucket_id": 10,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T00:08:02.863407Z",
        "updated_at": "2025-03-31T00:08:02.863407Z"
      },
      {
        "id": 45,
        "bucket_id": 2,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T23:20:53.863407Z",
        "updated_at": "2025-03-31T23:20:53.863407Z"
      },
      {
        "id": 46,
        "bucket_id": 5,
        "description": "metric=cpu_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T14:11:54.863407Z",
        "updated_at": "2025-03-31T14:11:54.863407Z"
      },
      {
        "id": 47,
        "bucket_id": 5,
        "description": "metric=memory_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T09:32:59.863407Z",
        "updated_at": "2025-04-01T09:32:59.863407Z"
      },
      {
        "id": 48,
        "bucket_id": 1,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T23:35:02.863407Z",
        "updated_at": "2025-03-31T23:35:02.863407Z"
      },
      {
        "id": 49,
        "bucket_id": 3,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T23:21:08.863407Z",
        "updated_at": "2025-04-01T23:21:08.863407Z"
      },
      {
        "id": 50,
        "bucket_id": 8,
        "description": "metric=network_latency, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T22:24:37.863407Z",
        "updated_at": "2025-03-31T22:24:37.863407Z"
      },
      {
        "id": 51,
        "bucket_id": 5,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T10:53:54.863407Z",
        "updated_at": "2025-04-01T10:53:54.863407Z"
      },
      {
        "id": 52,
        "bucket_id": 9,
        "description": "metric=cpu_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T03:09:32.863407Z",
        "updated_at": "2025-03-31T03:09:32.863407Z"
      },
      {
        "id": 53,
        "bucket_id": 5,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T07:55:50.863407Z",
        "updated_at": "2025-04-01T07:55:50.863407Z"
      },
      {
        "id": 54,
        "bucket_id": 1,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T16:49:57.863407Z",
        "updated_at": "2025-03-31T16:49:57.863407Z"
      },
      {
        "id": 55,
        "bucket_id": 4,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T03:28:09.863407Z",
        "updated_at": "2025-03-31T03:28:09.863407Z"
      },
      {
        "id": 56,
        "bucket_id": 9,
        "description": "metric=cpu_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T21:01:14.863407Z",
        "updated_at": "2025-04-01T21:01:14.863407Z"
      },
      {
        "id": 57,
        "bucket_id": 8,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T11:21:38.863407Z",
        "updated_at": "2025-03-31T11:21:38.863407Z"
      },
      {
        "id": 58,
        "bucket_id": 1,
        "description": "metric=disk_space, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T05:47:59.863407Z",
        "updated_at": "2025-04-01T05:47:59.863407Z"
      },
      {
        "id": 59,
        "bucket_id": 2,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T22:31:55.863407Z",
        "updated_at": "2025-04-01T22:31:55.863407Z"
      },
      {
        "id": 60,
        "bucket_id": 9,
        "description": "metric=cpu_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T16:05:57.863407Z",
        "updated_at": "2025-04-01T16:05:57.863407Z"
      },
      {
        "id": 61,
        "bucket_id": 5,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T15:00:24.863407Z",
        "updated_at": "2025-04-01T15:00:24.863407Z"
      },
      {
        "id": 62,
        "bucket_id": 4,
        "description": "metric=disk_space, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T15:58:53.863407Z",
        "updated_at": "2025-03-31T15:58:53.863407Z"
      },
      {
        "id": 63,
        "bucket_id": 8,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T00:19:29.863407Z",
        "updated_at": "2025-03-31T00:19:29.863407Z"
      },
      {
        "id": 64,
        "bucket_id": 6,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T15:36:31.863407Z",
        "updated_at": "2025-04-01T15:36:31.863407Z"
      },
      {
        "id": 65,
        "bucket_id": 10,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T12:11:31.863407Z",
        "updated_at": "2025-03-31T12:11:31.863407Z"
      },
      {
        "id": 66,
        "bucket_id": 6,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T19:50:59.863407Z",
        "updated_at": "2025-04-01T19:50:59.863407Z"
      },
      {
        "id": 67,
        "bucket_id": 7,
        "description": "metric=network_latency, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T14:43:03.863407Z",
        "updated_at": "2025-03-31T14:43:03.863407Z"
      },
      {
        "id": 68,
        "bucket_id": 9,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T19:52:00.863407Z",
        "updated_at": "2025-04-01T19:52:00.863407Z"
      },
      {
        "id": 69,
        "bucket_id": 3,
        "description": "metric=memory_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T07:58:41.863407Z",
        "updated_at": "2025-03-31T07:58:41.863407Z"
      },
      {
        "id": 70,
        "bucket_id": 8,
        "description": "metric=cpu_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T07:39:32.863407Z",
        "updated_at": "2025-03-31T07:39:32.863407Z"
      },
      {
        "id": 71,
        "bucket_id": 7,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T10:33:09.863407Z",
        "updated_at": "2025-04-01T10:33:09.863407Z"
      },
      {
        "id": 72,
        "bucket_id": 9,
        "description": "metric=network_latency, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T09:03:46.863407Z",
        "updated_at": "2025-03-31T09:03:46.863407Z"
      },
      {
        "id": 73,
        "bucket_id": 8,
        "description": "metric=cpu_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T06:29:50.863407Z",
        "updated_at": "2025-04-01T06:29:50.863407Z"
      },
      {
        "id": 74,
        "bucket_id": 2,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T00:47:54.863407Z",
        "updated_at": "2025-03-31T00:47:54.863407Z"
      },
      {
        "id": 75,
        "bucket_id": 10,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T00:57:00.863407Z",
        "updated_at": "2025-03-31T00:57:00.863407Z"
      },
      {
        "id": 76,
        "bucket_id": 9,
        "description": "metric=cpu_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T20:39:46.863407Z",
        "updated_at": "2025-03-31T20:39:46.863407Z"
      },
      {
        "id": 77,
        "bucket_id": 4,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T18:10:47.863407Z",
        "updated_at": "2025-04-01T18:10:47.863407Z"
      },
      {
        "id": 78,
        "bucket_id": 8,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T14:53:52.863407Z",
        "updated_at": "2025-03-31T14:53:52.863407Z"
      },
      {
        "id": 79,
        "bucket_id": 1,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T19:11:55.863407Z",
        "updated_at": "2025-03-31T19:11:55.863407Z"
      },
      {
        "id": 80,
        "bucket_id": 8,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T18:51:12.863407Z",
        "updated_at": "2025-03-31T18:51:12.863407Z"
      },
      {
        "id": 81,
        "bucket_id": 8,
        "description": "metric=disk_space, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T05:16:27.863407Z",
        "updated_at": "2025-03-31T05:16:27.863407Z"
      },
      {
        "id": 82,
        "bucket_id": 4,
        "description": "metric=disk_space, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T06:31:17.863407Z",
        "updated_at": "2025-03-31T06:31:17.863407Z"
      },
      {
        "id": 83,
        "bucket_id": 2,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T15:26:34.863407Z",
        "updated_at": "2025-03-31T15:26:34.863407Z"
      },
      {
        "id": 84,
        "bucket_id": 9,
        "description": "metric=cpu_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T17:18:11.863407Z",
        "updated_at": "2025-03-31T17:18:11.863407Z"
      },
      {
        "id": 85,
        "bucket_id": 5,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T17:51:53.863407Z",
        "updated_at": "2025-04-01T17:51:53.863407Z"
      },
      {
        "id": 86,
        "bucket_id": 2,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T06:29:33.863407Z",
        "updated_at": "2025-03-31T06:29:33.863407Z"
      },
      {
        "id": 87,
        "bucket_id": 5,
        "description": "metric=memory_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T12:42:25.863407Z",
        "updated_at": "2025-04-01T12:42:25.863407Z"
      },
      {
        "id": 88,
        "bucket_id": 5,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T21:16:06.863407Z",
        "updated_at": "2025-04-01T21:16:06.863407Z"
      },
      {
        "id": 89,
        "bucket_id": 7,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T19:21:23.863407Z",
        "updated_at": "2025-03-31T19:21:23.863407Z"
      },
      {
        "id": 90,
        "bucket_id": 10,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T19:11:28.863407Z",
        "updated_at": "2025-03-31T19:11:28.863407Z"
      },
      {
        "id": 91,
        "bucket_id": 3,
        "description": "metric=network_latency, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T00:14:53.863407Z",
        "updated_at": "2025-04-01T00:14:53.863407Z"
      },
      {
        "id": 92,
        "bucket_id": 4,
        "description": "metric=cpu_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T20:46:14.863407Z",
        "updated_at": "2025-03-31T20:46:14.863407Z"
      },
      {
        "id": 93,
        "bucket_id": 3,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T00:11:09.863407Z",
        "updated_at": "2025-03-31T00:11:09.863407Z"
      },
      {
        "id": 94,
        "bucket_id": 8,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T00:33:19.863407Z",
        "updated_at": "2025-04-01T00:33:19.863407Z"
      },
      {
        "id": 95,
        "bucket_id": 7,
        "description": "metric=disk_space, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T20:20:53.863407Z",
        "updated_at": "2025-03-31T20:20:53.863407Z"
      },
      {
        "id": 96,
        "bucket_id": 5,
        "description": "metric=cpu_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T08:27:16.863407Z",
        "updated_at": "2025-03-31T08:27:16.863407Z"
      },
      {
        "id": 97,
        "bucket_id": 3,
        "description": "metric=memory_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T14:06:21.863407Z",
        "updated_at": "2025-03-31T14:06:21.863407Z"
      },
      {
        "id": 98,
        "bucket_id": 7,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T01:49:06.863407Z",
        "updated_at": "2025-03-31T01:49:06.863407Z"
      },
      {
        "id": 99,
        "bucket_id": 8,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T02:46:44.863407Z",
        "updated_at": "2025-04-01T02:46:44.863407Z"
      },
      {
        "id": 100,
        "bucket_id": 4,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T14:12:48.863407Z",
        "updated_at": "2025-04-01T14:12:48.863407Z"
      },
      {
        "id": 101,
        "bucket_id": 9,
        "description": "metric=network_latency, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T07:31:44.863407Z",
        "updated_at": "2025-04-01T07:31:44.863407Z"
      },
      {
        "id": 102,
        "bucket_id": 6,
        "description": "metric=cpu_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T06:21:50.863407Z",
        "updated_at": "2025-04-01T06:21:50.863407Z"
      },
      {
        "id": 103,
        "bucket_id": 9,
        "description": "metric=disk_space, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T12:09:35.863407Z",
        "updated_at": "2025-04-01T12:09:35.863407Z"
      },
      {
        "id": 104,
        "bucket_id": 2,
        "description": "metric=memory_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T09:42:44.863407Z",
        "updated_at": "2025-04-01T09:42:44.863407Z"
      },
      {
        "id": 105,
        "bucket_id": 9,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T01:32:31.863407Z",
        "updated_at": "2025-04-01T01:32:31.863407Z"
      },
      {
        "id": 106,
        "bucket_id": 10,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T04:41:02.863407Z",
        "updated_at": "2025-04-01T04:41:02.863407Z"
      },
      {
        "id": 107,
        "bucket_id": 2,
        "description": "metric=cpu_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T17:30:27.863407Z",
        "updated_at": "2025-04-01T17:30:27.863407Z"
      },
      {
        "id": 108,
        "bucket_id": 10,
        "description": "metric=network_latency, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T19:16:09.863407Z",
        "updated_at": "2025-04-01T19:16:09.863407Z"
      },
      {
        "id": 109,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T08:14:00.863407Z",
        "updated_at": "2025-04-01T08:14:00.863407Z"
      },
      {
        "id": 110,
        "bucket_id": 1,
        "description": "metric=cpu_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T02:08:44.863407Z",
        "updated_at": "2025-04-01T02:08:44.863407Z"
      },
      {
        "id": 111,
        "bucket_id": 9,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T21:19:45.863407Z",
        "updated_at": "2025-03-31T21:19:45.863407Z"
      },
      {
        "id": 112,
        "bucket_id": 8,
        "description": "metric=memory_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T02:52:25.863407Z",
        "updated_at": "2025-04-01T02:52:25.863407Z"
      },
      {
        "id": 113,
        "bucket_id": 10,
        "description": "metric=disk_space, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T09:51:41.863407Z",
        "updated_at": "2025-04-01T09:51:41.863407Z"
      },
      {
        "id": 114,
        "bucket_id": 1,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T12:29:17.863407Z",
        "updated_at": "2025-03-31T12:29:17.863407Z"
      },
      {
        "id": 115,
        "bucket_id": 2,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T16:30:52.863407Z",
        "updated_at": "2025-03-31T16:30:52.863407Z"
      },
      {
        "id": 116,
        "bucket_id": 8,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T03:46:11.863407Z",
        "updated_at": "2025-04-01T03:46:11.863407Z"
      },
      {
        "id": 117,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T11:10:27.863407Z",
        "updated_at": "2025-04-01T11:10:27.863407Z"
      },
      {
        "id": 118,
        "bucket_id": 7,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T02:39:06.863407Z",
        "updated_at": "2025-03-31T02:39:06.863407Z"
      },
      {
        "id": 119,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T15:22:22.863407Z",
        "updated_at": "2025-03-31T15:22:22.863407Z"
      },
      {
        "id": 120,
        "bucket_id": 9,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T14:57:39.863407Z",
        "updated_at": "2025-03-31T14:57:39.863407Z"
      },
      {
        "id": 121,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T01:34:46.863407Z",
        "updated_at": "2025-04-01T01:34:46.863407Z"
      },
      {
        "id": 122,
        "bucket_id": 2,
        "description": "metric=disk_space, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T11:50:25.863407Z",
        "updated_at": "2025-03-31T11:50:25.863407Z"
      },
      {
        "id": 123,
        "bucket_id": 1,
        "description": "metric=network_latency, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T03:19:05.863407Z",
        "updated_at": "2025-03-31T03:19:05.863407Z"
      },
      {
        "id": 124,
        "bucket_id": 8,
        "description": "metric=memory_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T06:26:41.863407Z",
        "updated_at": "2025-04-01T06:26:41.863407Z"
      },
      {
        "id": 125,
        "bucket_id": 8,
        "description": "metric=memory_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T05:40:29.863407Z",
        "updated_at": "2025-04-01T05:40:29.863407Z"
      },
      {
        "id": 126,
        "bucket_id": 2,
        "description": "metric=memory_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T23:18:48.863407Z",
        "updated_at": "2025-03-31T23:18:48.863407Z"
      },
      {
        "id": 127,
        "bucket_id": 5,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T09:38:10.863407Z",
        "updated_at": "2025-03-31T09:38:10.863407Z"
      },
      {
        "id": 128,
        "bucket_id": 4,
        "description": "metric=network_latency, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T20:45:58.863407Z",
        "updated_at": "2025-04-01T20:45:58.863407Z"
      },
      {
        "id": 129,
        "bucket_id": 5,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T08:40:30.863407Z",
        "updated_at": "2025-04-01T08:40:30.863407Z"
      },
      {
        "id": 130,
        "bucket_id": 8,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T13:22:44.863407Z",
        "updated_at": "2025-03-31T13:22:44.863407Z"
      },
      {
        "id": 131,
        "bucket_id": 6,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T15:26:54.863407Z",
        "updated_at": "2025-03-31T15:26:54.863407Z"
      },
      {
        "id": 132,
        "bucket_id": 2,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T23:06:12.863407Z",
        "updated_at": "2025-03-31T23:06:12.863407Z"
      },
      {
        "id": 133,
        "bucket_id": 10,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T10:16:53.863407Z",
        "updated_at": "2025-03-31T10:16:53.863407Z"
      },
      {
        "id": 134,
        "bucket_id": 9,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T20:30:46.863407Z",
        "updated_at": "2025-04-01T20:30:46.863407Z"
      },
      {
        "id": 135,
        "bucket_id": 9,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T19:39:48.863407Z",
        "updated_at": "2025-04-01T19:39:48.863407Z"
      },
      {
        "id": 136,
        "bucket_id": 5,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T14:55:45.863407Z",
        "updated_at": "2025-04-01T14:55:45.863407Z"
      },
      {
        "id": 137,
        "bucket_id": 1,
        "description": "metric=network_latency, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T18:35:13.863407Z",
        "updated_at": "2025-04-01T18:35:13.863407Z"
      },
      {
        "id": 138,
        "bucket_id": 6,
        "description": "metric=network_latency, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T20:24:46.863407Z",
        "updated_at": "2025-04-01T20:24:46.863407Z"
      },
      {
        "id": 139,
        "bucket_id": 8,
        "description": "metric=disk_space, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T00:42:59.863407Z",
        "updated_at": "2025-03-31T00:42:59.863407Z"
      },
      {
        "id": 140,
        "bucket_id": 5,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T23:30:45.863407Z",
        "updated_at": "2025-04-01T23:30:45.863407Z"
      },
      {
        "id": 141,
        "bucket_id": 9,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T11:13:48.863407Z",
        "updated_at": "2025-03-31T11:13:48.863407Z"
      },
      {
        "id": 142,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T02:47:12.863407Z",
        "updated_at": "2025-03-31T02:47:12.863407Z"
      },
      {
        "id": 143,
        "bucket_id": 9,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T20:01:15.863407Z",
        "updated_at": "2025-03-31T20:01:15.863407Z"
      },
      {
        "id": 144,
        "bucket_id": 5,
        "description": "metric=memory_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T05:22:00.863407Z",
        "updated_at": "2025-03-31T05:22:00.863407Z"
      },
      {
        "id": 145,
        "bucket_id": 3,
        "description": "metric=cpu_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T09:46:15.863407Z",
        "updated_at": "2025-04-01T09:46:15.863407Z"
      },
      {
        "id": 146,
        "bucket_id": 3,
        "description": "metric=cpu_usage, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T13:51:12.863407Z",
        "updated_at": "2025-04-01T13:51:12.863407Z"
      },
      {
        "id": 147,
        "bucket_id": 3,
        "description": "metric=cpu_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T09:50:51.863407Z",
        "updated_at": "2025-04-01T09:50:51.863407Z"
      },
      {
        "id": 148,
        "bucket_id": 9,
        "description": "metric=disk_space, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T13:39:11.863407Z",
        "updated_at": "2025-04-01T13:39:11.863407Z"
      },
      {
        "id": 149,
        "bucket_id": 5,
        "description": "metric=disk_space, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T16:00:39.863407Z",
        "updated_at": "2025-03-31T16:00:39.863407Z"
      },
      {
        "id": 150,
        "bucket_id": 7,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T11:16:37.863407Z",
        "updated_at": "2025-03-31T11:16:37.863407Z"
      },
      {
        "id": 151,
        "bucket_id": 2,
        "description": "metric=network_latency, message=Disk space running low, system might experience slower performance., server=Windows-server, cluster=100",
        "app": "100",
        "server": "Windows-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T17:24:00.863407Z",
        "updated_at": "2025-03-31T17:24:00.863407Z"
      },
      {
        "id": 152,
        "bucket_id": 7,
        "description": "metric=memory_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T01:02:29.863407Z",
        "updated_at": "2025-03-31T01:02:29.863407Z"
      },
      {
        "id": 153,
        "bucket_id": 4,
        "description": "metric=cpu_usage, message=Logs are generated for process management like 'pam_unix(cron:session)' and session management issues., server=test_server, cluster=100",
        "app": "100",
        "server": "test_server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T16:39:37.863407Z",
        "updated_at": "2025-04-01T16:39:37.863407Z"
      },
      {
        "id": 154,
        "bucket_id": 9,
        "description": "metric=cpu_usage, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T02:59:03.863407Z",
        "updated_at": "2025-04-01T02:59:03.863407Z"
      },
      {
        "id": 155,
        "bucket_id": 2,
        "description": "metric=network_latency, message=Database performance issues detected, high latency on database queries., server=Postgres, cluster=100",
        "app": "100",
        "server": "Postgres",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T07:25:47.863407Z",
        "updated_at": "2025-04-01T07:25:47.863407Z"
      },
      {
        "id": 156,
        "bucket_id": 9,
        "description": "metric=disk_space, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-04-01T01:25:53.863407Z",
        "updated_at": "2025-04-01T01:25:53.863407Z"
      },
      {
        "id": 157,
        "bucket_id": 4,
        "description": "metric=memory_usage, message=LLM Chatbot mode is running, high network latency affecting communication with the bot., server=Envibuddy-server, cluster=100",
        "app": "100",
        "server": "Envibuddy-server",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T05:20:01.863407Z",
        "updated_at": "2025-03-31T05:20:01.863407Z"
      },
      {
        "id": 158,
        "bucket_id": 4,
        "description": "metric=network_latency, message=Monitoring services running for system health and performance, high memory usage or network issues., server=infia, cluster=100",
        "app": "100",
        "server": "infia",
        "region": "ap-south-1",
        "env": "production",
        "os": "linux",
        "is_resolved": true,
        "created_at": "2025-03-31T13:45:23.863407Z",
        "updated_at": "2025-03-31T13:45:23.863407Z"
      }
    ]
  }

const fetchAlertsByBucketId = async (bucketId, token) => {
  try {
    const response = await fetch(
      "https://infia.enviseer.com/api/alert/envi/alerts/search",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bucket_id: bucketId }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.alerts || [];
  } catch (error) {
    console.error(`Error fetching alerts for bucketId ${bucketId}:`, error);
    return [];
  }
};


  const generateAlertDescription = (service, incidentId) => {
    const scenarios = {
      1: {
        'auth-service': {
          server: 'test_server',
          alerts: [
            {
              description: "High latency in token validation due to Redis connection timeout",
              metric: "token_validation_latency",
              threshold: "200ms",
              current: "2.5s",
              impact: "Request queuing and increased authentication delays",
              details: "Token validation operations experiencing high latency spikes, causing authentication bottlenecks"
            },
            {
              description: "Connection pool exhaustion causing request queuing",
              metric: "connection_pool_utilization",
              threshold: "80%",
              current: "98%",
              impact: "Authentication request queuing and timeouts",
              details: "Connection pool saturation leading to request backlog and degraded authentication performance"
            }
          ]
        },
        'redis-cache': {
          server: 'test_server',
          alerts: [{
            description: "Cache miss rate exceeding threshold",
            metric: "cache_miss_rate",
            threshold: "20%",
            current: "45%",
            impact: "Increased database load and latency",
            details: "High cache miss rate causing excessive load on the authentication database"
          }]
        },
        'auth-validator': {
          server: 'test_server',
          alerts: [{
            description: "Token validation service experiencing high CPU usage",
            metric: "cpu_utilization",
            threshold: "75%",
            current: "92%",
            impact: "Reduced validation throughput",
            details: "CPU saturation in token validator causing processing delays"
          }]
        }
      },
      2: {
        'metrics-collector': {
          server: 'metrics-collector',
          alerts: [
            {
              description: "Memory leak in metric batch processing causing high heap usage",
              metric: "heap_memory_usage",
              threshold: "75%",
              current: "92%",
              impact: "Risk of OOM errors",
              details: "Memory growth pattern detected in metric processing pipeline"
            },
            {
              description: "Backpressure in metrics stream causing pipeline delays",
              metric: "stream_processing_lag",
              threshold: "10s",
              current: "45s",
              impact: "Delayed metric ingestion",
              details: "Metric stream processing unable to keep up with ingestion rate"
            }
          ]
        },
        'alert-processor': {
          server: 'metrics-collector',
          alerts: [{
            description: "Alert queue growing faster than processing rate",
            metric: "alert_queue_depth",
            threshold: "1000",
            current: "5000",
            impact: "Delayed alert notifications",
            details: "Alert processing capacity exceeded, causing notification delays"
          }]
        },
        'data-aggregator': {
          server: 'metrics-collector',
          alerts: [{
            description: "Timeout in time-series data aggregation",
            metric: "aggregation_latency",
            threshold: "3s",
            current: "8s",
            impact: "Incomplete metric aggregation",
            details: "Time-series aggregation jobs failing to complete within SLA"
          }]
        }
      },
      3: {
        'llm-tokenizer': {
          server: 'envibuddy',
          alerts: [
            {
              description: "Token generation bottleneck due to sequential processing",
              metric: "token_generation_time",
              threshold: "300ms",
              current: "850ms",
              impact: "Increased response latency",
              details: "Sequential token generation creating bottleneck in LLM pipeline"
            },
            {
              description: "Batch size causing memory pressure",
              metric: "memory_utilization",
              threshold: "70%",
              current: "85%",
              impact: "Reduced throughput",
              details: "Large batch sizes causing memory pressure in tokenization process"
            }
          ]
        },
        'context-vectorizer': {
          server: 'envibuddy',
          alerts: [{
            description: "GPU memory saturation from parallel vectorization",
            metric: "gpu_memory_usage",
            threshold: "80%",
            current: "95%",
            impact: "Vectorization queuing",
            details: "GPU memory pressure causing context vectorization delays"
          }]
        },
        'context-parser': {
          server: 'envibuddy',
          alerts: [{
            description: "Context parsing delays due to large input sizes",
            metric: "parse_time",
            threshold: "200ms",
            current: "450ms",
            impact: "Increased latency",
            details: "Large context sizes causing parsing performance degradation"
          }]
        }
      }
      // ... (similar detailed metrics for other scenarios)
    };

    const serviceScenarios = scenarios[incidentId]?.[service];
    if (!serviceScenarios) {
      return {
        description: `Performance issue detected in ${service}`,
        server: service,
        metric: "system_health",
        threshold: "N/A",
        current: "Degraded",
        impact: "Service performance affected",
        details: "General performance degradation detected"
      };
    }

    const alerts = serviceScenarios.alerts;
    if (Array.isArray(alerts)) {
      const index = Math.floor(Math.random() * alerts.length);
      const alert = alerts[index];
      return {
        description: `[${alert.metric}] ${alert.description} | Current: ${alert.current} (Threshold: ${alert.threshold}) | ${alert.details}`,
        server: serviceScenarios.server,
        metric: alert.metric,
        threshold: alert.threshold,
        current: alert.current,
        impact: alert.impact,
        details: alert.details
      };
    }

    return {
      description: alerts.description,
      server: serviceScenarios.server
    };
  };

  // Define flowDiagramScenarios before handleTabChange
  const flowDiagramScenarios = {
    1: {
      highlights: {
        'auth-service': 2,
        'redis-cache': 1,
        'auth-validator': 1
      }
    },
    2: {
      highlights: {
        'metrics-collector': 2,
        'alert-processor': 1,
        'data-aggregator': 1
      }
    },
    3: {
      highlights: {
        'llm-tokenizer': 2,
        'context-vectorizer': 1,
        'context-parser': 1
      }
    },
    4: {
      highlights: {
        'postgres': 2,
        'cache-service': 1,
        'data-sync': 1
      }
    },
    5: {
      highlights: {
        'chat-service': 2,
        'knowledge-base': 1,
        'response-formatter': 1
      }
    },
    6: {
      highlights: {
        'infia': 2,
        'alert-service': 1,
        'notification-handler': 1
      }
    },
    7: {
      highlights: {
        'envibuddy': 2,
        'training-pipeline': 1,
        'model-validator': 1
      }
    },
    8: {
      highlights: {
        'postgres': 2,
        'cache-api': 1,
        'data-transformer': 1
      }
    },
    9: {
      highlights: {
        'test_server': 2,
        'health-check': 1,
        'service-registry': 1
      }
    },
    10: {
      highlights: {
        'infia': 2,
        'analysis-pipeline': 1,
        'result-aggregator': 1
      }
    }
  };

  // Add UUID generation function
  const generateUUID = () => {
    // Generate 16 random bytes using Web Crypto API
    const array = new Uint8Array(16);
    window.crypto.getRandomValues(array);
    
    // Set version (4) and variant bits
    array[6] = (array[6] & 0x0f) | 0x40;  // version 4
    array[8] = (array[8] & 0x3f) | 0x80;  // variant 1
    
    // Convert to hex string with proper formatting
    return Array.from(array)
      .map(b => b.toString(16).padStart(2, '0'))
      .join('')
      .replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-$3-$4-$5');
  };

  const generateBaseTimeForIncident = (incidentId) => {
    // Ensure incidentId is a string and extract a numeric value
    let idNumber = 1;  // default to 1 if we can't extract a number
    
    if (incidentId) {
      // Convert to string and try different methods to extract a number
      const idString = String(incidentId);
      
      // Try to extract any numbers from the ID
      const numberMatch = idString.match(/\d+/);
      if (numberMatch) {
        idNumber = parseInt(numberMatch[0]);
      } else if (typeof incidentId === 'object' && incidentId.id) {
        // If it's an object with an id property
        const objIdString = String(incidentId.id);
        const objNumberMatch = objIdString.match(/\d+/);
        idNumber = objNumberMatch ? parseInt(objNumberMatch[0]) : 1;
      }
    }
    
    // Distribute events across March 2025
    // Start from March 5th, calculate days between events based on total events
    const startDate = new Date('2025-03-05T00:00:00Z');
    const endDate = new Date('2025-03-31T23:59:59Z');
    const totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
    
    // Assuming we might have up to 8 events, space them evenly
    const daysPerEvent = Math.floor(totalDays / 8);  // This gives us ~3 days between events
    const daysToAdd = (idNumber - 1) * daysPerEvent;
    
    // Add days and generate a time between 9 AM to 5 PM UTC
    const baseDate = new Date(startDate);
    baseDate.setDate(startDate.getDate() + daysToAdd);
    
    // Ensure we don't exceed March 31st
    if (baseDate > endDate) {
      baseDate.setDate(endDate.getDate() - 1); // Set to March 30th if exceeded
    }
    
    const hourOffset = 9 + Math.floor(Math.random() * 8); // Random hour between 9-17
    const minuteOffset = Math.floor(Math.random() * 60); // Random minute
    
    baseDate.setHours(hourOffset, minuteOffset);
    
    return baseDate.getTime();
  };

  const generateTimestamps = (baseTime, alertIndex = 0) => {
    // Each alert within an event should be 2-5 minutes after the previous one
    const alertOffset = alertIndex * (Math.floor(Math.random() * (300000 - 120000) + 120000));
    const alertTime = baseTime + alertOffset;
    
    // Generate detection time (created_at) within 1-2 minutes of the alert time
    const detectionDelay = Math.floor(Math.random() * (120000 - 60000) + 60000); // 1-2 minutes
    const createdAt = new Date(alertTime - detectionDelay);
    
    // Generate update time 30-60 seconds after creation
    const updateDelay = Math.floor(Math.random() * (60000 - 30000) + 30000); // 30-60 seconds
    const updatedAt = new Date(createdAt.getTime() + updateDelay);
    
    return {
      created_at: createdAt.toISOString(),
      updated_at: updatedAt.toISOString()
    };
  };

  const handleTabChange = async (tab) => {
    setActiveTab(tab);

    if (selectedIncident) {
      setLoadingAlerts(true);
      
      const scenario = flowDiagramScenarios[selectedIncident.id];
      if (!scenario) {
        setAlerts([]);
        setLoadingAlerts(false);
        return;
      }

      // Get base timestamp for this incident
      const baseTime = generateBaseTimeForIncident(selectedIncident.id);
      
      // Generate alerts based on the scenario highlights
      const generatedAlerts = [];
      let alertIndex = 0;

      Object.entries(scenario.highlights).forEach(([service, count]) => {
        for (let i = 0; i < count; i++) {
          const alertInfo = generateAlertDescription(service, selectedIncident.id);
          
          // Generate or retrieve alert timestamps
          const alertId = generateUUID();
          let timestamps;
          if (!alertTimestamps[alertId]) {
            timestamps = generateTimestamps(baseTime, alertIndex);
            // Store the timestamps
            setAlertTimestamps(prev => ({
              ...prev,
              [alertId]: timestamps
            }));
          } else {
            timestamps = alertTimestamps[alertId];
          }
          
          const alert = {
            id: alertId,
            bucket_id: selectedIncident.id,
            description: alertInfo.description,
            app: "100",
            server: alertInfo.server,
            service: service,
            metric: alertInfo.metric,
            threshold: alertInfo.threshold,
            current_value: alertInfo.current,
            impact: alertInfo.impact,
            details: alertInfo.details,
            region: selectedIncident.region || "ap-south-1",
            env: selectedIncident.env || "production",
            os: selectedIncident.os || "linux",
            is_resolved: selectedIncident.status.toLowerCase() === "resolved",
            created_at: timestamps.created_at,
            updated_at: timestamps.updated_at
          };
          generatedAlerts.push(alert);
          alertIndex++;
        }
      });

      // Sort alerts by created_at
      generatedAlerts.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      
      setAlerts(generatedAlerts);
      setLoadingAlerts(false);
    }
  };

  const handleDetailsClick = (alert) => {
    setSelectedAlert(alert);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setSelectedAlert(null);
  };

  const getStatusColor = (status) => {
    return status === 'open' ? '#e53e3e' : '#38a169';
  };

  const formatDate = (dateString) => {
    
    // Create a Date object from the input string
    const date = new Date(dateString);
    
    // Check for invalid date
    if (isNaN(date.getTime())) {
        return 'Invalid date';
    }

    // Format the date part
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();

    // Format the time part
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Return the formatted date string in 24-hour format
    return `${month} ${day}, ${hours}:${minutes}:${seconds}`;
};


  const filteredIncidents = incidents.filter((incident) =>
    incident.alert_name.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    if (filteredIncidents.length > 0) {
      const incident = filteredIncidents[0];
      setSelectedIncident({
        ...incident,
        server: incident.host || incident.server || 'Unknown Server',
        description: incident.description || incident.alert_name || 'No description available',
        alerts: incident.alerts || []
      });
    }
  }, [searchText]);

  const incidentScenarios = {
      1: {
        title: 'Authentication Service Degradation',
        host: 'auth-service',
        alerts: 4  // 2 + 1 + 1
      },
      2: {
        title: 'Monitoring System Overload',
        host: 'metrics-collector',
        alerts: 4  // 2 + 1 + 1
      },
      3: {
        title: 'LLM Token Processing Latency',
        host: 'llm-tokenizer',
        alerts: 4  // 2 + 1 + 1
      },
      4: {
        title: 'Database Performance Degradation',
        host: 'postgres',
        alerts: 4  // 2 + 1 + 1
      },
      5: {
        title: 'Chatbot Response Issues',
        host: 'chat-service',
        alerts: 4  // 2 + 1 + 1
      },
      6: {
        title: 'Alert Processing Delays',
        host: 'infia',
        alerts: 4  // 2 + 1 + 1
      },
      7: {
        title: 'Model Training Pipeline Issues',
        host: 'envibuddy',
        alerts: 4  // 2 + 1 + 1
      },
      8: {
        title: 'Query Performance Issues',
        host: 'postgres',
        alerts: 4  // 2 + 1 + 1
      },
      9: {
        title: 'Service Health Check Failures',
        host: 'test_server',
        alerts: 4  // 2 + 1 + 1
      },
      10: {
        title: 'Prediction Service Degradation',
        host: 'infia',
        alerts: 4  // 2 + 1 + 1
      }
    };

  const getTotalAlerts = (incident) => {
    const flowDiagramScenarios = {
      1: {
        highlights: {
          'auth-service': 2,
          'redis-cache': 1,
          'auth-validator': 1
        }
      },
      2: {
        highlights: {
          'metrics-collector': 2,
          'alert-processor': 1,
          'data-aggregator': 1
        }
      },
      3: {
        highlights: {
          'llm-tokenizer': 2,
          'context-vectorizer': 1,
          'context-parser': 1
        }
      },
      4: {
        highlights: {
          'postgres': 2,
          'cache-service': 1,
          'data-sync': 1
        }
      },
      5: {
        highlights: {
          'chat-service': 2,
          'knowledge-base': 1,
          'response-formatter': 1
        }
      },
      6: {
        highlights: {
          'infia': 2,
          'alert-service': 1,
          'notification-handler': 1
        }
      },
      7: {
        highlights: {
          'envibuddy': 2,
          'training-pipeline': 1,
          'model-validator': 1
        }
      },
      8: {
        highlights: {
          'postgres': 2,
          'cache-api': 1,
          'data-transformer': 1
        }
      },
      9: {
        highlights: {
          'test_server': 2,
          'health-check': 1,
          'service-registry': 1
        }
      },
      10: {
        highlights: {
          'infia': 2,
          'analysis-pipeline': 1,
          'result-aggregator': 1
        }
      }
    };

    const scenario = flowDiagramScenarios[incident.id];
    if (!scenario?.highlights) return 0;
    
    // Calculate total alerts from all services in the scenario
    return Object.values(scenario.highlights).reduce((sum, count) => sum + count, 0);
  };

  const renderDescription = (description) => {
    if (!description.includes('Environment:')) return description;
    
    const [envPart, osPart] = description.split(', ');
    const environment = envPart.split(': ')[1];
    const os = osPart.split(': ')[1];
    
    return (
      <div className="description-badges">
        <div className="badge environment-badge">
          <i className="bi bi-hdd-rack-fill"></i>
          <span>Environment:</span>
          <span className="badge-value">{environment}</span>
        </div>
        <div className="badge os-badge">
          <i className="bi bi-cpu-fill"></i>
          <span>OS:</span>
          <span className="badge-value">{os}</span>
        </div>
      </div>
    );
  };

  // Add this CSS to your correlation.css file
  const styles = `
    .info-card-full {
      background: #ffffff;
      border-radius: 12px;
      padding: 24px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      width: 100%;
      margin-bottom: 24px;
    }

    .info-card-header {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 24px;
    }

    .info-card-title {
      font-size: 18px;
      color: #1b2559;
      margin: 0;
      font-weight: 600;
    }

    .info-fields {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
    }

    .info-field {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .info-field-label {
      font-size: 14px;
      color: #6b7280;
      font-weight: 500;
    }

    .info-field-value {
      font-size: 15px;
      color: #1f2937;
    }

    .description-field {
      grid-column: 1 / -1;
    }

    .description-badges {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }

    .badge {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
    }

    .badge i {
      font-size: 16px;
    }

    .environment-badge {
      background-color: #f3f0ff;
      color: #6825EC;
    }

    .environment-badge .badge-value {
      color: #4c1d95;
      font-weight: 600;
    }

    .os-badge {
      background-color: #ecfdf5;
      color: #059669;
    }

    .os-badge .badge-value {
      color: #065f46;
      font-weight: 600;
    }

    @media (max-width: 768px) {
      .info-card-full {
        padding: 16px;
      }

      .info-fields {
        grid-template-columns: 1fr;
        gap: 16px;
      }

      .description-badges {
        flex-direction: column;
      }

      .badge {
        width: 100%;
      }
    }

    .incident-header {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 24px;
    }

    .incident-title-section {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .alerts-badge {
      display: inline-flex;
      align-items: center;
      padding: 4px 12px;
      background: #EEF2FF;
      border-radius: 16px;
      color: #6825EC;
      font-size: 14px;
      font-weight: 600;
    }

    .alerts-badge i {
      margin-right: 6px;
      font-size: 14px;
    }

    .event-info-header-icon {
      width: 40px;
      height: 40px;
      background-color: #6825EC;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    .event-info-header-icon i {
      color: white;
      font-size: 20px;
    }
  `;

  // Add the styles to the document
  React.useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Update the Event Information section in your JSX
  const renderEventInformation = () => {
    // Calculate event timestamp if not already stored
    if (!eventTimestamps[selectedIncident.id]) {
      const scenario = flowDiagramScenarios[selectedIncident.id];
      const baseTime = generateBaseTimeForIncident(selectedIncident.id);
      
      let totalAlerts = 0;
      if (scenario) {
        Object.values(scenario.highlights).forEach(count => {
          totalAlerts += count;
        });
      }
      
      const alertSpacing = 3 * 60 * 1000;
      const lastAlertTime = baseTime + (totalAlerts - 1) * alertSpacing;
      const investigationTime = 30 * 60 * 1000;
      const eventTime = new Date(lastAlertTime + investigationTime);
      
      // Store the calculated timestamp
      setEventTimestamps(prev => ({
        ...prev,
        [selectedIncident.id]: eventTime.getTime()
      }));
    }

    return (
      <div className="info-card-full">
        <div className="incident-header">
          <div className="incident-title-section">
            <div className="event-info-header-icon">
              <i className="bi bi-info-circle"></i>
            </div>
            <h3 className="info-card-title">Event Information</h3>
          </div>
          <div className="alerts-badge">
            <i className="bi bi-bell-fill"></i>
            {getTotalAlerts(selectedIncident)} Alerts
          </div>
        </div>
        <div className="info-fields">
          <div className="info-field">
            <div className="info-field-label">Event ID</div>
            <div className="info-field-value">{selectedIncident.id}</div>
          </div>
          <div className="info-field">
            <div className="info-field-label">Event Title</div>
            <div className="info-field-value">{incidentScenarios[selectedIncident.id]?.title}</div>
          </div>
          <div className="info-field">
            <div className="info-field-label">Data Source</div>
            <div className="info-field-value">{selectedIncident.dataSource}</div>
          </div>
          <div className="info-field">
            <div className="info-field-label">Host</div>
            <div className="info-field-value">{selectedIncident.host}</div>
          </div>
          <div className="info-field">
            <div className="info-field-label">Region</div>
            <div className="info-field-value">{selectedIncident.region}</div>
          </div>
          <div className="info-field">
            <div className="info-field-label">Status</div>
            <div className="info-field-value">
              <span className={`status-badge status-${selectedIncident.status.toLowerCase()}`}>
                {selectedIncident.status}
              </span>
            </div>
          </div>
          <div className="info-field">
            <div className="info-field-label">Date</div>
            <div className="info-field-value">
              <span className="event-date">
                {new Date(eventTimestamps[selectedIncident.id] || Date.now()).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZone: 'Asia/Kolkata',
                  hour12: true
                })}
              </span>
            </div>
          </div>
          <div className="info-field description-field">
            <div className="info-field-label">Description</div>
            <div className="info-field-value">
              {renderDescription(selectedIncident.description)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Render the Overview tab content
  const renderOverviewTab = (selectedIncident) => {
    return (
      <div className="overview-content">
        {renderEventInformation()}



        <div className="info-card-full">
          <div className="info-card-header">
            <div className="info-card-icon">
              <i className="bi bi-diagram-3"></i>
            </div>
            <h3 className="info-card-title">Root Cause</h3>
          </div>
          <div className="info-card-content">
            <div className="info-field">
              <FlowDiagram selectedIncident={selectedIncident} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Update the sidebar alert count display
  const renderIncidentList = () => {
    return filteredIncidents.map((incident) => (
      <div
        key={incident.id}
        className={`incident-item ${selectedIncident?.id === incident.id ? 'selected' : ''}`}
        onClick={() => {
          setSelectedIncident(incident);
          setActiveTab("Overview");
          handleTabChange("Overview");
        }}
      >
        <div className="incident-details">
          <h3 className="incident-name">{incident.alert_name}</h3>
          <p className="incident-host">
            <i className="bi bi-hdd-rack" style={{ color: '#6825EC' }}></i>
            {incident.host}
          </p>
          <p className="incident-host">
            <i className="bi bi-database" style={{ color: '#6825EC' }}></i>
            Application: {incident.dataSource}
          </p>
        </div>
        <div className="incident-meta">
          <span className={`status-badge status-${incident.status.toLowerCase()}`}>
            {incident.status}
          </span>
          <span className={`incident-alert-count ${incident.status === "Open" ? "alert-count-warning" : "alert-count-normal"}`}>
            {getTotalAlerts(incident)} Alerts
          </span>
        </div>
      </div>
    ));
  };

  // Add this function near the top with other utility functions
  const getIncidentTimestamp = (incident) => {
    // Base time for March 2025 based on incident ID
    const baseDate = new Date('2025-03-05T00:00:00Z');
    const idNumber = parseInt(incident.id) || 1;
    const daysToAdd = (idNumber - 1) * 3; // 3 days between incidents
    
    baseDate.setDate(baseDate.getDate() + daysToAdd);
    baseDate.setHours(9 + Math.floor(Math.random() * 8)); // Between 9 AM and 5 PM UTC
    baseDate.setMinutes(Math.floor(Math.random() * 60));
    
    return baseDate.getTime();
  };

  // Update the selectedIncident object when it's set
  const handleIncidentSelect = (incident) => {
    setSelectedIncident({
      ...incident,
      timestamp: getIncidentTimestamp(incident)
    });
    setActiveTab('overview');
  };

  return (
    <div className="correlation-container">
      <div className="left-panel">
        <div className="correlation-search-container">
          <input
            type="text"
            className="correlation-search-input"
            placeholder="Search incidents..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="incident-list custom-scrollbar">
          {renderIncidentList()}
        </div>
      </div>

      <div className="right-panel">
        {selectedIncident ? (
          <>
            <h1 className="page-title">{selectedIncident.alert_name}</h1>
            
            <div className="tabs">
              <button
                className={activeTab === "Overview" ? "active" : ""}
                onClick={() => handleTabChange("Overview")}
              >
                <i className="bi bi-grid"></i> Overview
              </button>
              <button
                className={activeTab === "Alerts" ? "active" : ""}
                onClick={() => handleTabChange("Alerts")}
              >
                <i className="bi bi-bell"></i> Alerts
              </button>
            </div>

            {activeTab === "Overview" && renderOverviewTab(selectedIncident)}

            {activeTab === "Alerts" && (
              <div style={{ 
                borderRadius: '12px', 
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                boxShadow: '0 2px 8px rgba(104, 37, 236, 0.1)',
              }}>
                {loadingAlerts ? (
                  <div style={{ 
                    padding: '40px', 
                    textAlign: 'center', 
                    color: '#8E9ABC',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px'
                  }}>
                    <div className="loading-spinner"></div>
                    <p>Loading alerts...</p>
                  </div>
                ) : (
                  <div style={{ flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className="alert-table-header">
                      {[
                        { key: 'id', label: 'ID' },
                        { key: 'description', label: 'Description' },
                        { key: 'status', label: 'Status' },
                        { key: 'created_at', label: 'Created' },
                        { key: 'updated_at', label: 'Updated' }
                      ].map(({ key, label }) => (
                        <div
                          key={key}
                          className="alert-table-header-cell"
                        >
                          {label}
                        </div>
                      ))}
                    </div>

                    <div className="custom-scrollbar" style={{ 
                      overflowY: 'auto',
                      flex: 1
                    }}>
                      {alerts.length > 0 ? (
                        alerts.map((alert, index) => (
                          <div 
                            key={alert.id} 
                            onClick={() => handleDetailsClick(alert)}
                            className={`alert-table-row ${alert.is_resolved ? "resolved-alert" : "open-alert"}`}
                            style={{
                              backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9fc',
                            }}
                          >
                            <div style={{ fontWeight: '500', color: '#4a5568' }}>{alert.id}</div>
                            <div style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              color: '#1b2559',
                              fontWeight: '500',
                            }} title={alert.description}>{alert.description}</div>
                            <div>
                              <span style={{
                                padding: '4px 8px',
                                borderRadius: '4px',
                                fontSize: '12px',
                                backgroundColor: alert.is_resolved ? '#ECFDF5' : '#FEF2F2',
                                color: alert.is_resolved ? '#059669' : '#DC2626',
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: '4px'
                              }}>
                                {alert.is_resolved ? 
                                  <span style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                                    <span style={{ 
                                      width: '8px', 
                                      height: '8px', 
                                      borderRadius: '50%', 
                                      backgroundColor: '#059669', 
                                      display: 'inline-block' 
                                    }}></span>
                                    Resolved
                                  </span> : 
                                  <span style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                                    <span style={{ 
                                      width: '8px', 
                                      height: '8px', 
                                      borderRadius: '50%', 
                                      backgroundColor: '#DC2626', 
                                      display: 'inline-block' 
                                    }}></span>
                                    Open
                                  </span>
                                }
                              </span>
                            </div>
                            <div className="date-cell">
                              {formatDate(alert.created_at)}
                            </div>
                            <div className="date-cell">
                              {formatDate(alert.updated_at)}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div style={{ 
                          padding: '30px', 
                          textAlign: 'center', 
                          color: '#8E9ABC',
                          fontSize: '14px'
                        }}>
                          No alerts found for this incident
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%',
            color: '#8E9ABC',
            gap: '16px'
          }}>
            <i className="bi bi-search" style={{ fontSize: '48px', color: '#6825EC' }}></i>
            <h2>Select an incident from the list</h2>
            <p>Choose an incident to view details and alerts</p>
          </div>
        )}

        {popupVisible && selectedAlert && (
          <div className="cor-popup" onClick={closePopup}>
            <div className="cor-popup-content" onClick={(e) => e.stopPropagation()}>
              <div className="popup-header">
                <h3 className="popup-title">
                  <i className="bi bi-info-circle" style={{ marginRight: '8px' }}></i> 
                  Alert Details
                </h3>
                <button className="popup-close" onClick={closePopup}>
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              
              <div className="popup-body custom-scrollbar">
                <div className="popup-section">
                  <div className="popup-section-title">
                    <i className="bi bi-file-earmark-text"></i>
                    Basic Information
                  </div>
                  <div className="popup-section-content">
                    <div className="popup-detail-item">
                      <div className="popup-detail-label">ID</div>
                      <div className="popup-detail-value" style={{ fontFamily: 'monospace', fontSize: '12px' }}>{selectedAlert.id}</div>
                    </div>
                    
                    <div className="popup-detail-item" style={{ gridColumn: '1 / -1' }}>
                      <div className="popup-detail-label">Description</div>
                      <div className="popup-detail-value">{selectedAlert.description}</div>
                    </div>
                    
                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Status</div>
                      <div className="popup-detail-value">
                        <span className={`status-badge ${selectedAlert.is_resolved ? "status-resolved" : "status-open"}`}>
                          {selectedAlert.is_resolved ? "Resolved" : "Open"}
                        </span>
                      </div>
                    </div>
                    
                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Severity</div>
                      <div className="popup-detail-value">{selectedAlert.severity || "High"}</div>
                    </div>
                  </div>
                </div>

                <div className="popup-section">
                  <div className="popup-section-title">
                    <i className="bi bi-graph-up"></i>
                    Metric Details
                  </div>
                  <div className="popup-section-content">
                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Metric</div>
                      <div className="popup-detail-value">{selectedAlert.metric}</div>
                    </div>

                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Current Value</div>
                      <div className="popup-detail-value" style={{ color: '#DC2626', fontWeight: '600' }}>{selectedAlert.current_value}</div>
                    </div>

                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Threshold</div>
                      <div className="popup-detail-value">{selectedAlert.threshold}</div>
                    </div>

                    <div className="popup-detail-item" style={{ gridColumn: '1 / -1' }}>
                      <div className="popup-detail-label">Impact</div>
                      <div className="popup-detail-value">{selectedAlert.impact}</div>
                    </div>

                    <div className="popup-detail-item" style={{ gridColumn: '1 / -1' }}>
                      <div className="popup-detail-label">Technical Details</div>
                      <div className="popup-detail-value">{selectedAlert.details}</div>
                    </div>
                  </div>
                </div>

                <div className="popup-section">
                  <div className="popup-section-title">
                    <i className="bi bi-hdd-rack"></i>
                    Environment Details
                  </div>
                  <div className="popup-section-content">
                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Application</div>
                      <div className="popup-detail-value">{selectedAlert.app}</div>
                    </div>

                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Service</div>
                      <div className="popup-detail-value">{selectedAlert.service}</div>
                    </div>

                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Server</div>
                      <div className="popup-detail-value">{selectedAlert.server}</div>
                    </div>

                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Region</div>
                      <div className="popup-detail-value">{selectedAlert.region}</div>
                    </div>

                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Environment</div>
                      <div className="popup-detail-value">{selectedAlert.env}</div>
                    </div>
                  </div>
                </div>
                
                <div className="popup-section">
                  <div className="popup-section-title">
                    <i className="bi bi-clock"></i>
                    Timestamps
                  </div>
                  <div className="popup-section-content">
                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Created</div>
                      <div className="popup-detail-value">{formatDate(selectedAlert.created_at)}</div>
                    </div>
                    
                    <div className="popup-detail-item">
                      <div className="popup-detail-label">Updated</div>
                      <div className="popup-detail-value">{formatDate(selectedAlert.updated_at)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Correlation;
