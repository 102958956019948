import React, { useState, useEffect } from 'react';
import styles from './APMLogs.module.css';
import { FaSearch, FaInfoCircle, FaPlayCircle, FaCircleNotch } from 'react-icons/fa';
import { act } from 'react-dom/test-utils';

const APMLogs = ({ selectedDataSource, services, startTime, endTime, selectedServices, setSelectedServices }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [logsStatus, setLogsStatus] = useState('paused'); // Options: 'paused', 'checking', 'active'
  const [activeLogFilter, setActiveLogFilter] = useState('all'); // Options: 'all', 'info', 'warning', 'error', 'debug'
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [pageInputValue, setPageInputValue] = useState('1');
  const [pageInputFocused, setPageInputFocused] = useState(false);
   const [loadedPages, setLoadedPages] = useState({}); 

   useEffect(() => {
    if (selectedDataSource && selectedServices) {
      setCurrentPage(1);
    }
  }, [selectedDataSource, selectedServices, startTime, endTime]);
  useEffect(() => {
    if (selectedDataSource && selectedServices) {
      fetchLogs(currentPage);
    }
  }, [selectedDataSource, selectedServices, startTime, endTime, currentPage, pageSize]);


  const fetchLogs = async (page) => {
    setLoading(true);
    setLogsStatus('checking');
    try {
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      
      const token = getCookie('token');
      const url = `https://infia.enviseer.com/api/apm/logs/search`;
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cluster: selectedDataSource,
          service_name: selectedServices,
          start_time: startTime?.toISOString(),
          end_time: endTime?.toISOString(),
          severity: "*",
          page: page,
          page_size: pageSize
        })
      });

      if (!response.ok) {
        setLogsStatus('paused');
        throw new Error('Failed to fetch logs');
      }
      const data = await response.json();
      setLogs(data.logs || []);
      setTotalPages(data.total_pages || 1);
      setLogsStatus('active');
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLogs([]);
    } finally {
      setLoading(false);
    }
  };
  

  const filteredLogs = logs.filter(log => {
    // Check if the search term matches log_message or severity
    const matchesSearchTerm = 
      log.log_message?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.severity?.toLowerCase().includes(searchTerm.toLowerCase());
  
    // Check if the log severity matches the active log filter (if activeLogFilter is not 'all')
    const matchesActiveFilter = 
      activeLogFilter === 'all' || 
      log.severity?.toLowerCase() === activeLogFilter;
  
    return matchesSearchTerm && matchesActiveFilter;
  });
  


  // Demo log for empty state visualization
  const demoLog = {
    timestamp: new Date().toISOString(),
    level: 'INFO',
    message: 'Application started successfully',
    service: 'demo-service',
    trace_id: '1234567890abcdef'
  };

  const getLevelColor = (level) => {
    switch (level?.toLowerCase()) {
      case 'error':
        return { bg: 'rgba(239, 68, 68, 0.1)', text: '#EF4444' };
      case 'warning':
        return { bg: 'rgba(245, 158, 11, 0.1)', text: '#F59E0B' };
      case 'info':
        return { bg: 'rgba(104, 37, 236, 0.1)', text: '#6825EC' };
      case 'debug':
        return { bg: 'rgba(16, 185, 129, 0.1)', text: '#10B981' };
      default:
        return { bg: 'rgba(107, 114, 128, 0.1)', text: '#6B7280' };
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterClick = (filter) => {
    setActiveLogFilter(filter);
  };

  const checkLogsStatus = () => {
    fetchLogs(currentPage);
    
    // Simulate checking status with a backend call
    setTimeout(() => {
      // For demonstration, we'll keep it in paused state
      // In real implementation, this would be determined by the API response
      setLogsStatus('paused');
    }, 2000);
  };

  const resumeLogs = () => {
    setLogsStatus('checking');
    
    // Simulate a resume logs API call
    setTimeout(() => {
      // For demonstration purposes, we'll keep it in paused state
      // In a real implementation, you would update based on API response
      setLogsStatus('paused');
    }, 2000);
  };

  const handlePageInputChange = (e) => {
    const value = e.target.value;
    setPageInputValue(value);
  };

  const handlePageInputSubmit = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(pageInputValue, 10);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        // Always mark the target page as not loaded to force a refresh
        setLoadedPages(prev => {
          const newLoadedPages = { ...prev };
          newLoadedPages[pageNumber] = false;
          return newLoadedPages;
        });
        setCurrentPage(pageNumber);
      } else {
        // Reset input to current page if invalid
        setPageInputValue(currentPage.toString());
      }
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1 && !loading) {
      const prevPage = currentPage - 1;
      // Always mark the page as not loaded to force a refresh
      setLoadedPages(prev => {
        const newLoadedPages = { ...prev };
        newLoadedPages[prevPage] = false;
        return newLoadedPages;
      });
      setCurrentPage(prevPage);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages && !loading) {
      const nextPage = currentPage + 1;
      // Always mark the page as not loaded to force a refresh
      setLoadedPages(prev => {
        const newLoadedPages = { ...prev };
        newLoadedPages[nextPage] = false;
        return newLoadedPages;
      });
      setCurrentPage(nextPage);
    }
  };

   useEffect(() => {
      setPageInputValue(currentPage.toString());
    }, [currentPage]);

  const IntegratedPaginationControls = () => {
      const [isInputFocused, setIsInputFocused] = useState(false);
      
      return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0',
          backgroundColor: '#F5F3FF',
          borderRadius: '10px',
          boxShadow: '0 2px 6px rgba(104, 37, 236, 0.15)',
          border: '1px solid rgba(104, 37, 236, 0.2)',
          transition: 'all 0.2s ease',
          overflow: 'hidden',
          maxWidth: '100%',
        }}>
          {/* Previous button */}
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1 || loading}
            style={{
              padding: '10px 14px',
              backgroundColor: currentPage === 1 || loading ? '#F3F4F6' : '#FFFFFF',
              color: currentPage === 1 || loading ? '#9CA3AF' : '#6825EC',
              border: 'none',
              borderRight: '1px solid rgba(104, 37, 236, 0.1)',
              cursor: currentPage === 1 || loading ? 'not-allowed' : 'pointer',
              fontSize: '14px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.2s ease',
              minWidth: '42px',
              height: '100%',
              borderTopLeftRadius: '9px',
              borderBottomLeftRadius: '9px'
            }}
            aria-label="Previous page"
          >
            &laquo;
          </button>
          
          {/* Page input field */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            height: '40px',
            backgroundColor: '#FFFFFF',
            padding: '0 14px',
          }}>
            <input
              type="text"
              value={pageInputValue}
              onChange={handlePageInputChange}
              onKeyDown={handlePageInputSubmit}
              onFocus={() => {
                setPageInputFocused(true);
                setIsInputFocused(true);
              }}
              onBlur={() => {
                setPageInputFocused(false);
                setIsInputFocused(false);
              }}
              style={{
                width: '32px',
                padding: '0',
                border: 'none',
                fontSize: '16px',
                textAlign: 'center',
                outline: 'none',
                transition: 'all 0.2s ease',
                backgroundColor: 'transparent',
                fontWeight: '600',
                color: '#6825EC',
                marginBottom: '0px'
              }}
              aria-label="Page number"
            />
            <span style={{ 
              color: '#4B5563', 
              fontWeight: '500', 
              whiteSpace: 'nowrap' 
            }}>
              of <span style={{ fontWeight: '600', color: '#6825EC' }}>{totalPages}</span>
            </span>
            
            {/* Show a small tooltip hint */}
            {isInputFocused && (
              <div style={{
                position: 'absolute',
                bottom: '-24px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#1F2937',
                color: 'white',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '10px',
                whiteSpace: 'nowrap',
                zIndex: 10,
                pointerEvents: 'none',
                opacity: 0.9
              }}>
                Press Enter to go to page
              </div>
            )}
          </div>
          
          {/* Next button */}
          <button
            onClick={handleNextPage}
            disabled={currentPage >= totalPages || loading}
            style={{
              padding: '10px 14px',
              backgroundColor: currentPage >= totalPages || loading ? '#F3F4F6' : '#FFFFFF',
              color: currentPage >= totalPages || loading ? '#9CA3AF' : '#6825EC',
              border: 'none',
              borderLeft: '1px solid rgba(104, 37, 236, 0.1)',
              cursor: currentPage >= totalPages || loading ? 'not-allowed' : 'pointer',
              fontSize: '14px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.2s ease',
              minWidth: '42px',
              height: '100%',
              borderTopRightRadius: '9px',
              borderBottomRightRadius: '9px'
            }}
            aria-label="Next page"
          >
            &raquo;
          </button>
        </div>
      );
    };

  return (
    <div className={styles.logsWrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Application Logs</h2>
        <p className={styles.subtitle}>Log entries for {selectedServices || selectedDataSource}</p>
         {/* Search and filter controls */}
      <div className={styles.logsHeader}>
        <div className={styles.logFilters}>
          <button 
            className={`${styles.filterButton} ${activeLogFilter === 'all' ? styles.active : ''}`}
            onClick={() => handleFilterClick('all')}
          >
            All Logs
          </button>
          <button 
            className={`${styles.filterButton} ${activeLogFilter === 'info' ? styles.active : ''}`}
            onClick={() => handleFilterClick('info')}
          >
            Info
          </button>
          <button 
            className={`${styles.filterButton} ${activeLogFilter === 'warning' ? styles.active : ''}`}
            onClick={() => handleFilterClick('warning')}
          >
            Warning
          </button>
          <button 
            className={`${styles.filterButton} ${activeLogFilter === 'error' ? styles.active : ''}`}
            onClick={() => handleFilterClick('error')}
          >
            Error
          </button>
          <button 
            className={`${styles.filterButton} ${activeLogFilter === 'debug' ? styles.active : ''}`}
            onClick={() => handleFilterClick('debug')}
          >
            Debug
          </button>
        </div>
      </div> 
      <div className={styles.controls}>
          <div className={styles.searchBox}>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            <input 
              type="text" 
              placeholder="Search logs..." 
              value={searchTerm}
              onChange={handleSearch}
              className={styles.searchInput}
            />
          </div>
          <div className={styles.controlsRight}>
            <div style={{fontSize:'14px'}}>Service:</div>
            <select 
              value={selectedServices}
              onChange={(e) => setSelectedServices(e.target.value)}
              className={styles.filterSelect}
            >
              {services.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
            
            {/* Inline Pagination Controls */}
            <div className={styles.inlinePagination} >
            {totalPages > 1 ? (
              <IntegratedPaginationControls />
            ) : null}
            
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tableSection}>
        <table className={styles.logsTable}>
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Level</th>
              <th>Message</th>
              <th>Service</th>
              <th>Trace ID</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5" className={styles.loadingCell}>
                  <div className={styles.loadingIndicator}>
                    <svg className={styles.spinner} viewBox="0 0 50 50">
                      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                    </svg>
                    <span>Loading logs...</span>
                  </div>
                </td>
              </tr>
            ) : filteredLogs.length > 0 ? (
              filteredLogs.map((log, index) => {
                const levelColors = getLevelColor(log.severity);
                return (
                  <tr key={index} className={styles.logRow}>
                    <td className={styles.timestampCell}>
                      {new Date(log.timestamp).toLocaleString()}
                    </td>
                    <td>
                      <span 
                        className={styles.levelBadge}
                        style={{
                          backgroundColor: levelColors.bg,
                          color: levelColors.text
                        }}
                      >
                        {log.severity}
                      </span>
                    </td>
                    <td className={styles.messageCell}>{log.log_message}</td>
                    <td>{log.service_name}</td>
                    <td>
                      <div className={styles.traceIdCell}>
                        <span>{log.trace_id}</span>
                        <button 
                          onClick={() => navigator.clipboard.writeText(log.trace_id)}
                          className={styles.copyButton}
                        >
                          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              // Demo row for empty state
              <td colSpan="4">No logs available for the selected time range</td>
            )}
          </tbody>
        </table>
      </div>

    

      {/* Main content area */}
      {logs.length===0&&<div className={styles.logsContent}>
        {logsStatus === 'paused' && (
          <div className={styles.logsStatusMessage}>
            <div className={styles.statusIcon}>
              <svg width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#6825EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="10" y1="15" x2="14" y2="15"></line>
                <line x1="8" y1="9" x2="9" y2="9"></line>
                <line x1="15" y1="9" x2="16" y2="9"></line>
              </svg>
            </div>
            <h2>Logs Collection Paused</h2>
            <p className={styles.statusDescription}>
              Log collection is currently paused for this service or application.
            </p>
            <p className={styles.statusSubtext}>
              This may be due to scheduled maintenance, high resource usage, or an
              administrative decision to reduce log volume.
            </p>
            <div className={styles.statusActions}>
              <button className={styles.primaryButton} onClick={resumeLogs}>
                <FaPlayCircle /> Resume Logs
              </button>
              <button className={styles.secondaryButton} onClick={checkLogsStatus}>
                <FaInfoCircle /> Check Status
              </button>
            </div>
          </div>
        )}

        {logsStatus === 'checking' && (
          <div className={styles.logsStatusMessage}>
            <div className={styles.statusIcon}>
              <FaCircleNotch className={styles.spinningIcon} />
            </div>
            <h2>Checking Logs Status</h2>
            <p className={styles.statusDescription}>
              Please wait while we verify the current logs collection status.
            </p>
          </div>
        )}

        {logsStatus === 'active' && (
          
              
                  <td colSpan="4"></td>
            
        )}
      </div>}

      {/* Service info footer */}
      {logsStatus === 'paused' && (
        <div className={styles.logsFooter}>
          <span className={styles.footerItem}>
            <span className={styles.footerLabel}>Service:</span> {selectedServices || selectedDataSource}
          </span>
          <span className={styles.footerItem}>
            <span className={styles.footerLabel}>Source:</span> {selectedDataSource || '100'}
          </span>
          <span className={styles.footerItem}>
            <span className={styles.footerLabel}>Status:</span> 
            <span className={styles.statusBadge}>System Maintenance</span>
          </span>
        </div>
      )}
    </div>
  );
};

export default APMLogs; 