import React, { useState, useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { formatDateTime } from './utils'; // Ensure you have a formatDateTime function

// Register Chart.js components and custom plugins
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const centerTextPlugin = (selectedOption) => ({
    id: 'centerTextPlugin',
    afterDraw: (chart) => {
        const { ctx, chartArea, data } = chart;
        if (chart.width === 0 || chart.height === 0) {
            return;
        }
        
        const sum = data.datasets[0].data.reduce((acc, value) => acc + value, 0);
        ctx.restore();
        const centerX = chart.width / 2;
        const centerY = 1 + chart.height/2;
        const fontSize = Math.min(chart.height / 10, 36); // Cap the font size
        let textColor;

        switch (selectedOption.label) {
            // case 'Basic Alert':
            //    textColor = 'rgba(204, 112, 0, 0.8)'; // Example color with opacity for Basic Alert
            //    break;
            case 'Collective Insight':
                textColor = 'rgba(207, 16, 32, 0.85)'; // Deep red with opacity for Collective Insight
                break;
            case 'Deviations':
                textColor = 'rgba(60, 179, 113, 0.85)'; // Medium sea green with opacity for Deviations
                break;
            default:
                textColor = 'rgba(142, 154, 188, 0.7)'; // Default color with opacity
        }

        // Draw subtle shadow
        ctx.font = `600 ${fontSize}px 'Segoe UI', Roboto, Arial, sans-serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'rgba(0, 0, 0, 0.15)';
        ctx.fillText(`${sum}`, centerX + 1, centerY + 1); 

        // Draw main text
        ctx.font = `600 ${fontSize}px 'Segoe UI', Roboto, Arial, sans-serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = textColor;
        ctx.fillText(`${sum}`, centerX, centerY);
        
        // Draw label below
        const labelFontSize = fontSize / 3;
        ctx.font = `400 ${labelFontSize}px 'Segoe UI', Roboto, Arial, sans-serif`;
        ctx.fillStyle = 'rgba(27, 38, 89, 0.7)';
        ctx.fillText('TOTAL', centerX, centerY + fontSize * 0.75);
        
        ctx.save();
    }
});

const percentageTextPlugin = {
    id: 'percentageTextPlugin',
    afterDatasetsDraw: (chart) => {
        const { ctx, data } = chart;
        const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);
        
        if (total === 0) return; // Don't draw percentages if there's no data

        data.datasets[0].data.forEach((value, index) => {
            if (value === 0) return; // Skip zero values
            
            const percentage = ((value / total) * 100).toFixed(1);
            if (percentage < 3) return; // Skip very small percentages to avoid clutter
            
            const model = chart.getDatasetMeta(0).data[index];
            const { x, y } = model.tooltipPosition();
            const radius = model.outerRadius;
            const midAngle = model.startAngle + (model.endAngle - model.startAngle) / 2;
            
            // Calculate position for better placement of percentage text
            // Move text a bit outward from the center for better visibility
            const fontSize = chart.height / 30;
            const xAdjust = Math.cos(midAngle) * (radius * 0.7);
            const yAdjust = Math.sin(midAngle) * (radius * 0.7);
            
            const textX = x + xAdjust;
            const textY = y + yAdjust;
            
            // Calculate optimal padding based on chart size
            const padding = Math.max(4, chart.height / 100);
            const borderRadius = Math.max(4, chart.height / 120);

            // Set up font for text
            ctx.font = `bold ${fontSize}px 'Segoe UI', Roboto, Arial, sans-serif`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Calculate text size
            const textWidth = ctx.measureText(`${percentage}%`).width;
            const textHeight = fontSize;

            // Draw background box with rounded corners
            ctx.fillStyle = 'rgba(0, 0, 0, 0.65)'; // Slightly more opaque for better contrast
            ctx.beginPath();
            const xPos = textX - textWidth / 2 - padding;
            const yPos = textY - textHeight / 2 - padding;
            const boxWidth = textWidth + padding * 2;
            const boxHeight = textHeight + padding * 2;

            // Draw rounded rectangle
            ctx.moveTo(xPos + borderRadius, yPos);
            ctx.lineTo(xPos + boxWidth - borderRadius, yPos);
            ctx.arc(xPos + boxWidth - borderRadius, yPos + borderRadius, borderRadius, 1.5 * Math.PI, 2 * Math.PI);
            ctx.lineTo(xPos + boxWidth, yPos + boxHeight - borderRadius);
            ctx.arc(xPos + boxWidth - borderRadius, yPos + boxHeight - borderRadius, borderRadius, 0, 0.5 * Math.PI);
            ctx.lineTo(xPos + borderRadius, yPos + boxHeight);
            ctx.arc(xPos + borderRadius, yPos + boxHeight - borderRadius, borderRadius, 0.5 * Math.PI, Math.PI);
            ctx.lineTo(xPos, yPos + borderRadius);
            ctx.arc(xPos + borderRadius, yPos + borderRadius, borderRadius, Math.PI, 1.5 * Math.PI);
            ctx.closePath();
            ctx.fill();
            
            // Add subtle shadow to the text for better readability
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
            ctx.fillText(`${percentage}%`, textX + 1, textY + 1);

            // Draw text with a brighter white for better contrast
            ctx.fillStyle = 'rgba(255, 255, 255, 0.95)';
            ctx.fillText(`${percentage}%`, textX, textY);
        });
    }
};

const EmptyState = () => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '20px',
        textAlign: 'center'
    }}>
        <div style={{
            width: '120px',
            height: '120px',
            borderRadius: '50%',
            background: 'linear-gradient(135deg, #8859d9, #4c6bf5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
            boxShadow: '0 8px 16px rgba(104, 37, 236, 0.15)'
        }}>
            <i className="bi bi-check-circle-fill" style={{
                fontSize: '60px',
                color: 'white'
            }}></i>
        </div>
        <h3 style={{
            color: '#1b2559',
            fontSize: '1.2rem',
            fontWeight: '600',
            marginBottom: '10px'
        }}>All Systems Functioning Normally</h3>
        <p style={{
            color: '#2B3674',
            fontSize: '0.9rem',
            opacity: 0.8
        }}>No anomalies detected in the current timeframe</p>
    </div>
);

const PieChart = ({ dataSource, server, startDate, endDate, updateAnomalyMetric, selectedOption }) => {
    const [anomalyData, setAnomalyData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const formattedStartTime = startDate ? formatDateTime(startDate) : '';
    const formattedEndTime = endDate ? formatDateTime(endDate) : '';
    const [refreshKey, setRefreshKey] = useState(0);
    const chartRef = useRef(null);

    const metricMapping = {
        'cpu.percent.used': { metricName: 'cpu_usage', label: 'CPU' },
        'memory.percent.used': { metricName: 'memory_usage', label: 'Memory' },
        'network_io_bytes_recv': { metricName: 'network_bytes_recv', label: 'Network' },
        'network_io_bytes_sent': { metricName: 'network_bytes_sent', label: 'Network' },
        'disk_usage': { metricName: 'disk_usage', label: 'Disk' },
        'gpu_usage': { metricName: 'gpu_usage', label: 'GPU' }
    };

    const metriclabels= {
        'cpu_usage':{label: 'CPU' }, 
        'memory_usage':{label: 'Memory usage' },
        'memory_total':{label: 'Memory Total' },
        'swap_usage':{label: 'Swap Usage' },
        'disk_read_count':{label: 'Read Count' },
        'disk_write_count':{label: 'Write Count' },
        'disk_read_bytes':{label: 'Read Bytes' },
        'disk_write_bytes':{label: 'Write Bytes' },
        'disk_read_time':{label: 'Read Time' },
        'disk_write_time':{label: 'Write Time' },
        'network_bytes_sent':{label: 'Bytes sent' },
        'network_bytes_recv':{label: 'Bytes Recv' },
        'network_packets_sent':{label: 'Packets Sent' },
        'network_packets_recv':{label: 'Packets Recv' },
        'network_errin':{label: 'Errin' },
        'network_errout':{label: 'Errout' },
        'network_dropin':{label: 'Dropin' },
        'network_dropout':{label: 'Dropout' },
    }

    // Get a formatted label for any metric, handling unknown metrics gracefully
    const getFormattedLabel = (metric) => {
        if (metriclabels[metric] && metriclabels[metric].label) {
            return metriclabels[metric].label;
        }
        
        // If not in the mapping, try to format it nicely
        return metric
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    function getMostAnomalousMetric(data) {
        if (data.length === 0) {
            return null; // In case there's no data to check
        }
    
        // Find the metric with the highest anomaly count
        const mostAnomalous = data.reduce((max, current) => {
            return current.count > max.count ? current : max;
        });
    
        return mostAnomalous;
    }

    useEffect(()=> {
        setSelectedCategory('');
      },[dataSource, server, startDate, endDate, selectedOption]);

    useEffect(() => {
        const fetchAnomalyData = async () => {
            try {
                // Determine the API mode based on the selected option
                const mode = selectedOption.label === "Collective Insight" ? 'strict' : 
                       selectedOption.label === "Deviations" ? 'deviations' : 'strict';  // Default to strict
                 function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }

            const token = getCookie('token');
            
                // If using deviations data, use the different endpoint
                if (mode === 'deviations') {
                    const response = await fetch(`https://infia.enviseer.com/api/clusters/${dataSource}/hosts/${server}/anomaly_analytics/mini?start=${formattedStartTime}&end=${formattedEndTime}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Accept': 'application/json',
                        }
                    });
                    
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    
                    const data = await response.json();
                    
                    // Group data by metric
                    const metricCounts = {};
                    if (Array.isArray(data)) {
                        data.forEach(entry => {
                            metricCounts[entry.metric] = (metricCounts[entry.metric] || 0) + 1;
                        });
                    }
                    
                    // Transform to the expected format
                    const transformedData = Object.entries(metricCounts).map(([key, value]) => ({
                        label: key,
                        count: value
                    }));
                    
                    console.log("Deviations raw metrics:", transformedData);
                    
                    if (selectedCategory) {
                        console.log("Selected Category Updated:", selectedCategory);
                        const subcategoryData = aggregateDataBySubCategory(transformedData, selectedCategory);
                        console.log("Subcategory data for", selectedCategory, ":", subcategoryData);
                        setCategoryData(subcategoryData);
                        
                        const mostAnomalousMetric = getMostAnomalousMetric(subcategoryData);
                        if (mostAnomalousMetric) {
                            const metricForUpdate = {
                                metricName: mostAnomalousMetric.label,
                                label: getFormattedLabel(mostAnomalousMetric.label)
                            };
                            updateAnomalyMetric(metricForUpdate);
                        }
                    } else {
                        const aggregatedData = aggregateDataByCategory(transformedData);
                        console.log("Aggregated category data:", aggregatedData);
                        setAnomalyData(aggregatedData);
                        
                        // Find the metric with the highest count
                        const maxMetric = transformedData.reduce((max, item) =>
                            item.count > max.count ? item : max, 
                            transformedData[0] || { label: null, count: 0 }
                        );
                        
                        // Map to category if it's a raw metric
                        const category = mapMetricToCategory(maxMetric.label);
                        const metricToUpdate = category !== maxMetric.label ? 
                            { metricName: maxMetric.label, label: category } : 
                            { metricName: maxMetric.label, label: getFormattedLabel(maxMetric.label) };
                        
                        console.log("Updating metric to:", metricToUpdate);
                        updateAnomalyMetric(metricToUpdate);
                    }
                } else {
                    // Original code for other modes
                    const response = await fetch('https://infia.enviseer.com/api/anomaliescount?' + new URLSearchParams({
                        cluster: dataSource,
                        hostname: server,
                        start: formattedStartTime,
                        end: formattedEndTime,
                        mode: mode,
                    }), {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Accept': 'application/json',
                        }
                    });
        
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
        
                    const data = await response.json();
                    const transformedData = Object.entries(data).map(([key, value]) => ({
                        label: key,
                        count: value
                    }));
                    console.log("Selected Category Updated2:", selectedCategory);
                    if (selectedCategory) {
                        console.log("Selected Category Updated:", selectedCategory);
                        const subcategoryData = aggregateDataBySubCategory(transformedData, selectedCategory);
                        setCategoryData(subcategoryData);
                        const mostAnomalousMetric = getMostAnomalousMetric(subcategoryData);
                        const metricForUpdate = {
                            metricName: mostAnomalousMetric.label,  // Set metricName to the label
                            label: mostAnomalousMetric.label        // Set label to the label
                         };
                       updateAnomalyMetric(metricForUpdate);
                    } else {
                        const aggregatedData = aggregateDataByCategory(transformedData);
                        setAnomalyData(aggregatedData);
                        const maxMetric = transformedData.reduce((max, item) =>
                            item.count > max.count ? item : max, 
                            transformedData[0] // Default to first element instead of `{ label: null, count: -Infinity }`
                        );
                    
                        // Send maxMetric in the correct format
                        updateAnomalyMetric({ metricName: maxMetric.label, label: maxMetric.label });
                        console.log("11111111111111111111111111111111111111111",transformedData.reduce((max, item) => 
                            item.count > max.count ? item : max, 
                            { label: null, count: -Infinity } // Default initial value
                        ).label);
                    }
                }
            } catch (error) {
                console.error('Error fetching anomaly data:', error);
            }
        };
    
        fetchAnomalyData();
    }, [dataSource, server, formattedStartTime, formattedEndTime, selectedOption, selectedCategory]);
    

    const colors = [
        '#6825EC', // Primary purple
        '#8C52FF', // Lighter purple
        '#AC7CE4', // Soft purple
        '#BA68C8', // Orchid purple
        '#9575CD', // Lavender 
        '#7986CB', // Blue purple
    ];
    
    const subColors = [
        '#02C39A', // Teal
        '#00B4D8', // Bright blue
        '#5390D9', // Soft blue
        '#FF9F1C', // Amber
        '#F15BB5', // Pink
        '#9B5DE5', // Bright purple
        '#00BBF9', // Light blue
        '#8AC926'  // Lime green
    ];

    const categoryMetrics = {
        'CPU': ['cpu_usage','cpu_wait','cpu.percent.used'],
        'Memory': ['memory_usage', 'memory_total', 'swap_usage', 'memory_buffered', 'memory_cached', 'memory_free', 'swap_io_out', 'swap_used'],
        'Disk': ['io_time_ms','disk_io_write_ms','disk_queue_size','disk_reads_bytes', 'disk_writes_bytes','disk_read_count', 'disk_write_count',  'disk_read_time', 'disk_write_time','disk_usage_percent'],
        'Network': ['network_bytes_sent', 'network_bytes_recv', 'network_packets_sent', 'network_packets_recv', 'network_errin', 'network_errout', 'network_dropin', 'network_dropout']
    };

    // Map raw metrics to categories for easier display
    const mapMetricToCategory = (metric) => {
        for (const [category, metrics] of Object.entries(categoryMetrics)) {
            if (metrics.includes(metric)) {
                return category;
            }
        }
        
        // If not found directly, check if it's a partial match
        if (metric.includes('memory') || metric.includes('swap')) {
            return 'Memory';
        } else if (metric.includes('cpu')) {
            return 'CPU';
        } else if (metric.includes('disk') || metric.includes('io_')) {
            return 'Disk';
        } else if (metric.includes('network') || metric.includes('bytes_sent') || metric.includes('bytes_recv')) {
            return 'Network';
        }
        
        // Default to original metric if no category is found
        return metric;
    };

    const aggregateDataByCategory = (data) => {
        const categoryMap = {};
    
        data.forEach(item => {
            // Use the enhanced mapping function
            const category = mapMetricToCategory(item.label);
            
            if (category) {
                if (categoryMap[category]) {
                    categoryMap[category] += item.count; // Add to existing category count
                } else {
                    categoryMap[category] = item.count; // Initialize new category count
                }
            }
        });
    
        // Convert the map to an array of objects
        return Object.keys(categoryMap).map(category => ({
            label: category,
            count: categoryMap[category]
        }));
    };
    
    const aggregateDataBySubCategory = (data, selectedCategory) => {
        // Check if the selectedCategory is valid
        const metricsForCategory = categoryMetrics[selectedCategory];
        if (!metricsForCategory) {
            return [];
        }
    
        // Filter the data based on the metrics for the selected category and fuzzy matching
        const filteredData = data.filter(metric => {
            // Direct match in the category metrics
            if (metricsForCategory.includes(metric.label)) {
                return true;
            }
            
            // For Memory category, include any metrics with "memory" in the name
            if (selectedCategory === 'Memory' && (metric.label.includes('memory') || metric.label.includes('swap'))) {
                return true;
            }
            
            // For CPU category, include any metrics with "cpu" in the name
            if (selectedCategory === 'CPU' && metric.label.includes('cpu')) {
                return true;
            }
            
            // For Disk category, include any metrics with "disk" or "io_" in the name
            if (selectedCategory === 'Disk' && (metric.label.includes('disk') || metric.label.includes('io_'))) {
                return true;
            }
            
            // For Network category, include any metrics with "network", "bytes_sent", or "bytes_recv" in the name
            if (selectedCategory === 'Network' && (metric.label.includes('network') || metric.label.includes('bytes_sent') || metric.label.includes('bytes_recv'))) {
                return true;
            }
            
            return false;
        });
    
        // Aggregate the counts for each label
        const aggregatedData = filteredData.reduce((acc, metric) => {
            acc[metric.label] = (acc[metric.label] || 0) + metric.count;
            return acc;
        }, {});
    
        // Convert aggregated data to an array of objects with label and count
        return Object.entries(aggregatedData).map(([label, count]) => ({ label, count }));
    };
    
    const safeAnomalyData = Array.isArray(anomalyData) ? anomalyData : [];
    const safeCategoryData = Array.isArray(categoryData) ? categoryData : [];


    
    const chartData = {
        datasets: selectedCategory ? [
            {
                // Outer Doughnut (Categories)
                data: safeAnomalyData.map(item => item.count),
                backgroundColor: safeAnomalyData.map(item => 
                    item.label === selectedCategory ? colors[safeAnomalyData.indexOf(item)] : `${colors[safeAnomalyData.indexOf(item)]}90`
                ),
                hoverBackgroundColor: safeAnomalyData.map(item => colors[safeAnomalyData.indexOf(item)]),
                borderColor: safeAnomalyData.map(item => 
                    item.label === selectedCategory ? '#ffffff' : 'rgba(255, 255, 255, 0.6)'
                ),
                borderWidth: safeAnomalyData.map(item => 
                    item.label === selectedCategory ? 2 : 1
                ),
                hoverBorderColor: '#ffffff',
                hoverBorderWidth: 2,
                hoverOffset: 6,
                offset: safeAnomalyData.map(item => 
                    item.label === selectedCategory ? 8 : 0
                ),
                labels: safeAnomalyData.map(item => getFormattedLabel(item.label)),
            },
            {
                // Inner Doughnut (Subcategories)
                data: safeCategoryData.map(item => item.count),
                backgroundColor: safeCategoryData.map(item => 
                    item.label === selectedSubCategory ? subColors[safeCategoryData.indexOf(item)] : `${subColors[safeCategoryData.indexOf(item)]}90`
                ),
                hoverBackgroundColor: safeCategoryData.map(item => subColors[safeCategoryData.indexOf(item)]),
                borderColor: safeCategoryData.map(item => 
                    item.label === selectedSubCategory ? '#ffffff' : 'rgba(255, 255, 255, 0.6)'
                ),
                borderWidth: safeCategoryData.map(item => 
                    item.label === selectedSubCategory ? 2 : 1
                ),
                hoverBorderColor: '#ffffff',
                hoverBorderWidth: 2,
                hoverOffset: 6, 
                offset: safeCategoryData.map(item => 
                    item.label === selectedSubCategory ? 8 : 0
                ),
                labels: safeCategoryData.map(item => getFormattedLabel(item.label)),
            }
        ] : [
            {
                // Single dataset when no category is selected
                data: safeAnomalyData.map(item => item.count),
                backgroundColor: colors.slice(0, safeAnomalyData.length),
                hoverBackgroundColor: safeAnomalyData.map((item, idx) => 
                    colors[idx % colors.length]
                ),
                borderColor: 'rgba(255, 255, 255, 0.8)',
                borderWidth: 1,
                hoverBorderColor: '#ffffff',
                hoverBorderWidth: 2,
                hoverOffset: 6,
                labels: safeAnomalyData.map(item => getFormattedLabel(item.label)),
            }
        ]
    };
    
    
    
    const handleClick = (event) => {
        if (!chartRef.current) return;

        const chart = chartRef.current;
        const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

        if (elements.length > 0) {
            // Add visual feedback - highlight the selected segment
            const index = elements[0].index;
            const datasetIndex = elements[0].datasetIndex;
            
            // Create animation effect on click
            const segment = chart.getDatasetMeta(datasetIndex).data[index];
            const currentRadius = segment.outerRadius;
            
            // Animate segment outward briefly
            segment.outerRadius = currentRadius * 1.08;
            chart.update();
            
            // Return to normal size after animation
            setTimeout(() => {
                segment.outerRadius = currentRadius;
                chart.update();
            }, 200);
            
            if (datasetIndex === 1 && selectedCategory) {
                // Clicking on inner chart (subcategories)
                const item = categoryData[index];
                const metricName = item.label;
                
                // Format label for display
                const label = getFormattedLabel(item.label);
                console.log("Inner Chart Clicked (Subcategory):", metricName, label, categoryData);
                
                setSelectedSubCategory(item.label);
                
                // Update the selected metric with both the raw name and formatted label
                updateAnomalyMetric({metricName, label});
            } else {
                // Clicking on outer chart (main categories)
                const item = anomalyData[index];
                
                // Toggle category selection
                if (selectedCategory === item.label) {
                    setSelectedCategory('');
                    console.log("Category unselected:", item.label);
                } else {
                    setSelectedCategory(item.label);
                    console.log("Category selected:", item.label); 
                }
                
                // Use the enhanced metric mapping
                let metricNameToUse = item.label;
                let labelToUse = item.label;
                
                // If this is a main category like CPU or Memory, we want to pass the category 
                // name as label, but find a representative metric for metricName
                if (['CPU', 'Memory', 'Disk', 'Network'].includes(item.label)) {
                    // Map category to a default metric
                    switch(item.label) {
                        case 'CPU':
                            metricNameToUse = 'cpu_usage';
                            break;
                        case 'Memory':
                            metricNameToUse = 'memory_usage';
                            break;
                        case 'Disk':
                            metricNameToUse = 'disk_usage';
                            break;
                        case 'Network':
                            metricNameToUse = 'network_bytes_recv';
                            break;
                    }
                    labelToUse = item.label; // Use category name as label
                } else {
                    // For specific metrics, use both the metric name and its formatted label
                    metricNameToUse = item.label;
                    labelToUse = getFormattedLabel(item.label);
                }
                
                console.log("Updating metric:", metricNameToUse, labelToUse);
                updateAnomalyMetric({ metricName: metricNameToUse, label: labelToUse });
            }
        }
    };
    return (
        <div style={{ 
            backgroundColor: '#ffffff', 
            padding: '24px 12px', 
            width: '26%', 
            minWidth: '250px',
            maxWidth: '100%',
            borderRadius: '12px', 
            boxShadow: '0 10px 20px rgba(104, 37, 236, 0.08), 0 6px 6px rgba(104, 37, 236, 0.12)',  
            overflowX: 'auto',
            transition: 'all 0.3s ease',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <h3 style={{ 
                marginBottom: '20px', 
                fontSize: '18px', 
                fontWeight: '600', 
                textAlign: 'center', 
                color: '#1b2559',
                letterSpacing: '0.2px'
            }}>
                Anomalies by Metric
            </h3>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%', 
                height: '280px',
                maxHeight: '320px', 
                zIndex: 0, 
                alignItems: 'center',
                position: 'relative'
            }}>
                {safeAnomalyData.length === 0 || safeAnomalyData.every(item => item.count === 0) ? (
                    <EmptyState />
                ) : (
                    <Doughnut
                        data={chartData}
                        onClick={handleClick}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            cutout: '65%', // Slightly larger hole for modern look
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                    labels: {
                                        boxWidth: 12,
                                        padding: 15,
                                        font: {
                                            size: 11,
                                            family: "'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
                                        },
                                        color: '#1b2559',
                                        usePointStyle: true,
                                        pointStyle: 'circle'
                                    },
                                    onClick: function(e, legendItem, legend) {
                                        // Enhanced legend click handling
                                        const index = legendItem.index;
                                        const meta = legend.chart.getDatasetMeta(0);
                                        
                                        // Create animation effect when legend is clicked
                                        const segment = meta.data[index];
                                        const currentRadius = segment.outerRadius;
                                        
                                        // Animate segment
                                        segment.outerRadius = currentRadius * 1.08;
                                        legend.chart.update();
                                        
                                        // Return to normal
                                        setTimeout(() => {
                                            segment.outerRadius = currentRadius;
                                            legend.chart.update();
                                        }, 200);
                                        
                                        // Trigger the same behavior as clicking on the segment
                                        const item = anomalyData[index];
                                        if (selectedCategory === item.label) {
                                            setSelectedCategory('');
                                        } else {
                                            setSelectedCategory(item.label);
                                        }
                                    }
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            const datasetIndex = context.datasetIndex;
                                            const label = context.dataset.labels[context.dataIndex]; // Use the label corresponding to the dataset
                                            return `${label}: ${context.raw}`;
                                        }
                                    },
                                    backgroundColor: 'rgba(27, 38, 89, 0.85)',
                                    titleFont: { size: 13, weight: 'bold', family: "'Segoe UI', Roboto, Arial, sans-serif" },
                                    bodyFont: { size: 12, family: "'Segoe UI', Roboto, Arial, sans-serif" },
                                    padding: 10,
                                    cornerRadius: 6,
                                    displayColors: true,
                                    usePointStyle: true,
                                    boxPadding: 5
                                },
                                centerTextPlugin: true // Register the custom plugin
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true,
                                onHover: function(event, elements) {
                                    if (elements && elements.length) {
                                        event.native.target.style.cursor = 'pointer';
                                        
                                        // Highlight hovered segment
                                        const segment = elements[0].element;
                                        const currentRadius = segment.outerRadius;
                                        segment.outerRadius = currentRadius * 1.04;
                                        event.chart.update();
                                    } else {
                                        event.native.target.style.cursor = 'default';
                                        
                                        // Reset all segments
                                        const meta = event.chart.getDatasetMeta(0);
                                        meta.data.forEach(segment => {
                                            segment.outerRadius = segment._model ? segment._model.outerRadius : segment.options.radius;
                                        });
                                        event.chart.update();
                                    }
                                }
                            },
                            animation: {
                                animateRotate: true,
                                animateScale: true,
                                duration: 800,
                                easing: 'easeOutQuart',
                                delay: function(context) {
                                    // Stagger animations for a nice effect
                                    return context.dataIndex * 50;
                                }
                            },
                            layout: {
                                padding: {
                                    top: 5,
                                    bottom: 15
                                }
                            }
                        }}
                        plugins={[centerTextPlugin(selectedOption.label), percentageTextPlugin]}
                        ref={chartRef}
                    />
                )}
            </div>
            {selectedCategory && (
                <div style={{
                    position: 'relative',
                    textAlign: 'center',
                    zIndex: 1,
                    fontWeight: '600',
                    fontSize: '14px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    color: '#3a416f',
                    backgroundColor: 'rgba(104, 37, 236, 0.05)',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    display: 'inline-block',
                    margin: '10px auto',
                    width: 'auto',
                    maxWidth: '90%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                    <span style={{ fontWeight: 400, marginRight: '6px' }}>Type:</span>
                    {selectedCategory}
                </div>
            )}
            <style>{`
                @media (max-width: 1200px) {
                    .chart-container {
                        width: 30%;
                    }
                }
                @media (max-width: 992px) {
                    .chart-container {
                        width: 50%;
                    }
                }
                @media (max-width: 768px) {
                    .chart-container {
                        width: 100%;
                    }
                }
            `}</style>
        </div>
    );
};

export default PieChart;
