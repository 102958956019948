import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import Logo from './Logo';
import { FaUserCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';


const HomePage = ({ handleLogout }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSettingsPanel, setShowSettingsPanel] = useState(false);
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const profileDropdownRef = useRef(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const [applications, setApplications] = useState([
        { name: "EnviState", description: "Monitor and manage system health effectively.", owned: true },
        { name: "EnviDetect", description: "Proactively detect anomalies and predict timelines.", owned: false },
        { name: "EnviHeal", description: "Automatically heal detected issues to ensure smooth operations.", owned: false },
        { name: "EnviBuddy", description: "Your HR companion for better workplace management.", owned: true }
    ]);

    useEffect(() => {
        fetchUserSettings();
    }, []);

    const handleEnvistateClick = () => {
        navigate('/dashboard');  // Redirect to your internal app's dashboard or landing page
    };

    const handleEnvikathaClick = () => {
        const data = { token: { token }, userName: { user } };

        document.cookie = `myData=${encodeURIComponent(JSON.stringify(data))}; path=/`;
        window.location.href = 'https://infia.enviseer.com/envibuddy/';  // Redirect to an external URL
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        fetchUserSettings();
        if (!showDropdown) {
            setShowSettingsPanel(false);
        }
    };

    const openSettingsPanel = () => {
        setShowSettingsPanel(true);
    };

    const closeSettingsPanel = () => {
        setShowSettingsPanel(false);
    };

    function handleClickOutside(event) {
        // Check for the profile dropdown
        if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
            setShowDropdown(false); // Close the profile dropdown
        }
    }

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showDropdown]);

    const [userSettings, setUserSettings] = useState({
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        customerId: '',
    });

    const fetchUserSettings = useCallback(async () => {
        setLoading(true);
        setError(null);
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        setToken(token);
        const username = getCookie('username');
        setUser(username);
        try {
            const response = await fetch(`https://infia.enviseer.com/api/api/user/fetchUser?email=${username}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add the Authorization header with token
                    'Accept': 'application/json',
                    'X-Application-Name': 'envi-onboarding'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    handleLogout();
                }
                else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            setUserSettings({
                username: data.displayName,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                customerId: data.customerId,
            });
        } catch (error) {
            console.error("Error fetching user settings:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [userSettings]);


    return (
        <div className="home-page">
            <header id="header1" className="header1 fixed-top d-flex ">
                <div className="sidebar-header" style={{ display: 'flex', marginLeft: '40px', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Logo sidebarOpen={true} />
                </div>
                <div className="header-icons">
                    <div className="profile-icon-container" onClick={toggleDropdown} ref={profileDropdownRef}>
                        <FaUserCircle className="header-icon profile-icon" />
                        {showDropdown && (
                            <div className="profile-dropdown">
                                <img src="/demoProfile.png" alt="Profile" className="profile-picture" />
                                <div className="profile-info">
                                    <h4>Hi, {userSettings.username}</h4>
                                    <p>{userSettings.email}</p>
                                </div>
                                <button className="settings-button" onClick={openSettingsPanel}>Settings</button>
                                <button className="logout-button" onClick={handleLogout}>Logout</button>
                            </div>
                        )}
                    </div>
                </div>
            </header>
            {showSettingsPanel && (
                <div className="settings-panel">
                    <div className="settings-panel-header">
                        <h5>Account Settings</h5>
                        <button onClick={closeSettingsPanel} className="close-settings-panel">X</button>
                    </div>
                    <form className="settings-form">
                        <div className="form-group">
                            <p> Username: {userSettings.username}</p>
                        </div>
                        <div className="form-group">
                            <p> Email: {userSettings.email}</p>
                        </div>
                        <div className="form-group">
                            <p> Firstname: {userSettings.firstName}</p>
                        </div>
                        <div className="form-group">
                            <p> Lastname: {userSettings.lastName}</p>
                        </div>
                        <div className="form-group">
                            <p> CustomerId: {userSettings.customerId}</p>
                        </div>
                    </form>
                </div>
            )}
            <main className="main-content">
                {/* Your Applications Section */}
                <section className="your-applications">
                    <h2 className="section-title">My Applications</h2>
                    <div className="applications-grid">
                        {applications.filter(app => app.owned).map(app => (
                            <div className={`card ${app.owned ? 'owned' : ''}`}>
                                {app.owned && <div className="badge owned">Owned</div>}
                                <h3>{app.name}</h3>
                                <p>{app.description}</p>
                                <button onClick={() => handleEnvistateClick(app.name)}>Go to app</button>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="available-applications">
                    <h2 className="section-title">Available Solutions</h2>
                    <div className="applications-grid">
                        {applications.filter(app => !app.owned).map(app => (
                            <div className="card available">
                                <h3>{app.name}</h3>
                                <p>{app.description}</p>
                                <button onClick={() => handleEnvistateClick(app.name)}>Learn More</button>
                            </div>
                        ))}
                    </div>
                </section>

            </main>


            <Tooltip id="envistate-tooltip" place="top" effect="solid" type="light">
                Go to Envistate
            </Tooltip>
            <Tooltip id="envikatha-tooltip" place="top" effect="solid" type="light">
                Go to EnviBuddy
            </Tooltip>
        </div>

    );
};

export default HomePage;
