import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebarnew';
import Dashboard from './components/Dashboard';
import Metrices from './components/Metrices';
import Landing from './components/Landing';
import Logs from './components/Logs';
import Login from './components/Login';
import Correlation from './components/correlation';
import Register from './components/Register';
import HomePage from './components/HomePage';
import Alerts from './components/Alerts';
import EnviDetect from './components/EnviDetect';
import Traces from './components/Traces';
import APM from './components/APMMonitoring';
import ITPA from './components/ITPA';
import RPA from './components/RPA/RPA';
import InfrastructureMetrics from './components/features/InfrastructureMetrics';
import InfraProcesses from './components/features/InfraProcesses';
import Enviheal from './components/Enviheal';
import { BrowserRouter as Router, Route, Navigate, Routes, useLocation } from 'react-router-dom';

// New Component to handle location and content
function AppContent({ toggleSidebar, handleLogout, handleSelect, selectedPage, sidebarOpen, setSidebarOpen, setSelectedPage, selectedMetric, setSelectedMetric }) {
    const location = useLocation(); // Get the current route
    const [isIframeVisible, setIframeVisible] = useState(false);
    const [clustersWithServers, setClustersWithServers] = useState({}); // Changed to object format
    const [loading, setLoading]= useState(false);
    const [error,setError] = useState();

    // Fetch clusters and servers
    const fetchClusters = useCallback(async () => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        // Retrieves token and username from cookies.

        const token = getCookie('token');
        const username = getCookie('username');
        const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
        setLoading(true);
        setError(null); // Reset error state
    
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
    
            if (!response.ok) {
                if (response.status === 401) {
                    handleLogout(); // Call logout if unauthorized
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
    
            const clusterData = await response.json();
            // Fetch servers for each cluster after fetching clusters
            const clustersWithServers = await fetchAllServers(clusterData);
            setClustersWithServers(clustersWithServers);
    
        } catch (error) {
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [handleLogout]);

    // New function to fetch servers for all clusters and structure the data as an object
    const fetchAllServers = async (clusters) => {
        const clustersWithServers = {};

        for (const cluster of clusters) {
            const servers = await fetchServers(cluster);  // Fetch servers for each cluster
            clustersWithServers[cluster] = { servers };  // Save servers under the cluster name
        }

        return clustersWithServers;
    };

    // Fetch servers for a specific cluster
    const fetchServers = useCallback(async (clusterName) => {
        const url = `https://infia.enviseer.com/api/clusters/${clusterName}/hostnames`;
    
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        // Retrieves token and username from cookies.

        const token = getCookie('token');
        setLoading(true);
        setError(null); // Reset error state
    
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
    
            if (!response.ok) {
                if (response.status === 401) {
                    handleLogout(); // Call logout if unauthorized
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
    
            const data = await response.json();
            return data; // Return the servers for the current cluster
        } catch (error) {
            console.error("Failed to fetch servers:", error);
            setError(error.message);
            return [];
        } finally {
            setLoading(false);
        }
    }, [handleLogout]);

    // Example usage of the helper functions
    useEffect(() => {
        fetchClusters(); // Initially fetch the clusters and servers
    }, []); 

    const toggleIframe = () => {
        setIframeVisible(!isIframeVisible);
    };
    useEffect(() => {
        // Collapse the sidebar when on the homepage
        if (location.pathname === '/') {
            setSidebarOpen(false);
        }
    }, [location.pathname, setSidebarOpen]);

    return (
        <>
            {location.pathname === '/dashboard' && (
                <>
                    <Header toggleSidebar={toggleSidebar} onLogout={handleLogout} selectedPage={selectedPage} sidebarOpen={sidebarOpen}/>
                    <Sidebar
                        onSelect={handleSelect}
                        sidebarOpen={sidebarOpen}
                        toggleSidebar={toggleSidebar}
                        selectedPage={selectedPage}
                        selectedSubPage={selectedMetric}
                    />
                </>
            )}


<iframe
                    src="https://infia.enviseer.com/envibuddy/envistate/"
                    width="90%"
                    height="70%"
                    style={{
                        maxHeight: '700px',
                        maxWidth: '400px',
                        position: 'fixed',
                        right: '20px',
                        bottom: isIframeVisible ? '80px' : '-100%', // Slide in/out effect
                        zIndex: 999,
                        display: isIframeVisible ? 'block' : 'none', // Toggle visibility
                        border: '2px solid #ddd',
                        borderRadius: '16px',
                        boxShadow: '0 4px 12px rgba(128, 0, 128, 0.3), 0 0 20px rgba(128, 0, 128, 0.2)',
                        backgroundColor: '#fff',
                        transition: 'bottom 0.3s ease',
                    }}
                    title="Embedded Website"
                ></iframe>

                {/* Toggle button */}
                <button
                    id="toggleButton"
                    onClick={toggleIframe}
                    style={{
                        position: 'fixed',
                        right: '20px',
                        bottom: '20px',
                        backgroundColor: isIframeVisible ? '#fff' : 'white',
                        color: isIframeVisible ? '#895CE8' : 'white',
                        border: '2px solid #895CE8',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '60px',
                        height: '60px',
                        fontSize: '24px',
                        boxShadow: '0 4px 12px rgba(128, 0, 128, 0.3), 0 0 20px rgba(128, 0, 128, 0.2)',
                        transition: 'all 0.3s ease',
                    }}
                >
                    {isIframeVisible ? (
                        <i className="bi bi-x" style={{ fontSize: '24px', color: '#895CE8' }}></i>
                    ) : (
                        <img src="/Logo2.png" alt="Logo" style={{ width: '44px', height: '44px', borderRadius:'8px' }} />
                    )}
                </button>

<main
  id="main"
  className={`main ${location.pathname === '/dashboard' ? (sidebarOpen ? 'sidebar-open' : 'sidebar-collapsed') : ''}`}
  style={location.pathname === '/' ? { margin: '0' } : {}}
>
                <Routes>
                    <Route path="/" element={<HomePage handleLogout={handleLogout} />} />
                    <Route
                        path="/dashboard"
                        element={
                            selectedPage === 'analytics' ? (
                                <Landing sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                            ) : selectedPage === 'dashboard' ? (
                                <Dashboard sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                            ) : selectedPage === 'enviDetect' ? (
                                <EnviDetect sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                            ) : selectedPage === 'infra' && selectedMetric === 'metrics' ? (
                                <InfrastructureMetrics sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : selectedPage === 'infra' && selectedMetric === 'processes' ? (
                                <InfraProcesses sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : selectedPage === 'infra' && selectedMetric === 'logs' ? (
                                <Logs sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : selectedPage === 'metrics' ? (
                                <Metrices selectedMetric={selectedMetric} sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : selectedPage === 'traces' ? (
                                <Traces sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ): selectedPage === 'APM' ? (
                                <APM sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} clustersWithServers={clustersWithServers} />
                            ) : selectedPage === 'correlation' ? (
                                <Correlation sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : selectedPage === 'alerts' ? (
                                <Alerts sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : selectedPage === 'enviheal' ? (
                                <Enviheal sidebarOpen={sidebarOpen} onLogout={handleLogout} initialTab='itpa' />
                            ) : null
                        }
                    />
                    <Route path="/login" element={<Navigate to="/" />} />
                </Routes>
            </main>
        </>
    );
}

function App() {
    const [selectedPage, setSelectedPage] = useState('analytics'); // Default to 'dashboard'
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [selectedMetric, setSelectedMetric] = useState('CPU');
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state

    useEffect(() => {
        // Check if the token is present to handle authentication
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        // Update a CSS variable based on sidebar state
        document.documentElement.style.setProperty('--sidebar-width', sidebarOpen ? '225px' : '80px');
    }, [sidebarOpen]);

    const handleSelect = (page, metric) => {
        setSelectedPage(page);
        if (metric) {
            setSelectedMetric(metric);
        }
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLogin = (token) => {
        // Save token and update auth state
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        // Remove token and update auth state
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
    };

    if (!isAuthenticated) {
        return (
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/login" element={<Login onLogin={handleLogin} />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                </div>
            </Router>
        );
    }

    return (
        <Router>
            <AppContent
                toggleSidebar={toggleSidebar}
                handleLogout={handleLogout}
                handleSelect={handleSelect}
                selectedPage={selectedPage}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                setSelectedPage={setSelectedPage}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
            />
        </Router>
    );
}

export default App;
