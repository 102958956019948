import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import { BiSortUp, BiSortDown, BiSearch } from 'react-icons/bi';
import MultiSelectorFilter from './MultiSelectorFilter';

const Row = ({ index, style, data }) => {
  const process = data.processes[index];
  return (
    <div style={{
      ...style,
      display: 'grid',
      gridTemplateColumns: data.columns,
      padding: '6px 12px',
      alignItems: 'center',
      borderBottom: '1px solid #eee',
      fontSize: '13px',
      backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9fc',
      transition: 'all 0.2s ease',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f0f0f5',
      }
    }}>
      <div style={{ paddingRight: '8px' }}>{process.process_id}</div>
      <div style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingRight: '8px',
        maxWidth: '100%'
      }} title={process.process_name}>{process.process_name}</div>
      <div style={{ textAlign: 'right', paddingRight: '8px' }}>{process.cpu_usage ? `${process.cpu_usage.toFixed(2)}%` : '0.00%'}</div>
      <div style={{ textAlign: 'right', paddingRight: '8px' }}>{process.memory_usage ? `${process.memory_usage.toFixed(2)}%` : '0.00%'}</div>
      <div style={{
        color: process.process_status === 'running' ? '#10b981' : 
               process.process_status === 'sleeping' ? '#f59e0b' : 
               process.process_status === 'idle' ? '#ea580c' : '#2B3674',
        fontWeight: '500'
      }}>{process.process_status}</div>
    </div>
  );
};

// Loading skeleton component
const LoadingSkeleton = () => {
  return (
    <div className="loading-skeleton">
      <div className="skeleton-header">
        <div className="skeleton-filter"></div>
        <div className="skeleton-search"></div>
      </div>
      <div className="skeleton-table-header"></div>
      {[...Array(8)].map((_, index) => (
        <div key={index} className="skeleton-row">
          <div className="skeleton-cell small"></div>
          <div className="skeleton-cell large"></div>
          <div className="skeleton-cell small"></div>
          <div className="skeleton-cell small"></div>
          <div className="skeleton-cell small"></div>
        </div>
      ))}
    </div>
  );
};

const ProcessTable = ({ processes, selectedStatuses, setSelectedStatuses, loading = false }) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1200);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSort = useCallback((key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  // Get all unique statuses for the filter
  const getUniqueStatuses = () => {
    if (!processes.length) return [];
    
    const uniqueStatuses = [...new Set(processes.map(p => p.process_status))];
    return uniqueStatuses.filter(Boolean).map(status => ({
      value: status,
      label: status.charAt(0).toUpperCase() + status.slice(1)
    }));
  };

  const filteredProcesses = useMemo(() => {
    let result = processes;
    
    // Filter by status if statuses are selected
    if (selectedStatuses && selectedStatuses.length > 0) {
      result = result.filter(process => 
        selectedStatuses.includes(process.process_status)
      );
    }
    
    // Filter by search term
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter(process => 
        process.process_id.toString().includes(searchLower) ||
        process.process_name.toLowerCase().includes(searchLower)
      );
    }
    
    return result;
  }, [processes, searchTerm, selectedStatuses]);

  const sortedProcesses = useMemo(() => {
    if (!sortConfig.key) return filteredProcesses;

    return [...filteredProcesses].sort((a, b) => {
      if (sortConfig.key === 'process_id') {
        return sortConfig.direction === 'asc' 
          ? parseInt(a[sortConfig.key]) - parseInt(b[sortConfig.key])
          : parseInt(b[sortConfig.key]) - parseInt(a[sortConfig.key]);
      }
      
      if (sortConfig.key === 'cpu_usage' || sortConfig.key === 'memory_usage') {
        return sortConfig.direction === 'asc'
          ? (a[sortConfig.key] || 0) - (b[sortConfig.key] || 0)
          : (b[sortConfig.key] || 0) - (a[sortConfig.key] || 0);
      }

      return sortConfig.direction === 'asc'
        ? (a[sortConfig.key] || '').localeCompare(b[sortConfig.key] || '')
        : (b[sortConfig.key] || '').localeCompare(a[sortConfig.key] || '');
    });
  }, [filteredProcesses, sortConfig]);

  // Responsive column layout
  const getColumns = useCallback(() => {
    if (windowWidth < 768) {
      return '60px minmax(120px, 1fr) 70px 80px 70px';
    } else {
      return '100px minmax(150px, 1fr) 90px 100px 90px';
    }
  }, [windowWidth]);
  
  const columns = getColumns();

  const SortIcon = useCallback(({ columnKey }) => {
    if (sortConfig.key !== columnKey) {
      return <BiSortUp style={{ opacity: 0.3 }} />;
    }
    return sortConfig.direction === 'asc' ? <BiSortUp /> : <BiSortDown />;
  }, [sortConfig]);

  const calculateTableHeight = () => {
    const baseHeight = Math.min(sortedProcesses.length * 34, 500);
    return sortedProcesses.length === 0 ? 200 : baseHeight;
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className="process-table-container" style={{ 
      borderRadius: '12px', 
      overflow: 'hidden',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      width: '100%',
      boxShadow: '0 2px 8px rgba(104, 37, 236, 0.1)',
    }}>
      <div className="process-table-controls" style={{
        padding: '10px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ffffff',
        flexWrap: 'wrap',
        gap: '10px',
      }}>
        <div className="table-filters" style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '15px',
          flexWrap: 'wrap',
        }}>
          <div className="status-filter-wrapper">
            <MultiSelectorFilter
              label="Status"
              options={getUniqueStatuses()}
              selectedOptions={selectedStatuses || []}
              setSelectedOptions={setSelectedStatuses}
              buttonClassName="status-filter-button"
              dropdownClassName="status-dropdown"
            />
          </div>
          
          <div className="search-container" style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '250px',
            maxWidth: '100%',
          }}>
            <BiSearch style={{
              position: 'absolute',
              left: '12px',
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '16px',
              color: searchFocused ? '#6825EC' : '#8E9ABC',
              transition: 'color 0.2s ease'
            }} />
            <input
              type="text"
              placeholder="Search by ID or name..."
              value={searchTerm}
              onChange={handleSearch}
              onFocus={() => setSearchFocused(true)}
              onBlur={() => setSearchFocused(false)}
              style={{
                width: '100%',
                padding: '6px 10px 6px 36px',
                border: `1px solid ${searchFocused ? '#6825EC' : '#E2E8F0'}`,
                borderRadius: '8px',
                fontSize: '13px',
                outline: 'none',
                transition: 'all 0.2s ease',
                backgroundColor: '#ffffff',
                color: '#2B3674',
                height: '40px',
                '&:hover': {
                  borderColor: '#6825EC',
                  boxShadow: '0 2px 4px rgba(104, 37, 236, 0.1)'
                }
              }}
            />
          </div>
        </div>
        
        {/* Right side - empty for now */}
        <div></div>
      </div>

      <div className="table-header" style={{
        display: 'grid',
        gridTemplateColumns: columns,
        background: 'linear-gradient(90deg, #6825EC 0%, #8C52FF 100%)',
        color: '#fff',
        fontWeight: '500',
        fontSize: '13px',
        padding: '8px 12px',
      }}>
        {[
          { key: 'process_id', label: 'Process ID' },
          { key: 'process_name', label: 'Process Name' },
          { key: 'cpu_usage', label: 'CPU Usage' },
          { key: 'memory_usage', label: 'Memory Usage' },
          { key: 'process_status', label: 'Status' }
        ].map(({ key, label }) => (
          <div
            key={key}
            onClick={() => handleSort(key)}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              userSelect: 'none',
              transition: 'opacity 0.2s ease',
              textAlign: key === 'cpu_usage' || key === 'memory_usage' ? 'right' : 'left',
              '&:hover': {
                opacity: 0.9
              }
            }}
          >
            {label} <SortIcon columnKey={key} />
          </div>
        ))}
      </div>

      <div className="table-body" style={{ height: calculateTableHeight() }}>
        <List
          height={calculateTableHeight()}
          itemCount={sortedProcesses.length}
          itemSize={34}
          width="100%"
          itemData={{ processes: sortedProcesses, columns }}
          style={{ 
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: '#6825EC #F4F7FE'
          }}
          className="custom-scrollbar"
        >
          {Row}
        </List>
      </div>

      {sortedProcesses.length === 0 && (
        <div className="empty-state" style={{
          padding: '30px 0',
          textAlign: 'center',
          color: '#8E9ABC',
          fontSize: '14px'
        }}>
          No processes found matching your search
        </div>
      )}

      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 5px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: #F4F7FE;
          borderRadius: 3px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #6825EC;
          border-radius: 3px;
          opacity: 0.7;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          opacity: 1;
        }

        input::placeholder {
          color: #8E9ABC;
        }
        
        .status-filter-button {
          height: 40px;
          border-radius: 8px;
          padding: 0 16px;
          white-space: nowrap;
        }
        
        /* Loading skeleton styles */
        .loading-skeleton {
          width: 100%;
          background: white;
          border-radius: 12px;
          padding: 16px;
          box-shadow: 0 2px 8px rgba(104, 37, 236, 0.1);
        }
        
        .skeleton-header {
          display: flex;
          gap: 15px;
          margin-bottom: 20px;
          flex-wrap: wrap;
        }
        
        .skeleton-filter {
          width: 120px;
          height: 40px;
          background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
          background-size: 200% 100%;
          animation: shimmer 1.5s infinite;
          border-radius: 8px;
        }
        
        .skeleton-search {
          width: 250px;
          height: 40px;
          background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
          background-size: 200% 100%;
          animation: shimmer 1.5s infinite;
          border-radius: 8px;
        }
        
        .skeleton-table-header {
          width: 100%;
          height: 36px;
          background: linear-gradient(90deg, #e0e0e0 25%, #d0d0d0 50%, #e0e0e0 75%);
          background-size: 200% 100%;
          animation: shimmer 1.5s infinite;
          border-radius: 6px;
          margin-bottom: 10px;
        }
        
        .skeleton-row {
          display: grid;
          grid-template-columns: 100px minmax(150px, 1fr) 90px 100px 90px;
          gap: 10px;
          padding: 6px 0;
          margin-bottom: 6px;
        }
        
        .skeleton-cell {
          height: 20px;
          background: linear-gradient(90deg, #f5f5f5 25%, #ebebeb 50%, #f5f5f5 75%);
          background-size: 200% 100%;
          animation: shimmer 1.5s infinite;
          border-radius: 4px;
        }
        
        .skeleton-cell.small {
          width: 90%;
        }
        
        .skeleton-cell.large {
          width: 100%;
        }
        
        @keyframes shimmer {
          0% {
            background-position: -200% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
        
        /* Responsive styles */
        @media (max-width: 768px) {
          .process-table-controls {
            padding: 10px;
          }
          
          .table-filters {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
          }
          
          .search-container {
            width: 100%;
          }
          
          .skeleton-row {
            grid-template-columns: 60px minmax(120px, 1fr) 70px 80px 70px;
          }
        }
      `}</style>
    </div>
  );
};

export default React.memo(ProcessTable);
