// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page .card.custom-card {
    cursor: pointer !important;
    padding: 20px !important;
    border: 1px solid #ccc !important;
    width: 300px !important;
    height: 150px !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
    transition: transform 0.2s ease-in-out !important;
}

.home-page .card.custom-card:hover {
    transform: translateY(-5px) !important;
}

.home-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100vh;
}

h3 {
    color: rgb(2, 62, 138);
}

.header{
    left: 0;
    width: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,wBAAwB;IACxB,iCAAiC;IACjC,uBAAuB;IACvB,wBAAwB;IACxB,6BAA6B;IAC7B,wBAAwB;IACxB,iCAAiC;IACjC,kCAAkC;IAClC,8BAA8B;IAC9B,qDAAqD;IACrD,iDAAiD;AACrD;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,OAAO;IACP,WAAW;AACf","sourcesContent":[".home-page .card.custom-card {\n    cursor: pointer !important;\n    padding: 20px !important;\n    border: 1px solid #ccc !important;\n    width: 300px !important;\n    height: 150px !important;\n    text-align: center !important;\n    display: flex !important;\n    flex-direction: column !important;\n    justify-content: center !important;\n    align-items: center !important;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;\n    transition: transform 0.2s ease-in-out !important;\n}\n\n.home-page .card.custom-card:hover {\n    transform: translateY(-5px) !important;\n}\n\n.home-page {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    height: 100vh;\n}\n\nh3 {\n    color: rgb(2, 62, 138);\n}\n\n.header{\n    left: 0;\n    width: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
