import React, { useState, useEffect, useMemo, useCallback, useRef} from "react";
import styles from "./Traces.module.css"; // Importing CSS Module
import { FaInfoCircle, FaSyncAlt, FaChartLine } from 'react-icons/fa';
import { BiSortUp, BiSortDown, BiSearch, BiChevronDown } from 'react-icons/bi';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'; // Required for chart.js
import { FixedSizeList as List } from 'react-window';
import CustomDropdown from './CustomDropdown';
import styled from 'styled-components';

// Styled components for the service dropdown
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 180px;
`;

const DropdownButton = styled.button`
  background: #FFFFFF;
  color: #1b2559;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #E0E6EF;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    border-color: #6825EC;
    box-shadow: 0 2px 8px rgba(104, 37, 236, 0.1);
  }

  svg {
    margin-left: 8px;
    color: #6825EC;
    transition: transform 0.2s ease;
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background-color: #FFFFFF;
  min-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 1px solid #E0E6EF;
  opacity: ${props => props.isOpen ? 1 : 0};
  transform: translateY(${props => props.isOpen ? '0' : '-4px'});
  transition: all 0.2s ease;
  pointer-events: ${props => props.isOpen ? 'auto' : 'none'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  max-height: 300px;
  overflow-y: auto;
`;

const DropdownItem = styled.div`
  color: #1b2559;
  padding: 10px 16px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background-color: #6825EC15;
    color: #6825EC;
  }
`;

// Custom service dropdown component
const ServiceDropdown = ({ label, options, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeTimeoutRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 150);
  };

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  // Find the selected option
  const selectedOption = options.find(option => option.value === value) || options[0];
  const displayLabel = `${label}: ${selectedOption.label}`;

  return (
    <DropdownContainer 
      ref={dropdownRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownButton>
        {displayLabel}
        <BiChevronDown 
          size={20} 
          style={{ 
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease'
          }} 
        />
      </DropdownButton>
      <DropdownContent isOpen={isOpen}>
        {options.map((option, index) => (
          <DropdownItem
            key={index}
            onClick={() => {
              onChange(option.value);
              setIsOpen(false);
              if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
              }
            }}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
};

// Add SortIcon component
const SortIcon = ({ columnKey, currentSortColumn, currentSortDirection }) => {
  if (currentSortColumn !== columnKey) {
    return <BiSortUp style={{ opacity: 0.3 }} />;
  }
  return currentSortDirection === 'asc' ? <BiSortUp /> : <BiSortDown />;
};

// Custom refresh button component
const RefreshButton = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    onClick();
    setTimeout(() => setIsClicked(false), 500);
  };

  return (
    <button
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        padding: '8px',
        border: '1px solid #E0E6EF',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '500',
        color: isHovered ? '#6825EC' : '#1b2559',
        backgroundColor: '#FFFFFF',
        transition: 'all 0.2s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        width: '38px',
        height: '38px',
        cursor: 'pointer',
        position: 'relative',
        overflow: 'hidden',
        transform: isClicked ? 'scale(0.95)' : isHovered ? 'translateY(-1px)' : 'none',
      }}
    >
      <FaSyncAlt
        style={{
          fontSize: '16px',
          animation: isClicked ? 'refresh-spin 1s cubic-bezier(0.4, 0, 0.2, 1)' : 'none',
        }}
      />
      <style jsx>{`
        @keyframes refresh-spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </button>
  );
};

const Traces = ({ selectedDataSource, services, startTime, endTime, selectedServices, setSelectedServices }) => {
  const [traceData, setTraceData] = useState([]); // Stores all fetched traces
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [loadedPages, setLoadedPages] = useState({}); 
  const [totalPages, setTotalPages] = useState(1); // Store total pages
  const [totalRecords, setTotalRecords] = useState(0); // Total traces count
  const [loading, setLoading] = useState(false); // Show loading when fetching
  const observer = useRef(null); // Observer for infinite scrolling
  const tableRef = useRef(null);
  const pageSize = 50; // Number of records per page
  const [selectedTrace, setSelectedTrace] = useState(null);
  const [activeTab, setActiveTab] = useState("serviceMap");
  const [expandedSpans, setExpandedSpans] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [logs, setLogs] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedMetricData, setSelectedMetricData] = useState(null);
  const [graphVisible, setGraphVisible] = useState({});
  const [showGraphs, setShowGraphs] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Chart refs
  const traceCountChartRef = useRef(null);
  const traceDurationChartRef = useRef(null);
  const errorCountChartRef = useRef(null);

  // State variables for Trace Graphs
  const [traceCountData, setTraceCountData] = useState([]);
  const [traceDurationData, setTraceDurationData] = useState([]);
  const [errorCountData, setErrorCountData] = useState([]);
  // State variables for Trace Graphs

  const [searchTerm, setSearchTerm] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const [columns, setColumns] = useState('140px 120px minmax(200px, 2fr) 120px 180px');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [pageInputValue, setPageInputValue] = useState('1');
  const [pageInputFocused, setPageInputFocused] = useState(false);
  
  // Function to track animation status
  const [animatedSpans, setAnimatedSpans] = useState({});

  // Initialize all spans to be visible by default when trace is loaded
  useEffect(() => {
    if (selectedTrace && selectedTrace.spans) {
      const allSpans = {};
      selectedTrace.spans.forEach(span => {
        allSpans[span.span_id] = true;
      });
      setAnimatedSpans(allSpans);
    }
  }, [selectedTrace]);

  // Add animation trigger buttons in the service map container
  const renderTree = (nodes, level = 0) => {
    if (!nodes || nodes.length === 0) return null;
    
    // Calculate color opacity based on level
    const getColorOpacity = (level) => {
      const baseOpacity = 1 - (level * 0.15);
      return Math.max(baseOpacity, 0.3);
    };
    
    return (
      <div style={{ 
        marginLeft: level * 32,
        position: 'relative'
      }}>
        {nodes.map((node, index) => {
          const isExpanded = expandedSpans[node.span_id] || false;
          const hasChildren = node.children && node.children.length > 0;
          const isSelected = selectedSpanId === node.span_id;
          const opacity = getColorOpacity(level);
          
          return (
            <div key={node.span_id} style={{ 
              position: 'relative',
              marginBottom: '16px'
            }}>
              {/* Connection lines */}
                {level > 0 && (
                <>
                  {/* Vertical line from parent */}
                  <div style={{
                    position: 'absolute',
                    left: '-24px',
                    top: '-8px',
                    width: '2px',
                    height: '44px',
                    background: `linear-gradient(to bottom, rgba(104, 37, 236, ${opacity * 0.5}), rgba(104, 37, 236, ${opacity * 0.1}))`,
                    zIndex: 1
                  }} />
                  {/* Horizontal line to node */}
                  <div style={{
                    position: 'absolute',
                    left: '-24px',
                    top: '16px',
                    width: '24px',
                    height: '2px',
                    background: `linear-gradient(to right, rgba(104, 37, 236, ${opacity * 0.1}), rgba(104, 37, 236, ${opacity * 0.5}))`,
                    zIndex: 1
                  }} />
                </>
              )}

              {/* Node card */}
              <div style={{ 
                position: 'relative',
                background: isSelected 
                  ? `linear-gradient(135deg, rgba(104, 37, 236, ${opacity * 0.15}) 0%, rgba(104, 37, 236, ${opacity * 0.1}) 100%)`
                  : 'white',
                border: `1px solid rgba(104, 37, 236, ${opacity * 0.2})`,
                borderRadius: '12px',
                padding: '16px',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                boxShadow: isSelected 
                  ? '0 4px 12px rgba(104, 37, 236, 0.12)'
                  : '0 2px 4px rgba(104, 37, 236, 0.05)',
                zIndex: 2
              }}
                onClick={() => setSelectedSpanId(node.span_id)}
                  onMouseEnter={(e) => {
                  if (!isSelected) {
                    e.currentTarget.style.background = `linear-gradient(135deg, rgba(104, 37, 236, ${opacity * 0.1}) 0%, rgba(104, 37, 236, ${opacity * 0.05}) 100%)`;
                    e.currentTarget.style.boxShadow = '0 4px 12px rgba(104, 37, 236, 0.08)';
                    e.currentTarget.style.transform = 'translateY(-1px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (!isSelected) {
                    e.currentTarget.style.background = 'white';
                    e.currentTarget.style.boxShadow = '0 2px 4px rgba(104, 37, 236, 0.05)';
                    e.currentTarget.style.transform = 'none';
                  }
                }}
              >
                {/* Status indicator */}
                <div style={{
                  width: '4px',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  borderRadius: '12px 0 0 12px',
                  background: level === 0 
                    ? 'linear-gradient(to bottom, #6825EC, #8B5CF6)'
                    : `rgba(104, 37, 236, ${opacity * 0.5})`
                }} />

                {/* Expand/Collapse button */}
                {hasChildren && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandedSpans(prev => ({
                        ...prev,
                        [node.span_id]: !isExpanded
                      }));
                    }}
                    style={{
                      width: '28px',
                      height: '28px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: 'none',
                      borderRadius: '8px',
                      background: isExpanded 
                        ? `rgba(104, 37, 236, ${opacity * 0.1})`
                        : 'transparent',
                      color: `rgba(104, 37, 236, ${opacity})`,
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      marginLeft: '4px'
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.background = `rgba(104, 37, 236, ${opacity * 0.15})`;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.background = isExpanded 
                        ? `rgba(104, 37, 236, ${opacity * 0.1})`
                        : 'transparent';
                    }}
                  >
                    <svg 
                      width="16" 
                      height="16" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2.5" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                      style={{
                        transform: isExpanded ? 'rotate(90deg)' : 'none',
                        transition: 'transform 0.2s ease'
                      }}
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </button>
                )}

                {/* Content */}
                <div style={{ flex: 1, minWidth: 0 }}>
                  {/* Operation name and duration */}
                  <div style={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '6px',
                    gap: '12px'
                  }}>
                    <div style={{ 
                      fontSize: '14px', 
                      fontWeight: '600', 
                      color: level === 0 ? '#6825EC' : `rgba(31, 41, 55, ${opacity})`,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                      {node.operation_name}
                              </div>
                    <div style={{
                      fontSize: '13px',
                      fontWeight: '500',
                      color: '#6825EC',
                      padding: '2px 8px',
                      borderRadius: '6px',
                      background: `rgba(104, 37, 236, ${opacity * 0.1})`,
                      whiteSpace: 'nowrap'
                    }}>
                      {node.duration_ms}ms
                              </div>
                              </div>

                  {/* Service name and child count */}
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '12px'
                  }}>
                    <div style={{ 
                      fontSize: '13px',
                      color: `rgba(107, 114, 128, ${opacity})`,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '6px'
                    }}>
                      <span style={{ 
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        backgroundColor: level === 0 ? '#6825EC' : `rgba(104, 37, 236, ${opacity})`
                      }} />
                      {node.service_name}
                    </div>
                    {hasChildren && (
                      <div style={{ 
                        fontSize: '12px',
                        color: '#6B7280',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                      }}>
                        <svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                        </svg>
                        {node.children.length} {node.children.length === 1 ? 'child' : 'children'}
                      </div>
                    )}
                                </div>
                    </div>
                </div>
                
              {/* Render children */}
              {isExpanded && hasChildren && (
                <div style={{
                  marginTop: '8px',
                  marginLeft: '14px',
                  paddingLeft: '24px',
                  borderLeft: `2px dashed rgba(104, 37, 236, ${opacity * 0.2})`
                }}>
                    {renderTree(node.children, level + 1)}
                  </div>
                )}
            </div>
          );
        })}
      </div>
    );
  };

  // Add handleRefresh function
  const handleRefresh = () => {
    setIsLoading(true);
    // Reset data
    setTraceData([]);
    setLoadedPages({});
    setCurrentPage(1);
    
    // Refresh data
    Promise.all([fetchData(), fetchTracesReset()])
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Get unique services from trace data
  const uniqueServices = useMemo(() => {
    const services = new Set(traceData.map(trace => trace.service_name));
    return Array.from(services);
  }, [traceData]);

  // Create service options for dropdown
  const serviceOptions = useMemo(() => {
    const options = [{ value: '*', label: 'All' }];
    
    if (services.length > 0) {
      services.forEach(service => {
        if (service) {  // Only add non-null/undefined services
          options.push({ value: service, label: service });
        }
      });
    }
    
    return options;
  }, [uniqueServices]);

  // Filter traces based on search and selected service
  const filteredTraces = useMemo(() => {
    //console.log("Filtering traces with selectedService:", selectedService);
    
    return traceData.filter(trace => {
      // Search filter
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = searchTerm.trim() === '' || 
        trace.trace_id.toLowerCase().includes(searchLower) ||
        trace.operation_name.toLowerCase().includes(searchLower);
      
      // Service filter
      const matchesService = !selectedServices || 
                            selectedServices === '*' || 
                            trace.service_name === selectedServices;
      
      return matchesSearch && matchesService;
    });
  }, [traceData, searchTerm, selectedServices]);

  // Debug selectedService changes

  // Update sortedTraces to use filteredTraces
  const sortedTraces = useMemo(() => {
    if (!filteredTraces || !sortColumn || !sortDirection) return filteredTraces;
    return [...filteredTraces].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      if (typeof valueA === 'string') {
        return sortDirection === 'asc' 
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number') {
        return sortDirection === 'asc'
          ? valueA - valueB
          : valueB - valueA;
      }
      return 0;
    });
  }, [filteredTraces, sortColumn, sortDirection]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const token = getCookie('token');


  const fetchData = useCallback(async () => {
    try {
      const [traceCountRes, avgDurationRes, errorCountRes] = await Promise.all([
        fetch("https://infia.enviseer.com/api/aggregated/trace_count", {
        method: "POST",
        headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({  
          cluster: selectedDataSource,
            service_name: selectedServices || (services.length ? services[0] : '*'),
          start_time: startTime,
            end_time: endTime,
          }),
        }),
        fetch("https://infia.enviseer.com/api/aggregated/avg_duration", {
        method: "POST",
        headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({ 
          cluster: selectedDataSource,
            service_name: selectedServices || (services.length ? services[0] : '*'),
          start_time: startTime,
          end_time: endTime,
         }),
        }),
        fetch("https://infia.enviseer.com/api/aggregated/error_count", {
        method: "POST",
        headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify({ 
          cluster: selectedDataSource,
            service_name: selectedServices || (services.length ? services[0] : '*'),
          start_time: startTime,
          end_time: endTime,
         }),
        })
      ]);
  
      const traceCount = await traceCountRes.json();
      const avgDuration = await avgDurationRes.json();
      const errorCount = await errorCountRes.json();

      setTraceCountData(traceCount.map(d => ({ time: d.time_bucket, count: d.value })));
      setTraceDurationData(avgDuration.map(d => ({ time: d.time_bucket, avgDuration: d.value })));
      setErrorCountData(errorCount.map(d => ({ time: d.time_bucket, count: d.value })));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [selectedDataSource, services, selectedServices, startTime, endTime]);



  // Fetch Data when component loads or parameters change
  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);



  const fetchTracesReset = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch("https://infia.enviseer.com/api/apm/traces/list", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cluster: selectedDataSource,
          service_name: selectedServices||'*',
          start_time: startTime,
          end_time: endTime,
          page: 1,  // Always start from page 1 when resetting
          page_size: pageSize,
        }),
      });
      const data = await response.json();
      
      // Update data states even if empty array
      setTraceData(data.traces || []);
      setTotalPages(data.pagination?.total_pages || 1);
      setTotalRecords(data.pagination?.total_records || 0);
      setLoadedPages({ 1: true }); // Mark page 1 as loaded
    } catch (error) {
      console.error("Error fetching traces:", error);
      // On error, clear data and set loaded
      setTraceData([]);
      setTotalPages(1);
      setTotalRecords(0);
      setLoadedPages({ 1: true });
    } finally {
      setLoading(false);
    }
  }, [selectedDataSource, selectedServices, startTime, endTime, pageSize]);

  const fetchTraces = useCallback(async () => {
    if (loading) return; // Prevent multiple calls while loading

    setLoading(true);
    try {
      // Check if we already have this page loaded
      if (loadedPages[currentPage] === true) {
        // Just reset loading state and exit - the page is already in state
        setLoading(false);
        return;
      }
      
      const response = await fetch("https://infia.enviseer.com/api/apm/traces/list", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cluster: selectedDataSource,
          service_name: selectedServices||'*',
          start_time: startTime,
          end_time: endTime,
          page: currentPage,  // Send current page
          page_size: pageSize, // Send page size
        }),
      });
      const data = await response.json();
      
      // Update data states even if empty array
      setTraceData(data.traces || []);
      setTotalPages(data.pagination?.total_pages || 1);
      setTotalRecords(data.pagination?.total_records || 0);
      setLoadedPages(prev => ({ ...prev, [currentPage]: true }));
    } catch (error) {
      console.error("Error fetching traces:", error);
      // On error, clear data and set loaded
      setTraceData([]);
      setTotalPages(1);
      setTotalRecords(0);
      setLoadedPages(prev => ({ ...prev, [currentPage]: true }));
    } finally {
      setLoading(false);
    }
  }, [selectedDataSource, selectedServices, startTime, endTime, currentPage, loading, loadedPages, pageSize]);

  // Update the useEffect that handles filter changes to properly reset pagination and refresh data
  useEffect(() => {
    setLoadedPages({});
    setTraceData([]); // Reset when new filters are applied
    setCurrentPage(1); // Reset to page 1
    setIsLoading(true); // Set loading state immediately
    
    // Fetch both traces and graph data
    selectedServices&&Promise.all([
      fetchData(),
      fetchTracesReset()
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [selectedDataSource, selectedServices, startTime, endTime, fetchTracesReset, fetchData]); 

  const lastElementRef = useCallback((node) => {
    if (loading || currentPage >= totalPages) return;
  
    if (observer.current) observer.current.disconnect();
  
    observer.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && currentPage < totalPages) {
          setCurrentPage(prev => prev + 1); // Fetch the next page when the last element is visible
        }
      });
    }, { rootMargin: "30% 0px" });
  
    if (node) {
      observer.current.observe(node);
    }
  }, [loading, currentPage, totalPages]);
  // 🔄 Detect scroll up & update `currentPage`
  const lastScrollTop = useRef(0);
  const rowsPerPage = 50; // Number of rows per page (each page = 100 rows)
  const rowHeight = 40; // Approximate row height in pixels

// Handle the scroll event, updating the currentPage based on the direction
const handleScroll = useCallback(() => {
  if (!tableRef.current) return;

  const scrollTop = tableRef.current.scrollTop; // Current scroll position
    const containerHeight = tableRef.current.clientHeight; // Height of the scrollable container

    // Calculate the last visible row index based on scroll position
    const lastVisibleRowIndex = Math.floor((scrollTop + containerHeight) / rowHeight);
    
    // Calculate the page number based on the last visible row
    const newPage = Math.floor(lastVisibleRowIndex / rowsPerPage) + 1;


  // If the new page is different from the current page, update the page number
  if (newPage !== currentPage) {
    //console.log(`Updating currentPage to ${newPage}`);
    setCurrentPage(newPage); // Update the current page
  }
}, [currentPage]);
useEffect(() => {
  const table = tableRef.current;
  if (table) {
    table.addEventListener('scroll', handleScroll); // Attach the scroll event listener
    return () => table.removeEventListener('scroll', handleScroll); // Cleanup on component unmount
  }
}, [handleScroll]);



  
  // 🔄 Fetch new pages when currentPage updates
  // useEffect(() => {
  //   fetchTraces(currentPage);
  // }, [currentPage]);
  
  
  
  


  const handleSelectTrace = (trace) => {
    fetch("https://infia.enviseer.com/api/apm/traces/detail", {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ cluster: selectedDataSource, trace_id: trace.trace_id, service_name: selectedServices })
    })
      .then(response => response.json())
      .then(data => {
        setSelectedTrace({ ...trace, spans: data.spans || [] });
        fetchLogs(trace.span_id, trace.service_name);
        setShowModal(true); // Show modal when trace is selected
      })
      .catch(error => console.error("Error fetching trace details:", error));
  };

  const fetchLogs = (traceId, service_name) => {
    fetch("https://infia.enviseer.com/api/apm/logs/by-span", {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        cluster_name: selectedDataSource,
        service_name: service_name,
        span_id: traceId
      }),
    })
      .then(response => response.json())
      .then(data => setLogs(data.logs || []))
      .catch(error => console.error("Error fetching logs:", error));
  };

  const fetchMetrics = () => {
    fetch("https://infia.enviseer.com/api/apm/metrics/distinct", {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        cluster: selectedDataSource,
        service_name: selectedServices,
        start_time: new Date(new Date(selectedTrace.start_time).getTime() - 30 * 60 * 1000).toISOString(),
        end_time: new Date(new Date(selectedTrace.start_time).getTime() + 30 * 60 * 1000).toISOString()
      }),
    })
      .then(response => response.json())
      .then(data => setMetrics(data.metrics || []))
      .catch(error => console.error("Error fetching metrics:", error));
  };

  const fetchMetricData = (metricName) => {
    fetch(`https://infia.enviseer.com/api/apm/metrics/timeseries`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        cluster: selectedDataSource,
        service_name: selectedServices,
        start_time: new Date(new Date(selectedTrace.start_time).getTime() - 30 * 60 * 1000).toISOString(),
        end_time: new Date(new Date(selectedTrace.start_time).getTime() + 30 * 60 * 1000).toISOString(),
        metric_name: metricName
      }),
    })
      .then(response => response.json()) // Parse the JSON from the response
      .then(responseData => {
        // Once we have the data, map over the data array
        const timestamps = responseData.data.map(item => new Date(item.timestamp).toLocaleString());
        const values = responseData.data.map(item => item.value);

        // Prepare the data object for setting in state
        const data = {
          metric_name: metricName, // Use the passed metricName
          timestamps: timestamps,
          values: values,
        };

        // Save the fetched metric data
        setSelectedMetricData(data);
      })
      .catch(error => console.error("Error fetching metric data:", error));
  };


  const closeModal = () => {
    setShowModal(false);
    setSelectedTrace(null);
  };

  const toggleExpand = (spanId) => {
    setExpandedSpans(prev => ({ ...prev, [spanId]: !prev[spanId] }));
  };

  const renderSpansHierarchy = (spans = [], parentId = "", level = 0) => {
    if (!spans || spans.length === 0) return <p>No trace details available.</p>;

    return spans
      .filter(span => span.parent_span_id === parentId)
      .map(span => {
        const hasChildren = spans.some(child => child.parent_span_id === span.span_id);
        const marginLeft = level * 25;

        return (
          <div key={span.span_id} className={styles.spanContainer} style={{ marginLeft }}>
            <div className={styles.spanHeader}>
              {hasChildren && (
                <button className={styles.toggleButton} onClick={() => toggleExpand(span.span_id)}>
                  {expandedSpans[span.span_id] ? "▼" : "►"}
                </button>
              )}
              <strong>{span.operation_name}</strong> ({span.service_name}) - {span.duration_ms} ms
            </div>

            {span.attributes && (
              <div className={styles.attributesContainer}>
                {Object.entries(JSON.parse(span.attributes)).map(([key, value]) => (
                  <span key={key} className={styles.attributeItem}>
                    <strong>{key}:</strong> {value}
                  </span>
                ))}
              </div>
            )}

            {expandedSpans[span.span_id] && renderSpansHierarchy(spans, span.span_id, level + 1)}
          </div>
        );
      });
  };

  const renderLogsTable = (logs) => {
    return (
      <div className={styles.logsTableContainer}>
        <div className={styles.header}>
          <h2 className={styles.title}>Log Entries</h2>
          <p className={styles.subtitle}>Log entries for this trace</p>
        </div>
        <div className={styles.tableSection}>
          <table className={styles.logsTable}>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th style={{ width: '100px' }}>Severity</th>
                <th>Message</th>
            </tr>
          </thead>
            <tbody>
            {logs.length > 0 ? (
              logs.map((log, index) => (
                  <tr key={index} className={styles.logRow}>
                    <td className={styles.timestampCell}>
                      {new Date(log.timestamp).toLocaleString()}
                    </td>
                    <td>
                      <span 
                        className={styles.severityBadge}
                        style={{
                          backgroundColor: log.severity === 'ERROR' 
                            ? 'rgba(239, 68, 68, 0.2)' 
                            : log.severity === 'WARN' 
                            ? 'rgba(245, 158, 11, 0.2)'
                            : 'rgba(104, 37, 236, 0.2)',
                          color: log.severity === 'ERROR'
                            ? '#EF4444'
                            : log.severity === 'WARN'
                            ? '#F59E0B'
                            : '#6825EC'
                        }}
                      >
                        {log.severity}
                      </span>
                    </td>
                    <td className={styles.messageCell}>
                      <div className={styles.messageContent}>
                        {log.log_message}
                      </div>
                    </td>
                </tr>
              ))
            ) : (
              <tr>
                  <td colSpan="3" style={{ textAlign: 'center', padding: '24px', color: '#6B7280' }}>
                    No logs available for this trace
                  </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
      </div>
    );
  };


  const toggleGraphVisibility = (metricName) => {
    if (selectedMetric === metricName) {
      setSelectedMetric(null);
    setGraphVisible(prevState => ({
      ...prevState,
        [metricName]: false
      }));
    } else {
      setSelectedMetric(metricName);
      setGraphVisible(prevState => ({
        ...prevState,
        [metricName]: true
    }));

    // Fetch metric data when the graph is shown
      fetchMetricData(metricName);
    }
  };

  const renderMetricsTable = (metrics) => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        height: '100%'
      }}>
        {/* Graph Section */}
        {selectedMetric && selectedMetricData && (
          <div style={{
            backgroundColor: 'white',
            borderRadius: '12px',
            padding: '20px',
            boxShadow: '0 4px 12px rgba(104, 37, 236, 0.08)',
            border: '1px solid rgba(104, 37, 236, 0.15)',
            animation: 'slideDown 0.3s ease'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '16px'
            }}>
              <h3 style={{
                margin: 0,
                fontSize: '16px',
                fontWeight: '600',
                color: '#1F2937',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}>
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#6825EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M3 3v18h18"></path>
                  <path d="M18 12l-6-6-6 6"></path>
                </svg>
                {selectedMetric}
              </h3>
              <button
                onClick={() => {
                  setSelectedMetric(null);
                  setSelectedMetricData(null);
                }}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#6B7280',
                  padding: '8px',
                  borderRadius: '6px',
                  transition: 'all 0.2s ease',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = 'rgba(104, 37, 236, 0.1)';
                  e.target.style.color = '#6825EC';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.color = '#6B7280';
                }}
              >
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div style={{ height: '300px' }}>
                          <Line
                            data={{
                              labels: selectedMetricData.timestamps,
                              datasets: [{
                                label: selectedMetricData.metric_name,
                                data: selectedMetricData.values,
                    borderColor: '#6825EC',
                    backgroundColor: 'rgba(104, 37, 236, 0.1)',
                                borderWidth: 2,
                    fill: true,
                    tension: 0.4
                  }]
                }}
                options={chartOptions}
                key={`metric-chart-${selectedMetricData.metric_name}-${Date.now()}`}
              />
            </div>
          </div>
        )}

        {/* Table Section */}
        <div style={{
          backgroundColor: 'white',
          borderRadius: '12px',
          overflow: 'hidden',
          boxShadow: '0 4px 12px rgba(104, 37, 236, 0.08)',
          border: '1px solid rgba(104, 37, 236, 0.15)'
        }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{
                backgroundColor: '#6825EC',
                color: 'white'
              }}>
                <th style={{
                  padding: '14px 20px',
                  textAlign: 'left',
                  fontSize: '14px',
                  fontWeight: '500'
                }}>Metric Name</th>
                <th style={{
                  padding: '14px 20px',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: '500',
                  width: '120px'
                }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {metrics.length > 0 ? (
                metrics.map((metric, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      if (selectedMetric === metric) {
                        setSelectedMetric(null);
                        setSelectedMetricData(null);
                      } else {
                        setSelectedMetric(metric);
                        fetchMetricData(metric);
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      backgroundColor: selectedMetric === metric ? 'rgba(104, 37, 236, 0.05)' : 'white',
                      borderBottom: '1px solid rgba(104, 37, 236, 0.1)'
                    }}
                    onMouseEnter={(e) => {
                      if (selectedMetric !== metric) {
                        e.currentTarget.style.backgroundColor = 'rgba(104, 37, 236, 0.02)';
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (selectedMetric !== metric) {
                        e.currentTarget.style.backgroundColor = 'white';
                      }
                    }}
                  >
                    <td style={{
                      padding: '16px 20px',
                      fontSize: '14px',
                      color: '#1F2937',
                      fontWeight: selectedMetric === metric ? '600' : '500'
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                      }}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke={selectedMetric === metric ? '#6825EC' : '#6B7280'}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          style={{ transition: 'all 0.2s ease' }}
                        >
                          <path d="M3 3v18h18"></path>
                          <path d="M18 12l-6-6-6 6"></path>
                        </svg>
                        {metric}
                        </div>
                  </td>
                    <td style={{
                      padding: '16px 20px',
                      textAlign: 'center'
                    }}>
                      <span style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px 12px',
                        borderRadius: '12px',
                        fontSize: '13px',
                        fontWeight: '500',
                        backgroundColor: 'rgba(104, 37, 236, 0.1)',
                        color: '#6825EC'
                      }}>
                        Active
                      </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                  <td colSpan="2" style={{
                    padding: '40px 20px',
                    textAlign: 'center',
                    color: '#6B7280'
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '12px'
                    }}>
                      <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M3 3v18h18"></path>
                        <path d="M18 12l-6-6-6 6"></path>
                      </svg>
                      <p style={{ margin: 0, fontSize: '14px' }}>No metrics available</p>
                    </div>
                  </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
      </div>
    );
  };

  const renderServiceMap = (spans = []) => {
    if (!spans || spans.length === 0) return <p>No trace details available.</p>;

    const tree = buildTree();
    
    return (
      <div className={styles.serviceMapContainer}>
        {/* Split view container */}
        <div style={{ 
          display: 'flex', 
          gap: '24px',
          height: '100%'
        }}>
          {/* Left side - Service Map Tree (2/3 width) */}
          <div style={{
            flex: '2',
            overflowY: 'auto',
            borderRight: '1px solid rgba(104, 37, 236, 0.1)',
            paddingRight: '24px'
          }}>
            {renderTree(tree)}
          </div>

          {/* Right side - Details Panel (1/3 width) */}
          <div style={{ 
            flex: '1',
            overflowY: 'auto',
            padding: '16px',
            backgroundColor: '#F9F7FF',
            borderRadius: '8px'
          }}>
            {selectedSpanId ? (
              <div>
                <h3 style={{ 
                  margin: '0 0 16px 0',
                  color: '#6825EC',
                  fontSize: '16px',
                  fontWeight: '600'
                }}>Span Details</h3>
                {spans.map(span => {
                  if (span.span_id === selectedSpanId) {
                    return (
                      <div key={span.span_id}>
                        <div style={{ marginBottom: '16px' }}>
                          <div style={{ fontSize: '13px', color: '#6B7280', marginBottom: '4px' }}>Operation</div>
                          <div style={{ 
                            fontSize: '14px', 
                            fontWeight: '500', 
                            color: '#1F2937',
                            padding: '8px 12px',
                            backgroundColor: 'white',
                            borderRadius: '6px',
                            border: '1px solid rgba(104, 37, 236, 0.15)'
                          }}>
                            {span.operation_name}
          </div>
        </div>
                        <div style={{ marginBottom: '16px' }}>
                          <div style={{ fontSize: '13px', color: '#6B7280', marginBottom: '4px' }}>Service</div>
                          <div style={{ 
                            fontSize: '14px', 
                            fontWeight: '500', 
                            color: '#6825EC',
                            padding: '8px 12px',
                            backgroundColor: 'white',
                            borderRadius: '6px',
                            border: '1px solid rgba(104, 37, 236, 0.15)'
                          }}>
                            {span.service_name}
            </div>
            </div>
                        <div style={{ marginBottom: '16px' }}>
                          <div style={{ fontSize: '13px', color: '#6B7280', marginBottom: '4px' }}>Duration</div>
                          <div style={{ 
                            fontSize: '14px', 
                            fontWeight: '500', 
                            color: '#1F2937',
                            padding: '8px 12px',
                            backgroundColor: 'white',
                            borderRadius: '6px',
                            border: '1px solid rgba(104, 37, 236, 0.15)'
                          }}>
                            {span.duration_ms}ms
            </div>
            </div>
                        {span.attributes && (
                          <div>
                            <div style={{ fontSize: '13px', color: '#6B7280', marginBottom: '8px' }}>Attributes</div>
                            <div style={{ 
                              backgroundColor: 'white',
                              borderRadius: '6px',
                              border: '1px solid rgba(104, 37, 236, 0.15)',
                              overflow: 'hidden'
                            }}>
                              {Object.entries(JSON.parse(span.attributes)).map(([key, value], index) => (
                                <div key={key} style={{
                                  padding: '8px 12px',
                                  borderBottom: index !== Object.entries(JSON.parse(span.attributes)).length - 1 ? '1px solid rgba(104, 37, 236, 0.1)' : 'none',
                                  fontSize: '13px'
                                }}>
                                  <div style={{ color: '#6825EC', fontWeight: '500', marginBottom: '2px' }}>{key}</div>
                                  <div style={{ color: '#1F2937' }}>{value}</div>
          </div>
                              ))}
        </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
          </div>
            ) : (
              <div style={{ 
                color: '#6B7280',
                fontSize: '14px',
                textAlign: 'center',
                marginTop: '40px'
              }}>
                Select a span to view details
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  // Add a state variable for the selected span
  const [selectedSpanId, setSelectedSpanId] = useState(null);
  
  // Add buildTree function
  const buildTree = () => {
    if (!selectedTrace || !selectedTrace.spans) return [];

    // Create a map of spans by ID for easier retrieval
    const spansById = {};
    selectedTrace.spans.forEach(span => {
      spansById[span.span_id] = span;
    });
    
    // Build a tree structure with parent-child relationships
    const buildSubTree = (parentId = "") => {
      return selectedTrace.spans
        .filter(span => span.parent_span_id === parentId)
        .map(span => ({
          ...span,
          children: buildSubTree(span.span_id)
        }));
    };
    
    // Start with root spans (those without parent)
    return buildSubTree("");
  };
  
  const simplifyLabels = (labels) => {
    const totalLabels = labels.length;
    const step = Math.ceil(totalLabels / 10); // Adjusts dynamically based on data size
    return labels.map((label, index) => (index % step === 0 ? label : ""));
  };
  const formatGraphTimestamp = (date) => {
    // For consistent formatting
    if (!date || !(date instanceof Date) || isNaN(date)) return '';
    
    // Format: "Mar 22, 10:30"
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  // Add key with time filter to prevent re-renders
  const graphTimeFilterKey = useMemo(() => 
    `${startTime?.getTime()}-${endTime?.getTime()}`, 
    [startTime, endTime]
  );

  // Memoize common chart options
  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 800,
      easing: 'easeOutQuart'
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.9)',
        titleFont: {
          size: 13,
          weight: 'bold'
        },
        bodyFont: {
          size: 12
        },
        padding: 12,
        cornerRadius: 8,
        displayColors: false,
        position: 'nearest',
        callbacks: {
          title: function(tooltipItems) {
            const item = tooltipItems[0];
            const date = new Date(item.label);
            
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            
            return `${hours}:${minutes}, ${day}/${month}/${year}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: 'rgba(229, 231, 235, 0.5)'
        },
        ticks: {
          display: true,
          color: '#6B7280',
          font: {
            size: 11,
            weight: '500'
          },
          maxRotation: 0,
          minRotation: 0,
          padding: 10,
          autoSkip: true,
          maxTicksLimit: 8,
          callback: function(value, index, values) {
            const date = new Date(this.getLabelForValue(value));
            if (!date) return '';
            
              // If many values, show fewer labels to prevent overcrowding
            if (values.length > 20 && index % Math.ceil(values.length / 8) !== 0) {
                return '';
              }

            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');

            // Calculate time difference between start and end dates
            const firstDate = new Date(values[0].value);
            const lastDate = new Date(values[values.length - 1].value);
            const diffInDays = Math.abs((lastDate - firstDate) / (1000 * 60 * 60 * 24));

            // If the time range is within a day, show both time and date
            if (diffInDays <= 1) {
              return [`${hours}:${minutes}`, `${day}/${month}`];
            } else {
              // For longer ranges, just show the date
              return `${day}/${month}`;
            }
          }
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(229, 231, 235, 0.5)'
        },
        ticks: {
          color: '#6B7280',
          font: {
            size: 10
          },
          padding: 8
        }
      }
    }
  }), []);

  // Memoize trace count chart data
  const traceCountChartData = useMemo(() => {
    // Don't reverse the data anymore to show time increasing left to right
    const data = traceCountData;
    return {
      labels: data.map(item => item.time),
      datasets: [
        {
          label: 'Trace Count',
          data: data.map(item => item.count),
          borderColor: '#6825EC',
          backgroundColor: 'rgba(104, 37, 236, 0.1)',
          borderWidth: 2,
          pointBackgroundColor: '#6825EC',
          pointRadius: 3,
          pointHoverRadius: 5,
                tension: 0.4,
          fill: true
        }
      ]
    };
  }, [traceCountData]);

  // Memoize trace duration chart data
  const traceDurationChartData = useMemo(() => {
    const data = traceDurationData;
    return {
      labels: data.map(item => item.time),
      datasets: [
        {
          label: 'Avg Duration (ms)',
          data: data.map(item => item.avgDuration),
          borderColor: '#0EA5E9',
          backgroundColor: 'rgba(14, 165, 233, 0.1)',
          borderWidth: 2,
          pointBackgroundColor: '#0EA5E9',
          pointRadius: 3,
          pointHoverRadius: 5,
          tension: 0.4,
          fill: true
        }
      ]
    };
  }, [traceDurationData]);

  // Memoize error count chart data
  const errorCountChartData = useMemo(() => {
    const data = errorCountData;
    return {
      labels: data.map(item => item.time),
      datasets: [
        {
          label: 'Error Count',
          data: data.map(item => item.count),
          borderColor: '#EF4444',
          backgroundColor: 'rgba(239, 68, 68, 0.1)',
          borderWidth: 2,
          pointBackgroundColor: '#EF4444',
          pointRadius: 3,
          pointHoverRadius: 5,
          tension: 0.4,
          fill: true
        }
      ]
    };
  }, [errorCountData]);

  // Add a function to render no data state
  const renderNoDataOverlay = () => (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '8px',
      padding: '20px'
    }}>
      <svg width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3 3v18h18"></path>
        <path d="M7 12l4-4 4 4 6-6"></path>
      </svg>
      <p style={{ 
        margin: '10px 0 0 0',
        color: '#4B5563',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'center'
      }}>
        No data available for this time period
      </p>
    </div>
  );

  // Optimize rendering of graphs to only update when time filter changes
  const renderGraphs = useCallback(() => {
    return (
      <>
        <div className={styles.chartWrapper}>
          <h3 className={styles.chartTitle}>Trace Count</h3>
          <div className={styles.chartContainer} style={{ position: 'relative' }}>
            <Line 
              data={traceCountChartData} 
              options={chartOptions} 
              key={`trace-count-${graphTimeFilterKey}`}
              ref={traceCountChartRef}
            />
            {(!traceCountData.length || traceCountData.every(item => item.count === 0)) && renderNoDataOverlay()}
          </div>
        </div>
        <div className={styles.chartWrapper}>
          <h3 className={styles.chartTitle}>Average Duration (ms)</h3>
          <div className={styles.chartContainer} style={{ position: 'relative' }}>
            <Line
              data={traceDurationChartData} 
              options={chartOptions} 
              key={`trace-duration-${graphTimeFilterKey}`}
              ref={traceDurationChartRef}
            />
            {(!traceDurationData.length || traceDurationData.every(item => item.avgDuration === 0)) && renderNoDataOverlay()}
          </div>
        </div>
        <div className={styles.chartWrapper}>
          <h3 className={styles.chartTitle}>Error Count</h3>
          <div className={styles.chartContainer} style={{ position: 'relative' }}>
            <Line 
              data={errorCountChartData} 
              options={chartOptions} 
              key={`error-count-${graphTimeFilterKey}`}
              ref={errorCountChartRef}
            />
            {(!errorCountData.length || errorCountData.every(item => item.count === 0)) && renderNoDataOverlay()}
          </div>
        </div>
      </>
    );
  }, [traceCountChartData, traceDurationChartData, errorCountChartData, chartOptions, graphTimeFilterKey, traceCountData, traceDurationData, errorCountData]);

  const Row = ({ index, style, data }) => {
    const trace = data.traces[index];
    return (
      <div 
        style={{
        ...style,
        display: 'grid',
        gridTemplateColumns: data.columns,
        padding: '12px 16px',
        alignItems: 'center',
          borderBottom: '1px solid rgba(104, 37, 236, 0.1)',
        fontSize: '14px',
        backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9fc',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        }} 
        onClick={() => data.onTraceSelect(trace)}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = 'rgba(104, 37, 236, 0.05)';
          e.currentTarget.style.transform = 'translateY(-1px)';
          e.currentTarget.querySelector('.trace-id').style.textDecoration = 'underline';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#ffffff' : '#f9f9fc';
          e.currentTarget.style.transform = 'translateY(0)';
          e.currentTarget.querySelector('.trace-id').style.textDecoration = 'none';
        }}
      >
        <div className="trace-id" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{trace.trace_id}</div>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{trace.service_name}</div>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{trace.operation_name}</div>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{trace.duration_ms} ms</div>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{new Date(trace.start_time).toLocaleString()}</div>
      </div>
    );
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Update the useEffect that gets triggered when currentPage changes
  useEffect(() => {
    // Only fetch if the page isn't already loaded
    if (currentPage!==1&&!loadedPages[currentPage]) {
      setLoading(true);
      fetchTraces();
    } else {
      // If the page is already loaded, make sure we reset the loading state
      // This is important for when going back to a previously loaded page
      setLoading(false);
    }
  }, [currentPage, fetchTraces]);

  // Add this function to handle page number input jumps
  const handlePageInputChange = (e) => {
    const value = e.target.value;
    setPageInputValue(value);
  };

  const handlePageInputSubmit = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(pageInputValue, 10);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        // Always mark the target page as not loaded to force a refresh
        setLoadedPages(prev => {
          const newLoadedPages = { ...prev };
          newLoadedPages[pageNumber] = false;
          return newLoadedPages;
        });
        setCurrentPage(pageNumber);
      } else {
        // Reset input to current page if invalid
        setPageInputValue(currentPage.toString());
      }
    }
  };

  // Update the previous and next button handlers
  const handlePrevPage = () => {
    if (currentPage > 1 && !loading) {
      const prevPage = currentPage - 1;
      // Always mark the page as not loaded to force a refresh
      setLoadedPages(prev => {
        const newLoadedPages = { ...prev };
        newLoadedPages[prevPage] = false;
        return newLoadedPages;
      });
      setCurrentPage(prevPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages && !loading) {
      const nextPage = currentPage + 1;
      // Always mark the page as not loaded to force a refresh
      setLoadedPages(prev => {
        const newLoadedPages = { ...prev };
        newLoadedPages[nextPage] = false;
        return newLoadedPages;
      });
      setCurrentPage(nextPage);
    }
  };

  // Update pageInputValue when currentPage changes
  useEffect(() => {
    setPageInputValue(currentPage.toString());
  }, [currentPage]);

  // Create an integrated pagination control component that combines page input and navigation
  const IntegratedPaginationControls = () => {
    const [isInputFocused, setIsInputFocused] = useState(false);
    
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0',
        backgroundColor: '#F5F3FF',
        borderRadius: '10px',
        boxShadow: '0 2px 6px rgba(104, 37, 236, 0.15)',
        border: '1px solid rgba(104, 37, 236, 0.2)',
        transition: 'all 0.2s ease',
        overflow: 'hidden',
        maxWidth: '100%',
      }}>
        {/* Previous button */}
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1 || loading}
          style={{
            padding: '10px 14px',
            backgroundColor: currentPage === 1 || loading ? '#F3F4F6' : '#FFFFFF',
            color: currentPage === 1 || loading ? '#9CA3AF' : '#6825EC',
            border: 'none',
            borderRight: '1px solid rgba(104, 37, 236, 0.1)',
            cursor: currentPage === 1 || loading ? 'not-allowed' : 'pointer',
            fontSize: '14px',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.2s ease',
            minWidth: '42px',
            height: '100%',
            borderTopLeftRadius: '9px',
            borderBottomLeftRadius: '9px'
          }}
          aria-label="Previous page"
        >
          &laquo;
        </button>
        
        {/* Page input field */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          height: '40px',
          backgroundColor: '#FFFFFF',
          padding: '0 14px',
        }}>
          <input
            type="text"
            value={pageInputValue}
            onChange={handlePageInputChange}
            onKeyDown={handlePageInputSubmit}
            onFocus={() => {
              setPageInputFocused(true);
              setIsInputFocused(true);
            }}
            onBlur={() => {
              setPageInputFocused(false);
              setIsInputFocused(false);
            }}
            style={{
              width: '32px',
              padding: '0',
              border: 'none',
              fontSize: '16px',
              textAlign: 'center',
              outline: 'none',
              transition: 'all 0.2s ease',
              backgroundColor: 'transparent',
              fontWeight: '600',
              color: '#6825EC',
              marginBottom: '0px'
            }}
            aria-label="Page number"
          />
          <span style={{ 
            color: '#4B5563', 
            fontWeight: '500', 
            whiteSpace: 'nowrap' 
          }}>
            of <span style={{ fontWeight: '600', color: '#6825EC' }}>{totalPages}</span>
          </span>
          
          {/* Show a small tooltip hint */}
          {isInputFocused && (
            <div style={{
              position: 'absolute',
              bottom: '-24px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: '#1F2937',
              color: 'white',
              padding: '4px 8px',
              borderRadius: '4px',
              fontSize: '10px',
              whiteSpace: 'nowrap',
              zIndex: 10,
              pointerEvents: 'none',
              opacity: 0.9
            }}>
              Press Enter to go to page
            </div>
          )}
        </div>
        
        {/* Next button */}
        <button
          onClick={handleNextPage}
          disabled={currentPage >= totalPages || loading}
          style={{
            padding: '10px 14px',
            backgroundColor: currentPage >= totalPages || loading ? '#F3F4F6' : '#FFFFFF',
            color: currentPage >= totalPages || loading ? '#9CA3AF' : '#6825EC',
            border: 'none',
            borderLeft: '1px solid rgba(104, 37, 236, 0.1)',
            cursor: currentPage >= totalPages || loading ? 'not-allowed' : 'pointer',
            fontSize: '14px',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.2s ease',
            minWidth: '42px',
            height: '100%',
            borderTopRightRadius: '9px',
            borderBottomRightRadius: '9px'
          }}
          aria-label="Next page"
        >
          &raquo;
        </button>
      </div>
    );
  };

  // Add a visual indicator for active loading
  const TableLoadingIndicator = () => (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10,
      padding: '24px 32px',
      borderRadius: '12px',
      backgroundColor: 'rgba(255, 255, 255, 0.98)',
      boxShadow: '0 8px 20px rgba(104, 37, 236, 0.15)',
      border: '1px solid rgba(104, 37, 236, 0.1)',
      backdropFilter: 'blur(5px)'
    }}>
      <div style={{ 
        position: 'relative',
        width: '70px', 
        height: '70px',
        marginBottom: '16px',
      }}>
        {/* Outer ring */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '70px',
          height: '70px',
          border: '4px solid rgba(104, 37, 236, 0.1)',
          borderRadius: '50%',
          borderTop: '4px solid #6825EC',
          animation: 'spin 1.2s ease-in-out infinite'
        }} />
        
        {/* Middle ring */}
        <div style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          width: '50px',
          height: '50px',
          border: '3px solid rgba(104, 37, 236, 0.15)',
          borderRadius: '50%',
          borderRight: '3px solid #8F5CF9',
          animation: 'spin 1s linear infinite reverse'
        }} />
        
        {/* Center with page number */}
        <div style={{
          position: 'absolute',
          top: '20px',
          left: '20px',
          width: '30px',
          height: '30px',
          backgroundColor: '#6825EC',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '16px',
          boxShadow: '0 2px 10px rgba(104, 37, 236, 0.3)'
        }}>
          {currentPage}
        </div>
      </div>
      
      <p style={{ 
        color: '#6825EC', 
        fontWeight: '600', 
        margin: 0,
        fontSize: '18px'
      }}>
        Loading traces...
      </p>
      <p style={{ 
        color: '#4B5563', 
        fontWeight: '500', 
        margin: '8px 0 0 0',
        fontSize: '14px'
      }}>
        Please wait while we fetch page {currentPage}
      </p>
    </div>
  );

  // Add a timeout to prevent infinite loading

  // Add keyframe animation for graph slide down
  const styleSheet = document.createElement('style');
  styleSheet.textContent = `
    @keyframes slideDown {
      from {
        opacity: 0;
        transform: translateY(-20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;
  document.head.appendChild(styleSheet);

  const renderErrorsTable = (errors) => {
    return (
      <div className={styles.logsTableContainer}>
        <div className={styles.header}>
          <h2 className={styles.title}>Error Traces</h2>
          <p className={styles.subtitle}>Error logs for this trace</p>
        </div>
        <div className={styles.tableSection}>
          <table className={styles.logsTable}>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th style={{ width: '120px' }}>Type</th>
                <th>Message</th>
                <th style={{ width: '120px' }}>Service</th>
              </tr>
            </thead>
            <tbody>
              {errors && errors.length > 0 ? (
                errors.map((error, index) => (
                  <tr key={index} className={styles.logRow}>
                    <td className={styles.timestampCell}>
                      {new Date(error.timestamp).toLocaleString()}
                    </td>
                    <td>
                      <span 
                        className={styles.severityBadge}
                        style={{
                          backgroundColor: 'rgba(239, 68, 68, 0.2)',
                          color: '#EF4444'
                        }}
                      >
                        {error.type}
                      </span>
                    </td>
                    <td className={styles.messageCell}>
                      <div className={styles.messageContent}>
                        {error.message}
                        {error.stack_trace && (
                          <pre className={styles.stackTrace}>
                            {error.stack_trace}
                          </pre>
                        )}
                      </div>
                    </td>
                    <td>{error.service}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: 'center', padding: '24px', color: '#6B7280' }}>
                    No errors found for this trace
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.tracesContainer}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'flex-end', 
        width: '100%', 
        padding: '0 20px 10px',
        alignItems: 'center'
      }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            onClick={() => setShowGraphs(!showGraphs)}
            style={{
              padding: '8px 16px',
              border: '1px solid #E0E6EF',
              borderRadius: '8px',
              fontSize: '14px',
              fontWeight: '500',
              color: showGraphs ? '#6825EC' : '#1b2559',
              backgroundColor: '#FFFFFF',
              transition: 'all 0.2s ease',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
              cursor: 'pointer',
              gap: '6px'
            }}
          >
            <FaChartLine style={{ fontSize: '16px' }} />
            {showGraphs ? 'Hide Graphs' : 'Show Graphs'}
          </button>
        </div>
      </div>

      {/*Graphs in One Row */}
      {showGraphs && (
      <div className={styles.graphRow}>
        {renderGraphs()}
      </div>
      )}

      {/* Table in Full Width */}
      <div style={{ 
        borderRadius: '12px', 
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        width: '100%',
        boxShadow: '0 2px 8px rgba(104, 37, 236, 0.1)',
      }}>
        <div style={{
          padding: '16px 16px 0 16px',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          background: '#ffffff',
          justifyContent: 'space-between'
        }}>
          {/* Left side: Search Bar and Service Filter */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            marginBottom: '16px'
          }}>
            {/* Search Bar */}
            <div style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: '300px',
            }}>
              <BiSearch style={{
                position: 'absolute',
                left: '12px',
                fontSize: '18px',
                color: searchFocused ? '#6825EC' : '#8E9ABC',
                transition: 'color 0.2s ease'
              }} />
              <input
                type="text"
                placeholder="Search by Trace ID or Operation..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                style={{
                  width: '100%',
                  padding: '10px 12px 10px 40px',
                  border: `1px solid ${searchFocused ? '#6825EC' : '#E2E8F0'}`,
                  borderRadius: '8px',
                  fontSize: '14px',
                  outline: 'none',
                  transition: 'all 0.2s ease',
                  backgroundColor: '#ffffff',
                  color: '#2B3674',
                }}
              />
            </div>
            
            {/* Service Filter */}
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px'
            }}>
              <ServiceDropdown
                label="Service"
                options={serviceOptions}
                value={selectedServices}
                onChange={(value) => {
                  //console.log("Selected service:", value);
                  setSelectedServices(value);
                }}
              />
            </div>
          </div>

          {/* Right side: Enhanced Pagination Controls */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            marginBottom: '16px',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}>
            {/* Only show pagination controls if there's more than one page */}
            {totalPages > 1 ? (
              <IntegratedPaginationControls />
            ) : null}
            
            {/* Total traces count */}
            <div style={{ 
              fontWeight: '500', 
              color: '#4B5563',
              padding: '0',
              borderRadius: '10px',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              boxShadow: '0 2px 6px rgba(104, 37, 236, 0.15)',
              border: '1px solid rgba(104, 37, 236, 0.2)',
              transition: 'all 0.2s ease',
              height: '40px'
            }}>
              <span style={{ 
                color: 'white', 
                fontWeight: '600', 
                fontSize: '15px',
                backgroundColor: '#6825EC',
                padding: '0 14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                minWidth: '54px'
              }}>
                {totalRecords}
              </span>
              <span style={{
                padding: '0 14px',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                height: '100%'
              }}>
                total traces
              </span>
            </div>
          </div>
        </div>

        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
          background: '#6825EC',
          color: '#fff',
          fontWeight: '500',
          fontSize: '14px',
          padding: '14px 16px',
        }}>
          {[
            { key: 'trace_id', label: 'Trace ID' },
            { key: 'service_name', label: 'Service' },
            { key: 'operation_name', label: 'Operation' },
            { key: 'duration_ms', label: 'Duration' },
            { key: 'start_time', label: 'Timestamp' }
          ].map(({ key, label }) => (
            <div
              key={key}
              onClick={() => handleSort(key)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                userSelect: 'none',
                transition: 'opacity 0.2s ease',
              }}
            >
              {label} <SortIcon columnKey={key} currentSortColumn={sortColumn} currentSortDirection={sortDirection} />
            </div>
          ))}
        </div>

        <div style={{ 
          position: 'relative',
          flex: 1, 
          minHeight: 0 
        }}>
          {isLoading && <TableLoadingIndicator />}
          
          {sortedTraces.length > 0 ? (
            <List
              height={traceData.length > 0 ? Math.min(sortedTraces.length * 45 + 20, 400) : 400}
              itemCount={sortedTraces.length}
              itemSize={45}
              width="100%"
              itemData={{ 
                traces: sortedTraces, 
                columns: '1fr 1fr 1fr 1fr 1fr',
                onTraceSelect: handleSelectTrace 
              }}
              style={{ 
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#6825EC #F4F7FE',
                opacity: loading ? 0.5 : 1,
                transition: 'opacity 0.2s ease'
              }}
              className="custom-scrollbar"
            >
              {Row}
            </List>
          ) : (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
              color: '#6B7280',
              fontSize: '16px',
              fontWeight: '500',
              opacity: loading ? 0.5 : 1,
              transition: 'opacity 0.2s ease'
            }}>
              {!isLoading && (
                <>
                  <svg 
                    width="64" 
                    height="64" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="#9CA3AF" 
                    strokeWidth="1.5" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    style={{ marginBottom: '16px' }}
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                  <p style={{ fontSize: '18px', marginBottom: '8px', fontWeight: '600', color: '#4B5563' }}>No traces found</p>
                  <p style={{ textAlign: 'center', maxWidth: '300px', color: '#6B7280' }}>
                    Try adjusting your time range, service filter, or refresh to check for new traces.
                  </p>
                  <button
                    onClick={handleRefresh}
                    style={{
                      marginTop: '20px',
                      padding: '10px 20px',
                      backgroundColor: '#6825EC',
                      color: 'white',
                      border: 'none',
                      borderRadius: '8px',
                      fontSize: '14px',
                      fontWeight: '500',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      transition: 'all 0.2s ease',
                      boxShadow: '0 2px 8px rgba(104, 37, 236, 0.2)',
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = '#5A1ECD';
                      e.target.style.transform = 'translateY(-2px)';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = '#6825EC';
                      e.target.style.transform = 'translateY(0)';
                    }}
                  >
                    <FaSyncAlt />
                    Refresh Traces
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: #F4F7FE;
          border-radius: 3px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #6825EC;
          border-radius: 3px;
          opacity: 0.7;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          opacity: 1;
        }

        input::placeholder {
          color: #8E9ABC;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>

      {showModal && selectedTrace && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent} style={{
            width: '92%',
            maxWidth: '1200px',
            maxHeight: '90vh',
            borderRadius: '12px',
            backgroundColor: '#FCFCFF',
            boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            border: '1px solid rgba(104, 37, 236, 0.2)',
            position: 'relative',
            marginTop: '60px'
          }}>
            {/* Header with close button */}
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px 24px',
              borderBottom: '1px solid rgba(104, 37, 236, 0.1)',
              backgroundColor: '#F9F7FF'
            }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <h2 style={{ 
                  margin: 0, 
                  color: '#6825EC', 
                  fontSize: '22px', 
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
                  <svg 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="#6825EC" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  >
                    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                  </svg>
                  Trace Details
                </h2>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 12px',
                  borderRadius: '8px',
                  backgroundColor: 'rgba(104, 37, 236, 0.1)',
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#6825EC'
                }}>
                  {selectedTrace.duration_ms}ms
                </div>
              </div>
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '36px',
                  height: '36px',
                  borderRadius: '50%',
                  border: 'none',
                  backgroundColor: 'rgba(239, 68, 68, 0.1)',
                  color: '#EF4444',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  fontSize: '20px',
                  fontWeight: '300',
                }}
                onClick={closeModal}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = 'rgba(239, 68, 68, 0.2)';
                  e.target.style.transform = 'scale(1.05)';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'rgba(239, 68, 68, 0.1)';
                  e.target.style.transform = 'scale(1)';
                }}
                aria-label="Close"
              >
                ×
              </button>
            </div>

            {/* Trace info section */}
            <div style={{
              display: 'flex',
              padding: '16px 24px',
              backgroundColor: '#F5F3FF',
              borderBottom: '1px solid rgba(104, 37, 236, 0.1)',
              flexWrap: 'wrap',
              gap: '20px'
            }}>
              <div style={{ flex: '1 1 auto', minWidth: '250px' }}>
                <div style={{ fontSize: '13px', color: '#6B7280', marginBottom: '4px' }}>Trace ID</div>
                <div style={{ 
                  fontSize: '14px', 
                  fontWeight: '500', 
                  color: '#1F2937',
                  padding: '8px 12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '6px',
                  border: '1px solid rgba(104, 37, 236, 0.15)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                    {selectedTrace.trace_id}
                  </span>
                  <button
                    onClick={() => navigator.clipboard.writeText(selectedTrace.trace_id)}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      color: '#6825EC',
                      padding: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '4px',
                      transition: 'all 0.2s'
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(104, 37, 236, 0.1)'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                    title="Copy to clipboard"
                  >
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                  </button>
                </div>
              </div>
              
              <div style={{ flex: '1 1 auto', minWidth: '250px' }}>
                <div style={{ fontSize: '13px', color: '#6B7280', marginBottom: '4px' }}>Service</div>
                <div style={{ 
                  fontSize: '14px', 
                  fontWeight: '600', 
                  color: '#6825EC',
                  padding: '8px 12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '6px',
                  border: '1px solid rgba(104, 37, 236, 0.15)'
                }}>
                  {selectedTrace.service_name}
                </div>
              </div>
              
              <div style={{ flex: '1 1 auto', minWidth: '200px' }}>
                <div style={{ fontSize: '13px', color: '#6B7280', marginBottom: '4px' }}>Timestamp</div>
                <div style={{ 
                  fontSize: '14px', 
                  fontWeight: '500', 
                  color: '#1F2937',
                  padding: '8px 12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '6px',
                  border: '1px solid rgba(104, 37, 236, 0.15)'
                }}>
                  {new Date(selectedTrace.start_time).toLocaleString()}
                </div>
              </div>
              
              <div style={{ flex: '1 1 auto', minWidth: '200px' }}>
                <div style={{ fontSize: '13px', color: '#6B7280', marginBottom: '4px' }}>Operation</div>
                <div style={{ 
                  fontSize: '14px', 
                  fontWeight: '500', 
                  color: '#1F2937',
                  padding: '8px 12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '6px',
                  border: '1px solid rgba(104, 37, 236, 0.15)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {selectedTrace.operation_name}
                </div>
              </div>
            </div>

            {/* Tabs Navigation */}
            <div style={{
              display: 'flex',
              backgroundColor: 'white',
              borderBottom: '1px solid rgba(104, 37, 236, 0.1)',
              padding: '0 24px',
              gap: '8px',
              overflowX: 'auto',
              scrollbarWidth: 'none'
            }} className="custom-scrollbar">
              {[
                { id: "serviceMap", label: "Service Map", icon: (
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="3" y="3" width="7" height="7"></rect>
                    <rect x="14" y="3" width="7" height="7"></rect>
                    <rect x="14" y="14" width="7" height="7"></rect>
                    <rect x="3" y="14" width="7" height="7"></rect>
                  </svg>
                ) },
                { id: "logs", label: "Logs", icon: (
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                    <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2z"></path>
                    <line x1="9" y1="9" x2="10" y2="9"></line>
                    <line x1="9" y1="13" x2="15" y2="13"></line>
                    <line x1="9" y1="17" x2="15" y2="17"></line>
                  </svg>
                ) },
                { id: "errors", label: "Errors", icon: (
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                ) }
              ].map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '12px 16px',
                    fontSize: '14px',
                    fontWeight: activeTab === tab.id ? '600' : '500',
                    color: activeTab === tab.id ? '#6825EC' : '#4B5563',
                    border: 'none',
                    borderBottom: `2px solid ${activeTab === tab.id ? '#6825EC' : 'transparent'}`,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    minWidth: 'max-content'
                  }}
                  onMouseEnter={(e) => {
                    if (activeTab !== tab.id) {
                      e.target.style.color = '#6825EC';
                      e.target.style.backgroundColor = 'rgba(104, 37, 236, 0.05)';
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (activeTab !== tab.id) {
                      e.target.style.color = '#4B5563';
                      e.target.style.backgroundColor = 'transparent';
                    }
                  }}
                >
                  {tab.icon}
                  {tab.label}
                </button>
              ))}
            </div>

            {/* Tab Content */}
            <div style={{
              flex: '1',
              overflow: 'auto',
              padding: '24px',
              backgroundColor: 'white'
            }} className="custom-scrollbar">
              {activeTab === "serviceMap" && (
                <div style={{ height: '100%', overflow: 'auto' }}>
                  {renderServiceMap(selectedTrace.spans)}
                </div>
              )}
              {activeTab === "logs" && (
                <div>
                  {renderLogsTable(logs)}
                </div>
              )}
              {activeTab === "errors" && (
                <div style={{ height: '100%', padding: '20px' }}>
                  {renderErrorsTable(selectedTrace.errors || [])}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Traces;