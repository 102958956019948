import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Dropdownnew.css';
import './Scores.css';
import './Graph.css';
import './Health.css';
import './Sidechart.css';
import PieChart from './features/PieChart';
import Graph from './features/Graph';
import MultiGraph from './features/MultiGraph';
import { formatDateTime } from './features/utils';
import UpTime from './features/UpTime';
import { Pie } from 'react-chartjs-2';
import ProcessTable from './features/ProcessTable';
import LazyLoad from './features/LazyLoad';
import GraphOptionsDropdown from './features/GraphOptionsDropdown';
import HealthScore from './HealthScore';

const options = [
    // { value: 'Basic Alert', label: 'Basic Alert' },
    { value: 'Collective Insight', label: 'Collective Insight' },
    { value: 'Deviations', label: 'Deviations' },
    {value:  'Confidence Range', label: 'Confidence Range'}
];

const Anomaly = ({ selectedDataSource, selectedServer, startTime, endTime, navigate, onZoom }) => {
    //console.log("999999999999999999999999999999",selectedDataSource,selectedServer,startTime,endTime);
    const [selectedOptions, setSelectedOptions] = useState(
        Array(8).fill(options[0])
    );
    const MetricList = [
        { value: 'cluster.state', label: selectedServer },
        { value: 'cpu_usage', label: 'CPU Usage' },
        { value: 'memory_usage', label: 'Memory Usage' },
        { value: 'disk_usage', label: 'Disk Usage' },
        { value: 'network_bytes_recv', label: 'Network Bytes Recv' }

    ];
    const [selectedMetric, setSelectedMetric] = useState(
        Array(8).fill(MetricList[0])
    );
    const [anomalyCount, setAnomalyCount] = useState({ basic: 0, strict: 0, deviations: 0 });
    const [healthScore, setHealthScore] = useState(0);
    const [clusterScore, setClusterScore] = useState(0);
    const [rootCouseMetric, setRootCouseMetric] = useState("No Data found")
    const [metricData, setMetricData] = useState([]);
    const [anomalyMetric, setAnomalyMetric] = useState({ metricName: 'cpu_usage', label: 'CPU Usage' });
    const [refreshKey, setRefreshKey] = useState(0);
    const [pressedButton, setPressedButton] = useState(null);
    const [processeList, setProcesseList] = useState([]);
    
    const [uptimeStatus, setUptimeStatus] = useState(null);
    const [uptimeRes, setUptimeRes] = useState(false);
    const [startDateTime, setStartDateTime] = useState(null);
    


    const fetchClusterData = useCallback(async (selectedDataSource) => {
        const url = `https://infia.enviseer.com/api/healthscores?cluster=${selectedDataSource}`;
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data)) {
            const timestamps = data.map(entry => entry.timestamp);
            const values = data.map(entry => entry.value);
            return { timestamps, values };
        } else {
            return { timestamps: [], values: [] };
        }
    }, []);

    const fetchRootCouseMetric = useCallback(async (selectedDataSource, selectedServer) => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        const url = `https://infia.enviseer.com/api/topanomalousmetric?cluster=${selectedDataSource}&host=${selectedServer}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json(); // Expecting a JSON object
        return data.metric;
    }, []);

    const fetchProcessData = useCallback(async (selectedDataSource, selectedServer) => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        const url = `https://infia.enviseer.com/api/clusters/${selectedDataSource}/process_monitoring?hostname=${selectedServer}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json(); // Expecting a JSON object
        return data;
    }, []);


    useEffect(() => {
        //console.log("Fetching cluster data...");

        const fetchData = async () => {
            try {
                // Fetch cluster data
                let clusterScore = "0.00"; // Default score
                try {
                    const data = await fetchClusterData(selectedDataSource);
                    if (data && data.values && data.values.length > 0) {
                        clusterScore = data.values[data.values.length - 1].toFixed(2);
                    }
                } catch (error) {
                    console.warn("Error fetching cluster data:", error);
                }
                setClusterScore(clusterScore);

                // Fetch root cause metric
                let rootMetric = "No data found"; // Default root metric
                try {
                    rootMetric = await fetchRootCouseMetric(selectedDataSource, selectedServer);
                } catch (error) {
                    console.warn("Error fetching root cause metric:", error);
                }

                const metricName = rootMetric;
                const label = rootMetric;
                // setAnomalyMetric({ metricName, label });

                const capitalizedRootMetric = rootMetric.charAt(0).toUpperCase() + rootMetric.slice(1);
                setRootCouseMetric(capitalizedRootMetric);

                // Fetch process data
                let processList = [];
                try {
                    processList = await fetchProcessData(selectedDataSource, selectedServer);
                } catch (error) {
                    console.warn("Error fetching process data:", error);
                }
                setProcesseList(processList);

            } catch (error) {
                console.error(`Error in fetchData:`, error);
            }
        };

        fetchData();
    }, [selectedDataSource, selectedServer]);


    const handleResize = () => {
        //console.log("Window resized!"); // Check if the event is triggering
        setRefreshKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        //console.log("Adding resize event listener"); // To confirm the effect is running
        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => {
            //console.log("Removing resize event listener"); // For confirmation
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDropdownChange = (index, selectedOption) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = selectedOption;
        setSelectedOptions(newSelectedOptions);
    };
    const handleHealthDropdownChange = (index, Metric) => {
        const newSelectedMetric = [...selectedMetric];
        newSelectedMetric[index] = Metric;
        setSelectedMetric(newSelectedMetric);
    };
    const handleClick = (option) => {
        const index = 0;
        handleDropdownChange(index, { label: option, value: option.toLowerCase().replace(' ', '-') });
        setPressedButton(option);
    };
    const updateAnomalyCount = (count) => {
        setAnomalyCount(count);
    };
    const updateScore = (Score) => {
        setHealthScore(Score);
    };
    const updateAnomalyMetric = (Metric) => {
        setAnomalyMetric(Metric);
        handleHealthDropdownChange(0, { value: Metric.metricName, label: Metric.label });
        // setRefreshKey(prevKey => prevKey + 1);
    };


    function getHealthScoreMessage(score) {
        if (score > 8) {
            return "Today's score for your server is excellent! Everything is running smoothly!";
        } else if (score >= 6) {
            return "Today's score for your server is normal. It's working fine, but some areas could be optimized.";
        } else {
            return "Today's score for your server is poor. Immediate attention is needed to improve performance!";
        }
    }

    function getClusterScoreMessage(score) {
        if (score > 8) {
            return "Today's score for your Application is excellent! Everything is running smoothly!";
        } else if (score >= 6) {
            return "Today's score for your Application is normal. It's working fine, but some areas could be optimized.";
        } else {
            return "Today's score for your Application is poor. Immediate attention is needed to improve performance!";
        }
    }


    function formatMetricName(metric) {
        return metric
            .replace(/_/g, ' ')  // Replace underscores with spaces
            .split(' ')           // Split the string by spaces
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
            .join(' ');           // Join the words back with spaces
    }


    const fetchUptimeData = useCallback(async () => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        setUptimeStatus("");
        const token = getCookie('token');
        const now = new Date(); // ✅ Use the current time
        const url = `https://infia.enviseer.com/api/system_uptime`;

        const payload = {
            cluster: selectedDataSource,
            hostname: selectedServer,
        };
    

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                setUptimeRes(false);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            if (data) {
                setUptimeRes(true);
                // ✅ Extract values from API response
    
                if (data.uptime_seconds === 0) {
                    setStartDateTime(null);  // ✅ Don't send startDateTime
                    setUptimeStatus("System Offline");  // ✅ Show "System Offline"
                    return;
                }
    
                // ✅ Update state with calculated start time
                const formatLocalTime = (utcDate) => {
                    const date = new Date(utcDate);
                    return date.toLocaleString(); // This will format to the local timezone
                  };
                  
                  // Example usage
                  const formattedDate = formatLocalTime(data.up_since);
                setStartDateTime(formattedDate);
                setUptimeStatus(null); // ✅ Remove "System Offline" message
    
                //console.log("✅ Start DateTime Calculated:", calculatedStartDateTime);
            }
        } catch (error) {
            setUptimeRes(false);
            setStartDateTime(null);
            setUptimeStatus(""); // ✅ If error, show "System Offline"
            console.error('Error fetching uptime data:', error);
        }
    }, [selectedDataSource, selectedServer]);

    useEffect(() => {
        if (!selectedDataSource || !selectedServer) return;
    
        fetchUptimeData();
        const fetchInterval = setInterval(fetchUptimeData, 60000);
    
        return () => clearInterval(fetchInterval);
    }, [fetchUptimeData]);


    return (
        <>
            <div className="metrics-grid" style={{ 
                gridTemplateColumns: rootCouseMetric==="NaN"? 'repeat(4, 1fr)': 'repeat(5, 1fr)',
                gap: '16px',
                padding: '8px'
            }}>
                <div className="metric-item" style={{
                    background: 'linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%)',
                    borderRadius: '16px',
                    padding: '20px',
                    boxShadow: '0 4px 20px rgba(104, 37, 236, 0.06)',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                    <div className="metric-header">
                        <h2 style={{
                            fontSize: '1.1rem',
                            fontWeight: '600',
                            color: '#1b2559',
                            marginBottom: '15px'
                        }}>Anomalies</h2>
                    </div>

                    <div className="metric-content" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <div className={`metric-sub-content ${pressedButton === 'Collective Insight' ? 'pressed' : ''}`}
                            onClick={() => handleClick('Collective Insight')}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 12px',
                                borderRadius: '10px',
                                background: pressedButton === 'Collective Insight' ? 'rgba(207, 16, 32, 0.05)' : 'transparent',
                                cursor: 'pointer',
                                transition: 'all 0.2s'
                            }}>
                            <p className="anomaly-button" style={{ 
                                fontSize: '0.9rem',
                                color: '#2B3674',
                                fontWeight: '500'
                            }}> Collective Insight : </p>
                            <p className="score" style={{ 
                                color: '#CF1020',
                                fontWeight: '600',
                                fontSize: '1.1rem'
                            }}>{anomalyCount.strict}</p>
                        </div>

                        <div className={`metric-sub-content ${pressedButton === 'Deviations' ? 'pressed' : ''}`}
                            onClick={() => handleClick('Deviations')}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 12px',
                                borderRadius: '10px',
                                background: pressedButton === 'Deviations' ? 'rgba(60, 179, 113, 0.05)' : 'transparent',
                                cursor: 'pointer',
                                transition: 'all 0.2s'
                            }}>
                            <p className="anomaly-button" style={{ 
                                fontSize: '0.9rem',
                                color: '#2B3674',
                                fontWeight: '500'
                            }}> Deviations : </p>
                            <p className="score" style={{ 
                                color: '#e0d32f',
                                fontWeight: '600',
                                fontSize: '1.1rem'
                            }}>{anomalyCount.deviations}</p>
                        </div>
                    </div>
                </div>

                <div className="metric-item" style={{
                    background: 'linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%)',
                    borderRadius: '16px',
                    padding: '20px',
                    boxShadow: '0 4px 20px rgba(104, 37, 236, 0.06)',
                    position: 'relative'
                }}>
                    <button className="score-info-button" style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        background: 'transparent',
                        border: 'none',
                        color: 'rgba(104, 37, 236, 0.6)',
                        cursor: 'pointer',
                        fontSize: '1.1rem'
                    }} data-tooltip={getHealthScoreMessage(healthScore)}>
                        <i className="bi bi-info-circle-fill"></i>
                    </button>
                    <div className="metric-header">
                        <h2 style={{
                            fontSize: '1.1rem',
                            fontWeight: '600',
                            color: '#1b2559',
                            marginBottom: '15px'
                        }}>EnviVital</h2>
                    </div>
                    <div className="metric-content">
                        <div className="health-score-container">
                            <HealthScore score={healthScore} />
                        </div>
                    </div>
                </div>

                <div className="metric-item" style={{
                    background: 'linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%)',
                    borderRadius: '16px',
                    padding: '20px',
                    boxShadow: '0 4px 20px rgba(104, 37, 236, 0.06)',
                    position: 'relative'
                }}>
                    <button className="score-info-button" style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        background: 'transparent',
                        border: 'none',
                        color: 'rgba(104, 37, 236, 0.6)',
                        cursor: 'pointer',
                        fontSize: '1.1rem'
                    }} data-tooltip={getClusterScoreMessage(clusterScore)}>
                        <i className="bi bi-info-circle-fill"></i>
                    </button>
                    <div className="metric-header">
                        <h2 style={{
                            fontSize: '1.1rem',
                            fontWeight: '600',
                            color: '#1b2559',
                            marginBottom: '15px'
                        }}>EnviScore</h2>
                    </div>
                    <div className="metric-content">
                        <div className="health-score-container">
                            <HealthScore score={clusterScore} />
                        </div>
                    </div>
                </div>

                {rootCouseMetric!=="NaN" && (
                    <div className="metric-item" style={{
                        background: 'linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%)',
                        borderRadius: '16px',
                        padding: '20px',
                        boxShadow: '0 4px 20px rgba(104, 37, 236, 0.06)',
                        position: 'relative'
                    }}>
                        <div className="metric-header">
                            <h2 style={{
                                fontSize: '1.1rem',
                                fontWeight: '600',
                                color: '#1b2559',
                                marginBottom: '15px'
                            }}>Root Cause</h2>
                        </div>
                        <div className="metric-content">
                            <p className="score" style={{ 
                                color: '#1b2559',
                                fontSize: '1rem',
                                fontWeight: '500',
                                textAlign: 'center'
                            }}>{formatMetricName(rootCouseMetric)}</p>
                        </div>
                    </div>
                )}

                <div className="metric-item" style={{
                    background: 'linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%)',
                    borderRadius: '16px',
                    padding: '20px',
                    boxShadow: '0 4px 20px rgba(104, 37, 236, 0.06)',
                    position: 'relative'
                }}>
                    <UpTime startDateTime={startDateTime} uptimeRes={uptimeRes} uptimeStatus={uptimeStatus}/>
                </div>
            </div>

            <div className="anomaly-row" style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px', marginBottom: '15px', gap: '18px' }}>
            <PieChart
                    key={refreshKey}
                    dataSource={selectedDataSource}
                    server={selectedServer}
                    startDate={startTime}
                    endDate={endTime}
                    updateAnomalyMetric={updateAnomalyMetric}
                    selectedOption={selectedOptions[0]}
                />
                <div className="sidechart" >
                    <GraphOptionsDropdown
                        key={refreshKey}
                        selectedOption={selectedOptions[0]}
                        onChange={(option) => handleDropdownChange(0, option)}
                        options={options}
                    />
                 <MultiGraph
                        key={refreshKey}
                        dataSource={selectedDataSource}
                        server={selectedServer}
                        startDate={startTime}
                        endDate={endTime}
                        metricName={anomalyMetric.metricName}
                        metricName2={anomalyMetric.metricName}
                        label1={anomalyMetric.label}
                        label2="Anomaly"
                        borderColor1="rgba(122, 38, 230, 1)"
                        backgroundColor1="rgba(122, 38, 230, 0.3)"
                        selectedOptions={selectedOptions[0].label}
                        updateAnomalyCount={updateAnomalyCount}
                        handleAnomalyClick={navigate}
                        onZoom={onZoom}
                    />

                </div>
            </div>

            {/* Graph Section */}
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px', marginBottom: '15px', gap: '18px' }}>

                <div className='health-total' style={{}}>
                    <p>EnviVital</p>
                    {/* <GraphOptionsDropdown
            key={refreshKey}
            selectedOption={selectedMetric[0]}
            onChange={(option) => handleHealthDropdownChange(0, option)}
            options={MetricList}
        /> */}
        <div className="health">
            <Graph
                dataSource={selectedDataSource}
                server={selectedServer}
                startDate={startTime}
                endDate={endTime}
                metricName={selectedMetric[0].value}
                label="EnviVital"
                borderColor="rgba(165, 84, 221, 1)"
                backgroundColor="rgba(165, 84, 221, 0.3)"
                updateAvgScore={updateScore}
            />
        </div>
    </div>

                {/* Table Section */}
                
                {/* <div className="graph-and-table-container">
                <LazyLoad height="350px">
                {processeList?.length > 0 ? <ProcessTable processes={processeList} /> : <div>No processes data found.</div>}
               </LazyLoad></div> */}
            </div>

        </>
    );
}

export default Anomaly;
