import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { formatDateTime, convertToIST, } from './utils'; // Assuming you have utility functions in a utils.js file

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            const label = chart.data.labels[activePoint.index];
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'lightgrey';
            ctx.stroke();
            ctx.restore();

            const labelX = x;
            const labelY = bottomY + 20;
            ctx.fillStyle = 'white';
            ctx.fillRect(labelX - 20, labelY - 10, 40, 20); // Adjust width and height accordingly
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(label.substring(0, 5), x, bottomY + 20);
            ctx.fillText(label.substring(6, label.length), x, bottomY + 35);
            ctx.restore();


        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps1, data1, label1, borderColor1, backgroundColor1, timestamps2, data2, label2, pointColor, anomaly) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
    gradient1.addColorStop(0, borderColor1);
    gradient1.addColorStop(0.6, backgroundColor1);
    gradient1.addColorStop(0.9, 'rgba(0,210,255,0)'); // Transparent end


    return {
        labels: timestamps1.map(convertToIST),
        datasets: [
            {
                label: label1,
                data: data1,
                fill: true,
                borderColor: borderColor1,
                backgroundColor: gradient1,
                tension: 0.3,
                pointBorderWidth: 1,
                pointRadius: 0,
                borderWidth: 2,
                pointBackgroundColor: borderColor1,
                pointStyle: 'circle',
                pointHoverRadius: 4,
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                order: 1
            },
            {
                label: label2,
                data: data2,
                fill: false,
                tension: 0.2,
                pointBorderWidth: 0.3,
                pointRadius: anomaly.map(value => value > 0 ? 4 : 0),
                pointBackgroundColor: anomaly.map(value => value > 0 ? pointColor : borderColor1),
                pointBorderColor: anomaly.map(value => value > 0 ? 'black' : 'transparent'),
                pointStyle: 'circle',
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                backgroundColor: pointColor,
                borderColor: 'transparent',
                pointHoverRadius: anomaly.map(value => value > 0 ? 6 : 0),
                order: 0,

            }
        ],
    };
};

const chartOptions = (metricName, handleAnomalyClick, dataSource, server, anomaly) => {
    return{
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                autoSkip: true,
                maxRotation: 45,
                minRotation: 0,
                maxTicksLimit: 7,
                callback: function(value) {
                    let label = this.getLabelForValue(value);
                    if (label.length > 5) {
                        label = label.substring(5);
                    }
                    return label;
                }
            },
            title: {
                display: true,
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function(context) {
                    let label = context.dataset.label || '';
                    let value = context.raw;

                        // Conditional formatting based on dataset index
                        if (context.datasetIndex === 0) {
                            // Format for metricData1
                            return label + ': ' + value + '%';
                        } else {
                            // Default formatting if there are more datasets
                            return label;
                        }
                    },
                },
            },
            verticalLinePlugin: true,
        },
        hover: {
            mode: 'nearest',
            intersect: true,
            onHover: function (event, chartElement) {
                const element = chartElement[0];
                if (element && element.datasetIndex === 1) { // Assuming anomaly data is in the second dataset (index 1)
                    const anomalyValue = anomaly[element.index];
                    if (anomalyValue > 0) {
                        event.native.target.style.cursor = 'pointer';
                    } else {
                        event.native.target.style.cursor = 'default';
                    }
                } else {
                    event.native.target.style.cursor = 'default';
                }
            },
        },
        onClick: function (event, chartElement) {
            const element = chartElement[0];
            if (element && element.datasetIndex === 1) {
                const anomalyValue = anomaly[element.index];
                const timestamp = this.data.labels[element.index]; // Get the timestamp

                if (anomalyValue > 0) {
                    localStorage.setItem('DataSource', dataSource);
                    localStorage.setItem('Server', server);
                    localStorage.setItem('Timestamp', timestamp);
                    handleAnomalyClick('logs');
                }
            }
        },
    };
};



const fetchMetricData = async (metricName, dataSource, server, start, end) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    const url = `https://infia.enviseer.com/api/metrics?&cluster=${dataSource}&hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}`;
    console.log(url,metricName);
    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {

        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => {
            // Check if entry.value is valid and a number
            const num = parseFloat(entry.value);
            return isNaN(num) ? '0.00' : num.toFixed(2);
        });
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};


const fetchAnomalyData = async (dataSource, server, start, end, metricName) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    const url = `https://infia.enviseer.com/api/clusters/${dataSource}/metrics?hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}&mode=moderate`;

    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
          const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.is_anomaly);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }

};

const fetchStrictAnomalyData = async (dataSource, server, start, end, metricName) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
	const url =`https://infia.enviseer.com/api/clusters/${dataSource}/metrics?hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}&mode=strict`;
          const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.is_anomaly);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }

};

    const fetchAnomalyCount = async (dataSource, server, start, end, mode) => {
        const formattedStartTime = formatDateTime(start);                                                                
        const formattedEndTime = formatDateTime(end);
        try {
            function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
            const response = await fetch('https://infia.enviseer.com/api/anomaliescount?' + new URLSearchParams({
                cluster: dataSource,
                hostname: server,
                start: formattedStartTime,
                end: formattedEndTime,
                mode: mode,
            }),
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json',
                }
            });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Transform the data to the required format
        const transformedData = Object.entries(data).map(([key, value]) => ({
            label: key,
            count: value
        }));

        return transformedData;
    } catch (error) {
        console.error('Error fetching anomaly data:', error);
        return [];
    }
};

const MultiGraph = ({ dataSource, server, startDate, endDate, metricName, metricName2, label1, label2, borderColor1, backgroundColor1, selectedOptions, updateAnomalyCount, handleAnomalyClick }) => {
    const [timestamps1, setTimestamps1] = useState([]);
    const [metricData1, setMetricData1] = useState([]);
    const [timestamps2, setTimestamps2] = useState([]);
    const [metricData2, setMetricData2] = useState([]);
    const [anomaly, setAnomaly] = useState([]);
    const [pointColor, setPointColor] = useState('');

    const chartRef = useRef(null);

    useEffect(() => {
        console.log('Metric Name Updated:', metricName);
        // Fetch and update chart data
    }, [metricName]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data1 = await fetchMetricData(metricName, dataSource, server, startDate, endDate);
                let data2;
                let color;
                if (selectedOptions === "Basic Alert") {
                    data2 = await fetchAnomalyData(dataSource, server, startDate, endDate, metricName);
                    color = '#F0E130';
                } else {
                    data2 = await fetchStrictAnomalyData(dataSource, server, startDate, endDate, metricName);
                    color = '#CF1020';
                }
                setPointColor(color);
                setTimestamps1(data1.timestamps);
                setMetricData1(data1.values);

                const AnomalyData2 = data1.timestamps.map((timestamp, index) => {
                    const value1 = data1.values[index];
                
                    // Handle the first timestamp (no previous timestamp to compare)
                    if (index === 0) {
                        const foundAnomaly = data2.timestamps.includes(timestamp) && data2.values[data2.timestamps.indexOf(timestamp)] > 0;
                        return foundAnomaly ? value1 : 0;
                    }
                
                    // Get the previous timestamp
                    const previousTimestamp = new Date(data1.timestamps[index - 1]);
                    const currentTimestamp = new Date(timestamp);
                
                    // Check if any timestamp from `data2` falls between previous and current timestamp
                    const foundAnomaly = data2.timestamps.some((data2Timestamp, data2Index) => {
                        const data2Time = new Date(data2Timestamp);
                
                        // Check if data2Time is strictly between the previous and current timestamps
                        return data2Time > previousTimestamp && data2Time <= currentTimestamp && data2.values[data2Index] > 0;
                    });
                
                    // If there's an anomaly, keep the value1; otherwise, return 0
                    return foundAnomaly ? value1 : 0;
                });
                const AnomalyData3 = data1.timestamps.map((timestamp, index) => {
                    const value1 = data1.values[index];
                
                    // Handle the first timestamp (no previous timestamp to compare)
                    if (index === 0) {
                        const foundAnomaly = data2.timestamps.includes(timestamp) && data2.values[data2.timestamps.indexOf(timestamp)] > 0;
                        return foundAnomaly ? value1 : 0;
                    }
                
                    // Get the previous timestamp
                    const previousTimestamp = new Date(data1.timestamps[index - 1]);
                    const currentTimestamp = new Date(timestamp);
                
                    // Check if any timestamp from `data2` falls between previous and current timestamp
                    const foundAnomaly = data2.timestamps.some((data2Timestamp, data2Index) => {
                        const data2Time = new Date(data2Timestamp);
                
                        // Check if data2Time is strictly between the previous and current timestamps
                        return data2Time > previousTimestamp && data2Time <= currentTimestamp && data2.values[data2Index] > 0;
                    });
                
                    // If there's an anomaly, keep the value1; otherwise, return 0
                    return foundAnomaly ? value1+1 : 0;
                });
                setTimestamps2(timestamps1);
                setMetricData2(AnomalyData2);
                setAnomaly(AnomalyData3);

                const basicData = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'moderate');
                const strictData = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'strict');

                const basicAnomalyCount = Array.isArray(basicData)
                    ? basicData.reduce((sum, item) => sum + (item.count || 0), 0) : 0;

                const strictAnomalyCount = Array.isArray(strictData)
                    ? strictData.reduce((sum, item) => sum + (item.count || 0), 0) : 0;


                updateAnomalyCount({ basic: basicAnomalyCount, strict: strictAnomalyCount });
            } catch (error) {
                console.error(`Error fetching data:`, error);
                setTimestamps1([]);
                setMetricData1([]);
                setTimestamps2([]);
                setMetricData2([]);
            }
        };

        fetchData();
    }, [dataSource, server, startDate, endDate,selectedOptions, metricName]);

    const chartData = generateChartData(
        timestamps1, metricData1, label1, borderColor1, backgroundColor1,
        timestamps2, metricData2, label2, pointColor, anomaly
    );

    return <Line data={chartData} options={chartOptions(metricName, handleAnomalyClick, dataSource, server, anomaly)} ref={chartRef} />;
};

export default MultiGraph;

