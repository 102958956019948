import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const HealthScore = ({ score }) => {
    const getColor = (value) => {
        if (value >= 8) {
            return 'green';
        } else if (value >= 6) {
            return '#cc7000'; // Orange color
        } else {
            return '#CF1020'; // Red color
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <div style={{ width: '120px', height: '120px', fontWeight: 'bold', position: 'relative' }}>
                {/* Gray Outer Border */}
                <CircularProgressbar
                    value={100} // Full arc for the border
                    styles={buildStyles({
                        rotation: 0.75, // Start position for semi-circle
                        strokeLinecap: 'round',
                        pathColor: '#d6d6d6', // Gray border color
                        trailColor: '#fff', // Transparent trail
                        pathWidth: 12,
                        trailWidth: 12,
                    })}
                    circleRatio={0.5} // Render half-circle
                />

                {/* Actual Progress Arc */}
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                    <CircularProgressbar
                        value={score * 10} // Map score (0-10) to (0-100)
                        text={score} // Display percentage
                        styles={buildStyles({
                            rotation: 0.75, // Semi-circle positioning
                            strokeLinecap: 'round', // Rounded ends
                            textSize: '16px', // Text size
                            fontWeight: 'bold', // Make text bold
                            pathColor: '#5d0cd2', // Progress bar color
                            textColor: getColor(score),
                            trailColor: 'transparent',
                            pathWidth: 12, // Progress bar thickness
                        })}
                        circleRatio={0.5} // Semi-circle
                    />
                </div>

                {/* Red Dot for Progress End */}
                <div
                    style={{
                        position: 'absolute',
                        width: '8px',
                        height: '8px',
                        backgroundColor: '#fff', // Red dot color
                        borderRadius: '50%', // Make it circular
                        top: '50%',
                        left: '50%',
                        transform: `translate(-50%, -50%) rotate(${
                            180 + (score * 180) / 10
                        }deg) translate(55px)`, // 135° start angle + progress angle
                        zIndex: 10, // On top of progress arc
                    }}
                ></div>
            </div>
        </div>
    );
};

export default HealthScore;

