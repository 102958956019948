import React, { useState, useEffect } from 'react';
import '../Scores.css';

const UpTime = ({ startDateTime, uptimeRes, uptimeStatus }) => {
    const [uptime, setUptime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [liveTime, setLiveTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        let interval;
        if (startDateTime && uptimeRes) {
            const updateTimes = () => {
                const now = new Date();
                const start = new Date(startDateTime);
                const diff = now - start;

                // Calculate total values
                const totalSeconds = Math.floor(diff / 1000);
                const totalMinutes = Math.floor(totalSeconds / 60);
                const totalHours = Math.floor(totalMinutes / 60);
                const totalDays = Math.floor(totalHours / 24);

                // Calculate remaining values
                const hours = totalHours % 24;
                const minutes = totalMinutes % 60;
                const seconds = totalSeconds % 60;

                setUptime({
                    days: totalDays,
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds
                });

                // Update live counter
                setLiveTime({
                    days: totalDays,
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds
                });
            };

            updateTimes();
            interval = setInterval(updateTimes, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [startDateTime, uptimeRes]);

    if (!uptimeRes) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '20px'
            }}>
                <h2 style={{
                    fontSize: '1.1rem',
                    fontWeight: '600',
                    color: '#1b2559',
                    marginBottom: '20px'
                }}>Up Time</h2>
                
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '15px'
                }}>
                    <div style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        background: 'rgba(207, 16, 32, 0.1)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        animation: 'pulse-red 2s infinite'
                    }}>
                        <i className="bi bi-power" style={{
                            fontSize: '24px',
                            color: '#CF1020'
                        }}></i>
                    </div>
                    
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '5px'
                    }}>
                        <div style={{
                            color: '#CF1020',
                            fontSize: '1rem',
                            fontWeight: '600'
                        }}>System Offline</div>
                        
                    </div>
                </div>
                
                <style>{`
                    @keyframes pulse-red {
                        0% { transform: scale(1); box-shadow: 0 0 0 0 rgba(207, 16, 32, 0.4); }
                        70% { transform: scale(1.05); box-shadow: 0 0 0 10px rgba(207, 16, 32, 0); }
                        100% { transform: scale(1); box-shadow: 0 0 0 0 rgba(207, 16, 32, 0); }
                    }
                `}</style>
            </div>
        );
    }

    if (uptimeStatus) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '20px'
            }}>
                <h2 style={{
                    fontSize: '1.1rem',
                    fontWeight: '600',
                    color: '#1b2559',
                    marginBottom: '20px'
                }}>Up Time</h2>
                
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '15px'
                }}>
                    <div style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        background: 'rgba(207, 16, 32, 0.1)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        animation: 'pulse-red 2s infinite'
                    }}>
                        <i className="bi bi-exclamation-circle" style={{
                            fontSize: '24px',
                            color: '#CF1020'
                        }}></i>
                    </div>
                    
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '5px'
                    }}>
                        <div style={{
                            color: '#CF1020',
                            fontSize: '1rem',
                            fontWeight: '600'
                        }}>{uptimeStatus}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '15px'
        }}>
            <h2 style={{
                fontSize: '1.1rem',
                fontWeight: '600',
                color: '#1b2559',
                marginBottom: '5px'
            }}>Up Time</h2>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
            }}>
                {/* Live Counter */}
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        background: 'rgba(104, 37, 236, 0.1)',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                    }}>
                        <span style={{
                            color: '#6825EC',
                            fontWeight: '600',
                            fontSize: '1.1rem'
                        }}>{liveTime.days}</span>
                        <span style={{
                            color: '#2B3674',
                            fontSize: '0.8rem'
                        }}>d</span>
                    </div>
                    <div style={{
                        background: 'rgba(104, 37, 236, 0.1)',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                    }}>
                        <span style={{
                            color: '#6825EC',
                            fontWeight: '600',
                            fontSize: '1.1rem'
                        }}>{liveTime.hours}</span>
                        <span style={{
                            color: '#2B3674',
                            fontSize: '0.8rem'
                        }}>h</span>
                    </div>
                    <div style={{
                        background: 'rgba(104, 37, 236, 0.1)',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                    }}>
                        <span style={{
                            color: '#6825EC',
                            fontWeight: '600',
                            fontSize: '1.1rem'
                        }}>{liveTime.minutes}</span>
                        <span style={{
                            color: '#2B3674',
                            fontSize: '0.8rem'
                        }}>m</span>
                    </div>
                    <div style={{
                        background: 'rgba(104, 37, 236, 0.1)',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                    }}>
                        <span style={{
                            color: '#6825EC',
                            fontWeight: '600',
                            fontSize: '1.1rem'
                        }}>{liveTime.seconds}</span>
                        <span style={{
                            color: '#2B3674',
                            fontSize: '0.8rem'
                        }}>s</span>
                    </div>
                </div>

                {/* Up Since Time */}
                <div style={{
                    fontSize: '0.9rem',
                    color: '#2B3674',
                    opacity: 0.8,
                    textAlign: 'center'
                }}>
                    Up since {startDateTime.toLocaleString('en-US', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    })}
                </div>
            </div>
        </div>
    );
};

export default UpTime;
