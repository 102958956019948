import React from 'react';
import { useInView } from 'react-intersection-observer';
import Graph from './Graph';

const LazyGraph = (props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} style={{ minHeight: '300px' }}>
      {inView ? <Graph {...props} /> : <div style={{ height: '300px', backgroundColor: '#f0f0f0' }} />}
    </div>
  );
};

export default React.memo(LazyGraph);