import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';

const Login = ({ history, onLogin }) => {  // Access history via props
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30); // Countdown timer for 30 seconds
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Function to send OTP
  const handleSendOtp = async () => {
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setIsLoading(true); // Start loading

      try {
        // API call to send OTP with email as a query parameter in the URL
        const response = await fetch(`https://infia.enviseer.com/api/api/otp/send?email=${encodeURIComponent(email)}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Application-Name': 'envi-onboarding',
          },
        });

        const data =await response.text();

        if (response.ok) {
          setIsOtpSent(true); // Show OTP input field
          setErrorMessage('');
          setSuccessMessage('OTP sent successfully to your email.');
          setIsResendDisabled(true); // Disable resend button during countdown
          setCountdown(30); // Reset countdown

          // Start the countdown
          const countdownInterval = setInterval(() => {
            setCountdown((prev) => {
              if (prev <= 1) {
                clearInterval(countdownInterval);
                setIsResendDisabled(false); // Enable resend button after countdown
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
        } else if(data === 'User is not allowed to login.') {
          setErrorMessage('User is not authorised to login. Please contact your admin for more details.')
        }
        else {
          setErrorMessage('Failed to send OTP. Please try again.');
        }
      } catch (error) {
        setErrorMessage('Failed to send OTP. Please try again.');
        console.error('Error details:', error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setErrorMessage('Please enter a valid email address.');
    }
  };

  // Function to submit OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    try {
      // API call to verify OTP, sending email and otp as query parameters in the URL
      const response = await fetch(`https://infia.enviseer.com/api/api/user/loginverify?email=${encodeURIComponent(email)}&otp=${encodeURIComponent(otp)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Application-Name': 'envi-onboarding',
        },
      });

      const data = await response.json();

      if (response.ok && data.message === 'User verified successfully and token generated.') {
        const token = data.token;
        const emailId = data.email;
        document.cookie = `token=${token}; path=/;`;
        document.cookie = `username=${encodeURIComponent(emailId)}; path=/;`;
        setErrorMessage('');
        setSuccessMessage('OTP validated successfully!');
        // Navigate to another page after successful OTP validation
        // history.push('http://65.0.172.15:3000');
        onLogin(); 
      } else {
        setErrorMessage('Invalid OTP. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to validate OTP. Please try again.');
      console.error('Error details:', error); // Detailed logging
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  const navigate = useNavigate();
  const handleNavigateToRegister = () => {
    navigate('/register');
    };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !isOtpSent) {
      event.preventDefault(); // Prevent form submission
      handleSendOtp(); // Call the OTP function
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="tabs">
          <button className="tab active">LOGIN</button>
          <button className="tab" onClick={handleNavigateToRegister}>REGISTER</button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Enter your Email ID</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              required
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
          </div>
          {isOtpSent && ( // Show the OTP input field only if OTP has been sent
            <div className="input-group">
              <label htmlFor="otp">Enter your OTP</label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
          )}
          <div className="actions">
            {isOtpSent ? (
              <>
                <button type="button" onClick={handleSendOtp} className="resend-button" disabled={isResendDisabled}>
                  {isResendDisabled ? `Wait ${countdown}s to Resend OTP` : 'Resend OTP'}
                </button>
                <button type="submit" className="submit-button" disabled={isLoading}>
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              </>
            ) : (
              <button type="button" onClick={handleSendOtp} className="submit-button" disabled={isLoading}>
                {isLoading ? 'Sending OTP...' : 'Request OTP'}
              </button>
            )}
          </div>
        </form>
        <div className="footer-links">
          <a href="#" onClick={handleNavigateToRegister} className="register-link">Don’t have an account? Register Now.</a>
        </div>
      </div>
    </div>
  );
};

export default Login; 
