import React, { useState, useEffect } from 'react';
import ITPA from './ITPA';
import RPA from './RPA/RPA';

const Enviheal = ({ sidebarOpen, onLogout, initialTab = 'itpa' }) => {
    const [activeTab, setActiveTab] = useState(initialTab);

    useEffect(() => {
        // Update active tab when initialTab prop changes
        setActiveTab(initialTab);
    }, [initialTab]);

    return (
        <div style={{ 
            width: '100%', 
            height: '100vh', 
            display: 'flex', 
            flexDirection: 'column',
            background: '#f8f9fd',
            fontFamily: 'Inter, sans-serif',
            marginTop: '50px'
        }}>
            {/* Sub-tabs header */}
            <div style={{
                background: 'white',
                borderBottom: '1px solid #e5e7eb',
                padding: '0 24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
                height: '56px'
            }}>
                <button
                    onClick={() => setActiveTab('itpa')}
                    style={{
                        background: 'none',
                        border: 'none',
                        borderBottom: `2px solid ${activeTab === 'itpa' ? '#6825EC' : 'transparent'}`,
                        color: activeTab === 'itpa' ? '#6825EC' : '#64748b',
                        padding: '16px 8px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <i className="bi bi-diagram-3-fill"></i>
                    IT Process Automation
                </button>
                <button
                    onClick={() => setActiveTab('rpa')}
                    style={{
                        background: 'none',
                        border: 'none',
                        borderBottom: `2px solid ${activeTab === 'rpa' ? '#6825EC' : 'transparent'}`,
                        color: activeTab === 'rpa' ? '#6825EC' : '#64748b',
                        padding: '16px 8px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <i className="bi bi-robot"></i>
                    Robotic Process Automation
                </button>
            </div>

            {/* Content area */}
            <div style={{ flex: 1, overflow: 'auto' }}>
                {activeTab === 'itpa' ? (
                    <ITPA sidebarOpen={sidebarOpen} onLogout={onLogout} />
                ) : (
                    <RPA sidebarOpen={sidebarOpen} onLogout={onLogout} />
                )}
            </div>
        </div>
    );
};

export default Enviheal; 