const incidentData = {
  title: "High CPU Usage Anomaly Detected on March 26, 2025",
  
  rootCause: `
**Root Cause Identified:**

The root cause of the high CPU usage anomaly is attributed to the increased load on the \`envi-iam\` service due to a spike in user authentication and token generation requests. Specifically, the POST \`/api/otp/send\` and POST \`/api/user/loginverify\` endpoints experienced a significant increase in traffic, leading to elevated CPU usage. The system's CPU usage peaked at 24.7% at 11:17:05, which is notably higher than the usual range of 18.1% to 24.7%.

**Supporting Evidence:**

- **APM Metrics:** The high number of processed spans (39,503) and logs (966) at the timestamp \`2025-03-26 11:17:09\` indicates a significant amount of tracing activity. This suggests that the system was processing a large volume of requests during the incident.
  
- **Traces:** The POST \`/api/otp/send\` endpoint had a duration of approximately 4 seconds (3428 ms), which is longer than the typical millisecond-level processing times observed in other endpoints. This prolonged processing time indicates a bottleneck or increased load on the system.

- **Logs:** Multiple logs indicate successful OTP send operations and user verification processes, which align with the observed spike in CPU usage. These logs suggest that the system was handling a high volume of OTP-related activities.

- **Infrastructure Metrics:** The CPU usage spiked to 24.7% at 11:17:05, indicating a significant increase in computational demand compared to the usual range of 18.1% to 24.7%.

**Anomalies and Error Patterns:**

- The \`POST /api/otp/send\` endpoint experienced a longer processing time compared to other endpoints, indicating potential bottlenecks or increased load.
- The CPU usage spiked to 24.7%, which is outside the usual range, confirming the presence of a significant anomaly.
`,
  
  reasoning: `
**Reasoning Steps:**

1. **Correlate Logs and Traces:**
   - The logs and traces indicate a significant increase in OTP send requests and user verification processes.
   - The \`POST /api/otp/send\` endpoint had a processing time of 4 seconds, which is unusually high compared to typical millisecond-level processing times.

2. **Analyze APM Metrics:**
   - The high number of processed spans and logs suggests that the system was handling a large volume of requests.
   - The low CPU usage in the JVM indicates efficient resource management, but the high number of processed spans implies increased load.

3. **Examine Infrastructure Metrics:**
   - The CPU usage spiked to 24.7%, which is significantly higher than the usual range, indicating a sudden increase in computational demand.
   - The disk write operations and network traffic showed increasing trends, supporting the hypothesis of increased activity.

**Insights:**

- The spike in CPU usage coincides with a significant increase in OTP send requests and user verification processes, suggesting that these activities were the primary contributors to the increased load.
- The prolonged processing time for the \`POST /api/otp/send\` endpoint indicates potential bottlenecks or increased load, contributing to the high CPU usage.
`,
  
  analysis: `
**Incident Impact:**

- **System Performance:** The spike in CPU usage impacted system performance, potentially leading to slower response times and increased latency.
- **Business Operations:** The increased load on the \`envi-iam\` service could affect user experience, particularly during peak usage periods, leading to delays in OTP delivery and user verification processes.

**Remediation Steps:**

1. **Scale Resources:**
   - Increase the number of instances or scale up the resources allocated to the \`envi-iam\` service to handle the increased load.
   
2. **Optimize Code:**
   - Review and optimize the code for the \`POST /api/otp/send\` endpoint to reduce processing time and improve efficiency.
   
3. **Load Balancing:**
   - Implement or adjust load balancing strategies to distribute the load more evenly across available instances.

**Further Investigation:**

- **Monitor Historical Data:** Review historical data to identify any recurring patterns or trends that could indicate periodic peaks in load.
- **Performance Testing:** Conduct performance testing to simulate the load conditions and identify potential bottlenecks.
- **Capacity Planning:** Assess the system's capacity to handle future load and plan accordingly to avoid similar incidents.

By addressing these areas, the system can better manage increased loads and maintain optimal performance during peak usage periods.
`
};

export default incidentData; 