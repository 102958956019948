import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../Dropdownnew.css';
import '../Metrices.css';
import '../Health.css';
import '../Scores.css';
import '../Graph.css';
import Graph from './Graph';
import CustomDropdown from '../CustomDropdown';
import { formatDateTime } from './utils';

function InfrastructureMetrics({ sidebarOpen, onLogout }) {
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataSources, setDataSources] = useState([]);
    const [servers, setServers] = useState([]);
    const [selectedDataSource, setSelectedDataSource] = useState('');
    const [selectedServer, setSelectedServer] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [lastDuration, setLastDuration] = useState('5 minutes');
    const [refreshKey, setRefreshKey] = useState(0);
    const [graphsWithNoData, setGraphsWithNoData] = useState([]);
    const [graphsWithData, setGraphsWithData] = useState([]);
    const [selectedMetrics, setSelectedMetrics] = useState({});
    const [selectedChips, setSelectedChips] = useState([]);
    const [showMetricSelector, setShowMetricSelector] = useState(true);

    // Define the order of metric categories
    const categoryOrder = ['cpu', 'memory', 'disk', 'network'];

    // Memoize the metric configuration to prevent unnecessary re-renders
    const metricConfig = useMemo(() => ({
        'cpu': [
            {
                metricName: 'cpu_usage',
                label: 'CPU Usage',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'cpu_wait',
                label: 'CPU Wait',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            }
        ],
        'memory': [
            {
                metricName: 'memory_cached',
                label: 'Memory Cached',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'memory_free',
                label: 'Memory Available',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'memory_buffered',
                label: 'Memory Buffered',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'memory_usage',
                label: 'Memory Usage',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'swap_total',
                label: 'Swap Total',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'swap_used',
                label: 'Swap Used',
                borderColor: 'rgba(122, 38, 230, 1)',
                backgroundColor: 'rgba(102, 153, 204, 0.3)'
            },
            {
                metricName: 'swap_io_out',
                label: 'Swap IO Out',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'swap_usage',
                label: 'Swap Usage',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            }
        ],
        'disk': [
            {
                metricName: 'disk_io_write_ms',
                label: 'Disk IO Write ms',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'disk_queue_size',
                label: 'Disk Queue Size',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'disk_usage_percent',
                label: 'Disk Usage Percent',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'disk_read_count',
                label: 'Disk Read Count',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'disk_write_count',
                label: 'Disk Write Count',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'disk_read_bytes',
                label: 'Disk Read Bytes',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'disk_write_bytes',
                label: 'Disk Write Bytes',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'disk_read_time',
                label: 'Disk Read Time',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'io_time_ms',
                label: 'IO Time ms',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            }
        ],
        'network': [
            {
                metricName: 'network_bytes_recv',
                label: 'Network Bytes Received',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'network_bytes_sent',
                label: 'Network Bytes Sent',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'network_packets_recv',
                label: 'Network Packets Received',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'network_packets_sent',
                label: 'Network Packets Sent',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'network_errin',
                label: 'Network Errors In',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'network_errout',
                label: 'Network Errors Out',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'network_dropin',
                label: 'Network Drop In',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            },
            {
                metricName: 'network_dropout',
                label: 'Network Drop Out',
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            }
        ]
    }), []);

    useEffect(() => {
        const currentTime = new Date();
        const fiveMinutesAgo = new Date(currentTime.getTime() - 5 * 60000);
        setEndTime(currentTime);
        setStartTime(fiveMinutesAgo);
    }, []);

    useEffect(() => {
        fetchClusters();
    }, []);

    // Add new effect to check data availability initially
    useEffect(() => {
        if (selectedDataSource && selectedServer && startTime && endTime) {
            checkMetricsAvailability();
        }
    }, [selectedDataSource, selectedServer, startTime, endTime]);

    const fetchClusters = async () => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        
        const token = getCookie('token');
        const username = getCookie('username');
        const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
        
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            setDataSources(data);
            if (data.length > 0) {
                setSelectedDataSource(data[0]);
                fetchServers(data[0]);
            }
        } catch (error) {
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchServers = async (dataSource) => {
        const url = `https://infia.enviseer.com/api/clusters/${dataSource}/hostnames`;
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        
        const token = getCookie('token');
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            setServers(data);
            if (data.length > 0) {
                setSelectedServer(data[0]);
            }
        } catch (error) {
            console.error("Failed to fetch servers:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDataSourceChange = (event) => {
        const selected = event.target.value;
        setSelectedDataSource(selected);
        fetchServers(selected);
    };

    const handleServerChange = (event) => {
        setSelectedServer(event.target.value);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const handleLastDurationChange = (event) => {
        const selectedDuration = event.target.value;
        setLastDuration(selectedDuration);

        const currentTime = new Date();
        let newStartTime = null;
        let newEndTime = currentTime;

        switch (selectedDuration) {
            case '5 minutes':
                newStartTime = new Date(currentTime.getTime() - 5 * 60000);
                break;
            case '10 minutes':
                newStartTime = new Date(currentTime.getTime() - 10 * 60000);
                break;
            case '30 minutes':
                newStartTime = new Date(currentTime.getTime() - 30 * 60000);
                break;
            case '1 hour':
                newStartTime = new Date(currentTime.getTime() - 60 * 60000);
                break;
            case '2 hours':
                newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
                break;
            case '4 hours':
                newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
                break;
            case '6 hours':
                newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
                break;
            case '12 hours':
                newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
                break;
            case '1 day':
                newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
                break;
            case '3 days':
                newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
                break;
            case '1 week':
                newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
                break;
            case 'custom':
                break;
            default:
                newStartTime = startTime;
                newEndTime = endTime;
                break;
        }

        setStartTime(newStartTime);
        setEndTime(newEndTime);
    };

    const handleRefresh = () => {
        let currentTime = new Date();
        let newStartTime = null;

        switch (lastDuration) {
            case '5 minutes':
                newStartTime = new Date(currentTime.getTime() - 5 * 60000);
                break;
            case '10 minutes':
                newStartTime = new Date(currentTime.getTime() - 10 * 60000);
                break;
            case '30 minutes':
                newStartTime = new Date(currentTime.getTime() - 30 * 60000);
                break;
            case '1 hour':
                newStartTime = new Date(currentTime.getTime() - 60 * 60000);
                break;
            case '2 hours':
                newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
                break;
            case '4 hours':
                newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
                break;
            case '6 hours':
                newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
                break;
            case '12 hours':
                newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
                break;
            case '1 day':
                newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
                break;
            case '3 days':
                newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
                break;
            case '1 week':
                newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
                break;
            case 'custom':
                newStartTime = startTime;
                currentTime = endTime;
                break;
            default:
                newStartTime = startTime;
                currentTime = endTime;
                break;
        }

        setStartTime(newStartTime);
        setEndTime(currentTime);
        setRefreshKey(prevKey => prevKey + 1);
    };

    // Handle empty data notification from Graph component
    const handleEmptyData = (graphInfo) => {
        setGraphsWithNoData(prev => {
            // Check if this metric is already in the list
            const exists = prev.some(g => g.metricName === graphInfo.metricName);
            if (!exists) {
                return [...prev, graphInfo];
            }
            return prev;
        });
    };

    // Reset graphs with no data when selection changes
    useEffect(() => {
        setGraphsWithNoData([]);
    }, [selectedDataSource, selectedServer, startTime, endTime, selectedCategory]);

    const toggleMetricSelection = (category, metricName, label) => {
        if (metricName === 'all') {
            // Get all available metrics for the category (excluding ones with no data)
            const availableMetrics = metricConfig[category].filter(
                metric => !graphsWithNoData.some(g => g.metricName === metric.metricName)
            );
            
            // Check if all metrics in this category are already selected
            const allSelected = availableMetrics.every(metric => 
                selectedChips.some(chip => chip.id === `${category}-${metric.metricName}`)
            );

            if (allSelected) {
                // Remove all metrics from this category
                setSelectedChips(chips => chips.filter(chip => !chip.id.startsWith(`${category}-`)));
            } else {
                // Add all available metrics from this category
                const newChips = availableMetrics.map(metric => ({
                    id: `${category}-${metric.metricName}`,
                    category,
                    metricName: metric.metricName,
                    label: metric.label
                }));
                
                // Remove existing chips from this category and add new ones
                setSelectedChips(chips => [
                    ...chips.filter(chip => !chip.id.startsWith(`${category}-`)),
                    ...newChips
                ]);
            }
        } else {
            const chipId = `${category}-${metricName}`;
            if (selectedChips.some(chip => chip.id === chipId)) {
                setSelectedChips(chips => chips.filter(chip => chip.id !== chipId));
            } else {
                setSelectedChips(chips => [...chips, {
                    id: chipId,
                    category,
                    metricName,
                    label
                }]);
            }
        }
    };

    const removeChip = (chipId) => {
        setSelectedChips(chips => chips.filter(chip => chip.id !== chipId));
    };

    const getDisplayedMetrics = () => {
        return selectedChips
            .filter(chip => !graphsWithNoData.some(g => g.metricName === chip.metricName))
            .map(chip => ({
                metricName: chip.metricName,
                label: chip.label,
                borderColor: "rgba(122, 38, 230, 1)",
                backgroundColor: "rgba(122, 38, 230, 0.3)"
            }));
    };

    const getMetricsWithNoData = () => {
        return selectedChips
            .filter(chip => graphsWithNoData.some(g => g.metricName === chip.metricName))
            .map(chip => ({
                metricName: chip.metricName,
                label: chip.label
            }));
    };

    const checkMetricsAvailability = async () => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        
        const token = getCookie('token');
        
        // Clear previous no-data metrics
        setGraphsWithNoData([]);
        
        // Format dates for API call
        const formattedStartTime = formatDateTime(startTime);
        const formattedEndTime = formatDateTime(endTime);
        

        // Check all metrics in parallel
        const checkPromises = categoryOrder.flatMap(category =>
            metricConfig[category].map(async (metric) => {
                const url = `https://infia.enviseer.com/api/metrics?&cluster=${selectedDataSource}&hostname=${selectedServer}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metric.metricName}`;
                //console.log(`Checking metric ${metric.metricName} at URL:`, url);
                
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Accept': 'application/json'
                        }
                    });
                    
                    if (!response.ok) {
                        console.error(`Error checking ${metric.metricName}:`, response.status);
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    
                    const data = await response.json();
                    //console.log(`Data for ${metric.metricName}:`, data);
                    
                    if (!data || !Array.isArray(data) || data.length === 0 || !data.some(entry => {
                        const value = parseFloat(entry.value_readable || entry.value);
                        return !isNaN(value) && value !== 0;
                    })) {
                        return { metricName: metric.metricName, hasData: false };
                    }
                    return { metricName: metric.metricName, hasData: true };
                } catch (error) {
                    console.error(`Failed to check metric ${metric.metricName}:`, error);
                    return { metricName: metric.metricName, hasData: false };
                }
            })
        );

        try {
            const results = await Promise.all(checkPromises);
            const noDataMetrics = results
                .filter(result => !result.hasData)
                .map(result => ({ metricName: result.metricName }));
            
            setGraphsWithNoData(noDataMetrics);
        } catch (error) {
            console.error("Failed to check metrics availability:", error);
        }
    };

    return (
        <div>
            <div className="dashboard">
                <div className="dropdowns" style={{ width: sidebarOpen ? '89%' : '96%' }}>
                    <div className="dropdown-contents">
                        <div className="dropdown">
                            <CustomDropdown
                                label="Cluster"
                                options={Array.isArray(dataSources) && dataSources.map(source => ({ value: source, label: source }))}
                                value={selectedDataSource}
                                onChange={handleDataSourceChange}
                            />
                        </div>

                        <div className="dropdown">
                            <CustomDropdown
                                label="Servers"
                                options={servers && servers.map(server => ({ value: server, label: server }))}
                                value={selectedServer}
                                onChange={handleServerChange}
                            />
                        </div>

                        <div className="dropdown">
                            <CustomDropdown
                                label="Duration"
                                options={[
                                    { value: '5 minutes', label: '5 minutes' },
                                    { value: '10 minutes', label: '10 minutes' },
                                    { value: '30 minutes', label: '30 minutes' },
                                    { value: '1 hour', label: '1 hour' },
                                    { value: '2 hours', label: '2 hours' },
                                    { value: '4 hours', label: '4 hours' },
                                    { value: '6 hours', label: '6 hours' },
                                    { value: '12 hours', label: '12 hours' },
                                    { value: '1 day', label: '1 day' },
                                    { value: '3 days', label: '3 days' },
                                    { value: '1 week', label: '1 week' },
                                    { value: 'custom', label: 'custom' }
                                ]}
                                value={lastDuration}
                                onChange={handleLastDurationChange}
                            />
                        </div>

                        <div>
                            <button onClick={handleRefresh} className="refresh-button">
                                <i className="bi bi-arrow-clockwise"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="metrics-category-filter" style={{
                    padding: '1.5rem',
                    background: '#ffffff',
                    borderRadius: '12px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    margin: '1rem 0',
                    marginTop: '40px'
                }}>
                    <div style={{
                        marginBottom: '1rem'
                    }}>
                        <h3 style={{
                            margin: '0 0 1rem',
                            color: '#333',
                            fontSize: '1.1rem'
                        }}>Select Metrics</h3>
                        
                        {/* Selected Chips Display */}
                        {selectedChips.length > 0 && (
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '0.5rem',
                                padding: '0.5rem',
                                borderRadius: '8px',
                                border: '1px solid #e0e0e0',
                                marginBottom: '1rem'
                            }}>
                                {selectedChips.map(chip => (
                                    <div key={chip.id} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0.4rem 0.8rem',
                                        background: '#f0f0f0',
                                        borderRadius: '16px',
                                        fontSize: '0.9rem',
                                        gap: '0.5rem'
                                    }}>
                                        <span>{chip.label}</span>
                                        <button
                                            onClick={() => removeChip(chip.id)}
                                            style={{
                                                border: 'none',
                                                background: 'none',
                                                padding: '0',
                                                cursor: 'pointer',
                                                color: '#666',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <i className="bi bi-x"></i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Metric Categories */}
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                            gap: '1.5rem'
                        }}>
                            {categoryOrder.map(category => {
                                const availableMetrics = metricConfig[category].filter(
                                    metric => !graphsWithNoData.some(g => g.metricName === metric.metricName)
                                );
                                const unavailableMetrics = metricConfig[category].filter(
                                    metric => graphsWithNoData.some(g => g.metricName === metric.metricName)
                                );
                                const allSelected = availableMetrics.every(metric => 
                                    selectedChips.some(chip => chip.id === `${category}-${metric.metricName}`)
                                );

                                return (
                                    <div key={category} style={{
                                        background: '#f8f9fa',
                                        padding: '1rem',
                                        borderRadius: '8px'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginBottom: '1rem'
                                        }}>
                                            <h4 style={{
                                                margin: 0,
                                                color: '#6825EC',
                                                fontSize: '1rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem'
                                            }}>
                                                <i className={`bi bi-${
                                                    category === 'cpu' ? 'cpu' :
                                                    category === 'memory' ? 'memory' :
                                                    category === 'disk' ? 'hdd' :
                                                    'diagram-3'
                                                }`}></i>
                                                {category.charAt(0).toUpperCase() + category.slice(1)}
                                            </h4>
                                            <button
                                                onClick={() => toggleMetricSelection(category, 'all')}
                                                style={{
                                                    padding: '0.3rem 0.8rem',
                                                    border: '1px solid #6825EC',
                                                    borderRadius: '4px',
                                                    background: allSelected ? '#6825EC15' : 'transparent',
                                                    cursor: 'pointer',
                                                    color: '#6825EC',
                                                    fontSize: '0.8rem',
                                                    fontWeight: '500',
                                                    transition: 'all 0.2s'
                                                }}
                                            >
                                                {allSelected ? 'Deselect All' : 'Select All'}
                                            </button>
                                        </div>
                                        <div style={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                                            gap: '0.5rem'
                                        }}>
                                            {/* Available Metrics */}
                                            {availableMetrics.map(metric => {
                                                const isSelected = selectedChips.some(
                                                    chip => chip.id === `${category}-${metric.metricName}`
                                                );
                                                return (
                                                    <button
                                                        key={metric.metricName}
                                                        onClick={() => toggleMetricSelection(category, metric.metricName, metric.label)}
                                                        style={{
                                                            display: 'block',
                                                            padding: '0.5rem',
                                                            border: `1px solid ${isSelected ? '#6825EC' : '#e0e0e0'}`,
                                                            borderRadius: '6px',
                                                            background: isSelected ? '#6825EC15' : '#fff',
                                                            cursor: 'pointer',
                                                            textAlign: 'left',
                                                            color: isSelected ? '#6825EC' : '#333',
                                                            fontSize: '0.85rem',
                                                            transition: 'all 0.2s',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}
                                                    >
                                                        {metric.label}
                                                    </button>
                                                );
                                            })}

                                            {/* Unavailable Metrics */}
                                            {unavailableMetrics.length > 0 && (
                                                <>
                                                    <div style={{
                                                        gridColumn: '1 / -1',
                                                        borderTop: '1px solid #e0e0e0',
                                                        margin: '0.5rem 0',
                                                        padding: '0.5rem 0 0 0'
                                                    }}>
                                                        <span style={{
                                                            fontSize: '0.8rem',
                                                            color: '#666',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '0.25rem'
                                                        }}>
                                                            <i className="bi bi-info-circle"></i>
                                                            No data available
                                                        </span>
                                                    </div>
                                                    {unavailableMetrics.map(metric => (
                                                        <div
                                                            key={metric.metricName}
                                                            style={{
                                                                display: 'block',
                                                                padding: '0.5rem',
                                                                border: '1px solid #e0e0e0',
                                                                borderRadius: '6px',
                                                                background: '#f5f5f5',
                                                                color: '#999',
                                                                fontSize: '0.85rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                cursor: 'not-allowed'
                                                            }}
                                                        >
                                                            {metric.label}
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="metrices" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.5rem',
                    width: '100%'
                }}>
                    {/* Graphs with data */}
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
                        gap: '1rem',
                        width: '100%'
                    }}>
                        {getDisplayedMetrics()
                            .map((metricConfig, index) => (
                                <div className="graph-total" key={index} style={{
                                    background: '#fff',
                                    borderRadius: '8px',
                                    padding: '1rem',
                                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '300px',
                                    overflow: 'hidden'
                                }}>
                                    <p style={{
                                        margin: '0 0 0.5rem',
                                        fontSize: '0.9rem',
                                        fontWeight: '500',
                                        flexShrink: 0
                                    }}>{metricConfig.label}</p>
                                    <div className="metric" style={{
                                        flex: 1,
                                        minHeight: 0,
                                        position: 'relative',
                                        width: '100%'
                                    }}>
                                        <Graph
                                            key={`${refreshKey}-${index}`}
                                            dataSource={selectedDataSource}
                                            server={selectedServer}
                                            startDate={startTime}
                                            endDate={endTime}
                                            metricName={metricConfig.metricName}
                                            label={metricConfig.label}
                                            borderColor={metricConfig.borderColor}
                                            backgroundColor={metricConfig.backgroundColor}
                                            onEmptyData={handleEmptyData}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>

                    {/* Metrics with no data */}
                    {getMetricsWithNoData().length > 0 && (
                        <div style={{
                            background: '#fff',
                            borderRadius: '8px',
                            padding: '1.5rem',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}>
                            <h4 style={{
                                margin: '0 0 1rem',
                                color: '#666',
                                fontSize: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem'
                            }}>
                                <i className="bi bi-info-circle"></i>
                                Metrics with No Data Available
                            </h4>
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '0.5rem'
                            }}>
                                {getMetricsWithNoData().map((metric, index) => (
                                    <div key={index} style={{
                                        padding: '0.4rem 0.8rem',
                                        background: '#f5f5f5',
                                        borderRadius: '16px',
                                        fontSize: '0.9rem',
                                        color: '#666'
                                    }}>
                                        {metric.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(InfrastructureMetrics); 