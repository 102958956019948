import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Dropdownnew.css';
import './Scores.css';
import './Graph.css';
import './Health.css';
import './Sidechart.css';
import PieChart from './features/PieChart';
import Graph from './features/Graph';
import MultiGraph from './features/MultiGraph';
import { formatDateTime } from './features/utils';
import { Pie } from 'react-chartjs-2';
import GraphOptionsDropdown from './features/GraphOptionsDropdown';
import HealthScore from './HealthScore';

const options = [
    { value: 'Basic Alert', label: 'Basic Alert' },
    { value: 'Collective Insight', label: 'Collective Insight' }
];

const Anomaly = ({ selectedDataSource, selectedServer, startTime, endTime, navigate }) => {
    const [selectedOptions, setSelectedOptions] = useState(
        Array(8).fill(options[0])
    );
    const MetricList = [
        { value: 'cluster.state', label: selectedServer },
        { value: 'cpu_usage', label: 'CPU Usage' },
        { value: 'memory_usage', label: 'Memory Usage' },
        { value: 'disk_usage', label: 'Disk Usage' },
        { value: 'network_bytes_recv', label: 'Network Bytes Recv'}

    ];
    const [selectedMetric, setSelectedMetric] = useState(
        Array(8).fill(MetricList[0])
    );
    const [anomalyCount, setAnomalyCount] = useState({ basic: 0, strict: 0 });
    const [healthScore, setHealthScore] = useState(0);
    const [clusterScore, setClusterScore] = useState(0);
    const [rootCouseMetric, setRootCouseMetric] = useState("No Data found")
    const [metricData, setMetricData] = useState([]);
    const [anomalyMetric, setAnomalyMetric] = useState({ metricName: 'cpu_usage', label: 'CPU Usage' });
    const [refreshKey, setRefreshKey] = useState(0);
    const [pressedButton, setPressedButton] = useState(null);
    const [processeList, setProcesseList] = useState([]);


    const fetchClusterData = useCallback(async (selectedDataSource) => {
    const url = `https://infia.enviseer.com/api/healthscores?cluster=${selectedDataSource}`;
         function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.value);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
  },[]);
  
  const fetchRootCouseMetric = useCallback(async (selectedDataSource,selectedServer) => {
     function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
    const url = `https://infia.enviseer.com/api/topanomalousmetric?cluster=${selectedDataSource}&host=${selectedServer}`;
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json(); // Expecting a JSON object
        return data.metric;
    }, []);

    const fetchProcessData = useCallback(async (selectedDataSource,selectedServer) => {
        function getCookie(name) {
                   const value = `; ${document.cookie}`;
                   const parts = value.split(`; ${name}=`);
                   if (parts.length === 2) return parts.pop().split(';').shift();
               }
               
               const token = getCookie('token');
       const url = `https://infia.enviseer.com/api/clusters/${selectedDataSource}/process_monitoring?hostname=${selectedServer}`;
        const response = await fetch(url, {
               method: 'GET',
               headers: {
                   'Authorization': `Bearer ${token}`, // Adding the Authorization header
                   'Accept': 'application/json'
               }
           });
           if (!response.ok) {
               throw new Error(`HTTP error! Status: ${response.status}`);
           }
           const data = await response.json(); // Expecting a JSON object
           return data;
       }, []);
   

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchClusterData(selectedDataSource);
                setClusterScore(data.values[data.values.length - 1].toFixed(2));
                const rootMetric = await fetchRootCouseMetric(selectedDataSource, selectedServer);
                const process = await fetchProcessData(selectedDataSource, selectedServer);
                setProcesseList(process);
                const capitalizedRootMetric = rootMetric.charAt(0).toUpperCase() + rootMetric.slice(1);
                setRootCouseMetric(capitalizedRootMetric);
            } catch (error) {
                console.error(`Error fetching data:`, error);
                setProcesseList([]);
                setMetricData([]);
                setRootCouseMetric("No data found");
            }
        };

        fetchData();
    }, [selectedDataSource, selectedServer]);

    const handleResize = () => {
        console.log("Window resized!"); // Check if the event is triggering
        setRefreshKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        console.log("Adding resize event listener"); // To confirm the effect is running
        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => {
            console.log("Removing resize event listener"); // For confirmation
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDropdownChange = (index, selectedOption) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = selectedOption;
        setSelectedOptions(newSelectedOptions);
    };
    const handleHealthDropdownChange = (index, Metric) => {
        const newSelectedMetric = [...selectedMetric];
        newSelectedMetric[index] = Metric;
        setSelectedMetric(newSelectedMetric);
    };
    const handleClick = (option) => {
        const index = 0;
        handleDropdownChange(index, { label: option, value: option.toLowerCase().replace(' ', '-') });
        setPressedButton(option);
    };
    const updateAnomalyCount = (count) => {
        setAnomalyCount(count);
    };
    const updateAvgScore = (Score) => {
        setHealthScore(Score);
    };
    const updateAnomalyMetric = (Metric) => {
        setAnomalyMetric(Metric);
        handleHealthDropdownChange(0, { value: Metric.metricName, label: Metric.label });
        // setRefreshKey(prevKey => prevKey + 1);
    };

    


    const processes = [
        { id: 29885, name: 'java', cpu: 1.099, threads: 296 },
        { id: 1, name: 'systemd', cpu: 0.4, threads: 1 },
        { id: 3023, name: 'dbus', cpu: 0.4, threads: 2 },
        { id: 1802, name: 'systemd-journald', cpu: 0.2, threads: 1 },
        { id: 2859, name: 'newrelic-infra', cpu: 0.2, threads: 13 },
        { id: 2934, name: 'polkit', cpu: 0.2, threads: 7 }
    ];
    
    return (
        <>
            <div className="metrics-grid">
                <div className="metric-item">
                    <div className="metric-header">
                        <h2>Anomalies</h2>
                    </div>

                    <div className="metric-content">

                        <div className={`metric-sub-content ${pressedButton === 'Basic Alert' ? 'pressed' : ''}`}
                            onClick={() => handleClick('Basic Alert')}>
                            <p className="anomaly-button"> Basic Alert : </p>
                            <p className="score" style={{ color: '#cc7000' }}>{anomalyCount.basic}</p>
                        </div>

                        <div className={`metric-sub-content ${pressedButton === 'Collective Insight' ? 'pressed' : ''}`}
                            onClick={() => handleClick('Collective Insight')}>
                            <p className="anomaly-button"> Collective Insight : </p>
                            <p className="score" style={{ color: '#CF1020' }}>{anomalyCount.strict}</p>
                        </div>

                    </div>

                    <span className="tooltip">Anomalies</span>
                </div>

                <div className="metric-item">
                    <div className="metric-header">
                        <h2>EnviVital</h2>
                    </div>

                    <div className="metric-content">
                        <div className="health-score-container">
                            <HealthScore score={healthScore} />
                        </div>
                    </div>

                    <span className="tooltip">Health-Score</span>
                </div>


                <div className="metric-item">

                    <div className="metric-header">
                        <h2>EnviScore</h2>
                    </div>

                    <div className="metric-content" >
                        <div className="health-score-container">
                            <HealthScore score={clusterScore} />
                        </div>

                    </div>

                    <span className="tooltip">Application Score</span>
                </div>

                <div className="metric-item">
                    <div className="metric-header">
                        <h2>Root Cause</h2>
                    </div>

                    <div className="metric-content">
                        <p className="score" style={{ color: '#023e8a' }}>{rootCouseMetric}</p>
                    </div>

                    <span className="tooltip">Root Cause Metric</span>
                </div>

            </div>

            <div className="anomaly-row" style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px', marginBottom: '15px', gap: '18px' }}>
                <PieChart
                    key={refreshKey}
                    dataSource={selectedDataSource}
                    server={selectedServer}
                    startDate={startTime}
                    endDate={endTime}
                    updateAnomalyMetric={updateAnomalyMetric}
                    selectedOption={selectedOptions[0]}
                />
                <div className="sidechart" >
                    <GraphOptionsDropdown
                        key={refreshKey}
                        selectedOption={selectedOptions[0]}
                        onChange={(option) => handleDropdownChange(0, option)}
                        options={options}
                    />
                    <MultiGraph
                        key={refreshKey}
                        dataSource={selectedDataSource}
                        server={selectedServer}
                        startDate={startTime}
                        endDate={endTime}
                        metricName={anomalyMetric.metricName}
                        metricName2={anomalyMetric.metricName}
                        label1={anomalyMetric.label}
                        label2="Anomaly"
                        borderColor1="rgba(0, 153, 128, 1)"
                        backgroundColor1="rgba(0, 191, 160, 0.3)"
                        selectedOptions={selectedOptions[0].label}
                        updateAnomalyCount={updateAnomalyCount}
                        handleAnomalyClick={navigate}
                    />

                </div>
            </div>

    {/* Graph Section */}
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px', marginBottom: '15px', gap: '18px' }}>

    <div className='health-total' style={{ }}>
        <p>EnviVital</p>
        {/* <GraphOptionsDropdown
            key={refreshKey}
            selectedOption={selectedMetric[0]}
            onChange={(option) => handleHealthDropdownChange(0, option)}
            options={MetricList}
        /> */}
        <div className="health">
            <Graph
                dataSource={selectedDataSource}
                server={selectedServer}
                startDate={startTime}
                endDate={endTime}
                metricName={selectedMetric[0].value}
                label="EnviVital"
                borderColor="rgba(0, 191, 160, 1)"
                backgroundColor="rgba(0, 191, 160, 0.3)"
                updateAvgScore={updateAvgScore}
            />
        </div>
    </div>

    {/* Table Section */}
    <div className="graph-and-table-container">
  {/* Process Table Section */}
  <div className='processes-table'>
    <p>Processes</p>
    <table>
      <thead>
        <tr>
          <th>Process Id</th>
          <th>Process Name</th>
          <th>CPU Usage </th>
          <th>Memory Usage</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {processeList?.map((process) => (
          <tr key={process.process_id}>
            <td>{process.process_id}</td>
            <td title={process.process_name}>{process.process_name}</td>
            <td>{process.cpu_usage ? `${process.cpu_usage.toFixed(2)}%` : '0.00%'}</td>
            <td>{process.memory_usage ? `${process.memory_usage.toFixed(2)}%` : '0.00%'}</td>
            <td>{process.process_status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>
</div>

        </>
    );
}

export default Anomaly;
