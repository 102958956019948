import React, { useState, useEffect, useRef, useMemo } from 'react';
import './EnviDetect.css'; // Styling
import { FaInfoCircle, FaSyncAlt, FaChartLine, FaExclamationTriangle } from 'react-icons/fa';
import { BiSortUp, BiSortDown, BiSearch } from 'react-icons/bi';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { FixedSizeList as List } from 'react-window';
import MultiGraph from './features/MultiGraph';
import DetectGraph from './features/DetectGraph';

// Add SortIcon component
const SortIcon = ({ columnKey, currentSortColumn, currentSortDirection }) => {
  if (currentSortColumn !== columnKey) {
    return <BiSortUp style={{ opacity: 0.3 }} />;
  }
  return currentSortDirection === 'asc' ? <BiSortUp /> : <BiSortDown />;
};

const Row = ({ index, style, data }) => {
  const anomaly = data.anomalies[index];
  const isExpired = anomaly.timeRemaining === 'Expired';
  
  return (
    <div 
      style={{
        ...style,
        display: 'grid',
        gridTemplateColumns: data.columns,
        padding: '12px 16px',
        alignItems: 'center',
        borderBottom: '1px solid #E0E6EF',
        fontSize: '14px',
        backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9fc',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        color: '#1b2559',
        minWidth: '100%',
      }}
      onClick={() => data.onSelectRow(anomaly)}
      className={data.selectedRow?.id === anomaly.id ? 'selected-row' : ''}
    >
      <div style={{ 
        color: isExpired ? '#EF4444' : '#10B981',
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: '0 8px'
      }}>
        {isExpired ? <FaExclamationTriangle size={14} /> : null}
        {anomaly.timeRemaining}
      </div>
      <div style={{ 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap', 
        padding: '0 8px',
        textAlign: 'center'
      }}>
        {new Date(anomaly.detectedTime).toLocaleString()}
      </div>
      <div style={{ 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap', 
        padding: '0 8px',
        textAlign: 'center'
      }}>
        {new Date(anomaly.predictedTime).toLocaleString()}
      </div>
      <div style={{ 
        fontWeight: '500', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap', 
        padding: '0 8px',
        textAlign: 'center'
      }}>
        {anomaly.dataSource}
      </div>
      <div style={{ 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap', 
        padding: '0 8px',
        textAlign: 'center'
      }}>
        {anomaly.server}
      </div>
      <div style={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        color: '#6825EC',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: '0 8px'
      }}>
        {anomaly.metric}
        <FaInfoCircle 
          size={16}
          style={{ cursor: 'pointer', flexShrink: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            data.onShowDetails(anomaly);
          }}
        />
      </div>
    </div>
  );
};

const EnviDetect = () => {
  const [anomalies, setAnomalies] = useState([]); // Full anomaly data
  const [activeTab, setActiveTab] = useState('Predicted'); // Tab state
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState('detectedTime');
  const [sortDirection, setSortDirection] = useState('desc');
  const chartRef = useRef(null);
  const [methodFilter, setMethodFilter] = useState({
    cluster: "",
    server: "",
    metric: "",
  });

  // Add handleSort function
  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  // const token = getCookie('token');
  const token = getCookie('token');
  // Fetch anomaly data from API
  const fetchData = async () => {
    try {
      const response = await fetch(
        'https://infia.enviseer.com/api/detect_anomalies_by_cluster?cluster=100',
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const apiData = await response.json();
      const currentTime = new Date(); // Use current time for calculations

      const processedData = apiData.anomalies.map((anomaly, index) => {
        const predictedTime = new Date(anomaly.predicted.predicted_downtime);
        const timeRemainingMs = predictedTime - currentTime;

        return {
          id: index + 1,
          dataSource: apiData.cluster, // The cluster ID
          server: anomaly.hostname, // Hostname
          metric: anomaly.metric, // Metric name
          detectedTime: new Date(anomaly.detected.timestamp).toISOString(), // Detected anomaly time
          predictedTime: predictedTime.toISOString(), // Predicted downtime
          timeRemaining:
            timeRemainingMs > 0
              ? `${Math.floor(timeRemainingMs / (1000 * 60 * 60))}h ${Math.floor(
                (timeRemainingMs / (1000 * 60)) % 60
              )}m`
              : "Expired", // Time until predicted downtime or expired
          status: timeRemainingMs > 0 ? "Open" : "Closed", // Status based on timeRemaining
          type: "Anomaly Detection", // Can be customized if other types exist
        };
      });

      //console.log(processedData);



      setAnomalies(processedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (anomalies.length > 0) {
      // Sort anomalies by detected time in descending order (most recent first)
      const sortedAnomalies = [...anomalies].sort((a, b) => 
        new Date(b.detectedTime) - new Date(a.detectedTime)
      );
      
      // Select the most recent anomaly
      setSelectedRow(sortedAnomalies[0]);
      
      // Update displayed data based on active tab
      const updatedDisplayedData = activeTab === 'Predicted'
        ? sortedAnomalies.filter(item => item.timeRemaining !== 'Expired')
        : sortedAnomalies.filter(item => new Date(item.predictedTime) < new Date());
    }
  }, [anomalies, activeTab]);


  const clusterColors = [
    'rgba(78, 245, 198, 0.9)',  // Main mint green
  ];

  const hoverBackgroundColor = [
    'rgba(78, 245, 198, 1)',  // Slightly more opaque on hover
  ];

  const [clusters, setClusters] = useState([]);

  // Process anomalies data
  useEffect(() => {
    if (anomalies && Array.isArray(anomalies)) {
      const clustersMap = {};

      anomalies.forEach((anomaly) => {
        const clusterName = anomaly.dataSource || 'Unknown Cluster';
        if (!clustersMap[clusterName]) {
          clustersMap[clusterName] = { anomalyCount: 0, servers: new Set() };
        }
        clustersMap[clusterName].anomalyCount += 1;
        clustersMap[clusterName].servers.add(anomaly.server);
      });

      const processedClusters = Object.entries(clustersMap).map(([name, details]) => ({
        name,
        anomalyCount: details.anomalyCount,
        serverCount: details.servers.size,
      }));
      setClusters(processedClusters);
    }
  }, [anomalies]);

  const formatMetricName = (metric) => {
    if (!metric) return "Metric";
    if(metric== "cpu_usage") return "CPU Usage";
}


  const centerTextPlugin = {
    id: 'centerTextPlugin',
    afterDraw: (chart) => {
      const { ctx, chartArea } = chart;
      ctx.save();
      
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      
      // Draw total number
      const total = clusters.reduce((sum, cluster) => sum + cluster.anomalyCount, 0);
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      // Main number
      ctx.font = `600 36px 'Inter'`;
      ctx.fillStyle = '#1b2559';
      ctx.fillText(total.toString(), centerX, centerY - 10);
      
      // Label below
      ctx.font = `400 13px 'Inter'`;
      ctx.fillStyle = '#64748B';
      ctx.fillText('Total Anomalies', centerX, centerY + 15);
      
      ctx.restore();
    }
  };

  // Prepare chart data
  const chartData = {
    labels: clusters.map((cluster) => cluster.name),
    datasets: [
      {
        data: clusters.map((cluster) => cluster.anomalyCount),
        backgroundColor: clusterColors,
        hoverBackgroundColor: hoverBackgroundColor,
        borderWidth: 0,
        borderRadius: 20,
        spacing: 10,
        offset: 0,
        hoverOffset: 4,
      },
    ],
  };

  // Chart Options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '80%',
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20
      }
    },
    plugins: {
      legend: { 
        display: false 
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#1b2559',
        bodyColor: '#1b2559',
        titleFont: { 
          size: 14,
          weight: '600',
          family: "'Inter', sans-serif"
        },
        bodyFont: { 
          size: 13,
          family: "'Inter', sans-serif"
        },
        padding: 12,
        boxPadding: 6,
        cornerRadius: 8,
        displayColors: false,
        borderColor: 'rgba(104, 37, 236, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: (tooltipItem) => {
            const cluster = clusters[tooltipItem.dataIndex];
            return [
              `Predicted Anomalies: ${cluster.anomalyCount}`,
              `Anomalous Servers: ${cluster.serverCount}`
            ];
          }
        }
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 1000,
      easing: 'easeOutQuart'
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };

  const percentageTextPlugin = {
    id: 'percentageTextPlugin',
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
      const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);

      // Only draw the percentage at the bottom
      const percentage = 100;  // Since we're showing total
      
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const bottomY = chart.chartArea.bottom - 10;  // Position at bottom

      // Draw percentage pill
      ctx.save();
      
      // Background pill
      const text = `${percentage.toFixed(1)}%`;
      ctx.font = `600 12px 'Inter'`;
      const textWidth = ctx.measureText(text).width;
      const padding = 8;
      const pillWidth = textWidth + padding * 2;
      const pillHeight = 24;
      
      // Draw rounded rectangle
      ctx.fillStyle = 'rgba(78, 245, 198, 0.9)';
      ctx.beginPath();
      ctx.roundRect(
        centerX - pillWidth/2,
        bottomY - pillHeight/2,
        pillWidth,
        pillHeight,
        12
      );
      ctx.fill();
      
      // Draw text
      ctx.fillStyle = '#1b2559';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, centerX, bottomY);
      
      ctx.restore();
    }
  };
  // Filter History data
  const historyData = anomalies.filter(
    (item) => new Date(item.predictedTime) < new Date()
  );

  // Tab-specific data
  const displayedData = activeTab === 'Predicted'
    ? anomalies.filter(
      (item) =>
        item.timeRemaining !== 'Expired'
    )
    : anomalies.filter(
      (item) =>
        new Date(item.predictedTime) < new Date()
    );


  // Add this helper function to calculate prediction window
  const calculatePredictionWindow = (predictedTime) => {
    const predictedDate = new Date(predictedTime);
    const windowStart = new Date(predictedDate.getTime() - 30 * 60000); // 30 mins before
    const windowEnd = new Date(predictedDate.getTime() + 30 * 60000);   // 30 mins after
    return { windowStart, windowEnd };
  };

  // Update the formatDateTime function to be more precise
  const formatDateTime = (date) => {
    const dateObj = new Date(date);
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // Add a function to format the prediction window display
  const formatPredictionWindow = (selectedRow) => {
    if (!selectedRow) return 'No data selected';
    
    const predictedTime = new Date(selectedRow.predictedTime);
    return `Predicted: ${formatDateTime(predictedTime)} (±30min)`;
  };

  // Add function to format the time range display
  const formatTimeRange = (selectedRow) => {
    if (!selectedRow) return '';
    const detectedTime = formatDateTime(selectedRow.detectedTime);
    const predictedTime = formatDateTime(selectedRow.predictedTime);
    return `${detectedTime} - ${predictedTime}`;
  };

  const raiseIncident = (server, cluster, metric) => {
    const requestData = {
      predicted_time: "2024-10-25 14:00:00", // You can update this to a dynamic value as needed
      anomalous_server: server,
      metric: metric,
      cluster: cluster,
      status: "Critical"
    };

    fetch("https://infia.enviseer.com/api/alert", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(data => {
        alert(`Incident raised successfully for ${server}`);
      })
      .catch(error => {
        console.error("There was a problem with the fetch operation:", error);
        alert(`Failed to raise incident for ${server}`);
      });
  };


  const handleClick = (event) => {

    // if (!chartRef.current) return;
    //       const chart = chartRef.current;
    //       const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    // if (elements && elements.length > 0) {
    //   const clickedIndex = elements[0].index;
    //   const item = clusters[clickedIndex];
    //   // const clickedLabel = chartData.labels[clickedIndex];
    //   setSelectedDataSource(item.name);
    // } else {
    //   console.error('No chart elements were clicked.');
    // }
  };

  //console.log("anomalies", anomalies);

  // Update filteredData to include default sorting
  const filteredData = useMemo(() => {
    let filtered = displayedData.filter((item) => {
      return (
        (!methodFilter.cluster || item.dataSource === methodFilter.cluster) &&
        (!methodFilter.server || item.server === methodFilter.server) &&
        (!methodFilter.metric || item.metric === methodFilter.metric) &&
        (activeTab === 'Predicted'
          ? item.timeRemaining !== 'Expired'
          : new Date(item.predictedTime) < new Date())
      );
    });

    // Always sort by detected time by default if no other sort is applied
    const currentSortColumn = sortColumn || 'detectedTime';
    const currentSortDirection = sortDirection || 'desc';

    filtered.sort((a, b) => {
      let valueA = a[currentSortColumn];
      let valueB = b[currentSortColumn];

      // Handle date comparisons
      if (currentSortColumn === 'detectedTime' || currentSortColumn === 'predictedTime') {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      }

      if (valueA < valueB) return currentSortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return currentSortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    return filtered;
  }, [displayedData, methodFilter, activeTab, sortColumn, sortDirection]);

  // Memoized column configuration
  const columnConfig = [
    { key: 'timeRemaining', label: 'Time Remaining', width: '1fr' },
    { key: 'detectedTime', label: 'Detected Time', width: '1fr' },
    { key: 'predictedTime', label: 'Predicted Time', width: '1fr' },
    { key: 'dataSource', label: 'Cluster', width: '1fr' },
    { key: 'server', label: 'Server', width: '1fr' },
    { key: 'metric', label: 'Metric', width: '1fr' },
  ];

  const columns = columnConfig.map(col => col.width).join(' ');

  // Enhanced chart options
  const enhancedChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.9)',
        titleFont: { size: 13, weight: 'bold' },
        bodyFont: { size: 12 },
        padding: 12,
        cornerRadius: 8,
        displayColors: false,
        callbacks: {
          label: (tooltipItem) => {
            const cluster = clusters[tooltipItem.dataIndex];
            return [
              `Predicted Anomalies: ${cluster.anomalyCount}`,
              `Anomalous Servers: ${cluster.serverCount}`
            ];
          }
        }
      }
    },
    cutout: '70%',
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 800,
      easing: 'easeOutQuart'
    }
  };

  return (
    <div className="table-container">
      {displayedData.length !== 0 && activeTab === 'History' && (
        <div style={{ 
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'space-between', 
          gap: '24px',
          marginBottom: '24px',
          flexWrap: 'wrap'
        }}>
          <div className="flex-container" style={{ 
            flex: '1',
            minWidth: '320px',
            maxWidth: '380px'
          }}>
            <div style={{ 
              width: '100%',
              borderRadius: '16px',
              padding: '24px',
              border: '1px solid rgba(104, 37, 236, 0.05)'
            }}>
              <h3 style={{ 
                fontSize: '20px', 
                fontWeight: '600', 
                color: '#1b2559',
                marginBottom: '24px',
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                gap: '12px'
              }}>
                <div style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '8px',
                  background: 'linear-gradient(135deg, #6825EC, #4A378C)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z" fill="white"/>
                  </svg>
                </div>
                Anomalous Clusters
              </h3>
              <div style={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}>
                {clusters.map((cluster, index) => (
                  <div key={index} style={{
                    background: 'linear-gradient(135deg, rgba(104, 37, 236, 0.03), rgba(104, 37, 236, 0.01))',
                    borderRadius: '12px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    border: '1px solid rgba(104, 37, 236, 0.08)',
                    transition: 'all 0.2s ease',
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'translateY(-2px)';
                    e.currentTarget.style.boxShadow = '0 8px 24px rgba(104, 37, 236, 0.12)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.boxShadow = 'none';
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px'
                    }}>
                      <h4 style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#1b2559',
                        margin: '0'
                      }}>
                        Cluster {cluster.name}
                      </h4>
                    </div>

                    <div style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: '12px'
                    }}>
                      <div style={{
                        background: 'white',
                        borderRadius: '10px',
                        padding: '16px',
                        boxShadow: '0 2px 4px rgba(104, 37, 236, 0.05)',
                        border: '1px solid rgba(104, 37, 236, 0.08)',
                        position: 'relative',
                        overflow: 'hidden'
                      }}>
                        <div style={{
                          fontSize: '28px',
                          fontWeight: '700',
                          color: '#6825EC',
                          marginBottom: '4px',
                          display: 'flex',
                          alignItems: 'baseline',
                          gap: '4px'
                        }}>
                          {cluster.anomalyCount}
                          <span style={{ fontSize: '14px', color: '#64748B', fontWeight: '500' }}>total</span>
                        </div>
                        <div style={{
                          fontSize: '14px',
                          color: '#64748B',
                          fontWeight: '500'
                        }}>
                          Predicted Anomalies
                        </div>
                      </div>

                      <div style={{
                        background: 'white',
                        borderRadius: '10px',
                        padding: '16px',
                        boxShadow: '0 2px 4px rgba(104, 37, 236, 0.05)',
                        border: '1px solid rgba(104, 37, 236, 0.08)',
                        position: 'relative',
                        overflow: 'hidden'
                      }}>
                        <div style={{
                          fontSize: '28px',
                          fontWeight: '700',
                          color: '#6825EC',
                          marginBottom: '4px',
                          display: 'flex',
                          alignItems: 'baseline',
                          gap: '4px'
                        }}>
                          {cluster.serverCount}
                          <span style={{ fontSize: '14px', color: '#64748B', fontWeight: '500' }}>total</span>
                        </div>
                        <div style={{
                          fontSize: '14px',
                          color: '#64748B',
                          fontWeight: '500'
                        }}>
                          Anomalous Servers
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="sidechart2" style={{ 
            flex: '1',
            minWidth: '320px'
          }}>
            <div className="graph-details-container" style={{
              borderRadius: '16px',
              padding: '24px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div className="graph-section" style={{ flex: '1' }}>
                <div className="graph-header" style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '24px',
                  flexWrap: 'wrap',
                  gap: '16px'
                }}>
                  <h3 style={{ 
                    fontSize: '20px', 
                    fontWeight: '600', 
                    color: '#1b2559',
                    margin: '0',
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px'
                  }}>
                    <div style={{
                      width: '32px',
                      height: '32px',
                      borderRadius: '8px',
                      background: 'linear-gradient(135deg, #6825EC, #4A378C)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 13.5L7.5 18L12 13.5M3 6.5L7.5 11L12 6.5M13 17H21M13 7H21M13 12H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                    Metric Analysis
                  </h3>
                  <div className="time-range" style={{
                    backgroundColor: '#F4F7FE',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    fontSize: '13px',
                    color: '#1b2559',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    whiteSpace: 'nowrap'
                  }}>
                    {selectedRow && (
                      <>
                        <span className="detected-time">Detected: {formatDateTime(selectedRow.detectedTime)}</span>
                        <span style={{ 
                          width: '4px',
                          height: '4px',
                          borderRadius: '50%',
                          backgroundColor: '#6825EC',
                          opacity: 0.5
                        }}></span>
                        <span className="predicted-window">{formatPredictionWindow(selectedRow)}</span>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ 
                  flex: '1',
                  position: 'relative',
                  minHeight: '300px',
                  maxHeight: '400px'
                }}>
                  {selectedRow ? (
                    <DetectGraph
                      dataSource={selectedRow?.dataSource || ''}
                      server={selectedRow?.server || ''}
                      startDate={selectedRow?.detectedTime || ''}
                      endDate={selectedRow?.predictedTime || ''}
                      metricName={selectedRow?.metric || ''}
                      metricName2={selectedRow?.metric || ''}
                      label1={selectedRow?.metric || 'Metric'}
                      label2="Anomaly"
                      borderColor1="#6825EC"
                      backgroundColor1="rgba(104, 37, 236, 0.1)"
                      predictionWindow={{
                        start: new Date(new Date(selectedRow.predictedTime).getTime() - 30 * 60000),
                        center: new Date(selectedRow.predictedTime),
                        end: new Date(new Date(selectedRow.predictedTime).getTime() + 30 * 60000),
                        color: 'rgba(104, 37, 236, 0.1)',
                        borderColor: '#6825EC'
                      }}
                    />
                  ) : (
                    <div className="no-data-graph" style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#64748B',
                      gap: '12px'
                    }}>
                      <FaChartLine size={24} />
                      <p style={{ margin: 0 }}>Select a row to view the graph</p>
                    </div>
                  )}
                </div>

                {/* Details Section */}
                {selectedRow && (
                  <div className="details-section" style={{ 
                    marginTop: '24px',
                    padding: '24px',
                    borderTop: '1px solid rgba(104, 37, 236, 0.08)',
                    background: 'linear-gradient(135deg, rgba(104, 37, 236, 0.02), rgba(104, 37, 236, 0.01))',
                    borderRadius: '12px'
                  }}>
                    <div className="details-header" style={{ 
                      marginBottom: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px'
                    }}>
                      <div style={{
                        width: '28px',
                        height: '28px',
                        borderRadius: '8px',
                        background: 'linear-gradient(135deg, #6825EC, #4A378C)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <h3 style={{ 
                        fontSize: '18px', 
                        fontWeight: '600', 
                        color: '#1b2559',
                        margin: '0'
                      }}>Details</h3>
                    </div>
                    
                    <div className="details-grid" style={{ 
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                      gap: '16px'
                    }}>
                      <div className="detail-item" style={{
                        background: 'white',
                        padding: '16px',
                        borderRadius: '10px',
                        border: '1px solid rgba(104, 37, 236, 0.08)'
                      }}>
                        <span className="detail-label" style={{
                          display: 'block',
                          fontSize: '13px',
                          color: '#64748B',
                          marginBottom: '4px'
                        }}>DataSource</span>
                        <span className="detail-value" style={{
                          fontSize: '15px',
                          color: '#1b2559',
                          fontWeight: '500'
                        }}>{selectedRow.dataSource}</span>
                      </div>
                      <div className="detail-item" style={{
                        background: 'white',
                        padding: '16px',
                        borderRadius: '10px',
                        border: '1px solid rgba(104, 37, 236, 0.08)'
                      }}>
                        <span className="detail-label" style={{
                          display: 'block',
                          fontSize: '13px',
                          color: '#64748B',
                          marginBottom: '4px'
                        }}>Server</span>
                        <span className="detail-value" style={{
                          fontSize: '15px',
                          color: '#1b2559',
                          fontWeight: '500'
                        }}>{selectedRow.server}</span>
                      </div>
                      <div className="detail-item" style={{
                        background: 'white',
                        padding: '16px',
                        borderRadius: '10px',
                        border: '1px solid rgba(104, 37, 236, 0.08)'
                      }}>
                        <span className="detail-label" style={{
                          display: 'block',
                          fontSize: '13px',
                          color: '#64748B',
                          marginBottom: '4px'
                        }}>Metric</span>
                        <span className="detail-value" style={{
                          fontSize: '15px',
                          color: '#1b2559',
                          fontWeight: '500'
                        }}>{formatMetricName(selectedRow.metric)}</span>
                      </div>
                      <div className="detail-item" style={{
                        background: 'white',
                        padding: '16px',
                        borderRadius: '10px',
                        border: '1px solid rgba(104, 37, 236, 0.08)'
                      }}>
                        <span className="detail-label" style={{
                          display: 'block',
                          fontSize: '13px',
                          color: '#64748B',
                          marginBottom: '4px'
                        }}>Time Remaining</span>
                        <span className={`detail-value ${selectedRow.timeRemaining === 'Expired' ? 'expired' : ''}`} style={{
                          fontSize: '15px',
                          color: selectedRow.timeRemaining === 'Expired' ? '#EF4444' : '#10B981',
                          fontWeight: '500'
                        }}>{selectedRow.timeRemaining}</span>
                      </div>
                      <div className="detail-item" style={{
                        background: 'white',
                        padding: '16px',
                        borderRadius: '10px',
                        border: '1px solid rgba(104, 37, 236, 0.08)'
                      }}>
                        <span className="detail-label" style={{
                          display: 'block',
                          fontSize: '13px',
                          color: '#64748B',
                          marginBottom: '4px'
                        }}>Detected Time</span>
                        <span className="detail-value" style={{
                          fontSize: '15px',
                          color: '#1b2559',
                          fontWeight: '500'
                        }}>{formatDateTime(selectedRow.detectedTime)}</span>
                      </div>
                      <div className="detail-item" style={{
                        background: 'white',
                        padding: '16px',
                        borderRadius: '10px',
                        border: '1px solid rgba(104, 37, 236, 0.08)'
                      }}>
                        <span className="detail-label" style={{
                          display: 'block',
                          fontSize: '13px',
                          color: '#64748B',
                          marginBottom: '4px'
                        }}>Predicted Time</span>
                        <span className="detail-value" style={{
                          fontSize: '15px',
                          color: '#1b2559',
                          fontWeight: '500'
                        }}>{formatDateTime(selectedRow.predictedTime)}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="tab-container">
        <button
          className={`tab-button ${activeTab === 'Predicted' ? 'active' : ''}`}
          onClick={() => setActiveTab('Predicted')}
        >
          Predictions
        </button>
        <button
          className={`tab-button ${activeTab === 'History' ? 'active' : ''}`}
          onClick={() => setActiveTab('History')}
        >
          History
        </button>
      </div>

      <div style={{
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(104, 37, 236, 0.1)',
        overflow: 'hidden',
        width: '100%'
      }}>
        {loading ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px',
            color: '#6B7280'
          }}>
            <FaSyncAlt className="spinning" style={{ marginRight: '12px' }} />
            Loading...
          </div>
        ) : error ? (
          <div style={{
            padding: '20px',
            color: '#EF4444',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
            <FaExclamationTriangle />
            Error: {error}
          </div>
        ) : activeTab === 'Predicted' ? (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '40px',
            color: '#6B7280',
            fontSize: '16px',
            textAlign: 'center'
          }}>
            All systems are running smoothly. No anomalies detected.
          </div>
        ) : (
          <>
            <div style={{
              display: 'grid',
              gridTemplateColumns: columns,
              background: '#6825EC',
              color: '#fff',
              fontWeight: '500',
              fontSize: '14px',
              padding: '14px 16px',
              width: '100%',
              minWidth: 'fit-content'
            }}>
              {columnConfig.map(({ key, label }) => (
                <div
                  key={key}
                  onClick={() => handleSort(key)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    userSelect: 'none',
                    padding: '0 8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {label}
                  <SortIcon columnKey={key} currentSortColumn={sortColumn} currentSortDirection={sortDirection} />
                </div>
              ))}
            </div>

            <List
              height={Math.min(filteredData.length * 45 + 20, 400)}
              itemCount={filteredData.length}
              itemSize={45}
              width="100%"
              style={{ overflowX: 'hidden' }}
              itemData={{
                anomalies: filteredData,
                columns,
                selectedRow,
                onSelectRow: setSelectedRow,
                onShowDetails: (anomaly) => alert(`Details for ${anomaly.server}`)
              }}
            >
              {Row}
            </List>
          </>
        )}
      </div>
    </div>
  );
};

export default EnviDetect;
