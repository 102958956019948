import React from 'react';
import { Handle, Position } from 'reactflow';

export const StartNode = ({ data }) => (
  <div className="custom-node start-node">
    <Handle type="source" position={Position.Bottom} />
    <div className="node-content">
      <div className="node-icon">▶️</div>
      <div className="node-label">{data.label}</div>
    </div>
  </div>
);

export const SshNode = ({ data }) => (
  <div className="custom-node ssh-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-terminal"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const TelnetNode = ({ data }) => (
  <div className="custom-node telnet-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-terminal-plus"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const FtpUploadNode = ({ data }) => (
  <div className="custom-node ftp-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-upload"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const FtpDownloadNode = ({ data }) => (
  <div className="custom-node ftp-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-download"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const ServerActionNode = ({ data }) => (
  <div className="custom-node server-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-pc-display"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const DatabaseQueryNode = ({ data }) => (
  <div className="custom-node database-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-database"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const DatabaseBackupNode = ({ data }) => (
  <div className="custom-node database-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-database-fill-check"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const NetworkScanNode = ({ data }) => (
  <div className="custom-node network-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-radar"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const NetworkMonitorNode = ({ data }) => (
  <div className="custom-node network-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-graph-up"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const CompressNode = ({ data }) => (
  <div className="custom-node compress-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-file-zip"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const TerminateNode = ({ data }) => (
  <div className="custom-node terminate-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon">⏹️</div>
      <div className="node-label">{data.label}</div>
    </div>
  </div>
);

export const ScpNode = ({ data }) => (
  <div className="custom-node scp-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-shield-lock"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const TlsNode = ({ data }) => (
  <div className="custom-node tls-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-shield-check"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const KillSqlNode = ({ data }) => (
  <div className="custom-node kill-sql-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-stop-circle"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const TablesNode = ({ data }) => (
  <div className="custom-node tables-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-table"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const NetworkSecurityNode = ({ data }) => (
  <div className="custom-node network-security-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-shield-lock-fill"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

export const NetworkDiagnosticsNode = ({ data }) => (
  <div className="custom-node network-diagnostics-node">
    <Handle type="target" position={Position.Top} />
    <div className="node-content">
      <div className="node-icon"><i className="bi bi-tools"></i></div>
      <div className="node-label">{data.label}</div>
    </div>
    <Handle type="source" position={Position.Bottom} />
  </div>
); 