import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { formatDateTime, convertToIST, getCondensedData } from './utils'; // Assuming you have utility functions in a utils.js file
import { Chart, registerables } from 'chart.js';
// Register Chart.js components
Chart.register(...registerables);
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const yScale = chart.scales.y;

            if (!yScale || typeof yScale.top === 'undefined') {
                console.error('yScale or yScale.top is undefined');
                return;
            }

            const topY = yScale.top;
            const bottomY = yScale.bottom;
            const label = chart.data.labels[activePoint.index];

            // Draw vertical line with gradient
            const gradient = ctx.createLinearGradient(0, topY, 0, bottomY);
            gradient.addColorStop(0, 'rgba(76, 107, 245, 0.1)');
            gradient.addColorStop(1, 'rgba(76, 107, 245, 0.4)');

            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = gradient;
            ctx.stroke();

            // Draw timestamp label with enhanced styling
            const labelX = x;
            const labelY = bottomY + 20;
            
            // Draw label background
            ctx.fillStyle = 'rgba(255, 255, 255, 0.95)';
            const padding = 8;
            const labelWidth = ctx.measureText(label).width + padding * 2;
            ctx.fillRect(labelX - labelWidth/2, labelY - 15, labelWidth, 30);
            
            // Add subtle shadow
            ctx.shadowColor = 'rgba(0, 0, 0, 0.1)';
            ctx.shadowBlur = 4;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 2;
            
            // Draw text
            ctx.fillStyle = '#2B3674';
            ctx.font = '12px system-ui, -apple-system, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(label, x, labelY);
            
            ctx.restore();
        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps, data, label, borderColor, backgroundColor) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, backgroundColor);
    gradient.addColorStop(0.2, backgroundColor.replace('rgba(', 'rgba(').replace(/,[^,]*\)/, ', 0.6)'));
    gradient.addColorStop(0.8, backgroundColor.replace('rgba(', 'rgba(').replace(/,[^,]*\)/, ', 0.1)'));
    gradient.addColorStop(1, backgroundColor.replace('rgba(', 'rgba(').replace(/,[^,]*\)/, ', 0)'));

    const lastIndex = data.length - 1;
    return {
        labels: timestamps.slice(-30).map(convertToIST),
        datasets: [
            {
                label: label,
                data: data.slice(-30),
                fill: true,
                borderColor: borderColor,
                backgroundColor: gradient,
                tension: 0.4,
                pointBorderWidth: (context) => {
                    return context.dataIndex === lastIndex ? 3 : 0;
                },
                pointRadius: (context) => {
                    return context.dataIndex === lastIndex ? 6 : 0;
                },
                borderWidth: 2.5,
                pointBackgroundColor: borderColor,
                pointStyle: 'circle',
                pointHoverRadius: 8,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: borderColor,
                pointShadowOffsetX: 2,
                pointShadowOffsetY: 2,
                pointShadowBlur: 5,
                pointShadowColor: 'rgba(0, 0, 0, 0.3)',
            },
        ],
    };
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        duration: 750,
        easing: 'easeInOutQuart'
    },
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: false
            },
            ticks: {
                autoSkip: true,
                maxRotation: 45,
                minRotation: 0,
                maxTicksLimit: 7,
                padding: 10,
                font: {
                    family: 'system-ui, -apple-system, sans-serif',
                    size: 11
                },
                color: '#666',
                callback: function (value) {
                    let label = this.getLabelForValue(value);
                    if (label.length > 5) {
                        label = label.substring(5);
                    }
                    return label;
                }
            },
            title: {
                display: true,
                font: {
                    size: 12,
                    weight: 'bold'
                }
            },
        },
        y: {
            grid: {
                display: true,
                color: 'rgba(0, 0, 0, 0.05)',
                drawBorder: false
            },
            beginAtZero: true,
            ticks: {
                padding: 10,
                font: {
                    family: 'system-ui, -apple-system, sans-serif',
                    size: 11
                },
                color: '#666'
            },
            title: {
                display: true,
                text: 'Value',
                font: {
                    size: 12,
                    weight: 'bold'
                }
            },
        },
    },
    plugins: {
        legend: {
            display: false,
            position: 'top',
            labels: {
                boxWidth: 0,
                font: {
                    family: 'system-ui, -apple-system, sans-serif',
                    size: 12
                }
            }
        },
        tooltip: {
            enabled: true,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            titleFont: {
                family: 'system-ui, -apple-system, sans-serif',
                size: 12,
                weight: 'bold'
            },
            bodyFont: {
                family: 'system-ui, -apple-system, sans-serif',
                size: 11
            },
            padding: 12,
            cornerRadius: 6,
            displayColors: false,
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.raw.toFixed(2);
                },
            },
            animation: {
                duration: 150
            }
        },
        verticalLinePlugin: true,
    },
    hover: {
        mode: 'index',
        intersect: false,
        animationDuration: 150
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: function (context) {
                    const value = typeof context.raw === 'number' ? context.raw.toFixed(2) : context.raw;
                    return context.dataset.label + ': ' + value;
                }
            }
        }
    },
    transitions: {
        active: {
            animation: {
                duration: 150
            }
        }
    }
};




const Graphnew = ({ dataSource, label, borderColor, backgroundColor, updateClusterScore }) => {
    const [timestamps, setTimestamps] = useState([]);
    const [metricData, setMetricData] = useState([]);
    const chartRef = useRef(null);

    const fetchAggregateData = useCallback(async (dataSource) => {

        const url = `https://infia.enviseer.com/api/healthscores?cluster=${dataSource}`;
         function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
         const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data)) {
            const timestamps = data.map(entry => entry.timestamp);
            const values = data.map(entry => entry.value);
            return { timestamps, values };
        } else {
            return { timestamps: [], values: [] };
        }
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAggregateData(dataSource);
                setTimestamps(data.timestamps);
                setMetricData(data.values.map(value => parseFloat(value).toFixed(2)));
                updateClusterScore(data.values[data.values.length - 1].toFixed(2),data.values[data.values.length - 2].toFixed(2));
            } catch (error) {
                console.error(`Error fetching data:`, error);
                setTimestamps([]);
                setMetricData([]);
                updateClusterScore(0);
            }
        };

        fetchData();
    }, [dataSource]);

    const { condensedTimestamps, condensedMetricData } = getCondensedData(timestamps, metricData);
    const chartData = generateChartData(condensedTimestamps, condensedMetricData, label, borderColor, backgroundColor);

    if (!condensedMetricData || condensedMetricData.length === 0) {
        //console.log('No valid data to plot');
        return <p style={{fontSize:'12px',color:'grey'}}>No data available to plot the graph.</p>; // Return null or some fallback UI if there is no valid data to plot
      }
    return <Line data={chartData} options={chartOptions} ref={chartRef} />;
};

export default Graphnew;


