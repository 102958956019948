import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Dropdownnew.css';
import Syslogtest from './Syslogtest';
import React, { useState, useEffect, useCallback } from 'react';
import { convertToIST } from './features/utils.js';
import { formatDateTime } from './features/utils.js';
import CustomDropdown from './CustomDropdown';

function Logs({ sidebarOpen, onLogout }) {
    const localDataSource = localStorage.getItem('DataSource') || '100';
    const localServer = localStorage.getItem('Server') || 'infia';
    const localTimestamp = localStorage.getItem('Timestamp') || '';
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataSources, setDataSources] = useState(['100']); // Set default data source
    const [servers, setServers] = useState(['infia']); // Set default server
    const [selectedDataSource, setSelectedDataSource] = useState(localDataSource || '100');
    const [selectedServer, setSelectedServer] = useState(localServer || 'infia');
    const [time, setTime] = useState(convertTimestamp(localTimestamp));
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [lastDuration, setLastDuration] = useState('1 hour');
    const [selectedOptions, setSelectedOptions] = useState(
        Array(8).fill('option1') // Assuming 'option1' is the default option
    );
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedSysApp, setSelectedSysApp] = useState('');
    const [selectedEnvironment, setSelectedEnvironment] = useState('Dev');
    const [logType, setLogType] = useState('all');
    //const [logFileType, setLogFileType] = useState('authlog');

    function convertTimestamp(input) {
        // Get current date components
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth(); // Months are zero-indexed (0-11)
        const currentDay = now.getDate();

        // Split input into time and date components
        const [time, date] = input.split(' ');
        const [hours, minutes] = time.split(':').map(Number);

        // If date is provided, use it; otherwise, use the current date
        let day, month;
        if (date) {
            [day, month] = date.split('/').map(Number);
        } else {
            day = currentDay;
            month = currentMonth + 1; // Adjust for zero-indexed month
        }

        // Create a Date object using the specified or current date
        let dateObject = new Date(currentYear, month - 1, day, hours, minutes);

        // Convert the Date object into the desired string format
        const formattedDate = dateObject.toString();
        const result = `${formattedDate}`;

        return result;
    }

    useEffect(() => {
        if (localTimestamp) {
            // Convert the localTimestamp to a Date object
            const timestampDate = new Date(convertTimestamp(localTimestamp));

            // Calculate startTime (1 minute before) and endTime (1 minute after)
            const oneMinuteBefore = new Date(timestampDate.getTime() - 2 * 60000);
            const oneMinuteAfter = new Date(timestampDate.getTime() + 2 * 60000);
            setLastDuration('custom');
            setStartTime(oneMinuteBefore);
            setEndTime(oneMinuteAfter);
        } else {
            // Default behavior: set current time and 60 minutes ago
            const currentTime = new Date();
            const sixtyMinutesAgo = new Date(currentTime.getTime() - 60 * 60000);
            setLastDuration('1 hour')
            setEndTime(currentTime);
            setStartTime(sixtyMinutesAgo);
        }
    }, []);
    useEffect(() => {
        fetchClusters();
        localStorage.removeItem('DataSource');
        localStorage.removeItem('Server');
        localStorage.removeItem('Timestamp');
    }, [selectedDataSource]);

    const fetchClusters = useCallback(async () => {

        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        const username = getCookie('username');
        const email = decodeURIComponent(username);
        const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
        //console.log("Cluster", url);
        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            setDataSources(data);
            if (data.length > 0) {
                //     if(selectedDataSource==''){
                //     setSelectedDataSource(data[0]);
                //     fetchServers(data[0]);
                // }
            }
        } catch (error) {
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchServers = useCallback(async (dataSource) => {
        if (!dataSource) return;

        const url = `https://infia.enviseer.com/api/clusters/${dataSource}/hostnames`;
        //console.log(url);
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const token = getCookie('token');
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            setServers(data);
            // Only set selected server if current selection is not in new list
            if (data.length > 0 && !data.includes(selectedServer)) {
                setSelectedServer(data[0]);
            }
        } catch (error) {
            console.error("Failed to fetch servers:", error);
            setError(error.message);
            // Set a default server list if fetch fails
            setServers(['infia']);
            setSelectedServer('infia');
        } finally {
            setLoading(false);
        }
    }, [onLogout, selectedServer]);

    // Add effect to fetch servers when dataSource changes
    useEffect(() => {
        if (selectedDataSource) {
            fetchServers(selectedDataSource);
        }
    }, [selectedDataSource, fetchServers]);

    const handleDataSourceChange = (event) => {
        const selected = event.target.value;
        setSelectedDataSource(selected);
        fetchServers(selected);
    };

    const handleServerChange = (event) => {
        const selected = event.target.value;
        setSelectedServer(selected);
    };

    
const handleStartTimeChange = (selectedStartTime) => {
    const currentTime = new Date();
  
    if (selectedStartTime > currentTime) {
        setErrorMessage('Start time cannot be in the future. Setting start and end times to the current time.');
        setStartTime(currentTime);
        setEndTime(currentTime);
        return;
    }
  
    setStartTime(selectedStartTime);
    
    if (!endTime || selectedStartTime > endTime) {
        setEndTime(selectedStartTime);
    }
  
    setErrorMessage('');
  };
  
  
  
  const handleEndTimeChange = (selectedEndTime) => {
  
    const currentTime = new Date();
  
    if (!startTime) {
      setErrorMessage('Please set a start time first.');
      return;
    }
  
    // Normalize dates for accurate comparison (removes time component)
    const startDateOnly = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
    const endDateOnly = new Date(selectedEndTime.getFullYear(), selectedEndTime.getMonth(), selectedEndTime.getDate());
  
    if (endDateOnly < startDateOnly) {
      setErrorMessage('End date should be later than or equal to the start date.');
      return;
    }
  
    // Same date, check time comparison
    if (endDateOnly.getTime() === startDateOnly.getTime() && selectedEndTime <= startTime) {
      setErrorMessage('End time should be later than start time.');
      return;
    }
  
    if (selectedEndTime > currentTime) {
      setErrorMessage('End time cannot be in the future. Setting end time to the current time.');
      setEndTime(currentTime);
    } else {
      setErrorMessage('');  // Clear any existing error messages
      setEndTime(selectedEndTime);
    }
  };

    const filterPassedTime = (time) => {
        const currentTime = new Date();
        const selectedTime = new Date(time);
        return currentTime.getTime() >= selectedTime.getTime();
    };

    const handleLastDurationChange = (event) => {
        const selectedDuration = event.target.value;
        setLastDuration(selectedDuration);

        const currentTime = new Date();
        let newStartTime = null;
        let newEndTime = currentTime;

        switch (selectedDuration) {
            case '5 minutes':
                newStartTime = new Date(currentTime.getTime() - 5 * 60000);
                break;
            case '10 minutes':
                newStartTime = new Date(currentTime.getTime() - 10 * 60000);
                break;
            case '30 minutes':
                newStartTime = new Date(currentTime.getTime() - 30 * 60000);
                break;
            case '1 hour':
                newStartTime = new Date(currentTime.getTime() - 60 * 60000);
                break;
            case '2 hours':
                newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
                break;
            case '4 hours':
                newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
                break;
            case '6 hours':
                newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
                break;
            case '12 hours':
                newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
                break;
            case '1 day':
                newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
                break;
            case '3 days':
                newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
                break;
            case '1 week':
                newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
                break;
            case 'custom':
                newStartTime = null;
                newEndTime = null;
                break;
            default:
                newStartTime = startTime;
                newEndTime = endTime;
                break;
        }

        setStartTime(newStartTime);
        setEndTime(newEndTime);
    };

    const dismissErrorMessage = () => {
        setErrorMessage('');
    };

    const handleDropdownChange = (index, selectedOption) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = selectedOption;
        setSelectedOptions(newSelectedOptions);
    };

    const updateAnomalyCount = (count) => {
        setAnomalyCount(count);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        //console.log('Searching for:', searchQuery);
    };
    const handleEnvironmentChange = (event) => {
        setSelectedEnvironment(event.target.value);
    };


    const handleLogTypeChange = (type) => {
        setLogType(type);
    };

    const handleclick = () => {
        if (dataSources.length === 0) {
            alert('Please contact your administrator. No clusters available.');
        }
    };



    return (
        <div>
            <div className="dashboard">
                <div className="dropdowns" style={{ display: 'flex', alignItems: 'center', width: sidebarOpen ? '90%' : '96%' }}>
                    <div className="dropdown-contents">
                        <div className="dropdown">
                            <CustomDropdown
                                label="Environment"
                                options={[
                                    { value: 'Dev', label: 'Dev' },
                                    { value: 'QA', label: 'QA' },
                                    { value: 'Prod', label: 'Prod' }
                                ]}
                                value={selectedEnvironment}
                                onChange={(event) => handleEnvironmentChange(event)}
                            />
                        </div>

                        <div className="dropdown">
                            <CustomDropdown
                                label="Cluster"
                                options={Array.isArray(dataSources) && dataSources.map(source => ({ value: source, label: source }))}
                                value={selectedDataSource}
                                onChange={(event) => handleDataSourceChange(event)}
                            />
                        </div>

                        <div className="dropdown">
                            <CustomDropdown
                                label="Servers"
                                options={servers.map(server => ({ value: server, label: server }))}
                                value={selectedServer}
                                onChange={(event) => handleServerChange(event)}
                            />
                        </div>

                        <div className="dropdown">
                            <CustomDropdown
                                label="Duration"
                                options={[
                                    { value: '5 minutes', label: '5 minutes' },
                                    { value: '10 minutes', label: '10 minutes' },
                                    { value: '30 minutes', label: '30 minutes' },
                                    { value: '1 hour', label: '1 hour' },
                                    { value: '2 hours', label: '2 hours' },
                                    { value: '4 hours', label: '4 hours' },
                                    { value: '6 hours', label: '6 hours' },
                                    { value: '12 hours', label: '12 hours' },
                                    { value: '1 day', label: '1 day' },
                                    { value: '3 days', label: '3 days' },
                                    { value: '1 week', label: '1 week' },
                                    { value: 'custom', label: 'custom' }
                                ]}
                                value={lastDuration}
                                onChange={(event) => handleLastDurationChange(event)}
                            />
                        </div>

                        {lastDuration === 'custom' && (
                            <>
                                <div className="dropdown">
                                    <DatePicker
                                        selected={startTime}
                                        onChange={handleStartTimeChange}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        timeFormat="HH:mm:ss"
                                        timeCaption="Time"
                                        placeholderText="Select start time"
                                        className={`react-datepicker__input-container ${startTime ? 'selected' : ''}`}
                                        timeIntervals={5}
                                        maxDate={new Date()}
                                        filterTime={filterPassedTime}
                                        dayClassName={(date) => {
                                            if (date.getTime() > new Date().getTime()) {
                                                return 'react-datepicker__day--disabled';
                                            }
                                            return '';
                                        }}
                                        timeClassName={(time) => {
                                            if (new Date(time).getTime() > new Date().getTime()) {
                                                return 'react-datepicker__time-list-item--disabled';
                                            }
                                            return '';
                                        }}
                                    />
                                </div>

                                <div className="dropdown-container">
                                    <div className="dropdown">
                                        <DatePicker
                                            selected={endTime}
                                            onChange={handleEndTimeChange}
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            timeFormat="HH:mm:ss"
                                            timeCaption="Time"
                                            placeholderText="Select end time"
                                            className={`react-datepicker__input-container ${endTime ? 'selected' : ''}`}
                                            timeIntervals={5}
                                            maxDate={new Date()}
                                            filterTime={filterPassedTime}
                                            dayClassName={(date) => {
                                                if (date.getTime() > new Date().getTime()) {
                                                    return 'react-datepicker__day--disabled';
                                                }
                                                return '';
                                            }}
                                            timeClassName={(time) => {
                                                if (new Date(time).getTime() > new Date().getTime()) {
                                                    return 'react-datepicker__time-list-item--disabled';
                                                }
                                                return '';
                                            }}
                                        />
                                    </div>

                                    {errorMessage && (
                                        <div className="error-message-container2">
                                            <p>
                                                <i className="bi bi-exclamation-circle-fill" style={{ marginRight: '8px' }}></i>
                                                {errorMessage}
                                                <button onClick={dismissErrorMessage} style={{ background: 'none', border: 'none', color: '#B71C1C', cursor: 'pointer', marginLeft: '8px' }}>
                                                    <i className="bi bi-x-circle-fill"></i>
                                                </button>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {selectedDataSource && selectedServer && selectedEnvironment && startTime && endTime && (
                    <Syslogtest
                        searchQuery={searchQuery}
                        dataSource={selectedDataSource}
                        host={selectedServer}
                        start={formatDateTime(startTime)}
                        end={formatDateTime(endTime)}
                        logType={logType}
                        environment={selectedEnvironment}
                        onZoom={(start, end) => {
                            setStartTime(start);
                            setEndTime(end);
                        }}
                        onLogTypeChange={handleLogTypeChange}
                        onSearchChange={handleSearchChange}
                    />
                )}
            </div>
        </div>
    );
}

export default Logs;
