import React, { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import dummyIncidentData from "./data/dummyIncidentData";
import './RootCauseAnalysis.css';
import { FaSearch, FaExclamationTriangle, FaWrench, FaChartLine, FaTimesCircle, FaInfoCircle, FaClock } from 'react-icons/fa';
import { BiAnalyse, BiErrorCircle } from 'react-icons/bi';
import { MdOutlineRecommend } from 'react-icons/md';

const RootCauseAnalysis = ({ isOpen, onClose, alertId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Generate fallback data customized for the current alert
  const generateFallbackData = (alert) => {
    if (!alert) return null;
    
    const formattedDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    
    const metricName = alert.metric || 'Resource';
    const serverName = alert.host || 'Server';
    const triggeredTime = alert.triggeredAt || new Date().toISOString();
    const formattedValue = (Math.random() * 30 + 70).toFixed(1); // Random value between 70-100
    
    return {
      root_cause: dummyIncidentData.rootCause
        .replace("High CPU Usage Anomaly", `High ${metricName} Anomaly`)
        .replace("March 26, 2025", formattedDate)
        .replace("envi-iam", serverName)
        .replace("24.7%", `${formattedValue}%`)
        .replace("18.1% to 24.7%", `60% to 80%`),
        
      impact_assessment: dummyIncidentData.analysis
        .replace("CPU usage", `${metricName} usage`)
        .replace("envi-iam", serverName),
        
      recommended_actions: [
        `Scale up resources for the ${serverName} service to handle increased ${metricName.toLowerCase()} load`,
        `Implement more aggressive caching for frequently accessed data`,
        `Optimize database queries to reduce ${metricName.toLowerCase()} consumption`,
        `Set up auto-scaling policies based on ${metricName.toLowerCase()} metrics`
      ],
      
      related_metrics: [
        { name: "Memory Usage", value: (Math.random() * 20 + 60).toFixed(1), unit: "%" },
        { name: "Disk I/O", value: (Math.random() * 500 + 100).toFixed(0), unit: "ops/sec" },
        { name: "Network Traffic", value: (Math.random() * 50 + 10).toFixed(1), unit: "MB/s" },
        { name: "Response Time", value: (Math.random() * 400 + 100).toFixed(0), unit: "ms" }
      ],
      
      timestamp: triggeredTime
    };
  };

  useEffect(() => {
    if (isOpen && alertId) {
      // handleMockResponse(); // Uncomment to test with mock data
      fetchRCAData();
    }
  }, [isOpen, alertId]);

  const fetchRCAData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Get the authentication token
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      const token = getCookie('token');
      
      const apiUrl = process.env.REACT_APP_API_URL || 'https://infia.enviseer.com';
      const response = await fetch(`${apiUrl}/api/alerts/rca`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          alert_id: alertId.id,
          host: alertId.host,
          cluster: alertId.cluster
        })
      });
      
      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }
      
      const responseData = await response.json();
      
      console.log("RCA API Response:", responseData);
      
      if (responseData && responseData[0]?.rca_data) {
        // Clean up the rca_data
        let cleanedRcaData = responseData[0].rca_data;
        
        // Remove conclusion and everything after it
        cleanedRcaData = cleanedRcaData
          .split(/This concludes the (?:Root Cause Analysis \(RCA\)|Envi Incident Analysis) [Rr]eport/)[0]
          .trim();

        // Remove common closing patterns
        cleanedRcaData = cleanedRcaData
          // Remove any variations of "Feel free to provide..."
          .replace(/Feel free to provide.*$/s, '')
          // Remove any variations of "Best regards" and following content
          .replace(/\n*Best regards,\s*(?:\n.*)*$/s, '')
          // Remove any variations of "If you need further assistance..."
          .replace(/\n*If you need (?:further|any) (?:assistance|help).*$/s, '')
          // Remove any variations of "If further analysis is required..."
          .replace(/\n*If (?:further|additional) analysis is required.*$/s, '')
          // Remove any variations of "Thank you for your attention..."
          .replace(/\n*Thank you for your attention.*$/s, '')
          // Remove any contact information blocks
          .replace(/\n*\[(?:Your Name|Contact Information|Date)\].*$/s, '')
          // Remove "Would you like me to provide..."
          .replace(/\n*Would you like me to provide.*$/s, '')
          // Remove any variations of "Please let me know..."
          .replace(/\n*[Pp]lease let me know.*$/s, '')
          // Replace multiple dashes with line breaks
          .replace(/\n---\n/g, '\n\n')
          // Remove any trailing dashes
          .replace(/---\s*$/, '')
          // Clean up extra whitespace and newlines
          .replace(/\n{3,}/g, '\n\n')
          .trim();

        // Parse the content into sections
        const sections = cleanedRcaData
          .split(/(?=# |## |### )/)
          .filter(section => section.trim())
          .map(section => {
            const lines = section.trim().split('\n');
            const titleMatch = lines[0].match(/^(#{1,3}) (.*)/);
            if (titleMatch) {
              return {
                level: titleMatch[1].length,
                title: titleMatch[2].replace(/\*\*/g, '').trim(),
                content: lines.slice(1).join('\n')
                  .replace(/\*\*/g, '')
                  .replace(/- /g, '• ')
                  .trim()
              };
            }
            return null;
          })
          .filter(Boolean);
        
        console.log("Parsed sections:", sections);
        
        setData({
          ...responseData[0],
          sections
        });
      } else if (!responseData || Object.keys(responseData).length === 0) {
        console.log("API returned empty data");
        setData(null);
      } else {
        setData(responseData);
      }
    } catch (err) {
      console.error("Error fetching RCA data:", err);
      setError("An error occurred while fetching RCA data. Please try again later.");
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to parse RCA content into structured format
  const parseRcaContent = (content) => {
    if (!content) return null;

    // First clean up the repetitive sections at the end
    content = content.replace(/(\n---\n\n.*?let me know\..*$)/s, '');
    
    const sections = {};

    // Extract the main sections using markdown headers
    const mainContentMatch = content.match(/# Envi Incident Analysis Report(.*?)(?=\n---\n|$)/s);
    if (mainContentMatch) {
      const mainContent = mainContentMatch[1];

      // Extract Root Cause
      const rootCauseMatch = mainContent.match(/## Envi Incident Root Cause(.*?)(?=##|$)/s);
      if (rootCauseMatch) {
        sections.rootCause = rootCauseMatch[1]
          .replace(/- \*\*(Root Cause|Explanation|Evidence):\*\* /g, '')
          .replace(/\n- /g, '\n')
          .trim();
      }

      // Extract Analysis/Impact
      const analysisMatch = mainContent.match(/## Envi Analysis(.*?)(?=##|$)/s);
      if (analysisMatch) {
        const analysisContent = analysisMatch[1]
          .replace(/- \*\*.*?:\*\* /g, '')
          .replace(/\n- /g, '\n')
          .trim();
        sections.impactAssessment = `<p>${analysisContent}</p>`;
      }

      // Extract Remediation Steps
      const remediationMatch = mainContent.match(/\*\*Remediation Steps:\*\*(.*?)(?=\*\*Further Investigation:|$)/s);
      if (remediationMatch) {
        const steps = remediationMatch[1]
          .split(/\n\s*-\s*/)
          .filter(step => step.trim())
          .map(step => step.replace(/\*\*.*?:\*\* /, '').trim());

        if (steps.length > 0) {
          sections.recommendedActions = '<ul>' + 
            steps.map(step => `<li>${step}</li>`).join('') + 
            '</ul>';
        }
      }
    } else {
      // If no markdown structure, just use the content as root cause
      sections.rootCause = content.trim();
    }

    return sections;
  };

  // Add example mock handler after parseRcaContent function
  // Example response handler with mock data
  /*
  const handleMockResponse = () => {
    // Example response with rca_data field
    const mockResponse = {
      "id": "95c4c69a-81ed-4d66-9efb-cc723224c2c1",
      "alert_id": "941ad4fd-df78-413e-8caf-94730297070",
      "cluster": "Rezsap",
      "host": "RDPSRV2022",
      "anomaly_metric": "memory_usage",
      "rca_data": "Based on the provided correlated input data, here is a structured report analyzing the memory usage anomaly:\n\nRoot Cause Analysis:\nThe memory usage spike observed on server RDPSRV2022 is primarily due to a Java application memory leak in the authentication service. The process 'auth-service.jar' has been gradually accumulating memory without proper garbage collection, reaching 87% of the server's total available memory. This is significantly above the normal operating threshold of 45-60%.\n\nImpact Assessment:\nThe elevated memory consumption has led to:\n1. Increased response times for authentication requests (up from 120ms to 380ms)\n2. Occasional timeouts for some client applications connecting to the service\n3. Higher overall system load as the OS attempts to manage the constrained memory resources\n4. Risk of service disruption if memory continues to increase\n\nRecommended Actions:\n1. Restart the Java authentication service to immediately reclaim memory\n2. Update the JVM parameters to include proper garbage collection settings: -XX:+UseG1GC -XX:MaxGCPauseMillis=200\n3. Apply the latest patch (v2.4.7) which addresses the known memory leak in the connection pooling component\n4. Increase monitoring frequency for this service and set up alerting at 75% memory usage\n5. Schedule regular restart of this service during low-traffic periods until the patch is fully tested\n\nThank you. \n\n---\n\nIf you need further details or adjustments, please let me know.",
      "created_at": "2025-03-27T13:36:23Z"
    };

    // Process the response
    if (mockResponse && mockResponse.rca_data) {
      // Clean up the rca_data by removing the unnecessary text at the end
      let cleanedRcaData = mockResponse.rca_data;
      cleanedRcaData = cleanedRcaData.replace(/\n\nThank you\.?\s*\n\n---\n\nIf you need further details or adjustments, please let me know\.?/g, '');
      
      // Parse the cleaned RCA data to extract structured information
      const parsedData = parseRcaContent(cleanedRcaData);
      
      console.log("Parsed RCA data:", parsedData);
      
      setData({
        ...mockResponse,
        rca_data: cleanedRcaData,
        parsed: parsedData
      });
    }
  };
  */

  if (!isOpen) return null;

  return (
    <div className="rca-modal-overlay" onClick={onClose}>
      <div className="rca-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="rca-modal-header">
          <button className="rca-modal-close" onClick={onClose}>
            <FaTimesCircle size={20} />
          </button>
          <div className="rca-header-content">
            <BiAnalyse size={28} />
            <div className="rca-header-text">
              <h3>Root Cause Analysis</h3>
              {data?.host && <span className="rca-host">{data.host}</span>}
            </div>
          </div>
        </div>
        <div className="rca-modal-body">
          {console.log("Current data state:", data)}
          {loading ? (
            <div className="rca-loading">
              <div className="rca-spinner"></div>
              <p>Analyzing root cause...</p>
              <span className="rca-loading-subtitle">This may take a few moments</span>
            </div>
          ) : error ? (
            <div className="rca-error">
              <BiErrorCircle size={40} />
              <p>{error}</p>
              <button className="rca-retry-button" onClick={fetchRCAData}>
                Try Again
              </button>
            </div>
          ) : !data ? (
            <div className="rca-empty">
              <FaSearch size={40} />
              <p>No root cause analysis data available</p>
              <span className="rca-empty-subtitle">
                Analysis will be performed when sufficient data is collected
              </span>
            </div>
          ) : (
            <div className="rca-sections">
              {data.sections?.map((section, index) => (
                <div 
                  key={index} 
                  className={`rca-section level-${section.level}`}
                >
                  {section.level === 1 ? (
                    <h2>{section.title}</h2>
                  ) : (
                    <div className="rca-section-title">
                      {section.level === 2 && <BiAnalyse size={20} />}
                      {section.level === 3 && <FaInfoCircle size={18} />}
                      <h3>{section.title}</h3>
                    </div>
                  )}
                  <div className="rca-section-content">
                    {section.content.split('\n').map((paragraph, idx) => (
                      paragraph.startsWith('•') ? (
                        <ul key={idx} className="rca-bullet-list">
                          <li>{paragraph.substring(2)}</li>
                        </ul>
                      ) : (
                        <p key={idx}>{paragraph}</p>
                      )
                    ))}
                  </div>
                </div>
              ))}
              
              <div className="rca-section">
                <div className="rca-section-title">
                  <FaChartLine size={18} />
                  <h3>Related Metrics</h3>
                </div>
                <div className="rca-section-content">
                  <ul className="rca-metrics-list">
                    {data.anomaly_metric && (
                      <li>
                        <strong>Anomaly Metric</strong>
                        <span>{data.anomaly_metric}</span>
                      </li>
                    )}
                    <li>
                      <strong>Host</strong>
                      <span>{data.host || 'N/A'}</span>
                    </li>
                    <li>
                      <strong>Cluster</strong>
                      <span>{data.cluster || 'N/A'}</span>
                    </li>
                    {data.created_at && (
                      <li>
                        <strong>Analysis Time</strong>
                        <span>
                          {formatDistanceToNow(new Date(data.created_at), {
                            addSuffix: true,
                          })}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RootCauseAnalysis; 