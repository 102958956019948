import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Dropdownnew.css';
import './Scores.css';
import './Graph.css';
import './Health.css';
import Graph from './features/Graph';
import Graphnew from './features/Graphnew';
import LandingGraph from './features/LandingGraph';
import { formatDateTime } from './features/utils';
import GraphOptionsDropdown from './features/GraphOptionsDropdown';
import CustomDropdown from './CustomDropdown';
import BarChart from './features/BarChart';


function Landing({ sidebarOpen, onSelect, onLogout }) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataSources, setDataSources] = useState([]);
    const [dataSources2, setDataSources2] = useState([]);
    const [servers, setServers] = useState([]);
    const [selectedDataSource, setSelectedDataSource] = useState('');
    const [selectedServer, setSelectedServer] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [serverCount, setServerCount] = useState(0);
    const [lastDuration, setLastDuration] = useState('6 hours');
    const [healthScore, setHealthScore] = useState([]);
    const [clusterScore, setClusterScore] = useState([0, 0]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [tooltipVisible, setTooltipVisible] = useState('');
    const [tooltipPosition, setTooltipPosition] = React.useState({ top: 0, left: 0 });
    const [appSortOrder, setAppSortOrder] = useState('desc');
    const [hostSortOrder, setHostSortOrder] = useState('desc');
    const [activeFilter, setActiveFilter] = useState('ALL');
    const [activeAppFilter, setActiveAppFilter] = useState('ALL');
    const [showAppFilters, setShowAppFilters] = useState(false);
    const [showHostFilters, setShowHostFilters] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const minSwipeDistance = 50;


    // Retrieves authentication token from cookies.
    // If the token is missing, clears local storage and logs out.
    // Sets Duration to the last 6 hours.

    useEffect(() => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        const token = getCookie('token');
        if (!token) {
            localStorage.clear();
            onLogout();
        }
        const currentTime = new Date();
        const SixtyMinutesAgo = new Date(currentTime.getTime() - 6 * 60 * 60000);
        setEndTime(currentTime);
        setStartTime(SixtyMinutesAgo);
    }, []);

    useEffect(() => {
        fetchClusters();
        fetchClusterWithValue();
    }, []);

    // Fetches clusters using an API call.

    const fetchClusters = useCallback(async () => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        // Retrieves token and username from cookies.

        const token = getCookie('token');
        const username = getCookie('username');
        const email = decodeURIComponent(username);

        // Constructs API URL.
        const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
        //console.log("Fetching Cluster Scores from API:", url); // Log API URL
        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json'
                }
            });

            // If unauthorized (401), logs out. Otherwise, stores fetched data in dataSources.

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            //console.log("Raw Cluster Scores Response:", data); // Debug: Log raw response

            setDataSources(data);
            if (data.length > 0) {
                const firstDataSource = data[0];
                setSelectedDataSource(firstDataSource);
                fetchServers(firstDataSource);
            }
        } catch (error) {
            //console.log("Response Status:", error);
            setDataSources([]);
            if (dataSources.length === 0) {
                alert('Please contact your administrator. No clusters available.');
            }
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    // Fetches scores for clusters from an API.

    const fetchClusterWithValue = useCallback(async () => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3MzUxNDA0MDgsImV4cCI6MTczNTE0NDAwOH0.b_qa3uqLPHpYRmq86qZpKFwTJqxHXBD9Xwijg-A-g2I';

        const token = getCookie('token');
        const username = getCookie('username');
        const email = decodeURIComponent(username);
        const url = `https://infia.enviseer.com/api/clusterscores?email=${username}`;
        //console.log("Cluster", url);
        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            //console.log("ffffffffsssssss", data);
            setDataSources2(data);
            //console.log("Updated Cluster Scores in State:", dataSources2); // Debug: Log after state update

        } catch (error) {
            console.error("Error Fetching Cluster Scores:", error);
            setDataSources2([]);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    // Fetches servers associated with a selected cluster.

    const fetchServers = useCallback(async (dataSource) => {
        const url = `https://infia.enviseer.com/api/clusters/${dataSource}/hostnames`;
        //console.log(url);
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        const token = getCookie('token');
        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();

            // Stores fetched servers in servers.

            setServers(data);
            setServerCount(data.length);
            if (data.length > 0) 
		{

                const newData = ['All', ...data];
                setSelectedOptions(prevOptions => {
                    const newOptions = [ ...prevOptions];
                    newOptions[0] = { value: newData[0], label: newData[0] };
                    return newOptions;
                });
            }
        } catch (error) {
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const serverOptions = (Array.isArray(healthScore) && healthScore.length === servers.length)
        ? healthScore.map((score, index) => ({
            cluster: servers[index],
            enviscore: parseFloat(score).toFixed(2)
        }))
        : servers.map(server => ({
            cluster: server,
            enviscore: "0.00"
        }));


    //console.log("IIIIIII5555555555", healthScore, servers, serverOptions);



    const handleExpand = (selectedDataSource, selectedOptions) => {
        localStorage.setItem('DataSource', selectedDataSource);
        localStorage.setItem('Server', selectedOptions);
        // Navigate to the constructed URL
        onSelect('dashboard')
    };
    const updateAvgScore = (Score) => {
        //console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj2222222222222222", Score);
        setHealthScore(Score);
    };
    const updateAvg = (Score) => {
        //console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj2222222222222222", Score);
    };
    const updateClusterScore = (Score1, Score2) => {
        //console.log("Updated Cluster Score from Graph:", Score1, Score2);
        setClusterScore([Score1, Score2]);
    }

    // Updates the selected cluster and fetches its servers.

    const updateSelectedCluster = (cluster) => {
        //console.log("Selected Cluster:", cluster);
        setSelectedDataSource(cluster);
        fetchServers(cluster);
        setHealthScore([]);
        setSelectedServer(null);
    }

    // Updates the selected server.

    const updateSelectedHost = (host) => {
        setSelectedServer(host);
    }

    const handleRefresh = () => {
        // Get the current time - Reassigned in the case of custom
        let currentTime = new Date();

        // Calculate the new start time based on the selected duration
        let newStartTime = null;

        switch (lastDuration) {
            case '5 minutes':
                newStartTime = new Date(currentTime.getTime() - 5 * 60000);
                break;
            case '10 minutes':
                newStartTime = new Date(currentTime.getTime() - 10 * 60000);
                break;
            case '30 minutes':
                newStartTime = new Date(currentTime.getTime() - 30 * 60000);
                break;
            case '1 hour':
                newStartTime = new Date(currentTime.getTime() - 60 * 60000);
                break;
            case '2 hours':
                newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
                break;
            case '4 hours':
                newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
                break;
            case '6 hours':
                newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
                break;
            case '12 hours':
                newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
                break;
            case '1 day':
                newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
                break;
            case '3 days':
                newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
                break;
            case '1 week':
                newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
                break;
            case 'custom':
                // If custom is selected, don't update startTime or endTime
                newStartTime = startTime;
                currentTime = endTime;
                break;
            default:
                newStartTime = startTime;
                currentTime = endTime;
                break;
        }

        // Update the state with the new times
        setStartTime(newStartTime);
        setEndTime(currentTime);

        // Increment refreshKey to trigger re-fetching of data
        setRefreshKey(prevKey => prevKey + 1);
    };
    const handleclick = () => {
        if (dataSources.length === 0) {
            alert('Please contact your administrator. No clusters available.');
        }
    };


    let scoreChangeClass = '';
    let scoreChangeIcon = '';

    // Logic to determine if the score is up or down
    if (clusterScore[0] > clusterScore[1]) {
        scoreChangeClass = 'up';
        scoreChangeIcon = '↑';  // Green Up arrow
    } else if (clusterScore[0] < clusterScore[1]) {
        scoreChangeClass = 'down';
        scoreChangeIcon = '↓';  // Red Down arrow
    } else {
        scoreChangeClass = 'neutral';
        scoreChangeIcon = '';  // Neutral if no change
    }

    const showTooltip = (e) => {
        const rect = e.target.getBoundingClientRect();
        setTooltipPosition({
            top: rect.top + window.scrollY - 10, // Adjust to align with theme and layout
            left: rect.left + window.scrollX + rect.width / 2, // Center horizontally
        });
        setTooltipVisible(true);
    };


    const hideTooltip = () => {
        setTooltipVisible(false);
    };

    // Define a function to determine the color based on the score
    const getColor = (score) => {
        const numScore = parseFloat(score);
        if (numScore < 5) return '#FF4D4F';  // Red
        if (numScore < 7.5) return '#FFA940';  // Orange
        return '#52C41A';  // Green
    };

    const sortedServerOptions = [...serverOptions].sort((a, b) => {
        const scoreA = parseFloat(a.enviscore);
        const scoreB = parseFloat(b.enviscore);
        return hostSortOrder === 'asc' ? scoreA - scoreB : scoreB - scoreA;
    });

    const sortedAppOptions = [...dataSources2].sort((a, b) => {
        const scoreA = parseFloat(a.enviscore);
        const scoreB = parseFloat(b.enviscore);
        return appSortOrder === 'asc' ? scoreA - scoreB : scoreB - scoreA;
    });

    const filterHosts = (hosts) => {
        switch(activeFilter) {
            case 'CRITICAL':
                return hosts.filter(host => parseFloat(host.enviscore) < 5);
            case 'MODERATE':
                return hosts.filter(host => parseFloat(host.enviscore) >= 5 && parseFloat(host.enviscore) < 7.5);
            case 'OPTIMIZED':
                return hosts.filter(host => parseFloat(host.enviscore) >= 7.5);
            default:
                return hosts;
        }
    };

    const filterApplications = (apps) => {
        switch(activeAppFilter) {
            case 'CRITICAL':
                return apps.filter(app => parseFloat(app.enviscore) < 5);
            case 'MODERATE':
                return apps.filter(app => parseFloat(app.enviscore) >= 5 && parseFloat(app.enviscore) < 7.5);
            case 'OPTIMIZED':
                return apps.filter(app => parseFloat(app.enviscore) >= 7.5);
            default:
                return apps;
        }
    };

    // Add this function to generate detailed tooltip content for scores
    const getScoreTooltipContent = (score) => {
        const numScore = parseFloat(score);
        if (numScore < 5) {
            return "Critical status: This item requires immediate attention";
        } else if (numScore < 7.5) {
            return "Moderate status: This item requires monitoring";
        } else {
            return "Optimized status: This item is performing well";
        }
    };

    // Add an effect to update the window width on resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onTouchStart = (e) => {
        setTouchEnd(null); // Reset touchEnd
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onTouchEnd = (itemType, item) => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        
        // Handle left and right swipes differently
        if (isLeftSwipe) {
            // If swiping left on a cluster item
            if (itemType === 'cluster') {
                updateSelectedCluster(item.cluster);
            } 
            // If swiping left on a host item
            else if (itemType === 'host') {
                updateSelectedHost(item.cluster);
            }
        }
    };

    return (
        <div className="dashboard" style={{ 
            height: 'calc(92vh)', 
            overflow: 'visible', 
            marginTop: '30px',
            padding: '0 15px'
        }}>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: '20px', 
                marginBottom: '20px',
                width: '100%',
                maxWidth: '100%'
            }}>
                {/* Cluster row - first row */}
                <div className='clusterRow'>
                    <div className="applications-section" style={{
                        background: 'white',
                        borderRadius: '16px',
                        padding: '20px',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.08)',
                        flex: '0 1 500px'
                    }}>
                        <div className="applications-header" style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '15px'
                        }}>
                            <h3 className="applications-title" style={{
                                margin: 0,
                                fontSize: '18px',
                                fontWeight: '600',
                                color: '#2B3674'
                            }}>Clusters</h3>
                            <button 
                                className="applications-filter"
                                onClick={() => setShowAppFilters(!showAppFilters)}
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    color: '#2B3674',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    padding: '8px 12px',
                                    borderRadius: '8px',
                                    transition: 'all 0.2s ease',
                                    backgroundColor: showAppFilters ? 'rgba(104, 37, 236, 0.1)' : 'transparent',
                                }}
                            >
                                Filters <i className={`bi bi-filter${showAppFilters ? '-fill' : ''}`} style={{ color: '#6825EC' }}></i>
                            </button>
                        </div>
                        
                        {/* Filter Tabs - Only show when showAppFilters is true */}
                        {showAppFilters && (
                            <div style={{
                                display: 'flex',
                                gap: '8px',
                                marginBottom: '15px',
                                overflowX: 'auto',
                                width: '100%',
                                justifyContent: 'space-between',
                                borderRadius: '10px',
                                background: '#F4F7FE',
                                padding: '6px',
                                WebkitOverflowScrolling: 'touch'
                            }}>
                                {['ALL', 'CRITICAL', 'MODERATE', 'OPTIMIZED'].map((filter) => (
                                    <button
                                        key={filter}
                                        onClick={() => setActiveAppFilter(filter)}
                                        style={{
                                            padding: '6px 10px',
                                            borderRadius: '20px',
                                            border: 'none',
                                            background: activeAppFilter === filter ? '#6825EC' : 'transparent',
                                            color: activeAppFilter === filter ? 'white' : '#2B3674',
                                            cursor: 'pointer',
                                            fontSize: '13px',
                                            fontWeight: '500',
                                            transition: 'all 0.3s ease',
                                            whiteSpace: 'nowrap',
                                            flex: '1',
                                            minWidth: filter === 'ALL' ? '40px' : '80px',
                                            textAlign: 'center',
                                            boxShadow: activeAppFilter === filter ? '0 4px 8px rgba(104, 37, 236, 0.25)' : 'none'
                                        }}
                                    >
                                        {filter}
                                    </button>
                                ))}
                            </div>
                        )}

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '15px',
                            color: '#2B3674',
                            fontSize: '14px'
                        }}>
                            <span style={{ fontWeight: '500' }}>Total Clusters: <span style={{ fontWeight: '600', color: '#6825EC' }}>{filterApplications(sortedAppOptions).length}</span></span>
                            <button
                                onClick={() => setAppSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
                                style={{
                                    background: 'rgba(104, 37, 236, 0.1)',
                                    border: 'none',
                                    color: '#2B3674',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px',
                                    padding: '8px 12px',
                                    borderRadius: '8px',
                                    transition: 'all 0.2s ease',
                                    fontWeight: '500'
                                }}
                            >
                                EnviScore <i className={`bi bi-arrow-${appSortOrder === 'asc' ? 'up' : 'down'}`} style={{ color: '#6825EC' }} />
                            </button>
                        </div>

                        <div className="applications-list">
                            {filterApplications(sortedAppOptions).map((app, index) => (
                                <div
                                    key={index}
                                    className="application-item"
                                    onClick={() => updateSelectedCluster(app.cluster)}
                                    onTouchStart={onTouchStart}
                                    onTouchMove={onTouchMove}
                                    onTouchEnd={() => onTouchEnd('cluster', app)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: windowWidth <= 480 ? '10px 12px' : '12px 16px',
                                        borderRadius: '10px',
                                        background: selectedDataSource === app.cluster ? 'rgba(104, 37, 236, 0.1)' : '#f8fafc',
                                        border: selectedDataSource === app.cluster ? '1px solid #6825EC' : '1px solid transparent',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        minWidth: 0,
                                        transform: selectedDataSource === app.cluster ? 'translateX(5px)' : 'none',
                                        boxShadow: selectedDataSource === app.cluster ? '0 4px 12px rgba(104, 37, 236, 0.15)' : '0 2px 5px rgba(0, 0, 0, 0.05)'
                                    }}
                                >
                                    <span
                                        className="status-dot"
                                        style={{
                                            height: windowWidth <= 480 ? '8px' : '10px',
                                            width: windowWidth <= 480 ? '8px' : '10px',
                                            backgroundColor: getColor(app.enviscore),
                                            borderRadius: '50%',
                                            flexShrink: 0,
                                            marginRight: windowWidth <= 480 ? '8px' : '12px',
                                            boxShadow: `0 0 6px ${getColor(app.enviscore)}`
                                        }}
                                    ></span>
                                    <span style={{
                                        flex: 1,
                                        fontSize: windowWidth <= 480 ? '12px' : '14px',
                                        color: '#2B3674',
                                        fontWeight: selectedDataSource === app.cluster ? '600' : '500',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        marginRight: '8px'
                                    }}>
                                        {app.cluster}
                                    </span>
                                    <span 
                                        style={{
                                            fontSize: windowWidth <= 480 ? '12px' : '14px',
                                            fontWeight: '600',
                                            color: getColor(app.enviscore),
                                            flexShrink: 0,
                                            padding: windowWidth <= 480 ? '3px 6px' : '4px 8px',
                                            borderRadius: '6px',
                                            background: `${getColor(app.enviscore)}15`,
                                            position: 'relative',
                                            cursor: 'help'
                                        }}
                                        title={getScoreTooltipContent(app.enviscore)}
                                    >
                                        {parseFloat(app.enviscore).toFixed(2)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="landing-graph-container">
                        <p className="landing-graph-title">
                            <span>EnviScore</span>
                        </p>
                        {tooltipVisible && (
                            <div
                                className="custom-tooltip-enviScore"
                                style={{
                                    top: `${tooltipPosition.top}px`,
                                    left: `${tooltipPosition.left}px`,
                                }}
                            >
                                <p style={{ fontSize: '12px', marginTop: '5px' }}>Today's Score: {clusterScore[0]}</p>
                                {clusterScore[0] !== clusterScore[1] && (
                                    <p style={{ fontSize: '12px', marginTop: '5px' }}>
                                        {clusterScore[0] > clusterScore[1]
                                            ? `A Increase of ${(clusterScore[0] - clusterScore[1]).toFixed(2)}`
                                            : `A Decrease of ${(clusterScore[1] - clusterScore[0]).toFixed(2)}`}
                                        since yesterday.
                                    </p>
                                )}
                            </div>
                        )}
                        <div className="graph">
                            <Graphnew
                                key={refreshKey}
                                dataSource={selectedDataSource}
                                label="EnviScore"
                                borderColor="rgba(122, 38, 230, 1)"
                                backgroundColor="rgba(122, 38, 230, 0.3)"
                                updateClusterScore={updateClusterScore}
                            />
                        </div>
                    </div>
                </div>

                {/* Hosts row - second row */}
                <div className='clusterRow'>
                    <div className="applications-section" style={{
                        background: 'white', 
                        borderRadius: '16px',
                        padding: '20px',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.08)',
                        flex: '0 1 500px'
                    }}>
                        <div className="applications-header" style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '15px'
                        }}>
                            <h3 className="applications-title" style={{
                                margin: 0,
                                fontSize: '18px',
                                fontWeight: '600',
                                color: '#2B3674'
                            }}>Hosts</h3>
                            <button 
                                className="applications-filter"
                                onClick={() => setShowHostFilters(!showHostFilters)}
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    color: '#2B3674',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    padding: '8px 12px',
                                    borderRadius: '8px',
                                    transition: 'all 0.2s ease',
                                    backgroundColor: showHostFilters ? 'rgba(104, 37, 236, 0.1)' : 'transparent',
                                }}
                            >
                                Filters <i className={`bi bi-filter${showHostFilters ? '-fill' : ''}`} style={{ color: '#6825EC' }}></i>
                            </button>
                        </div>
                        
                        {/* Filter Tabs - Only show when showHostFilters is true */}
                        {showHostFilters && (
                            <div style={{
                                display: 'flex',
                                gap: '8px',
                                marginBottom: '15px',
                                overflowX: 'auto',
                                width: '100%',
                                justifyContent: 'space-between',
                                borderRadius: '10px',
                                background: '#F4F7FE',
                                padding: '6px',
                                WebkitOverflowScrolling: 'touch'
                            }}>
                                {['ALL', 'CRITICAL', 'MODERATE', 'OPTIMIZED'].map((filter) => (
                                    <button
                                        key={filter}
                                        onClick={() => setActiveFilter(filter)}
                                        style={{
                                            padding: '6px 10px',
                                            borderRadius: '20px',
                                            border: 'none',
                                            background: activeFilter === filter ? '#6825EC' : 'transparent',
                                            color: activeFilter === filter ? 'white' : '#2B3674',
                                            cursor: 'pointer',
                                            fontSize: '13px',
                                            fontWeight: '500',
                                            transition: 'all 0.3s ease',
                                            whiteSpace: 'nowrap',
                                            flex: '1',
                                            minWidth: filter === 'ALL' ? '40px' : '80px',
                                            textAlign: 'center',
                                            boxShadow: activeFilter === filter ? '0 4px 8px rgba(104, 37, 236, 0.25)' : 'none'
                                        }}
                                    >
                                        {filter}
                                    </button>
                                ))}
                            </div>
                        )}

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '15px',
                            color: '#2B3674',
                            fontSize: '14px'
                        }}>
                            <span style={{ fontWeight: '500' }}>Total Hosts: <span style={{ fontWeight: '600', color: '#6825EC' }}>{filterHosts(sortedServerOptions).length}</span></span>
                            <button
                                onClick={() => setHostSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
                                style={{
                                    background: 'rgba(104, 37, 236, 0.1)',
                                    border: 'none',
                                    color: '#2B3674',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px',
                                    padding: '8px 12px',
                                    borderRadius: '8px',
                                    transition: 'all 0.2s ease',
                                    fontWeight: '500'
                                }}
                            >
                                EnviVital <i className={`bi bi-arrow-${hostSortOrder === 'asc' ? 'up' : 'down'}`} style={{ color: '#6825EC' }} />
                            </button>
                        </div>

                        <div className="hosts-list">
                            {filterHosts(sortedServerOptions).map((server, index) => (
                                <div
                                    key={index}
                                    className="host-item"
                                    onClick={() => updateSelectedHost(server.cluster)}
                                    onTouchStart={onTouchStart}
                                    onTouchMove={onTouchMove}
                                    onTouchEnd={() => onTouchEnd('host', server)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: windowWidth <= 480 ? '10px 12px' : '12px 16px',
                                        borderRadius: '10px',
                                        background: selectedServer === server.cluster ? 'rgba(104, 37, 236, 0.1)' : '#f8fafc',
                                        border: selectedServer === server.cluster ? '1px solid #6825EC' : '1px solid transparent',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        minWidth: 0,
                                        transform: selectedServer === server.cluster ? 'translateX(5px)' : 'none',
                                        boxShadow: selectedServer === server.cluster ? '0 4px 12px rgba(104, 37, 236, 0.15)' : '0 2px 5px rgba(0, 0, 0, 0.05)'
                                    }}
                                >
                                    <span
                                        className="status-dot"
                                        style={{
                                            height: windowWidth <= 480 ? '8px' : '10px',
                                            width: windowWidth <= 480 ? '8px' : '10px',
                                            backgroundColor: getColor(server.enviscore),
                                            borderRadius: '50%',
                                            flexShrink: 0,
                                            marginRight: windowWidth <= 480 ? '8px' : '12px',
                                            boxShadow: `0 0 6px ${getColor(server.enviscore)}`
                                        }}
                                    ></span>
                                    <span style={{
                                        flex: 1,
                                        fontSize: windowWidth <= 480 ? '12px' : '14px',
                                        color: '#2B3674',
                                        fontWeight: selectedServer === server.cluster ? '600' : '500',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        marginRight: '8px'
                                    }}>
                                        {server.cluster}
                                    </span>
                                    <span 
                                        style={{
                                            fontSize: windowWidth <= 480 ? '12px' : '14px',
                                            fontWeight: '600',
                                            color: getColor(server.enviscore),
                                            flexShrink: 0,
                                            padding: windowWidth <= 480 ? '3px 6px' : '4px 8px',
                                            borderRadius: '6px',
                                            background: `${getColor(server.enviscore)}15`,
                                            position: 'relative',
                                            cursor: 'help'
                                        }}
                                        title={getScoreTooltipContent(server.enviscore)}
                                    >
                                        {parseFloat(server.enviscore).toFixed(2)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="landing-graph-container">
                        <p className="landing-graph-title">
                            <span>EnviVital</span>
                            {selectedServer &&
                                <div style={{ zIndex: '100', position: "absolute", right: '30px', top: '16px' }}>
                                    <button
                                        className="btn btn-outline-secondary expand-button"
                                        type="button"
                                        id="expandButton"
                                        onClick={() => handleExpand(selectedDataSource, selectedServer)}
                                        onMouseEnter={() => setTooltipVisible(true)}
                                        onMouseLeave={() => setTooltipVisible(false)}
                                        style={{
                                            background: 'white',
                                            borderColor: '#6825EC',
                                            color: '#6825EC',
                                            fontSize: windowWidth <= 480 ? '12px' : '14px',
                                            fontWeight: '500',
                                            borderRadius: '8px',
                                            padding: windowWidth <= 480 ? '6px 8px' : '8px 12px',
                                            cursor: 'pointer',
                                            boxShadow: '0 4px 8px rgba(104, 37, 236, 0.15)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: windowWidth <= 480 ? '3px' : '6px',
                                            transition: 'all 0.3s ease',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {windowWidth > 480 && <span></span>} 
                                        <i className="bi bi-box-arrow-up-right"></i>
                                    </button>
                                    {tooltipVisible && (
                                        <div className="button-tooltip" style={{
                                            ...tooltipStyle,
                                            background: '#6825EC',
                                            boxShadow: '0 4px 12px rgba(104, 37, 236, 0.3)',
                                            fontSize:'12px'
                                        }}>
                                            View Anomalies
                                        </div>
                                    )}
                                </div>}
                        </p>
                        <div className="graph">
                            {selectedServer ? <Graph
                                key={refreshKey}
                                dataSource={selectedDataSource}
                                server={selectedServer}
                                startDate={startTime}
                                endDate={endTime}
                                metricName="cluster.state"
                                label="EnviVital"
                                borderColor="rgba(122, 38, 230, 1)"
                                backgroundColor="rgba(122, 38, 230, 0.3)"
                                updateAvgScore={updateAvg}
                            /> :
                                <LandingGraph
                                    key={refreshKey}
                                    dataSource={selectedDataSource}
                                    servers={servers}
                                    startDate={startTime}
                                    endDate={endTime}
                                    metricName="cpu_usage"
                                    labels="EnviVital"
                                    updateClusterScore={updateAvgScore}
                                />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const tooltipStyle = {
    background: '#6825EC',
    color: 'white',
    padding: '8px 12px',
    borderRadius: '8px',
    fontSize: '12px',
    position: 'absolute',
    top: '52px',
    transform: 'translateX(-50%)',
    boxShadow: '0 4px 12px rgba(104, 37, 236, 0.2)',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    zIndex: 1000,
};
export default Landing;
