import React from 'react';
import { Server, Database, Bot, Activity, Cpu, AlertTriangle, MessageSquare, Search, Gauge, Network } from 'lucide-react';
import styles from './flowDiagram.module.css';

const FlowDiagram = ({ selectedIncident }) => {
  const { env = 'staging', os = 'windows', id = 1, server_type = 'test_server' } = selectedIncident || {};

  // Updated incident scenarios with technical details
  const incidentScenarios = {
    1: {
      servers: ['test_server', 'postgres', 'auth-service', 'redis-cache', 'auth-validator'],
      affected_server: 'auth-service',
      apis: ['Authentication API', 'User Service', 'Token Validation'],
      issue: {
        title: 'Authentication Service Degradation',
        description: [
          'High latency in auth-service token validation',
          'Redis cache hit rate below 40%',
          'Connection pool saturation in auth-validator',
          'Increased error rate in token validation'
        ],
        technical_details: {
          'auth-service': [
            {
              function: 'validateToken()',
              file: 'src/services/auth/tokenValidator.js',
              line: 127,
              issue: 'High latency in token validation due to Redis connection timeout',
              metrics: 'Avg latency: 2.5s (threshold: 200ms)'
            },
            {
              function: 'refreshTokenMiddleware()',
              file: 'src/middleware/auth.js',
              line: 45,
              issue: 'Connection pool exhaustion causing request queuing',
              metrics: 'Pool utilization: 98%'
            }
          ],
          'redis-cache': [
            {
              function: 'getCachedToken()',
              file: 'src/cache/tokenCache.js',
              line: 89,
              issue: 'Low cache hit rate affecting performance',
              metrics: 'Hit rate: 38% (target: 80%)'
            }
          ],
          'auth-validator': [
            {
              function: 'validateUserSession()',
              file: 'src/validators/session.js',
              line: 156,
              issue: 'Connection pool saturation causing timeouts',
              metrics: 'Timeout rate: 15%'
            }
          ]
        },
        actions: [
          'Scale up auth-service instances',
          'Optimize Redis cache strategy',
          'Review connection pool settings',
          'Implement circuit breaker in auth-validator'
        ]
      },
      highlights: {
        'auth-service': 2,
        'redis-cache': 1,
        'auth-validator': 1
      }
    },
    2: {
      servers: ['infia', 'postgres', 'metrics-collector', 'alert-processor', 'data-aggregator'],
      affected_server: 'metrics-collector',
      apis: ['Anomaly Detection', 'Metrics API', 'Alert Processing'],
      issue: {
        title: 'Monitoring System Overload',
        description: [
          'Metrics collection pipeline delays',
          'Alert processing queue buildup',
          'Data aggregation timeouts',
          'High memory usage in metrics-collector'
        ],
        technical_details: {
          'metrics-collector': [
            {
              function: 'processMetricsBatch()',
              file: 'src/collectors/metricProcessor.js',
              line: 156,
              issue: 'Memory leak in metric batch processing causing high heap usage',
              metrics: 'Memory usage: 92% (threshold: 75%)'
            },
            {
              function: 'streamMetrics()',
              file: 'src/collectors/streamHandler.js',
              line: 89,
              issue: 'Backpressure in metrics stream causing pipeline delays',
              metrics: 'Stream lag: 45s (threshold: 10s)'
            }
          ],
          'alert-processor': [
            {
              function: 'processAlertQueue()',
              file: 'src/processors/alertQueue.js',
              line: 234,
              issue: 'Alert queue growing faster than processing rate',
              metrics: 'Queue depth: 5000 (target: <1000)'
            }
          ],
          'data-aggregator': [
            {
              function: 'aggregateTimeSeriesData()',
              file: 'src/aggregators/timeSeriesAggregator.js',
              line: 178,
              issue: 'Timeout in time-series data aggregation',
              metrics: 'Aggregation latency: 8s (threshold: 3s)'
            }
          ]
        },
        actions: [
          'Scale metrics-collector horizontally',
          'Implement batch processing for alerts',
          'Optimize data aggregation pipeline',
          'Add memory monitoring and alerts'
        ]
      },
      highlights: {
        'metrics-collector': 2,
        'alert-processor': 1,
        'data-aggregator': 1
      }
    },
    3: {
      servers: ['envibuddy', 'postgres', 'llm-tokenizer', 'context-vectorizer', 'context-parser'],
      affected_server: 'llm-tokenizer',
      apis: ['LLM API', 'Context Processing', 'user_prompt_history'],
      issue: {
        title: 'LLM Token Processing Latency',
        description: [
          'Excessive token generation load on llm-tokenizer',
          'Unindexed queries in user_prompt_history table',
          'context-vectorizer GPU strain due to concurrent hits'
        ],
        technical_details: {
          'llm-tokenizer': [
            {
              function: 'generateTokens()',
              file: 'src/tokenizer/tokenGenerator.js',
              line: 145,
              issue: 'Token generation bottleneck due to sequential processing',
              metrics: 'Processing time: 850ms (threshold: 300ms)'
            },
            {
              function: 'batchTokenize()',
              file: 'src/tokenizer/batchProcessor.js',
              line: 67,
              issue: 'Batch size causing memory pressure',
              metrics: 'Memory usage: 85% (threshold: 70%)'
            }
          ],
          'context-vectorizer': [
            {
              function: 'vectorizeContext()',
              file: 'src/vectorizer/contextProcessor.js',
              line: 234,
              issue: 'GPU memory saturation from parallel vectorization',
              metrics: 'GPU utilization: 95% (target: <80%)'
            }
          ],
          'context-parser': [
            {
              function: 'parseUserContext()',
              file: 'src/parser/contextParser.js',
              line: 189,
              issue: 'Context parsing delays due to large input sizes',
              metrics: 'Parse time: 450ms (threshold: 200ms)'
            }
          ]
        },
        actions: [
          'Cache pre-tokenized prompts in Redis',
          'Add indexes to user_prompt_history',
          'Implement batch token vectorization',
          'Scale llm-tokenizer microservice horizontally'
        ]
      },
      highlights: {
        'llm-tokenizer': 2,
        'context-vectorizer': 1,
        'context-parser': 1
      }
    },
    4: {
      servers: ['postgres', 'infia', 'query-optimizer', 'cache-service', 'data-sync'],
      affected_server: 'postgres',
      apis: ['Data Pipeline', 'Analytics API', 'Cache Management'],
      issue: {
        title: 'Database Performance Degradation',
        description: [
          'Slow query performance in postgres',
          'Cache service hit rate below 30%',
          'Data sync delays',
          'High I/O wait times'
        ],
        technical_details: {
          'postgres': [
            {
              function: 'executeAnalyticsQuery()',
              file: 'src/db/queryExecutor.js',
              line: 178,
              issue: 'Full table scan on analytics queries causing high I/O',
              metrics: 'I/O wait: 45% (threshold: 20%)'
            },
            {
              function: 'syncTableData()',
              file: 'src/db/dataSyncer.js',
              line: 234,
              issue: 'Lock contention during data sync operations',
              metrics: 'Lock wait time: 3.5s (threshold: 1s)'
            }
          ],
          'cache-service': [
            {
              function: 'getCachedResult()',
              file: 'src/cache/resultCache.js',
              line: 145,
              issue: 'Cache eviction rate too high causing low hit rate',
              metrics: 'Hit rate: 28% (target: >80%)'
            }
          ],
          'data-sync': [
            {
              function: 'synchronizeData()',
              file: 'src/sync/dataSynchronizer.js',
              line: 89,
              issue: 'Sync job queue saturation',
              metrics: 'Queue depth: 2000 (target: <500)'
            }
          ]
        },
        actions: [
          'Optimize database indices',
          'Review cache strategy',
          'Scale data sync workers',
          'Implement query caching'
        ]
      },
      highlights: {
        'postgres': 2,
        'cache-service': 1,
        'data-sync': 1
      }
    },
    5: {
      servers: ['test_server', 'envibuddy', 'chat-service', 'knowledge-base', 'response-formatter'],
      affected_server: 'chat-service',
      apis: ['Chat API', 'Knowledge Base', 'Response Formatting'],
      issue: {
        title: 'Chatbot Response Issues',
        description: [
          'High latency in chat-service responses',
          'Knowledge base query timeouts',
          'Response formatting delays',
          'Memory leaks in chat-service'
        ],
        technical_details: {
          'chat-service': [
            {
              function: 'processUserMessage()',
              file: 'src/services/chat/messageProcessor.js',
              line: 167,
              issue: 'Memory leak in message processing causing OOM risks',
              metrics: 'Memory growth: +2MB/min (threshold: 0.5MB/min)'
            },
            {
              function: 'generateResponse()',
              file: 'src/services/chat/responseGenerator.js',
              line: 234,
              issue: 'Response generation blocking on external API calls',
              metrics: 'Response time: 2.8s (threshold: 1s)'
            }
          ],
          'knowledge-base': [
            {
              function: 'queryKnowledgeBase()',
              file: 'src/services/kb/queryEngine.js',
              line: 145,
              issue: 'Inefficient query patterns causing timeouts',
              metrics: 'Query latency: 5s (threshold: 2s)'
            }
          ],
          'response-formatter': [
            {
              function: 'formatChatResponse()',
              file: 'src/formatters/responseFormatter.js',
              line: 89,
              issue: 'CPU-intensive formatting operations causing delays',
              metrics: 'CPU usage: 85% (threshold: 60%)'
            }
          ]
        },
        actions: [
          'Scale chat-service instances',
          'Optimize knowledge base queries',
          'Implement response caching',
          'Fix memory leak in chat-service'
        ]
      },
      highlights: {
        'chat-service': 2,
        'knowledge-base': 1,
        'response-formatter': 1
      }
    },
    6: {
      servers: ['test_server', 'infia', 'monitoring-api', 'alert-service', 'notification-handler'],
      affected_server: 'infia',
      apis: ['Monitoring API', 'Alert Service', 'Notification Processing'],
      issue: {
        title: 'Alert Processing Delays',
        description: [
          'Alert processing queue buildup',
          'Monitoring data lag',
          'Notification delivery delays',
          'High CPU utilization in alert-service'
        ],
        technical_details: {
          'infia': [
            {
              function: 'processAlertBatch()',
              file: 'src/services/alerts/batchProcessor.js',
              line: 189,
              issue: 'Alert batch processing causing CPU spikes',
              metrics: 'CPU utilization: 92% (threshold: 75%)'
            },
            {
              function: 'aggregateAlerts()',
              file: 'src/services/alerts/aggregator.js',
              line: 234,
              issue: 'Alert aggregation causing memory pressure',
              metrics: 'Memory usage: 87% (threshold: 70%)'
            }
          ],
          'alert-service': [
            {
              function: 'processAlertQueue()',
              file: 'src/services/alerts/queueProcessor.js',
              line: 156,
              issue: 'Queue processing rate lower than ingestion rate',
              metrics: 'Queue backlog: 15k messages (target: <5k)'
            }
          ],
          'notification-handler': [
            {
              function: 'sendNotificationBatch()',
              file: 'src/handlers/notificationHandler.js',
              line: 123,
              issue: 'Notification delivery throttling due to rate limits',
              metrics: 'Delivery latency: 45s (threshold: 15s)'
            }
          ]
        },
        actions: [
          'Scale alert processing workers',
          'Optimize monitoring data flow',
          'Implement notification batching',
          'Review CPU allocation'
        ]
      },
      highlights: {
        'infia': 2,
        'alert-service': 1,
        'notification-handler': 1
      }
    },
    7: {
      servers: ['envibuddy', 'infia', 'model-service', 'training-pipeline', 'model-validator'],
      affected_server: 'envibuddy',
      apis: ['Model API', 'Training Pipeline', 'Model Validation'],
      issue: {
        title: 'Model Training Pipeline Issues',
        description: [
          'Training pipeline stalls',
          'Model loading timeouts',
          'Validation service delays',
          'Resource contention in GPU cluster'
        ],
        technical_details: {
          'envibuddy': [
            {
              function: 'loadModelWeights()',
              file: 'src/services/model/weightLoader.js',
              line: 234,
              issue: 'Model weight loading causing memory spikes',
              metrics: 'Load time: 12s (threshold: 5s)'
            },
            {
              function: 'predictBatch()',
              file: 'src/services/model/predictor.js',
              line: 167,
              issue: 'GPU memory fragmentation during batch prediction',
              metrics: 'GPU memory: 95% (threshold: 80%)'
            }
          ],
          'training-pipeline': [
            {
              function: 'trainModelBatch()',
              file: 'src/pipeline/trainer.js',
              line: 189,
              issue: 'Training pipeline bottleneck in data preprocessing',
              metrics: 'Pipeline lag: 45min (target: 15min)'
            }
          ],
          'model-validator': [
            {
              function: 'validateModelAccuracy()',
              file: 'src/validators/accuracyValidator.js',
              line: 145,
              issue: 'Validation queue buildup due to slow processing',
              metrics: 'Validation backlog: 8 models (target: 2)'
            }
          ]
        },
        actions: [
          'Optimize model loading',
          'Scale training resources',
          'Implement validation caching',
          'Review GPU allocation'
        ]
      },
      highlights: {
        'envibuddy': 2,
        'training-pipeline': 1,
        'model-validator': 1
      }
    },
    8: {
      servers: ['postgres', 'test_server', 'query-service', 'cache-api', 'data-transformer'],
      affected_server: 'postgres',
      apis: ['Query Service', 'Cache API', 'Data Transformation'],
      issue: {
        title: 'Query Performance Issues',
        description: [
          'Cache miss rate increased',
          'Query timeout frequency high',
          'Data transformation delays',
          'Connection pool exhaustion'
        ],
        technical_details: {
          'postgres': [
            {
              function: 'executeComplexQuery()',
              file: 'src/db/queryExecutor.js',
              line: 234,
              issue: 'Complex queries causing table scans',
              metrics: 'Query time: 8s (threshold: 3s)'
            },
            {
              function: 'maintainConnPool()',
              file: 'src/db/connectionPool.js',
              line: 167,
              issue: 'Connection pool saturation causing timeouts',
              metrics: 'Pool utilization: 98% (threshold: 80%)'
            }
          ],
          'cache-api': [
            {
              function: 'updateCacheEntry()',
              file: 'src/cache/cacheManager.js',
              line: 189,
              issue: 'Cache invalidation storms causing high miss rate',
              metrics: 'Miss rate: 65% (target: <20%)'
            }
          ],
          'data-transformer': [
            {
              function: 'transformDataBatch()',
              file: 'src/transformer/batchTransformer.js',
              line: 145,
              issue: 'Data transformation bottleneck in JSON parsing',
              metrics: 'Transform time: 3.5s (threshold: 1s)'
            }
          ]
        },
        actions: [
          'Review cache strategy',
          'Optimize query patterns',
          'Scale transformation workers',
          'Adjust connection pool size'
        ]
      },
      highlights: {
        'postgres': 2,
        'cache-api': 1,
        'data-transformer': 1
      }
    },
    9: {
      servers: ['test_server', 'postgres', 'load-balancer', 'health-check', 'service-registry'],
      affected_server: 'test_server',
      apis: ['Load Balancer', 'Health Check', 'Service Discovery'],
      issue: {
        title: 'Service Health Check Failures',
        description: [
          'Health check timeout increase',
          'Load balancer connection issues',
          'Service discovery delays',
          'High latency in health checks'
        ],
        technical_details: {
          'test_server': [
            {
              function: 'performHealthCheck()',
              file: 'src/health/checker.js',
              line: 234,
              issue: 'Health check timeouts due to network latency',
              metrics: 'Check latency: 2.5s (threshold: 1s)'
            },
            {
              function: 'updateServiceStatus()',
              file: 'src/health/statusManager.js',
              line: 167,
              issue: 'Status update conflicts causing retry storms',
              metrics: 'Conflict rate: 25% (threshold: 5%)'
            }
          ],
          'health-check': [
            {
              function: 'processHealthResults()',
              file: 'src/health/resultProcessor.js',
              line: 189,
              issue: 'Result processing queue saturation',
              metrics: 'Queue depth: 1000 (target: <200)'
            }
          ],
          'service-registry': [
            {
              function: 'syncServiceRegistry()',
              file: 'src/registry/syncManager.js',
              line: 145,
              issue: 'Registry sync delays causing stale data',
              metrics: 'Sync lag: 45s (threshold: 15s)'
            }
          ]
        },
        actions: [
          'Review health check config',
          'Optimize load balancer settings',
          'Scale service registry',
          'Implement health check caching'
        ]
      },
      highlights: {
        'test_server': 2,
        'health-check': 1,
        'service-registry': 1
      }
    },
    10: {
      servers: ['infia', 'envibuddy', 'prediction-service', 'analysis-pipeline', 'result-aggregator'],
      affected_server: 'infia',
      apis: ['Prediction API', 'Analysis Service', 'Result Aggregation'],
      issue: {
        title: 'Prediction Service Degradation',
        description: [
          'Prediction accuracy deviation',
          'Analysis pipeline delays',
          'Result aggregation timeouts',
          'High memory usage in prediction-service'
        ],
        technical_details: {
          'infia': [
            {
              function: 'generatePrediction()',
              file: 'src/services/prediction/generator.js',
              line: 234,
              issue: 'Prediction generation causing high memory usage',
              metrics: 'Memory usage: 92% (threshold: 75%)'
            },
            {
              function: 'optimizePredictionModel()',
              file: 'src/services/prediction/optimizer.js',
              line: 167,
              issue: 'Model optimization causing CPU spikes',
              metrics: 'CPU usage: 95% (threshold: 80%)'
            }
          ],
          'analysis-pipeline': [
            {
              function: 'analyzeResults()',
              file: 'src/pipeline/analyzer.js',
              line: 189,
              issue: 'Analysis pipeline bottleneck in data processing',
              metrics: 'Processing lag: 5min (target: 1min)'
            }
          ],
          'result-aggregator': [
            {
              function: 'aggregateResults()',
              file: 'src/aggregator/resultAggregator.js',
              line: 145,
              issue: 'Result aggregation timeouts due to data volume',
              metrics: 'Aggregation time: 8s (threshold: 3s)'
            }
          ]
        },
        actions: [
          'Review prediction models',
          'Optimize analysis pipeline',
          'Scale aggregation workers',
          'Implement memory monitoring'
        ]
      },
      highlights: {
        'infia': 2,
        'analysis-pipeline': 1,
        'result-aggregator': 1
      }
    },
  };

  // Get current scenario based on incident ID
  const currentScenario = incidentScenarios[id] || incidentScenarios[1];

  // Generate node data based on environment and scenario
  const getNodeData = () => {
    const servers = {};
    currentScenario.servers.forEach(server => {
      servers[server] = server;
    });
    return { servers };
  };

  const nodeData = getNodeData();

  // Helper function to get technical details for a node
  const getTechnicalDetails = (nodeName) => {
    return currentScenario.issue.technical_details?.[nodeName] || [];
  };

  // Format description with proper environment
  React.useEffect(() => {
    if (currentScenario && currentScenario.issue) {
      currentScenario.issue.description = currentScenario.issue.description.map(desc => {
        if (desc.includes('Environment:')) {
          // Ensure environment is either staging or production
          const environment = env === 'development' || env === 'testing' ? 'staging' : 
                            env === 'production' ? 'production' : 'staging';
          return `Environment: ${environment}, OS: ${os.toLowerCase()}`;
        }
        return desc;
      });
    }
  }, [currentScenario, env, os]);

  // Define nodes based on scenario
  const nodes = [
    // First server
    {
      id: 'node1',
      icon: Server,
      label: nodeData.servers[currentScenario.servers[0]],
      color: currentScenario.affected_server === currentScenario.servers[0] ? '#dc2626' : '#93a617',
      position: { top: 140, left: 40 },
      isAffected: currentScenario.affected_server === currentScenario.servers[0],
      highlightCount: currentScenario.highlights?.[currentScenario.servers[0]] || 0
    },
    // Second server
    {
      id: 'node2',
      icon: Server,
      label: nodeData.servers[currentScenario.servers[1]],
      color: currentScenario.affected_server === currentScenario.servers[1] ? '#dc2626' : '#0077b6',
      position: { top: 40, left: 160 },
      isAffected: currentScenario.affected_server === currentScenario.servers[1],
      highlightCount: currentScenario.highlights?.[currentScenario.servers[1]] || 0
    },
    // Third server
    {
      id: 'node3',
      icon: Server,
      label: nodeData.servers[currentScenario.servers[2]],
      color: currentScenario.affected_server === currentScenario.servers[2] ? '#dc2626' : '#00b4d8',
      position: { top: 40, left: 280 },
      isAffected: currentScenario.affected_server === currentScenario.servers[2],
      highlightCount: currentScenario.highlights?.[currentScenario.servers[2]] || 0
    },
    // Fourth server
    {
      id: 'node4',
      icon: Server,
      label: nodeData.servers[currentScenario.servers[3]],
      color: currentScenario.affected_server === currentScenario.servers[3] ? '#dc2626' : '#34d399',
      position: { top: 160, left: 240 },
      isAffected: currentScenario.affected_server === currentScenario.servers[3],
      highlightCount: currentScenario.highlights?.[currentScenario.servers[3]] || 0
    },
    // Fifth server
    {
      id: 'node5',
      icon: Server,
      label: nodeData.servers[currentScenario.servers[4]],
      color: currentScenario.affected_server === currentScenario.servers[4] ? '#dc2626' : '#f59e0b',
      position: { top: 200, left: 320 },
      isAffected: currentScenario.affected_server === currentScenario.servers[4],
      highlightCount: currentScenario.highlights?.[currentScenario.servers[4]] || 0
    },
    // First API
    {
      id: 'node6',
      icon: Network,
      label: currentScenario.apis[0],
      color: '#8b5cf6',
      position: { top: 280, left: 100 }
    },
    // Second API
    {
      id: 'node7',
      icon: MessageSquare,
      label: currentScenario.apis[1],
      color: '#ec4899',
      position: { top: 280, left: 220 }
    },
    // Third API
    {
      id: 'node8',
      icon: Search,
      label: currentScenario.apis[2],
      color: '#14b8a6',
      position: { top: 280, left: 340 }
    }
  ];

  // Define connections between nodes
  const connections = [
    // Server to Server connections
    { from: 'node1', to: 'node2' },
    { from: 'node2', to: 'node3' },
    { from: 'node3', to: 'node4' },
    { from: 'node4', to: 'node5' },
    
    // Server to API connections
    { from: 'node1', to: 'node6' },
    { from: 'node2', to: 'node6' },
    { from: 'node3', to: 'node6' },
    { from: 'node4', to: 'node7' },
    { from: 'node5', to: 'node7' },
    { from: 'node6', to: 'node8' },
    { from: 'node7', to: 'node8' }
  ];

  // Function to generate curved path between two points
  const generatePath = (from, to) => {
    const fromNode = nodes.find(n => n.id === from);
    const toNode = nodes.find(n => n.id === to);
    
    if (!fromNode || !toNode) return '';
    
    const x1 = fromNode.position.left;
    const y1 = fromNode.position.top;
    const x2 = toNode.position.left;
    const y2 = toNode.position.top;
    
    // Calculate control point for the curve
    const cpx = (x1 + x2) / 2;
    const cpy = (y1 + y2) / 2;
    
    return `M${x1},${y1} Q${cpx},${cpy} ${x2},${y2}`;
  };

  return (
    <div className={styles['flow-diagram-wrapper']}>
      <div className={styles['content-container']}>
        <div className={styles['root-cause-info']}>

          <div className={styles['root-cause-header']}>
            <div className={styles['header-icon-wrapper']}>
              <i className="bi bi-diagram-2"></i>
            </div>
            <div className={styles['header-text']}>
              <span className={styles['header-label']}>INCIDENT ANALYSIS</span>
              <h3>{currentScenario.issue.title}</h3>
            </div>
          </div>

          <div className={styles['issues-section']}>
            <div className={styles['section-title']}>
              <i className="bi bi-exclamation-diamond"></i>
              <h4>Issues Identified</h4>
            </div>
            <ul>
              {currentScenario.issue.description.map((issue, index) => (
                <li key={index}>
                  <span className={styles['bullet']}></span>
                  {issue}
                </li>
              ))}
            </ul>
          </div>

          <div className={styles['actions-section']}>
            <div className={styles['section-title']}>
              <i className="bi bi-lightning"></i>
              <h4>Recommended Actions</h4>
            </div>
            <ul>
              {currentScenario.issue.actions.map((action, index) => (
                <li key={index}>
                  <span className={styles['action-number']}>{index + 1}</span>
                  {action}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles['flow-diagram-container']}>
          <div className={styles['flow-header']}>
            <div className={styles['flow-icon']}>
              <i className="bi bi-share-fill"></i>
            </div>
            <div className={styles['flow-title']}>
              <span className={styles['flow-label']}>SERVICE MAP</span>
              <h2>Dependency Flow</h2>
            </div>
          </div>
          <div className={styles['flow-diagram']}>
            {nodes.map((node) => (
              <div
                key={node.id}
                className={styles['node-card']}
                style={{
                  position: 'absolute',
                  top: node.position.top + 'px',
                  left: node.position.left + 'px'
                }}
              >
                <div className={styles['node-content']}>
                  <div 
                    className={`${styles['circle-node']} ${node.isAffected ? styles.affected : ''}`} 
                    style={{ backgroundColor: node.color }}
                  >
                    <node.icon size={16} color="#ffffff" />
                    {node.highlightCount > 0 && (
                      <>
                        <div className={styles['node-alert']}>{node.highlightCount}</div>
                        {getTechnicalDetails(node.label).length > 0 && (
                          <div className={styles.nodeTooltip}>
                            <div className={styles.tooltipHeader}>
                              <AlertTriangle size={14} />
                              <span>Technical Details</span>
                            </div>
                            <div className={styles.tooltipContent}>
                              {getTechnicalDetails(node.label).map((detail, index) => (
                                <div key={index} className={styles.tooltipItem}>
                                  <div className={styles.tooltipItemHeader}>
                                    <span className={styles.functionName}>{detail.function}</span>
                                    <span className={styles.filePath}>{detail.file}:{detail.line}</span>
                                  </div>
                                  <div className={styles.tooltipItemBody}>
                                    <div className={styles.issueText}>{detail.issue}</div>
                                    <div className={styles.metrics}>{detail.metrics}</div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className={styles['node-label']}>{node.label}</div>
                </div>
              </div>
            ))}
            
            <svg className={styles['connection-lines']} width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}>
              {connections.map((conn, index) => (
                <path
                  key={`${conn.from}-${conn.to}`}
                  d={generatePath(conn.from, conn.to)}
                  className={styles['connection-path']}
                />
              ))}
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowDiagram;
