import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { BiChevronDown } from 'react-icons/bi';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 180px;
`;

const DropdownButton = styled.button`
  background: #FFFFFF;
  color: #1b2559;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #E0E6EF;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    border-color: #6825EC;
    box-shadow: 0 2px 8px rgba(104, 37, 236, 0.1);
  }

  svg {
    margin-left: 8px;
    color: #6825EC;
    transition: transform 0.2s ease;
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background-color: #FFFFFF;
  min-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 1px solid #E0E6EF;
  opacity: ${props => props.isOpen ? 1 : 0};
  transform: translateY(${props => props.isOpen ? '0' : '-4px'});
  transition: all 0.2s ease;
  pointer-events: ${props => props.isOpen ? 'auto' : 'none'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
`;

const DropdownItem = styled.div`
  color: #1b2559;
  padding: 10px 16px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background-color: #6825EC15;
    color: #6825EC;
  }
`;

const CustomDropdown = ({ label, options, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeTimeoutRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 150); // 1 second delay
  };

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  const selectedOption = (Array.isArray(options) ? options : []).find(option => option.value === value);
  const displayLabel = selectedOption ? `${label}: ${selectedOption.label}` : label;

  return (
    <DropdownContainer 
      ref={dropdownRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownButton>
        {displayLabel}
        <BiChevronDown 
          size={20} 
          style={{ 
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease'
          }} 
        />
      </DropdownButton>
      <DropdownContent isOpen={isOpen}>
        {(Array.isArray(options) ? options : []).map((option, index) => (
          <DropdownItem
            key={index}
            onClick={() => {
              onChange({ target: { value: option.value } });
              setIsOpen(false);
              if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
              }
            }}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default CustomDropdown;
