import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Header.css';
import { FaBell, FaUserCircle } from 'react-icons/fa';

function Header({onLogout, selectedPage,sidebarOpen}) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSettingsPanel, setShowSettingsPanel] = useState(false);
    const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
    const [activeTab, setActiveTab] = useState('application'); // Manage the active tab
    const [visibleTooltip, setVisibleTooltip] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const profileDropdownRef = useRef(null);
    const notificationDropdownRef = useRef(null);

    const [userProfile, setUserProfile] = useState({
        username: '',
        email: '',
    });
    const [userSettings, setUserSettings] = useState({
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        customerId: '',
    });

    // Notification state with structure adapted to your API response
    const [notifications, setNotifications] = useState({
        application: [],
        server: [],
        metric: [],
    });

    // Replace with your API endpoints (modify for different notification types)
    const API_ENDPOINTS = {
        application: 'https://infia.enviseer.com/api/trends/cluster?cluster=100',
        server: 'https://infia.enviseer.com/api/trends/host?cluster=100&hostname=test_server',
        metric: 'https://infia.enviseer.com/api/trends/metric?cluster=100&hostname=test_server&metric=cpu_usage',
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        fetchUserSettings();
        if (!showDropdown) {
            setShowSettingsPanel(false);
        }
    };

    const toggleNotificationDropdown = (event) => {
        event.stopPropagation();
        setShowNotificationDropdown(!showNotificationDropdown);
    };

    const handleLogout = () => {
        console.log('User logged out');
        // Clear localStorage
        localStorage.clear();
        // Clear sessionStorage
        sessionStorage.clear();
        onLogout();
    };

    const openSettingsPanel = () => {
        setShowSettingsPanel(true);
    };

    const closeSettingsPanel = () => {
        setShowSettingsPanel(false);
    };

    function handleClickOutside(event) {
        // Check for the profile dropdown
        if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
            setShowDropdown(false); // Close the profile dropdown
        }
    
        // Check for the notification dropdown
        if (notificationDropdownRef.current && !notificationDropdownRef.current.contains(event.target)) {
            setShowNotificationDropdown(false); // Close the notification dropdown
        }
    }
    


    const switchTab = (tab) => {
        setActiveTab(tab);
    };

    const fetchNotifications = useCallback(async (endpoint) => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        
        const token = getCookie('token');
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Application-Name': 'envi-onboarding',
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`API request failed with status: ${response.status}`);
            }

            const data = await response.json();

            // Process the data to extract relevant notification information
            if(data.length>0){
            const formattedNotifications = processNotificationData(data); // Implement this function
            setNotifications(prevNotifications => ({
                ...prevNotifications,
                [activeTab]: formattedNotifications,
            }));
        }
        
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [activeTab]);

    useEffect(() => {
        // Fetch notifications for the active tab on component mount
        fetchNotifications(API_ENDPOINTS[activeTab]);

        // Consider fetching notifications periodically based on your use case
    }, [activeTab, fetchNotifications]); // Dependency on activeTab and fetchNotifications

    // useEffect(() => {
    //     if (showSettingsPanel) {
    //         fetchUserSettings();
    //     }
    // }, [showSettingsPanel]);


    useEffect(() => {
        if (showDropdown||showNotificationDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showDropdown, showNotificationDropdown]); // Ensure effects run only if showDropdown changes

    const fetchUserSettings = useCallback(async () => {
        setLoading(true);
        setError(null);
        function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
            const username = getCookie('username');

        try {
            const response = await fetch(`https://infia.enviseer.com/api/api/user/fetchUser?email=${username}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add the Authorization header with token
                    'Accept': 'application/json',
                    'X-Application-Name': 'envi-onboarding'
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setUserSettings({
                username: data.displayName,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                customerId: data.customerId,
            });
        } catch (error) {
            console.error("Error fetching user settings:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [userSettings]);

    const handleClickEnviApps = () =>{
        window.location.href = 'https://infia.enviseer.com/';
      }

    return (
        <div>
            <header id="header" className="header fixed-top d-flex align-items-center justify-content-between">
                <div className='title' style={{marginLeft:'32px'}}>{selectedPage.charAt(0).toUpperCase() + selectedPage.slice(1)}</div>
                <div className="header-icons">
                  <div className="icon-wrapper d-none d-md-block" onClick={handleClickEnviApps}
                    onMouseEnter={() => setVisibleTooltip('applauncher')}
                    onMouseLeave={() => setVisibleTooltip(null)}>
                    <i className="bi bi-boxes" style={{}}></i>
                    {visibleTooltip === 'applauncher' && (
                     <div className="custom-tooltip">
                      EnviApps
                     </div>
                     )}
                   </div>
                    <div className="notification-container" onClick={toggleNotificationDropdown} ref={notificationDropdownRef}>
                    <FaBell className="notification" />
                        {showNotificationDropdown && (
                            <div className="notification-dropdown">
                                <h3>Notifications</h3>
                                <div className="notification-tabs">
                                    <button
                                        className={activeTab === 'application' ? 'active' : ''}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            switchTab('application');
                                            fetchNotifications(API_ENDPOINTS.application); // Fetch on tab change
                                        }}
                                    >
                                        Application Insight
                                    </button>
                                    <button
                                        className={activeTab === 'server' ? 'active' : ''}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            switchTab('server');
                                            fetchNotifications(API_ENDPOINTS.server); // Fetch on tab change
                                        }}
                                    >
                                        Server Insight
                                    </button>
                                    <button
                                        className={activeTab === 'metric' ? 'active' : ''}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            switchTab('metric');
                                            fetchNotifications(API_ENDPOINTS.metric); // Fetch on tab change
                                        }}
                                    >
                                        Metric Insight
                                    </button>
                                </div>
                                {/* {error && <p className="error-message">Error fetching notifications: {error}</p>} */}
                                {notifications[activeTab].length > 0 ? (
                                    <ul>
                                        {notifications[activeTab].map((notif, index) => (
                                            <li key={index}>
                                                {notif.message}
                                                <span>{notif.time}</span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p style={{fontSize:'16px'}}>No notifications found.</p>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="profile-icon-container" onClick={toggleDropdown} ref={profileDropdownRef}>
                        <FaUserCircle className="header-icon profile-icon" />
                        {showDropdown && (
                            <div className="profile-dropdown">
                                <img 
                                    src="/demoProfile.png" 
                                    alt="Profile" 
                                    className="profile-picture" 
                                />
                                <div className="profile-info">
                                    <h4>Hi, {userSettings.username}</h4>
                                    <p>{userSettings.email}</p>
                                </div>
                                <button className="settings-button" onClick={openSettingsPanel}>
                                    Settings
                                </button>
                                <button className="logout-button" onClick={handleLogout}>
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </header>

            {showSettingsPanel && (
                <div className="settings-panel">
                    <div className="settings-panel-header">
                        <h5>Account Settings</h5>
                        <button onClick={closeSettingsPanel} className="close-settings-panel">X</button>
                    </div>
                    <form className="settings-form">
                        <div className="form-group">
                            <p> Username : {userSettings.username}</p>
                        </div>
                        <div className="form-group">
                            <p> Email : {userSettings.email}</p>
                        </div>
                        <div className="form-group">
                            <p> Firstname : {userSettings.firstName} </p>
                        </div>
                        <div className="form-group">
                            <p>Lastname : {userSettings.lastName} </p>
                        </div>
                        <div className="form-group">
                            <p>CustomerId : {userSettings.customerId}</p>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}

export default Header;
