import React from 'react';
import { Handle, Position } from 'reactflow';

const CustomNode = ({ data }) => {
    return (
        <div className={`node ${data.selected ? 'selected' : ''}`}>
            <Handle
                type="target"
                position={Position.Top}
                className="handle input"
            />
            <div className="node-header">
                <i className={`bi ${data.icon}`}></i>
                <h3>{data.label}</h3>
            </div>
            {data.description && (
                <div className="node-content">
                    {data.description}
                </div>
            )}
            <Handle
                type="source"
                position={Position.Bottom}
                className="handle output"
            />
        </div>
    );
};

export default CustomNode; 