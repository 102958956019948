import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { formatDateTime, convertToIST, } from './utils'; // Assuming you have utility functions in a utils.js file

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            const label = chart.data.labels[activePoint.index];
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'lightgrey';
            ctx.stroke();
            ctx.restore();

            const labelX = x;
            const labelY = bottomY + 20;
            ctx.fillStyle = 'white';
            ctx.fillRect(labelX - 20, labelY - 10, 40, 20); // Adjust width and height accordingly
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(label.substring(0, 5), x, bottomY + 20);
            ctx.fillText(label.substring(6, label.length), x, bottomY + 35);
            ctx.restore();


        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps1, data1, label1, borderColor1, backgroundColor1, timestamps2, data2, label2, pointColor, anomaly) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
    gradient1.addColorStop(0, borderColor1);
    gradient1.addColorStop(0.6, backgroundColor1);
    gradient1.addColorStop(0.9, 'rgba(0,210,255,0)'); // Transparent end

    const warningTriangle = new Image();   
    warningTriangle.src = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="red" width="24" height="24" viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/></svg>';    
    const dummyStartTimestamp = new Date();

    return {
        labels: timestamps1.map(convertToIST),
        datasets: [
            {
                label: label1,
                data: data1,
                fill: true,
                borderColor: borderColor1,
                backgroundColor: timestamps1.map((timestamp) => {
                    const currentTimestamp = new Date(timestamp);
                    return currentTimestamp >= dummyStartTimestamp ? 'rgba(0, 255, 0, 0.3)' : gradient1; // Greenish background for dummy data
                }),                tension: 0.3,
                pointBorderWidth: 1,
                pointRadius: 0,
                borderWidth: 2,
                pointBackgroundColor: borderColor1,
                pointStyle: 'circle',
                pointHoverRadius: 4,
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                order: 1
            },
            {
                label: label2,
                data: data2,
                fill: false,
                tension: 0.2,
                pointBorderWidth: 0.3,
                pointRadius: anomaly.map(value => value > 0 ? 4 : 0),
                pointBackgroundColor: anomaly.map((value, index) => {
                    return value > 0 ? pointColor : borderColor1; // Orange for dummy points
                }),                pointBorderColor: anomaly.map(value => value > 0 ? 'black' : 'transparent'),
                pointStyle: anomaly.map((value, index) => {
                    const currentTimestamp = new Date(timestamps2[index]);
                    return value > 0 && currentTimestamp >= dummyStartTimestamp ? warningTriangle : 'circle';
                }),
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                backgroundColor: pointColor,
                borderColor: 'transparent',
                pointHoverRadius: anomaly.map(value => value > 0 ? 6 : 0),
                order: 0,

            }
        ],
    };
};

const chartOptions = (metricName, handleAnomalyClick, dataSource, server, anomaly) => {
    return{
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                autoSkip: true,
                maxRotation: 45,
                minRotation: 0,
                maxTicksLimit: 7,
                callback: function(value) {
                    let label = this.getLabelForValue(value);
                    if (label.length > 5) {
                        label = label.substring(5);
                    }
                    return label;
                }
            },
            title: {
                display: true,
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function(context) {
                    let label = context.dataset.label || '';
                    let value = context.raw;

                        // Conditional formatting based on dataset index
                        if (context.datasetIndex === 0) {
                            // Format for metricData1
                            return label + ': ' + value + '%';
                        } else {
                            // Default formatting if there are more datasets
                            return label;
                        }
                    },
                },
            },
            verticalLinePlugin: true,
        },
        hover: {
            mode: 'nearest',
            intersect: true,
            onHover: function (event, chartElement) {
                const element = chartElement[0];
                if (element && element.datasetIndex === 1) { // Assuming anomaly data is in the second dataset (index 1)
                    const anomalyValue = anomaly[element.index];
                    if (anomalyValue > 0) {
                        event.native.target.style.cursor = 'pointer';
                    } else {
                        event.native.target.style.cursor = 'default';
                    }
                } else {
                    event.native.target.style.cursor = 'default';
                }
            },
        },
        onClick: function (event, chartElement) {
            const element = chartElement[0];
            if (element && element.datasetIndex === 1) {
                const anomalyValue = anomaly[element.index];
                const timestamp = this.data.labels[element.index]; // Get the timestamp

                if (anomalyValue > 0) {
                    localStorage.setItem('DataSource', dataSource);
                    localStorage.setItem('Server', server);
                    localStorage.setItem('Timestamp', timestamp);
                    handleAnomalyClick('logs');
                }
            }
        },
    };
};



const fetchMetricData = async (metricName, dataSource, server, start, end) => {
    const formattedStartTime = "2024-10-20T17:52:25Z";
    const currentTime = new Date();
    const formattedEndTime = currentTime.toISOString().slice(0, 19) + 'Z';  

    console.log("jjjjjjjjjjjjjj");
    const url = `https://infia.enviseer.com/api/metrics?&cluster=${dataSource}&hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}`;
    console.log(url,metricName);
    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {

        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => {
            // Check if entry.value is valid and a number
            const num = parseFloat(entry.value);
            return isNaN(num) ? '0.00' : num.toFixed(2);
        });
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};


const fetchStrictAnomalyData = async (dataSource, server, start, end, metricName) => {
    const formattedStartTime = "2024-10-20T17:52:25Z";
    const currentTime = new Date();
    const formattedEndTime = currentTime.toISOString().slice(0, 19) + 'Z';  
    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
	const url =`https://infia.enviseer.com/api/clusters/${dataSource}/metrics?hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}&mode=strict`;
          const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.is_anomaly);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }

};

const DetectGraph = ({ dataSource, server, startDate, endDate, metricName, metricName2, label1, label2, borderColor1, backgroundColor1, handleAnomalyClick }) => {
    const [timestamps1, setTimestamps1] = useState([]);
    const [metricData1, setMetricData1] = useState([]);
    const [timestamps2, setTimestamps2] = useState([]);
    const [metricData2, setMetricData2] = useState([]);
    const [anomaly, setAnomaly] = useState([]);
    const [pointColor, setPointColor] = useState('');
    const chartRef = useRef(null);

    useEffect(() => {
        console.log('Metric Name Updated:', metricName);
        // Fetch and update chart data
    }, [metricName]);

    useEffect(() => {
        
        const fetchData = async () => {

            try {
                const data1 = await fetchMetricData(metricName, dataSource, server, startDate, endDate);
                let data2;
                let color;
                data2 = await fetchStrictAnomalyData(dataSource, server, startDate, endDate, metricName);
                color = '#CF1020';
                
                setPointColor(color);
                setTimestamps1(data1.timestamps);
                setMetricData1(data1.values);

                const AnomalyData2 = data1.timestamps.map((timestamp, index) => {
                    const value1 = data1.values[index];
                
                    // Handle the first timestamp (no previous timestamp to compare)
                    if (index === 0) {
                        const foundAnomaly = data2.timestamps.includes(timestamp) && data2.values[data2.timestamps.indexOf(timestamp)] > 0;
                        return foundAnomaly ? value1 : 0;
                    }
                
                    // Get the previous timestamp
                    const previousTimestamp = new Date(data1.timestamps[index - 1]);
                    const currentTimestamp = new Date(timestamp);
                
                    // Check if any timestamp from `data2` falls between previous and current timestamp
                    const foundAnomaly = data2.timestamps.some((data2Timestamp, data2Index) => {
                        const data2Time = new Date(data2Timestamp);
                
                        // Check if data2Time is strictly between the previous and current timestamps
                        return data2Time > previousTimestamp && data2Time <= currentTimestamp && data2.values[data2Index] > 0;
                    });
                
                    // If there's an anomaly, keep the value1; otherwise, return 0
                    return foundAnomaly ? value1 : 0;
                });
                const AnomalyData3 = data1.timestamps.map((timestamp, index) => {
                    const value1 = data1.values[index];
                
                    // Handle the first timestamp (no previous timestamp to compare)
                    if (index === 0) {
                        const foundAnomaly = data2.timestamps.includes(timestamp) && data2.values[data2.timestamps.indexOf(timestamp)] > 0;
                        return foundAnomaly ? value1 : 0;
                    }
                
                    // Get the previous timestamp
                    const previousTimestamp = new Date(data1.timestamps[index - 1]);
                    const currentTimestamp = new Date(timestamp);
                
                    // Check if any timestamp from `data2` falls between previous and current timestamp
                    const foundAnomaly = data2.timestamps.some((data2Timestamp, data2Index) => {
                        const data2Time = new Date(data2Timestamp);
                
                        // Check if data2Time is strictly between the previous and current timestamps
                        return data2Time > previousTimestamp && data2Time <= currentTimestamp && data2.values[data2Index] > 0;
                    });
                
                    // If there's an anomaly, keep the value1; otherwise, return 0
                    return foundAnomaly ? value1+1 : 0;
                });
                const lastTimestamp = new Date(data1.timestamps[data1.timestamps.length - 1]);
            const dummyTimestamps = [];
            const dummyMetricData1 = [];
            const dummyMetricData2 = [];
            const dummyAnomaly = [];

            for (let i = 1; i <= 10; i++) {
                const newTimestamp = new Date(lastTimestamp.getTime() + i * 60 * 60 * 1000); // Add 1 hour each
                dummyTimestamps.push(newTimestamp.toISOString());

                // For metricData1, copy the last value except for the 4th value, which is set to 0
                const lastValue = data1.values[data1.values.length - 1];
                dummyMetricData1.push(i> 4 ? 2*lastValue : lastValue);

                // For metricData2, set all to 0 except for the 4th value, which is 1
                dummyMetricData2.push(i === 4 ? lastValue : 0);

                // For anomaly, set all to 0 except for the 4th value, which is 2
                dummyAnomaly.push(i === 4 ? lastValue : 0);
            }
            setTimestamps1(prev => [...prev, ...dummyTimestamps]);
            setMetricData1(prev => [...prev, ...dummyMetricData1]);
            setMetricData2(prev => [ ...AnomalyData2, ...dummyMetricData2]);
            setAnomaly(prev => [ ...AnomalyData3, ...dummyAnomaly]);

            } catch (error) {
                console.error(`Error fetching data:`, error);
                setTimestamps1([]);
                setMetricData1([]);
                setTimestamps2([]);
                setMetricData2([]);
            }
        };

        fetchData();
    }, [dataSource, server, startDate, endDate, metricName]);

    const chartData = generateChartData(
        timestamps1, metricData1, label1, borderColor1, backgroundColor1,
        timestamps1, metricData2, label2, pointColor, anomaly
    );

    return <Line data={chartData} options={chartOptions(metricName, handleAnomalyClick, dataSource, server, anomaly)} ref={chartRef} />;
};

export default DetectGraph;

