import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip?._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;

            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'lightgrey';
            ctx.stroke();
            ctx.restore();
        }
    }
};

ChartJS.register(verticalLinePlugin);

// Add prediction window plugin
const predictionWindowPlugin = {
    id: 'predictionWindowPlugin',
    beforeDraw: (chart, args, options) => {
        const { ctx, chartArea, scales } = chart;
        if (!options.predictionWindow) return;

        const { start, center, end } = options.predictionWindow;
        
        // Convert dates to x-axis positions
        const startX = scales.x.getPixelForValue(
            `${start.getHours().toString().padStart(2, '0')}:${start.getMinutes().toString().padStart(2, '0')}`
        );
        const endX = scales.x.getPixelForValue(
            `${end.getHours().toString().padStart(2, '0')}:${end.getMinutes().toString().padStart(2, '0')}`
        );
        const centerX = scales.x.getPixelForValue(
            `${center.getHours().toString().padStart(2, '0')}:${center.getMinutes().toString().padStart(2, '0')}`
        );
        
        if (!startX || !endX) return;

        ctx.save();

        // Create gradient for the prediction window
        const gradient = ctx.createLinearGradient(startX, 0, endX, 0);
        gradient.addColorStop(0, 'rgba(104, 37, 236, 0.02)');
        gradient.addColorStop(0.5, 'rgba(104, 37, 236, 0.08)');
        gradient.addColorStop(1, 'rgba(104, 37, 236, 0.02)');

        // Draw prediction window with gradient
        ctx.fillStyle = gradient;
        ctx.fillRect(startX, chartArea.top, endX - startX, chartArea.height);
        
        // Draw borders with gradient
        const borderGradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        borderGradient.addColorStop(0, 'rgba(104, 37, 236, 0.4)');
        borderGradient.addColorStop(1, 'rgba(104, 37, 236, 0.1)');

        // Draw dashed borders
        ctx.beginPath();
        ctx.setLineDash([4, 4]);
        ctx.strokeStyle = borderGradient;
        ctx.lineWidth = 1.5;
        
        // Left border
        ctx.beginPath();
        ctx.moveTo(startX, chartArea.top);
        ctx.lineTo(startX, chartArea.bottom);
        ctx.stroke();
        
        // Right border
        ctx.beginPath();
        ctx.moveTo(endX, chartArea.top);
        ctx.lineTo(endX, chartArea.bottom);
        ctx.stroke();

        // Draw center line with gradient
        if (centerX) {
            const centerGradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
            centerGradient.addColorStop(0, 'rgba(104, 37, 236, 0.8)');
            centerGradient.addColorStop(1, 'rgba(104, 37, 236, 0.2)');

            ctx.beginPath();
            ctx.setLineDash([]);
            ctx.strokeStyle = centerGradient;
            ctx.lineWidth = 1.5;
            ctx.moveTo(centerX, chartArea.top);
            ctx.lineTo(centerX, chartArea.bottom);
            ctx.stroke();
        }
        
        // Add label background
        const labelText = '±30min';
        const labelY = chartArea.top + 16;
        const labelX = (startX + endX) / 2;
        const labelMetrics = ctx.measureText(labelText);
        const labelPadding = 6;
        const labelHeight = 20;

        // Draw label background
        ctx.fillStyle = 'rgba(104, 37, 236, 0.08)';
        ctx.beginPath();
        ctx.roundRect(
            labelX - labelMetrics.width / 2 - labelPadding,
            labelY - labelHeight / 2,
            labelMetrics.width + labelPadding * 2,
            labelHeight,
            4
        );
        ctx.fill();

        // Draw label text
        ctx.font = '600 11px Inter';
        ctx.fillStyle = '#6825EC';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(labelText, labelX, labelY);
        
        ctx.restore();
    }
};

// Register the new plugin
ChartJS.register(predictionWindowPlugin);

const formatMetricName = (metric) => {
    if (!metric) return "Metric";
    if (metric == "cpu_usage") return "CPU Usage";
}

const generateChartData = (timestamps, data, label1, label2, borderColor, backgroundColor, startDate, endDate) => {
    const nearestStartIndex = timestamps.reduce((nearestIndex, timestamp, index) => {
        const currentDiff = Math.abs(new Date(timestamp) - new Date(startDate));
        const nearestDiff = Math.abs(new Date(timestamps[nearestIndex]) - new Date(startDate));
        return currentDiff < nearestDiff ? index : nearestIndex;
    }, 0);

    const nearestEndIndex = timestamps.reduce((nearestIndex, timestamp, index) => {
        const currentDiff = Math.abs(new Date(timestamp) - new Date(endDate));
        const nearestDiff = Math.abs(new Date(timestamps[nearestIndex]) - new Date(endDate));
        return currentDiff < nearestDiff ? index : nearestIndex;
    }, 0);

    // Default color if borderColor is not provided
    const defaultColor = '#6825EC';
    const lineColor = borderColor || defaultColor;

    return {
        labels: timestamps.map(timestamp => {
            const date = new Date(timestamp);
            return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        }),
        datasets: [
            {
                label: formatMetricName(label1),
                data: data,
                fill: true,
                borderColor: lineColor,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) return 'rgba(104, 37, 236, 0.1)';
                    
                    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                    gradient.addColorStop(0, `rgba(104, 37, 236, 0.2)`);
                    gradient.addColorStop(1, `rgba(104, 37, 236, 0.05)`);
                    return gradient;
                },
                tension: 0.4,
                pointBorderWidth: 2,
                pointRadius: data.map((_, index) => {
                    if (index === nearestStartIndex || index === nearestEndIndex) return 6;
                    return 3;
                }),
                pointBackgroundColor: data.map((_, index) => {
                    if (index === nearestStartIndex) return '#FFD700';
                    if (index === nearestEndIndex) return '#FF4444';
                    return lineColor;
                }),
                pointHoverRadius: 5,
                pointStyle: data.map((_, index) => {
                    if (index === nearestStartIndex || index === nearestEndIndex) return 'circle';
                    return 'circle';
                }),
                borderWidth: 2,
            }
        ],
    };
};

const chartOptions = (metricName, startDate, endDate, timestamps, predictionWindow) => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 800,
            easing: 'easeOutQuart'
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'rgba(229, 231, 235, 0.5)'
                },
                ticks: {
                    display: true,
                    color: '#6B7280',
                    font: {
                        size: 10
                    },
                    maxRotation: 45,
                    minRotation: 45,
                    callback: function(value, index, values) {
                        return index % Math.ceil(values.length / 6) === 0 ? this.getLabelForValue(value) : '';
                    }
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(229, 231, 235, 0.5)'
                },
                ticks: {
                    color: '#6B7280',
                    font: {
                        size: 10
                    },
                    padding: 8,
                    callback: function(value) {
                        return value > 100 ? '' : value;
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: 'rgba(17, 24, 39, 0.9)',
                titleFont: {
                    size: 13,
                    weight: 'bold'
                },
                bodyFont: {
                    size: 12
                },
                padding: 12,
                cornerRadius: 8,
                displayColors: false,
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function(context) {
                        const index = context[0].dataIndex;
                        const fullTimestamp = new Date(timestamps[index]);
                        return `${fullTimestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} ${fullTimestamp.toLocaleDateString([], { day: '2-digit', month: '2-digit' })}`;
                    },
                    label: function(context) {
                        return `${formatMetricName(metricName)}: ${context.raw}`;
                    }
                }
            },
            verticalLinePlugin: true,
            predictionWindowPlugin: {
                predictionWindow: predictionWindow
            }
        },
    };
};

const fetchMetricData = async (metricName, dataSource, server, start, end) => {
    const endTime = new Date(end);

    // Calculate start time: 6 hours before `end`
    const startTime = new Date(start);

    // Calculate formatted end time: 2 hours after `end`
    const formattedEndTime = new Date(endTime);
    formattedEndTime.setHours(formattedEndTime.getHours() + 1);

    // Format times to ISO string format 'YYYY-MM-DDTHH:mm:ssZ'
    const formattedStartTime = new Date(startTime);
    formattedStartTime.setHours(formattedStartTime.getHours() - 2);
    const formattedFormattedStartTime = formattedStartTime.toISOString().slice(0, 19) + 'Z';
    const formattedFormattedEndTime = formattedEndTime.toISOString().slice(0, 19) + 'Z';

    const url = `https://infia.enviseer.com/api/metrics?&cluster=${dataSource}&hostname=${server}&start=${formattedFormattedStartTime}&end=${formattedFormattedEndTime}&metric=${metricName}`;
    //console.log(url, metricName);
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const token = getCookie('token');
    // const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3MzQ1MzM5OTIsImV4cCI6MTczNDUzNzU5Mn0.aFcQ7jfj1uDBgkAes8iJnor-WEUttIapcdpkZNCR1NA';
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`, // Adding the Authorization header
            'Accept': 'application/json'
        }
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {

        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => {
            // Check if entry.value is valid and a number
            const num = parseFloat(entry.value);
            return isNaN(num) ? '0.00' : num.toFixed(2);
        });
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};

// Update DetectGraph component to accept predictionWindow prop
const DetectGraph = ({ 
    dataSource, 
    server, 
    startDate, 
    endDate, 
    metricName, 
    label1, 
    label2, 
    borderColor, 
    backgroundColor,
    predictionWindow 
}) => {
    const [timestamps, setTimestamps] = useState([]);
    const [metricData, setMetricData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchMetricData(metricName, dataSource, server, startDate, endDate);
                setTimestamps(data.timestamps);
                setMetricData(data.values);
            } catch (error) {
                console.error('Error fetching metric data:', error);
                setTimestamps([]);
                setMetricData([]);
            }
        };
        fetchData();
    }, [dataSource, server, startDate, endDate, metricName]);

    const chartData = generateChartData(timestamps, metricData, label1, label2, borderColor, backgroundColor, startDate, endDate);

    return (
        <div style={{
            borderRadius: '12px',
            padding: '20px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div style={{
                flex: 1,
                position: 'relative',
                minHeight: '280px',
                paddingBottom: '20px', // Add padding to ensure x-axis is visible
                overflow: 'visible' // Ensure x-axis labels are visible
            }}>
                <Line 
                    data={chartData} 
                    options={chartOptions(metricName, startDate, endDate, timestamps, predictionWindow)} 
                />
            </div>
        </div>
    );
};

export default DetectGraph;