import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { formatDateTime, convertToIST, } from './utils'; // Assuming you have utility functions in a utils.js file

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            const label = chart.data.labels[activePoint.index];
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'lightgrey';
            ctx.stroke();
            ctx.restore();

            const labelX = x;
            const labelY = bottomY + 20;
            ctx.fillStyle = 'white';
            ctx.fillRect(labelX - 20, labelY - 10, 40, 20); // Adjust width and height accordingly
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(label.substring(0, 5), x, bottomY + 20);
            ctx.fillText(label.substring(6, label.length), x, bottomY + 35);
            ctx.restore();


        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps1, data1, label1, borderColor1, backgroundColor1, timestamps2, data2, label2, pointColor, anomaly) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
    gradient1.addColorStop(0, borderColor1);
    gradient1.addColorStop(0, backgroundColor1);
    gradient1.addColorStop(0.7, 'rgba(138,43,226,0)'); // Transparent end

    return {
        labels: timestamps1.map(convertToIST),
        datasets: [
            {
                label: label1,
                data: data1,
                fill: true,
                borderColor: borderColor1,
                backgroundColor: gradient1,
                tension: 0.3,
                pointBorderWidth: 1,
                pointRadius: 0,
                borderWidth: 2,
                pointBackgroundColor: borderColor1,
                pointStyle: 'circle',
                pointHoverRadius: 4,
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                order: 1
            },
            {
                label: label2,
                data: data2,
                fill: false,
                tension: 0.2,
                pointBorderWidth: 0.3,
                pointRadius: anomaly.map(value => value > 0 ? 4 : 0),
                pointBackgroundColor: pointColor, // Fixed red color for anomaly points
                pointBorderColor: anomaly.map(value => value > 0 ? 'black' : 'transparent'),
                pointStyle: 'circle',
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                backgroundColor: '#CF1020', // Fixed red color for legend
                borderColor: 'transparent',
                pointHoverRadius: anomaly.map(value => value > 0 ? 6 : 0),
                order: 0,
            }
        ],
    };
};

const generateChartDataRange = (timestamps1, data1, label1, borderColor1, backgroundColor1, data2) => {
    

    const ctx = document.createElement('canvas').getContext('2d');
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
    gradient1.addColorStop(0, borderColor1);
    gradient1.addColorStop(0, backgroundColor1);
    gradient1.addColorStop(0.7, 'rgba(138,43,226,0)'); // Transparent end for fill

    // Extract lower and upper bounds from the confidence interval data (data2)
    const lowerBounds = data2.map(item => item.lower_value);
    const upperBounds = data2.map(item => item.upper_value);
    const confidenceTimestamps = data2.map(item => item.timestamp); // Extract confidence timestamps

    

    // Prepare the data for the line, with metric data and confidence bounds
    const points = [];
    const lowerBoundPoints = [];
    const upperBoundPoints = [];
    const inRangeData = [];
    const inRangeTimestamps = [];

    let rangeStarted = false;

    for (let i = 0; i < timestamps1.length; i++) {
        const timestamp = timestamps1[i];
        const value = data1[i];
        const matchedIndex = confidenceTimestamps.indexOf(timestamp);

        // If there's matching confidence range data, use it
        if (matchedIndex !== -1) {
            const lowerBound = lowerBounds[matchedIndex];
            const upperBound = upperBounds[matchedIndex];

            if (!rangeStarted) {
                // Start showing the confidence range only after the first match
                rangeStarted = true;
            }

            lowerBoundPoints.push(lowerBound);
            upperBoundPoints.push(upperBound);
        } else {
            // If no confidence range data, just add the metric value
            if (rangeStarted) {
                // If range has started, we still need to push null values after that
                lowerBoundPoints.push(null);
                upperBoundPoints.push(null);
            } else {
                lowerBoundPoints.push(null); // No lower bound to show
                upperBoundPoints.push(null); // No upper bound to show
            }
        }

        // Always push the metric data points
        points.push({ x: timestamp, y: value });
        inRangeData.push(value);
        inRangeTimestamps.push(timestamp);
    }

    return {
        labels: timestamps1.map(convertToIST), // Format timestamps to IST
        datasets: [
            {
                label: label1,
                data: inRangeData, // The metric data (line)
                fill: false,
                borderColor: borderColor1,
                backgroundColor: gradient1,
                tension: 0.3,
                pointBorderWidth: 1,
                pointRadius: 0,
                borderWidth: 2,
                pointBackgroundColor: borderColor1,
                pointStyle: 'circle',
                pointHoverRadius: 4,
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                order: 1,
            },
            {
                label: 'CIL',
                data: lowerBoundPoints,
                tension: 0.3,
                fill: false,  // No fill for this dataset
                borderColor: 'transparent',
                backgroundColor: 'rgba(138,43,226,0.2)', // Light purple color for the lower bound
                borderWidth: 2,
                pointRadius: 0,
                order: 2,
            },
            {
                label: 'CIU',
                tension: 0.3,
                data: upperBoundPoints,
                fill: '-1',  // Fill between this dataset and the previous one (lower bound)
                borderColor: 'transparent',
                backgroundColor: 'rgba(138,43,226,0.2)', // Light purple color for the upper bound
                borderWidth: 2,
                pointRadius: 0,
                order: 2,
            },
        ],
    };
};



// Update the chart options to handle consistent tooltip formatting across all modes
const chartOptions = (metricName, handleAnomalyClick, dataSource, server, anomaly, onZoom) => {
    const percentageMetrics = ['cpu_usage', 'memory_usage', 'disk_usage', 'cpu.percent.used'];
    
    // Create a formatted title with consistent date/time format
    const formatTooltipTitle = (tooltipItems) => {
        const item = tooltipItems[0];
        const timestamp = item.label; // Use the label which contains the timestamp
        const date = new Date(timestamp);
        
        // Format as Time in 24 Hour Format, Date in DD/MM/YYYY
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        
        // Format time as 24-hour HH:MM
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        return `${hours}:${minutes}, ${day}/${month}/${year}`;
    };
    
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: { display: false },
                ticks: {
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 0,
                    maxTicksLimit: 7,
                    callback: function(value) {
                        let label = this.getLabelForValue(value);
                        if (label.length > 5) {
                            label = label.substring(5);
                        }
                        return label;
                    }
                },
                title: { display: true },
            },
            y: {
                grid: { display: false },
                beginAtZero: true,
                title: { display: false },
            },
        },
        plugins: {
            legend: { display: true, position: 'top' },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: formatTooltipTitle, // Use consistent title format for all modes
                    label: function(context) {
                        let label = context.dataset.label || '';
                        
                        if (context.datasetIndex === 0) {
                            // For metric data (first dataset)
                            let value = context.parsed.y;
                            if (percentageMetrics.includes(metricName)) {
                                return `${label}: ${value}%`;
                            } else if (metricName.includes('memory') || metricName === 'memory_total') {
                                return `${label}: ${value} GB`;
                            }
                            return `${label}: ${value}`;
                        } else {
                            // For anomaly data (second dataset)
                            if (selectedOptions === "Collective Insight") {
                                // Show anomaly count for Collective Insight
                                return anomaly[context.dataIndex] > 0 ? 
                                    `${label}: ${anomaly[context.dataIndex]} anomalies` : 
                                    `${label}: 0`;
                            } else {
                                // For Deviations, show the metric value
                                let value = context.parsed.y;
                                if (percentageMetrics.includes(metricName)) {
                                    return `${label}: ${value}%`;
                                } else if (metricName.includes('memory') || metricName === 'memory_total') {
                                    return `${label}: ${value} GB`;
                                }
                                return `${label}: ${value}`;
                            }
                        }
                    },
                },
            },
            zoom: {
                pan: { enabled: true, mode: 'x' },
                zoom: {
                    drag: {
                        enabled: true,
                        backgroundColor: 'rgba(177, 162, 231, 0.3)',
                        borderColor: 'rgba(142, 141, 144, 0.5)',
                        borderWidth: 1,
                    },
                    mode: 'x',
                    onZoomComplete({ chart }) {
                        const labels = chart.data.labels;
                        const startX = Math.max(Math.floor(chart.scales.x.min), 0);
                        const endX = Math.min(Math.ceil(chart.scales.x.max), labels.length - 1);
            
                        const parseCustomDate = (label) => {
                          if (label.includes(' ')) {
                            const [time, date] = label.split(' ');
                            const [hours, minutes] = time.split(':');
                            const [day, month] = date.split('/');
                            const year = new Date().getFullYear();
                            return new Date(year, month - 1, day, hours, minutes);
                          } else {
                            const [hours, minutes] = label.split(':');
                            const now = new Date();
                            return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
                          }
                        };
            
                        const newStartDate = parseCustomDate(labels[startX]);
                        const newEndDate = parseCustomDate(labels[endX]);
            
                        if (onZoom && !isNaN(newStartDate) && !isNaN(newEndDate)) {
                          onZoom(newStartDate, newEndDate);
                          //console.log("Zoom Dates:", newStartDate, newEndDate);
                        } else {
                          console.error("Invalid dates from zoom:", labels[startX], labels[endX]);
                        }
                      },
                    },
                  },
                },
        hover: {
            mode: 'nearest',
            intersect: true,
            onHover: function (event, chartElement) {
                const element = chartElement[0];
                if (element && element.datasetIndex === 1) {
                    event.native.target.style.cursor = anomaly[element.index] > 0 ? 'pointer' : 'default';
                } else {
                    event.native.target.style.cursor = 'default';
                }
            },
        },
        onClick: function (event, chartElement) {
            const element = chartElement[0];
            if (element && element.datasetIndex === 1 && anomaly[element.index] > 0) {
                const timestamp = this.data.labels[element.index];
                localStorage.setItem('DataSource', dataSource);
                localStorage.setItem('Server', server);
                localStorage.setItem('Timestamp', timestamp);
                handleAnomalyClick('infra');
            }
        },
    };
};




const fetchMetricData = async (metricName, dataSource, server, start, end) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    const url = `https://infia.enviseer.com/api/metrics?&cluster=${dataSource}&hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}`;
    //console.log(url,metricName);
    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {

        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => {
            // Check if entry.value is valid and a number
            const num = parseFloat(entry.value_readable? entry.value_readable:entry.value);
            return isNaN(num) ? '0.00' : num.toFixed(2);
        });
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};


const fetchAnomalyData = async (dataSource, server, start, end, metricName) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    const url = `https://infia.enviseer.com/api/clusters/${dataSource}/metrics?hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}&mode=moderate`;

    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
          const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.is_anomaly);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }

};

const fetchStrictAnomalyData = async (dataSource, server, start, end, metricName) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
	const url =`https://infia.enviseer.com/api/clusters/${dataSource}/metrics?hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}&mode=strict`;
          const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.is_anomaly);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }

};

const fetchDeviationData = async (dataSource, server, start, end, specificMetric = null) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
        
    const token = getCookie('token');
    const url = `https://infia.enviseer.com/api/clusters/${dataSource}/hosts/${server}/anomaly_analytics/mini?start=${formattedStartTime}&end=${formattedEndTime}`;
    
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        }
    });
    
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    const data = await response.json();
    
    // Filter data by specific metric if provided
    const filteredData = specificMetric ? 
        data.filter(entry => {
            // Check if it matches the specific metric name
            if (entry.metric === specificMetric) return true;
            
            // Handle category matches (e.g., if specificMetric is CPU, include all cpu_* metrics)
            if (specificMetric === 'CPU' && entry.metric.includes('cpu')) return true;
            if (specificMetric === 'Memory' && (entry.metric.includes('memory') || entry.metric === 'memory_total')) return true;
            if (specificMetric === 'Disk' && entry.metric.includes('disk')) return true;
            if (specificMetric === 'Network' && entry.metric.includes('network')) return true;
            
            return false;
        }) : data;
    
    // Group data by timestamp
    const groupedByTimestamp = {};
    const metricsByTimestamp = {};
    
    // First pass: Organize data by hourly timestamps
    if (Array.isArray(filteredData)) {
        filteredData.forEach(entry => {
            // Round timestamp to the nearest hour for aggregation
            const timestamp = entry.detected_timestamp;
            const date = new Date(timestamp);
            const hourTimestamp = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours()
            ).toISOString();
            
            // Initialize if not exists
            if (!groupedByTimestamp[hourTimestamp]) {
                groupedByTimestamp[hourTimestamp] = [];
                metricsByTimestamp[hourTimestamp] = {};
            }
            
            // Add entry to this hour's group
            groupedByTimestamp[hourTimestamp].push(entry);
            
            // Track metrics for this timestamp
            const metricName = entry.metric;
            metricsByTimestamp[hourTimestamp][metricName] = 
                (metricsByTimestamp[hourTimestamp][metricName] || 0) + 1;
        });
    }
    
    // Convert to arrays with counts
    const timestamps = Object.keys(groupedByTimestamp).sort();
    const values = timestamps.map(ts => groupedByTimestamp[ts].length);
    const metricsData = timestamps.map(ts => metricsByTimestamp[ts]);
    
    const totalAnomalies = values.reduce((sum, count) => sum + count, 0);
    console.log(`Total ${specificMetric || 'all'} anomalies:`, totalAnomalies);
    
    return { 
        timestamps, 
        values, 
        metricsData,
        rawData: filteredData, // Include raw data for detailed processing
        totalCount: totalAnomalies
    };
};

// Extend the fetchAnomalyCount function to include deviations with metric filtering
const fetchAnomalyCount = async (dataSource, server, start, end, mode, specificMetric = null) => {
        const formattedStartTime = formatDateTime(start);                                                                
        const formattedEndTime = formatDateTime(end);
        try {
            function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
        
        // Handle deviations differently
        if (mode === 'deviations') {
            const deviationsData = await fetchDeviationData(dataSource, server, start, end, specificMetric);
            
            // Create a synthetic result similar to the standard API response format
            if (specificMetric) {
                // If a specific metric was requested, return just that count
                return [{
                    label: specificMetric.toLowerCase(),
                    count: deviationsData.totalCount
                }];
            } else {
                // Otherwise count anomalies by metric category
                const metricCounts = {};
                
                // Count anomalies by category
                if (Array.isArray(deviationsData.rawData)) {
                    deviationsData.rawData.forEach(entry => {
                        const metric = entry.metric;
                        let category;
                        
                        // Categorize by metric name
                        if (metric.includes('cpu')) {
                            category = 'cpu';
                        } else if (metric.includes('memory') || metric === 'memory_total') {
                            category = 'memory';
                        } else if (metric.includes('disk')) {
                            category = 'disk';
                        } else if (metric.includes('network')) {
                            category = 'network';
                        } else {
                            category = 'other';
                        }
                        
                        metricCounts[category] = (metricCounts[category] || 0) + 1;
                    });
                }
                
                // Convert to array format expected by the caller
                return Object.entries(metricCounts).map(([key, value]) => ({
                    label: key,
                    count: value
                }));
            }
        }
        
        // Original anomaly count fetching for other modes
            const response = await fetch('https://infia.enviseer.com/api/anomaliescount?' + new URLSearchParams({
                cluster: dataSource,
                hostname: server,
                start: formattedStartTime,
                end: formattedEndTime,
                mode: mode,
            }),
            {
                method: 'GET',
                headers: {
                'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Transform the data to the required format
        const transformedData = Object.entries(data).map(([key, value]) => ({
            label: key,
            count: value
        }));

        return transformedData;
    } catch (error) {
        console.error('Error fetching anomaly data:', error);
        return [];
    }
};

const fetchConfidenceIntervalData = async (dataSource, server, start, end, metricName) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
        
    const token = getCookie('token');
    const url = `https://infia.enviseer.com/api/metric_ci_range`;

    const payload = {
        cluster: dataSource,
        hostname: server,
        type: 'basic',
        metric: metricName,
        start: formattedStartTime,
        end: formattedEndTime
    };


    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(payload),
        });


        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();

        if (!Array.isArray(data)) {
            throw new Error("Unexpected response format: expected an array");
        }

        // Optional: Filter out invalid CI values
        const cleanedData = data.map(entry => ({
            ...entry,
            lower_value: Math.max(0, entry.lower_value),
            upper_value: Math.max(entry.lower_value, entry.upper_value)
        }));

        console.log("Fetched CI data:", cleanedData);
        return cleanedData;
    } catch (error) {
        console.error("Error fetching confidence interval data:", error.message || error);
        return [];
    }
};


const EmptyGraphState = ({ selectedOptions }) => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '350px',
        padding: '20px',
        borderRadius: '12px',
    }}>
        <div style={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            background: 'linear-gradient(135deg, #8859d9, #4c6bf5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
            boxShadow: '0 8px 16px rgba(104, 37, 236, 0.15)',
            animation: 'pulse 2s infinite'
        }}>
            <i className="bi bi-check-lg" style={{
                fontSize: '32px',
                color: 'white'
            }}></i>
        </div>
        <h3 style={{
            color: '#1b2559',
            fontSize: '1.1rem',
            fontWeight: '600',
            marginBottom: '10px',
            textAlign: 'center'
        }}>All Systems Functioning Normally</h3>
        <p style={{
            color: '#2B3674',
            fontSize: '0.9rem',
            opacity: 0.8,
            textAlign: 'center',
            maxWidth: '280px',
            lineHeight: '1.4'
        }}>
            {selectedOptions === "Deviations" 
                ? "No deviations detected in the current timeframe" 
                : "No anomalies detected in the current timeframe"}
        </p>
        <style>{`
            @keyframes pulse {
                0% { transform: scale(1); }
                50% { transform: scale(1.05); }
                100% { transform: scale(1); }
            }
        `}</style>
    </div>
);

const MultiGraph = ({ dataSource, server, startDate, endDate, metricName, metricName2, label1, label2, borderColor1, backgroundColor1, selectedOptions, updateAnomalyCount, handleAnomalyClick, onZoom }) => {
    const [timestamps1, setTimestamps1] = useState([]);
    const [metricData1, setMetricData1] = useState([]);
    const [timestamps2, setTimestamps2] = useState([]);
    const [metricData2, setMetricData2] = useState([]);
    const [anomaly, setAnomaly] = useState([]);
    const [pointColor, setPointColor] = useState('');
    const [deviationMetricsData, setDeviationMetricsData] = useState([]);
    const [cIData, setCIData] = useState([]);
    const [metricTotals, setMetricTotals] = useState({});
    
    // Define the percentageMetrics array for proper unit handling
    const percentageMetrics = ['cpu_usage', 'memory_usage', 'disk_usage', 'cpu.percent.used'];

    const chartRef = useRef(null);

    useEffect(() => {
        //console.log('Metric Name Updated:',dataSource, metricName, server, startDate, endDate, selectedOptions);
        // Fetch and update chart data when metric or other parameters change
    }, [metricName, selectedOptions]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data1 = await fetchMetricData(metricName, dataSource, server, startDate, endDate);
                let data2;
                let color;
                
                // Determine which specific metric to filter by based on either the category (CPU, Memory)
                // or the specific metric name (cpu_usage, memory_total)
                let specificMetric = null;
                
                // If label1 is a category like CPU or Memory, use that for filtering
                if (['CPU', 'Memory', 'Disk', 'Network'].includes(label1)) {
                    specificMetric = label1;
                } else {
                    // Otherwise use the metric name directly
                    specificMetric = metricName;
                }
                
                if (selectedOptions === "Deviations") {
                    // Handle deviations option with specific metric filtering
                    console.log("Fetching deviations for specific metric:", specificMetric);
                    data2 = await fetchDeviationData(dataSource, server, startDate, endDate, specificMetric);
                    color = '#F0E130'; // Medium Sea Green
                    setDeviationMetricsData(data2.metricsData || []);
                } else  if (selectedOptions == "Confidence Range"){
                    data2 = await fetchConfidenceIntervalData(dataSource, server, startDate, endDate, metricName);
                    setCIData(data2);
                } else{
                    data2 = await fetchStrictAnomalyData(dataSource, server, startDate, endDate, metricName);
                    color = '#CF1020';
                }
                
                setPointColor(color);
                setTimestamps1(data1.timestamps);
                setMetricData1(data1.values);

                if (selectedOptions === "Deviations") {
                    // Process deviation data differently - use hourly aggregations
                    const deviationTimestamps = data2.timestamps || [];
                    const deviationValues = data2.values || [];
                    
                    console.log("Deviation data points:", deviationTimestamps.length, "for", specificMetric);
                    
                    // Map metric data timestamps to hourly buckets for matching with deviation data
                    const hourlyTimestamps = {};
                    data1.timestamps.forEach((ts, idx) => {
                        const date = new Date(ts);
                        const hourKey = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            date.getHours()
                        ).toISOString();
                        
                        if (!hourlyTimestamps[hourKey]) {
                            hourlyTimestamps[hourKey] = [];
                        }
                        hourlyTimestamps[hourKey].push(idx);
                    });
                    
                    // Now map deviation data to metric data points
                    const AnomalyData2 = new Array(data1.timestamps.length).fill(0);
                    const AnomalyData3 = new Array(data1.timestamps.length).fill(0);
                    
                    // For each deviation timestamp (hourly bucket)
                    deviationTimestamps.forEach((devTs, devIdx) => {
                        // Find metric data points in the same hour
                        const matchingIndices = hourlyTimestamps[devTs] || [];
                        
                        if (matchingIndices.length > 0) {
                            // We have metric data points in this hour, set deviation count
                            const deviationCount = deviationValues[devIdx];
                            
                            // Place the count on the most central point in that hour
                            const midIndex = matchingIndices[Math.floor(matchingIndices.length / 2)];
                            AnomalyData2[midIndex] = data1.values[midIndex]; // Use the actual metric value
                            AnomalyData3[midIndex] = deviationCount; // Store the number of deviations
                        }
                    });
                    
                    setTimestamps2(data1.timestamps);
                    setMetricData2(AnomalyData2);
                    setAnomaly(AnomalyData3);
                } else if (selectedOptions == "Confidence Range"){

                }
                else{
                    // Original processing for Collective Insight (basic alerts removed)
                const AnomalyData2 = data1.timestamps.map((timestamp, index) => {
                    const value1 = data1.values[index];
                
                        // Handle the first timestamp
                    if (index === 0) {
                        const foundAnomaly = data2.timestamps.includes(timestamp) && data2.values[data2.timestamps.indexOf(timestamp)] > 0;
                        return foundAnomaly ? value1 : 0;
                    }
                
                        // Process other timestamps
                    const previousTimestamp = new Date(data1.timestamps[index - 1]);
                    const currentTimestamp = new Date(timestamp);
                
                    const foundAnomaly = data2.timestamps.some((data2Timestamp, data2Index) => {
                        const data2Time = new Date(data2Timestamp);
                        return data2Time > previousTimestamp && data2Time <= currentTimestamp && data2.values[data2Index] > 0;
                    });
                
                    return foundAnomaly ? value1 : 0;
                });
                    
                const AnomalyData3 = data1.timestamps.map((timestamp, index) => {
                        // Similar processing for anomaly count values
                    if (index === 0) {
                        const foundAnomaly = data2.timestamps.includes(timestamp) && data2.values[data2.timestamps.indexOf(timestamp)] > 0;
                        return foundAnomaly ? 1 : 0;
                    }
                
                    const previousTimestamp = new Date(data1.timestamps[index - 1]);
                    const currentTimestamp = new Date(timestamp);
                
                    const anomalyCount = data2.timestamps.reduce((count, data2Timestamp, data2Index) => {
                        const data2Time = new Date(data2Timestamp);
                        if (data2Time > previousTimestamp && data2Time <= currentTimestamp && data2.values[data2Index] > 0) {
                                return count + 1;
                        }
                        return count;
                    }, 0);
                        
                    return anomalyCount > 0 ? anomalyCount : 0;
                });
                    
                setTimestamps2(timestamps1);
                setMetricData2(AnomalyData2);
                setAnomaly(AnomalyData3);
                }

                // Update anomaly counts, including by metric for deviations
                // const basicData = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'moderate');
                const strictData = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'strict');

                // For deviations, fetch both total and per-metric counts
                let deviationsData;
                let metricSpecificCounts = {};
                
                if (selectedOptions === "Deviations") {
                    // Get total deviations (all metrics)
                    deviationsData = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'deviations');
                    
                    // Also get counts for specific metric categories
                    const cpuDeviations = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'deviations', 'CPU');
                    const memoryDeviations = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'deviations', 'Memory');
                    const diskDeviations = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'deviations', 'Disk');
                    const networkDeviations = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'deviations', 'Network');
                    
                    const getCategoryTotal = (data) => 
                        Array.isArray(data) ? data.reduce((sum, item) => sum + (item.count || 0), 0) : 0;
                    
                    metricSpecificCounts = {
                        cpu: getCategoryTotal(cpuDeviations),
                        memory: getCategoryTotal(memoryDeviations),
                        disk: getCategoryTotal(diskDeviations),
                        network: getCategoryTotal(networkDeviations)
                    };
                    
                    console.log("Metric totals:", metricSpecificCounts);
                    setMetricTotals(metricSpecificCounts);
                } else {
                    deviationsData = await fetchAnomalyCount(dataSource, server, startDate, endDate, 'deviations');
                }

                // const basicAnomalyCount = Array.isArray(basicData)
                //     ? basicData.reduce((sum, item) => sum + (item.count || 0), 0) : 0;

                const strictAnomalyCount = Array.isArray(strictData)
                    ? strictData.reduce((sum, item) => sum + (item.count || 0), 0) : 0;

                const deviationsCount = Array.isArray(deviationsData)
                    ? deviationsData.reduce((sum, item) => sum + (item.count || 0), 0) : 0;

                updateAnomalyCount({ 
                    basic: 0, // Set to 0 since Basic Alert is removed 
                    strict: strictAnomalyCount, 
                    deviations: deviationsCount
                });
                
                // If there are no anomalies/deviations, show empty state
                if ((selectedOptions === "Deviations" && deviationsCount === 0) ||
                    (selectedOptions === "Collective Insight" && strictAnomalyCount === 0)) {
                    setTimestamps1([]);
                    setMetricData1([]);
                    setTimestamps2([]);
                    setMetricData2([]);
                    setAnomaly([]);
                }
                
            } catch (error) {
                console.error(`Error fetching data:`, error);
                setTimestamps1([]);
                setMetricData1([]);
                setTimestamps2([]);
                setMetricData2([]);
                setAnomaly([]);
            }
        };

        fetchData();
    }, [dataSource, server, startDate, endDate, selectedOptions, metricName, label1]);

    // Update chart options to handle deviations tooltips
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
            tooltip: {
                callbacks: {
                    // Global tooltip title formatter for all modes
                    title: function(tooltipItems) {
                        const item = tooltipItems[0];
                        let timestamp;
                        
                        // Different data sources based on the mode
                        if (selectedOptions === "Deviations") {
                            timestamp = timestamps1[item.dataIndex];
                        } else if (selectedOptions === "Basic Alert") {
                            timestamp = timestamps1[item.dataIndex];
                        } else {
                            // Collective Insight
                            timestamp = timestamps1[item.dataIndex];
                        }
                        
                        const date = new Date(timestamp);
                        
                        // Format date as DD/MM/YYYY and time as 24-hour HH:MM
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        const year = date.getFullYear();
                        
                        const hours = date.getHours().toString().padStart(2, '0');
                        const minutes = date.getMinutes().toString().padStart(2, '0');
                        
                        return `${day}/${month}/${year}, ${hours}:${minutes}`;
                    },
                    // Global label formatter for all modes
                    label: function(context) {
                        let label = context.dataset.label || '';
                        
                        if (context.datasetIndex === 0) {
                            // For metric data (first dataset)
                            let value = context.parsed.y;
                            if (percentageMetrics.includes(metricName)) {
                                return `${label}: ${value}%`;
                            } else if (metricName.includes('memory') || metricName === 'memory_total') {
                                return `${label}: ${value} GB`;
                            }
                            return `${label}: ${value}`;
                        } else {
                            // For anomaly data (second dataset)
                            if (selectedOptions === "Collective Insight") {
                                // Show anomaly count for Collective Insight
                                return anomaly[context.dataIndex] > 0 ? 
                                    `${label}: ${anomaly[context.dataIndex]} anomalies` : 
                                    `${label}: 0`;
                            } else {
                                // For Deviations, show the metric value
                                let value = context.parsed.y;
                                if (percentageMetrics.includes(metricName)) {
                                    return `${label}: ${value}%`;
                                } else if (metricName.includes('memory') || metricName === 'memory_total') {
                                    return `${label}: ${value} GB`;
                                }
                                return `${label}: ${value}`;
                            }
                        }
                    }
                }
            },
            zoom: {
                pan: { enabled: true, mode: 'x' },
                zoom: {
                    drag: {
                        enabled: true,
                        backgroundColor: 'rgba(177, 162, 231, 0.3)',
                        borderColor: 'rgba(142, 141, 144, 0.5)',
                        borderWidth: 1,
                    },
                    mode: 'x',
                    onZoomComplete({ chart }) {
                        const labels = chart.data.labels;
                        const startX = Math.max(Math.floor(chart.scales.x.min), 0);
                        const endX = Math.min(Math.ceil(chart.scales.x.max), labels.length - 1);
            
                        const parseCustomDate = (label) => {
                          if (label.includes(' ')) {
                            const [time, date] = label.split(' ');
                            const [hours, minutes] = time.split(':');
                            const [day, month] = date.split('/');
                            const year = new Date().getFullYear();
                            return new Date(year, month - 1, day, hours, minutes);
                          } else {
                            const [hours, minutes] = label.split(':');
                            const now = new Date();
                            return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
                          }
                        };
            
                        const newStartDate = parseCustomDate(labels[startX]);
                        const newEndDate = parseCustomDate(labels[endX]);
            
                        if (onZoom && !isNaN(newStartDate) && !isNaN(newEndDate)) {
                          onZoom(newStartDate, newEndDate);
                          //console.log("Zoom Dates:", newStartDate, newEndDate);
                        } else {
                          console.error("Invalid dates from zoom:", labels[startX], labels[endX]);
                        }
                      },
                    },
                  },
        },
        scales: {
            x: {
                grid: { display: false },
                ticks: {
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 0,
                    maxTicksLimit: 7,
                    callback: function(value) {
                        let label = this.getLabelForValue(value);
                        if (label.length > 5) {
                            label = label.substring(5);
                        }
                        return label;
                    }
                },
                title: { display: true },
            },
            y: {
                grid: { display: false },
                beginAtZero: true,
                title: { display: false },
            },
        },
        hover: {
            mode: 'nearest',
            intersect: true,
            onHover: function (event, chartElement) {
                const element = chartElement[0];
                if (element && element.datasetIndex === 1) {
                    event.native.target.style.cursor = anomaly[element.index] > 0 ? 'pointer' : 'default';
                } else {
                    event.native.target.style.cursor = 'default';
                }
            },
        },
        onClick: function (event, chartElement) {
            const element = chartElement[0];
            if (element && element.datasetIndex === 1 && anomaly[element.index] > 0) {
                const timestamp = this.data.labels[element.index];
                localStorage.setItem('DataSource', dataSource);
                localStorage.setItem('Server', server);
                localStorage.setItem('Timestamp', timestamp);
                //handleAnomalyClick('infra');
            }
        },
    };
    
    if (selectedOptions === "Deviations") {
        // Save the original callbacks
        const originalTooltipTitleCallback = options.plugins.tooltip.callbacks.title;
        const originalTooltipLabelCallback = options.plugins.tooltip.callbacks.label;
        
        // Update title callback specifically for Deviations mode
        options.plugins.tooltip.callbacks.title = function(tooltipItems) {
            const item = tooltipItems[0];
            const timestamp = timestamps1[item.dataIndex];
            const date = new Date(timestamp);
            
            // Format date as DD/MM/YYYY and time as 24-hour HH:MM
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            
            return `${day}/${month}/${year}, ${hours}:${minutes}`;
        };
        
        // Update label callback for the second line showing deviation count
        options.plugins.tooltip.callbacks.label = function(context) {
            if (context.datasetIndex === 1 && anomaly[context.dataIndex] > 0) {
                // For the anomaly dataset (datasetIndex = 1)
                const deviationCount = anomaly[context.dataIndex];
                return `Deviations: ${deviationCount}`;
            }
            // For the metric dataset (datasetIndex = 0)
            return originalTooltipLabelCallback(context);
        };
        
        // Add afterLabel to show metric details - third line only for memory metrics
        options.plugins.tooltip.callbacks.afterLabel = function(context) {
            if (context.datasetIndex === 1 && anomaly[context.dataIndex] > 0) {
                const timestamp = timestamps1[context.dataIndex];
                const timestampDate = new Date(timestamp);
                const hourTimestamp = new Date(
                    timestampDate.getFullYear(),
                    timestampDate.getMonth(),
                    timestampDate.getDate(),
                    timestampDate.getHours()
                ).toISOString();
                
                // Find metrics data for this hour
                const metricsForHour = deviationMetricsData.find((metrics, idx) => {
                    const devTimestamp = timestamps2[idx];
                    return devTimestamp === hourTimestamp;
                });
                
                if (metricsForHour) {
                    // For Memory category, only show memory_total with GB suffix
                    if (label1 === "Memory") {
                        const memoryEntries = Object.entries(metricsForHour).filter(([key]) => 
                            key === 'memory_total'
                        );
                        
                        if (memoryEntries.length > 0) {
                            // Return just memory_total with GB suffix
                            const [key, value] = memoryEntries[0];
                            return [`Memory Total: ${value} GB`];
                        }
                    }
                }
            }
            return null;
        };
        
        // Set an empty footer function
        options.plugins.tooltip.callbacks.footer = function() {
            return '';
        };
        
        // Make point sizes proportional to deviation counts
        options.elements = {
            point: {
                radius: function(context) {
                    const index = context.dataIndex;
                    const value = anomaly[index];
                    // Base size on the deviation count (min 5, max 15)
                    return value > 0 ? Math.min(5 + value, 15) : 0;
                },
                hoverRadius: function(context) {
                    const index = context.dataIndex;
                    const value = anomaly[index];
                    // Slightly larger on hover
                    return value > 0 ? Math.min(7 + value, 18) : 0;
                },
                backgroundColor: anomaly.map(value => value > 0 ? pointColor : 'transparent'),
                borderColor: anomaly.map(value => value > 0 ? '#fff' : 'transparent'),
                borderWidth: anomaly.map(value => value > 0 ? 2 : 0),
            }
        };
    } else if (selectedOptions === "Collective Insight") {
        // Collective Insight specific adjustments
        // (The global tooltip functions already handle the formatting)
        // You can add other Collective Insight specific configuration here if needed
    }

    let chartData;

    if (selectedOptions === "Confidence Range") {
        chartData = generateChartDataRange(
            timestamps1, metricData1, label1, borderColor1, backgroundColor1, cIData
        );
    } else {
        chartData = generateChartData(
            timestamps1, metricData1, label1, borderColor1, backgroundColor1,
            timestamps2, metricData2, label2, pointColor, anomaly
        );
    }
    

    // Show empty state if no data or all anomaly values are 0
    const shouldShowEmptyState = !timestamps1.length || anomaly.every(value => value === 0);

    return (
        <>
            {selectedOptions!=="Confidence Range" && shouldShowEmptyState ? (
                <EmptyGraphState selectedOptions={selectedOptions} />
            ) : (
                <Line data={chartData} options={options} ref={chartRef} onZoom={onZoom}/>
            )}
    </>
      );
};

export default MultiGraph;

