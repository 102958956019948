import React, { useState, useRef, useEffect } from 'react';
import './EnviDetect.css'; // Assuming you have a separate CSS file for styling
import { FaInfoCircle } from 'react-icons/fa'; // Importing an icon for the "more details" button
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import MultiGraph from './features/MultiGraph';
import DetectGraph from './features/DetectGraph';


ChartJS.register(ArcElement, Tooltip, Legend);

const EnviDetect = () => {
  const [selectedRowId, setSelectedRowId] = useState(2);
  const [statusFilter, setStatusFilter] = useState('All');
  const [serverFilter, setServerFilter] = useState('');
  const [clusterFilter, setClusterFilter] = useState('');
  const [selectedDataSource, setSelectedDataSource] = useState('All');
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const chartRef = useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  const centerTextPlugin = () => ({
    id: 'centerTextPlugin',
    afterDraw: (chart) => {
        const { ctx, chartArea, data } = chart;
        if (chart.width === 0 || chart.height === 0) {
            return;
        }
        
        const sum = 2;
        ctx.restore();
        const centerX = chart.width / 2;
        const centerY = 1+chart.height/2;
        const fontSize = chart.height /12;
        let textColor;


        ctx.font = `bold ${fontSize}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black'; // Adjust color as needed
        ctx.fillText(`${sum}`, centerX, centerY);
        ctx.save();
    }
});
  // const clusters = [
  //   { name: 'Cluster1', anomalyCount: 10 },
  //   { name: 'Cluster2', anomalyCount: 20 },
  //   { name: 'Cluster3', anomalyCount: 15 },
  //   { name: 'Cluster4', anomalyCount: 5 },
  // ];

  const clusters = [
    { name: 'Production', anomalyCount: 2 },
  ];

  const clusterColors = [
    'rgba(2, 245, 164, 0.7)', // Original opacity is 0.8
    'rgba(7, 222, 211, 0.7)',
    'rgba(249, 185, 162, 0.7)',
    'rgba(76, 161, 163, 0.7)',
    'rgba(1, 143, 119, 0.7)',
    'rgba(34, 139, 34, 0.7)',
    'rgba(255, 140, 0, 0.7)',
    'rgba(255, 191, 0, 0.7)'
  ];
  
  // Create a hover background color with increased opacity (closer to 1.0)
  const hoverBackgroundColor = clusterColors.map(color => color.replace('0.7', '1'));  

  const anomalyTableData = [
    { id: 1, server: 'Server1', enviVital: '9.0', predictedAnomalies: 5 },
    { id: 2, server: 'Server2', enviVital: '8.2', predictedAnomalies: 3 },
    { id: 3, server: 'Server3', enviVital: '3.5', predictedAnomalies: 8 },
    { id: 4, server: 'Server4', enviVital: '5.5', predictedAnomalies: 6 },
    { id: 5, server: 'Server5', enviVital: '6.3', predictedAnomalies: 4 },
  ];

  const chartData = {
    labels: ['Cluster 1', 'Cluster 2', 'Cluster 3', 'Cluster 4'],
    datasets: [
      {
        data: clusters.map(cluster => cluster.anomalyCount),
        backgroundColor: clusterColors,
        hoverOffset: 8,
        hoverBackgroundColor: hoverBackgroundColor, // Make it slightly transparent on hover
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => { let anomalyCount = `Predicted Anomalies - ${tooltipItem.raw}`;
          let additionalLine1 = "EnviScore - 7.03";
          let additionalLine2 = "Anomalous Servers - 2";
          return [additionalLine1, anomalyCount, additionalLine2];
        }
        },
      },
      centerTextPlugin: true,
    },
  };

  // Dummy data with 20 rows, now includes metrics in a separate column and predictedTime as DateTime
  // const dummyData = [
  //   { id: 1, timeRemaining: '5h', predictedTime: new Date('2024-07-20T12:30:05'), dataSource: 'Cluster1', server: 'Server1', metric: 'CPU', status: 'Resolved' },
  //   { id: 2, timeRemaining: '1h', predictedTime: new Date('2024-07-20T13:30:05'), dataSource: 'Cluster1', server: 'Server2', metric: 'Memory', status: 'Open' },
  //   { id: 3, timeRemaining: '3h', predictedTime: new Date('2024-07-20T14:30:05'), dataSource: 'Cluster1', server: 'Server3', metric: 'Network', status: 'Resolved' },
  //   { id: 4, timeRemaining: '35m', predictedTime: new Date('2024-07-20T15:30:05'), dataSource: 'Cluster1', server: 'Server4', metric: 'Disk', status: 'Open' },
  //   { id: 5, timeRemaining: '5h', predictedTime: new Date('2024-07-20T16:30:05'), dataSource: 'Cluster1', server: 'Server5', metric: 'CPU', status: 'Resolved' },
  //   { id: 6, timeRemaining: '2h', predictedTime: new Date('2024-07-21T10:30:05'), dataSource: 'Cluster2', server: 'Server1', metric: 'Memory', status: 'Open' },
  //   { id: 7, timeRemaining: '12h', predictedTime: new Date('2024-07-21T11:30:05'), dataSource: 'Cluster2', server: 'Server2', metric: 'Network', status: 'Open' },
  //   { id: 8, timeRemaining: '3h', predictedTime: new Date('2024-07-21T12:30:05'), dataSource: 'Cluster2', server: 'Server3', metric: 'Disk', status: 'Resolved' },
  //   { id: 9, timeRemaining: '40m', predictedTime: new Date('2024-07-21T13:30:05'), dataSource: 'Cluster2', server: 'Server4', metric: 'CPU', status: 'Open' },
  //   { id: 10, timeRemaining: '5h', predictedTime: new Date('2024-07-21T14:30:05'), dataSource: 'Cluster2', server: 'Server5', metric: 'Memory', status: 'Resolved' },
  //   { id: 11, timeRemaining: '22h', predictedTime: new Date('2024-07-22T09:30:05'), dataSource: 'Cluster4', server: 'Server1', metric: 'Network', status: 'Open' },
  //   { id: 12, timeRemaining: '1h', predictedTime: new Date('2024-07-22T10:30:05'), dataSource: 'Cluster4', server: 'Server2', metric: 'Disk', status: 'Open' },
  //   { id: 13, timeRemaining: '3h', predictedTime: new Date('2024-07-22T11:30:05'), dataSource: 'Cluster4', server: 'Server3', metric: 'CPU', status: 'Resolved' },
  //   { id: 14, timeRemaining: '50m', predictedTime: new Date('2024-07-22T12:30:05'), dataSource: 'Cluster4', server: 'Server4', metric: 'Memory', status: 'Open' },
  //   { id: 15, timeRemaining: '5h', predictedTime: new Date('2024-07-22T13:30:05'), dataSource: 'Cluster4', server: 'Server5', metric: 'Network', status: 'Resolved' },
  //   { id: 16, timeRemaining: '2h', predictedTime: new Date('2024-07-23T10:30:05'), dataSource: 'Cluster3', server: 'Server1', metric: 'Disk', status: 'Open' },
  //   { id: 17, timeRemaining: '11h', predictedTime: new Date('2024-07-23T11:30:05'), dataSource: 'Cluster3', server: 'Server2', metric: 'CPU', status: 'Open' },
  //   { id: 18, timeRemaining: '3h', predictedTime: new Date('2024-07-23T12:30:05'), dataSource: 'Cluster3', server: 'Server3', metric: 'Memory', status: 'Resolved' },
  //   { id: 19, timeRemaining: '30m', predictedTime: new Date('2024-07-23T13:30:05'), dataSource: 'Cluster3',server: 'Server4', metric: 'Network', status: 'Open' },
  //   { id: 20, timeRemaining: '5h', predictedTime: new Date('2024-07-23T14:30:05'), dataSource: 'Cluster3', server: 'Server5', metric: 'Disk', status: 'Resolved' },
  // ];

  const dummyData = [
    { id: 1, timeRemaining: '1h', predictedTime: new Date('2024-07-20T13:30:05'), dataSource: 'Production', server: 'server2', metric: 'cpu_wait', status: 'Open' },
    { id: 2, timeRemaining: '2h', predictedTime: new Date('2024-07-20T15:30:05'), dataSource: 'Production', server: 'server3', metric: 'io_time_ms', status: 'Open' },
  ];

  // Servers to filter
  // const serverOptions = ['Server1', 'Server2', 'Server3', 'Server4', 'Server5'];
  const serverOptions = ['server1', 'server2', 'server3'];

  const clusterOptions = clusters.map(cluster => cluster.name);

  // Function to format the predictedTime as DD/MM HH:mm:ss
  const formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month} ${hours}:${minutes}:${seconds}`;
  };

  const percentageTextPlugin = {
    id: 'percentageTextPlugin',
    afterDatasetsDraw: (chart) => {
        const { ctx, data } = chart;
        const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);

        data.datasets[0].data.forEach((value, index) => {
            const percentage = ((value / total) * 100).toFixed(2);
            const model = chart.getDatasetMeta(0).data[index];
            const { x, y } = model.tooltipPosition();
            const fontSize = chart.height / 30;
            const padding = 4; // Padding around the text
            const borderRadius = 4; // Border radius for rounded corners

            // Set up font for text
            ctx.font = `${fontSize}px Arial`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Calculate text size
            const textWidth = ctx.measureText(`${percentage}%`).width;
            const textHeight = fontSize;

            // Draw background box with rounded corners
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Transparent black background
            ctx.beginPath();
            const xPos = x - textWidth / 2 - padding;
            const yPos = y - textHeight / 2 - padding;
            const boxWidth = textWidth + padding * 2;
            const boxHeight = textHeight + padding * 2;

            // Draw rounded rectangle
            ctx.moveTo(xPos + borderRadius, yPos);
            ctx.lineTo(xPos + boxWidth - borderRadius, yPos);
            ctx.arc(xPos + boxWidth - borderRadius, yPos + borderRadius, borderRadius, 1.5 * Math.PI, 2 * Math.PI);
            ctx.lineTo(xPos + boxWidth, yPos + boxHeight - borderRadius);
            ctx.arc(xPos + boxWidth - borderRadius, yPos + boxHeight - borderRadius, borderRadius, 0, 0.5 * Math.PI);
            ctx.lineTo(xPos + borderRadius, yPos + boxHeight);
            ctx.arc(xPos + borderRadius, yPos + boxHeight - borderRadius, borderRadius, 0.5 * Math.PI, Math.PI);
            ctx.lineTo(xPos, yPos + borderRadius);
            ctx.arc(xPos + borderRadius, yPos + borderRadius, borderRadius, Math.PI, 1.5 * Math.PI);
            ctx.closePath();
            ctx.fill();

            // Draw text
            ctx.fillStyle = '#fff'; // White text color
            ctx.fillText(`${percentage}%`, x, y);
        });
    }
};
  // Filter data based on selected filters
 // Helper function to convert "2h" or "30m" to total minutes
const convertTimeToMinutes = (timeString) => {
  if (timeString.includes('h')) {
    return parseInt(timeString) * 60; // Convert hours to minutes
  } else if (timeString.includes('m')) {
    return parseInt(timeString); // Minutes remain the same
  }
  return 0; // Default to 0 if the format is unrecognized
};

// Sort by least time remaining using the converted minutes
const filteredData = dummyData
  .filter(item => (statusFilter === 'All' || item.status === statusFilter))
  .filter(item => (serverFilter === '' || item.server.includes(serverFilter)))
  .filter(item => (selectedDataSource==='All' || item.dataSource.includes(selectedDataSource)))
  .sort((a, b) => convertTimeToMinutes(a.timeRemaining) - convertTimeToMinutes(b.timeRemaining)); // Sort based on total minutes

  useEffect(() => {
    // Ensure filteredData has items before setting the selected row
    if (filteredData.length > 0) {
      setSelectedRowId(filteredData[0].id);
    }
  }, [ selectedDataSource]); // Add filteredData and selectedDataSource to the dependencies
  

const handleRowSelect = (id) => {
  setSelectedRowId(id); // Set the selected row's id
};

const raiseIncident = (server, cluster, metric) => {
  const requestData = {
    predicted_time: "2024-10-25 14:00:00", // You can update this to a dynamic value as needed
    anomalous_server: server,
    metric: metric,
    cluster: cluster,
    status: "Critical"
  };

  fetch("https://infia.enviseer.com/api/alert", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestData)
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(data => {
      alert(`Incident raised successfully for ${server}`);
    })
    .catch(error => {
      console.error("There was a problem with the fetch operation:", error);
      alert(`Failed to raise incident for ${server}`);
    });
};


const handleClick = (event) => {

  if (!chartRef.current) return;
        const chart = chartRef.current;
        const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

  if (elements && elements.length > 0) {
    const clickedIndex = elements[0].index;
    const item = clusters[clickedIndex];
    // const clickedLabel = chartData.labels[clickedIndex];
    setSelectedDataSource(item.name);
  } else {
    console.error('No chart elements were clicked.');
  }
};
const selectedRow = filteredData.find(row => row.id === selectedRowId);
  return (
    <div className="table-container">
      <div style={{ fontSize: '20px', color: '#023e8a', marginTop: '-20px', marginBottom: '10px', display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>EnviDetect</div>
      <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
      <div className="flex-container" style={{ backgroundColor: 'white', display:'flex', flexDirection:'column', paddingTop: '20px', width: '20%', borderRadius: '5px', justifyContent:'center', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)'}}>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Anomalous Clusters</h3>

        {/* Donut Chart for Anomaly Clusters */}
        <div className="chart-container">
          <Doughnut data={chartData} ref={chartRef}  onClick={handleClick} options={chartOptions} plugins={[centerTextPlugin(), percentageTextPlugin]}  />
        </div>
      </div>
      <div className="sidechart2">
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <div style={{ width: '80%', height:'270px' }}>
      <DetectGraph
        dataSource={selectedRow.dataSource}
        server={selectedRow.server}
        startDate={"Sun oct 20 2024 22:27:53 GMT+0530 (India Standard Time)"}
        endDate={"Wed Oct 23 2024 22:27:53 GMT+0530 (India Standard Time)"}
        metricName={selectedRow.metric}
        metricName2={selectedRow.metric}
        label1={selectedRow.metric}
        label2="Anomaly"
        borderColor1="rgba(0, 153, 128, 1)"
        backgroundColor1="rgba(0, 191, 160, 0.3)"
      />
    </div>

    {/* Data display next to the graph */}
    <div style={{ width: '18%', paddingLeft: '10px', textAlign: 'left', justifyContent:'space-between' }}>

      {selectedRow && (
        <>
          <div style={{ fontSize: '16px', color: '#333', marginBottom:'24px' }}>
           <div style={{ fontWeight: '500', color:'grey' }}>DataSource</div>
           <div style={{ fontWeight: 'bold' }}>{selectedRow.dataSource}</div>
          </div>
          <div style={{ fontSize: '16px', color: '#333', marginTop: '10px', marginBottom: '24px' }}>
            <div style={{ fontWeight: '500', color: 'grey' }}>Server</div>
            <div style={{ fontWeight: 'bold' }}>{selectedRow.server}</div> {/* Server from selected row */}
          </div>
          <div style={{ fontSize: '16px', color: 'red', marginBottom: '24px' }}>
            <div style={{ fontWeight: '500', color: 'grey' }}>Severity Score</div>
            <div style={{ fontWeight: 'bold' }}>30</div> {/* Static value for now */}
          </div>
          <div style={{ fontSize: '16px', color: 'red', marginTop: '10px' }}>
            <div style={{ fontWeight: '500', color: 'grey' }}>Time Remaining</div>
            <div style={{ fontWeight: 'bold' }}>{selectedRow.timeRemaining}</div> {/* Time remaining from selected row */}
          </div>
        </>
      )}
    </div>
  </div>
</div>


  </div>
    <div style={{backgroundColor: 'white', display:'flex', flexDirection:'column', padding: '20px', width: '100%', borderRadius: '5px', justifyContent:'center', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)'}}>
    <h3 style={{marginBottom:'-20px', fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Predicted Anomalies</h3>
      <div style={{ display: 'flex', justifyContent: 'start', alignItems:'center', backgroundColor: '#023E8A',paddingLeft:'10px', width:'241px', borderRadius:'4px' }}>
        <div style={{marginRight:'10px', fontWeight:'600', color:'white'}}>Data Source : </div>
        <select 
          value={clusterFilter} 
          onChange={(e) => setSelectedDataSource(e.target.value)} 
            style={{ padding: '5px', fontSize: '16px' }}>
  
             <option value="All">{selectedDataSource}</option>
             {selectedDataSource !== 'All'&&(
          <option key={'All'} value={'All'}>
          All
        </option>
         )}
            {clusterOptions.map(cluster => (
            cluster !== selectedDataSource && (
            <option key={cluster} value={cluster}>
            {cluster}
          </option>
          )
         ))}
        </select>
      </div>
      <table>
      <thead>
        <tr>
          <th>Select</th>
          <th>Time Remaining</th>
          <th>Predicted Time</th>
          <th>
            Anomalous Server
            <br />
            <select>
              <option value="">All Servers</option>
              {serverOptions.map(server => (
                <option key={server} value={server}>{server}</option>
              ))}
            </select>
          </th>
          <th>Metric</th>
          <th>
            Status
            <br />
            <select>
              <option value="All">All</option>
              <option value="Open">Open</option>
              <option value="Resolved">Resolved</option>
            </select>
          </th>
          <th>Action</th>
          <th>Details</th>
        </tr>
      </thead>
          <tbody>
          {filteredData.map((item) => (
            <tr
              key={item.id}
              className={item.id === selectedRowId ? 'selected-row' : ''}
              onClick={() => handleRowSelect(item.id)} // Make the row clickable
            >
              <td>
                <input
                  type="radio"
                  name="selectServer"
                  checked={item.id === selectedRowId}
                  onChange={() => handleRowSelect(item.id)} // Set the selected row on change
                />
              </td>
              <td style={{ color: 'red', fontWeight: 'bold' }}>{item.timeRemaining}</td>
              <td>{formatDateTime(item.predictedTime)}</td>
              <td>{item.server}</td>
              <td>{item.metric}</td>
              <td>{item.status}</td>
              <td>
                <button
                  className="raise-incident"
                  onClick={() => raiseIncident(item.server, item.dataSource, item.metric)}                >
                  Raise Incident
                </button>
              </td>
              <td>
                <FaInfoCircle
                  className="details-icon"
                  onClick={() => alert(`More details for ${item.server}`)}
                  style={{ cursor: 'pointer', color: '#007bff' }}
                />
              </td>
            </tr>
          ))}
        </tbody>
        
    </table>
      </div>
    </div>
  );
};

export default EnviDetect;
