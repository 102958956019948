// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*----------------------
# Logo
-----------------------*/
.logo {
    line-height: 1;
    display : flex;
    align-items : center;
    text-decoration:none;
    color: #4A11C5;
    max-width:100%;
}

@media (min-width: 1200px) {
    .logo {
        width: 210px;
    }
}

.logo img {
    max-height: 50px;
    border-radius : 5px;
}

.logo span {
    font-size: 22px;
    font-weight: 700;
    color: #1b2559;
    font-family: 'Poppins', sans-serif;
}

/*Added below*/

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-img {
    width: 40px;  /* Ensure the logo size is consistent */
}

.logo-text {
    margin-left: 7px;  /* Space between logo and text */
    display: inline-block;  /* Make sure it's inline with the logo*/
    
}

/* Adjust the sidebar-header to handle alignment and padding */
.sidebar-header {
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/Logo.css"],"names":[],"mappings":"AAAA;;wBAEwB;AACxB;IACI,cAAc;IACd,cAAc;IACd,oBAAoB;IACpB,oBAAoB;IACpB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,kCAAkC;AACtC;;AAEA,cAAc;;AAEd;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW,GAAG,uCAAuC;AACzD;;AAEA;IACI,gBAAgB,GAAG,gCAAgC;IACnD,qBAAqB,GAAG,uCAAuC;;AAEnE;;AAEA,8DAA8D;AAC9D;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["/*----------------------\n# Logo\n-----------------------*/\n.logo {\n    line-height: 1;\n    display : flex;\n    align-items : center;\n    text-decoration:none;\n    color: #4A11C5;\n    max-width:100%;\n}\n\n@media (min-width: 1200px) {\n    .logo {\n        width: 210px;\n    }\n}\n\n.logo img {\n    max-height: 50px;\n    border-radius : 5px;\n}\n\n.logo span {\n    font-size: 22px;\n    font-weight: 700;\n    color: #1b2559;\n    font-family: 'Poppins', sans-serif;\n}\n\n/*Added below*/\n\n.logo-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.logo-img {\n    width: 40px;  /* Ensure the logo size is consistent */\n}\n\n.logo-text {\n    margin-left: 7px;  /* Space between logo and text */\n    display: inline-block;  /* Make sure it's inline with the logo*/\n    \n}\n\n/* Adjust the sidebar-header to handle alignment and padding */\n.sidebar-header {\n    padding: 15px 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
