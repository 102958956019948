import React from 'react';
import { useInView } from 'react-intersection-observer';

const LazyLoad = ({ children, height = '300px' }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} style={{ minHeight: height }}>
      {inView ? children : <div style={{ height, background: '#f0f0f0' }} />}
    </div>
  );
};

export default LazyLoad;
