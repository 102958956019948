import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { formatDateTime, convertToIST, getCondensedData } from './utils'; // Assuming you have utility functions in a utils.js file
import { Chart, registerables } from 'chart.js';
// Register Chart.js components
Chart.register(...registerables);
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const yScale = chart.scales.y;

            // Check if yScale is defined and has a top property
            if (!yScale || typeof yScale.top === 'undefined') {
                console.error('yScale or yScale.top is undefined');
                return;
            }
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            const label = chart.data.labels[activePoint.index];

            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)'; // Adjusted for a subtle line
            ctx.stroke();
            ctx.restore();

            const labelX = x;
            const labelY = bottomY + 20;
            ctx.fillStyle = '#000';
            ctx.fillRect(labelX - 20, labelY - 10, 40, 20); // Adjust width and height accordingly
            ctx.fillStyle = '#fff';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(label.substring(0, 5), x, bottomY + 20);
            ctx.fillText(label.substring(6, label.length), x, bottomY + 35);
            ctx.restore();
        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps, data, label, borderColor, backgroundColor, metricDataLabels) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, borderColor);
    gradient.addColorStop(0, backgroundColor);
    gradient.addColorStop(0.7, 'rgba(138,43,226,0)'); // Transparent end
    const lastIndex = data.length - 1;
    return {
        labels: timestamps.map(convertToIST),
        datasets: [
            {
                label: label,
                data: data,
                fill: true,
                borderColor: borderColor,
                backgroundColor: gradient,
                tension: 0.3,
                pointBorderWidth: (context) => {
                    // Apply different pointBorderWidth for the last point
                    return context.dataIndex === lastIndex ? 3 : 0.6;
                },
                pointRadius: (context) => {
                    // Apply different pointRadius for the last point
                    return context.dataIndex === lastIndex ? 5 : 0;
                },
                borderWidth: 2,
                pointBackgroundColor: borderColor,
                pointStyle: 'circle',
                pointHoverRadius: 7,
                pointHoverBorderWidth: 3,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 2,
                pointShadowColor: 'rgba(0, 0, 0, 0.5)',
            },
        ],
    };
};

const chartOptions = (metricName, metricDataLabels, onZoom) => {

    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: { display: false },
                ticks: {
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 0,
                    maxTicksLimit: 7,
                    color: '#888',
                    callback: function (value) {
                        let label = this.getLabelForValue(value);
                        return label.length > 5 ? label.substring(5) : label;
                    },
                },
            },
            y: {
                grid: { display: false },
                ticks: { color: '#888', fontSize: 12 },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                enabled: true,
                backgroundColor: 'black',
                titleFontColor: '#fff',
                bodyFontColor: '#fff',
                borderColor: '#666',
                borderWidth: 1,
                cornerRadius: 4,
                displayColors: false,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: (context) => {
                        let index = context.dataIndex;
                        let label = context.dataset.label || '';
                        let rawValue = metricDataLabels?.[index]; // Safely get the value

                        if (rawValue == null) {
                            return `${label}: No Data`; // Handle missing data
                        }

                        if (typeof rawValue !== 'string') {
                            rawValue = String(rawValue); // Convert to string if it's a number
                        }

                        let match = rawValue.match(/^([\d.]+)(.*)$/); // Regex to separate number from unit
                        let numericValue = match ? parseFloat(match[1]).toFixed(2) : rawValue; // Fix to 2 decimals
                        let unit = match ? match[2] : ''; // Extract unit if exists
                        return `${label}: ${numericValue}${unit}`;
                    },
                },
            },
            zoom: {
                pan: { enabled: true, mode: 'x' },
                zoom: {
                    drag: {
                        enabled: true,
                        backgroundColor: 'rgba(177, 162, 231, 0.3)',
                        borderColor: 'rgba(142, 141, 144, 0.5)',
                        borderWidth: 1,
                    },
                    mode: 'x',
                    onZoomComplete({ chart }) {
                        const labels = chart.data.labels;
                        const startX = Math.max(Math.floor(chart.scales.x.min), 0);
                        const endX = Math.min(Math.ceil(chart.scales.x.max), labels.length - 1);
            
                        const parseCustomDate = (label) => {
                          if (label.includes(' ')) {
                            const [time, date] = label.split(' ');
                            const [hours, minutes] = time.split(':');
                            const [day, month] = date.split('/');
                            const year = new Date().getFullYear();
                            return new Date(year, month - 1, day, hours, minutes);
                          } else {
                            const [hours, minutes] = label.split(':');
                            const now = new Date();
                            return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
                          }
                        };
            
                        const newStartDate = parseCustomDate(labels[startX]);
                        const newEndDate = parseCustomDate(labels[endX]);
            
                        if (onZoom && !isNaN(newStartDate) && !isNaN(newEndDate)) {
                          onZoom(newStartDate, newEndDate);
                          //console.log("Zoom Dates:", newStartDate, newEndDate);
                        } else {
                          console.error("Invalid dates from zoom:", labels[startX], labels[endX]);
                        }
                      },
                    },
                  },

            verticalLinePlugin: true,
        },
        hover: {
            mode: 'index',
            intersect: false,
        },
    };
};

const fetchMetricData = async (dataSource, metricName, label, server, start, end) => {
    const formattedStartTime = formatDateTime(start);

    //console.log(metricName);
    const formattedEndTime = formatDateTime(end);
    const url = label === 'EnviVital' ? `https://infia.enviseer.com/api/healthscores/clusterhost?cluster=${dataSource}&hostname=${server}` : `https://infia.enviseer.com/api/metrics?&cluster=${dataSource}&hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}`;
    //console.log(url);
     function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');

            // //console.log("GraphAS11 - 1 - API URL:", url);  // Add this to log the request URL

     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();

    // //console.log("GraphAS11 - 2 - API Response:", data);  // Add this to log API response


    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.value_readable? entry.value_readable:entry.value);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};

const Graph = ({ refreshKey, dataSource, server, startDate, endDate, metricName, label, borderColor, backgroundColor, updateAvgScore, onZoom, onEmptyData = () => {} }) => {
    const [timestamps, setTimestamps] = useState([]);
    const [metricData, setMetricData] = useState([]);
    const [metricDataLabels, setMetricDataLabels] = useState([]);
    const [hasData, setHasData] = useState(true);
    const chartRef = useRef(null);

    const calculateAverage = (values) => {
        if (!values || values.length === 0) return 0;
        const validValues = values.filter(val => {
            const num = parseFloat(val);
            return !isNaN(num) && num !== 0;
        });
        if (validValues.length === 0) return 0;
        const sum = validValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
        return sum / validValues.length;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchMetricData(dataSource, metricName, label, server, startDate, endDate);
                
                // Check if we have valid data
                const hasValidData = data.values && data.values.length > 0 && 
                    data.values.some(val => {
                        const num = parseFloat(val);
                        return !isNaN(num) && num !== 0;
                    });
                
                setHasData(hasValidData);
                
                if (!hasValidData) {
                    setTimestamps([]);
                    setMetricData([]);
                    setMetricDataLabels([]);
                    if (updateAvgScore) {
                        updateAvgScore(0);
                    }
                    // Notify parent about empty data
                    onEmptyData({
                        metricName,
                        label,
                        borderColor,
                        backgroundColor
                    });
                    return;
                }

                setTimestamps(data.timestamps);
                setMetricDataLabels(data.values);
                const formattedValues = data.values.map(entry => {
                    const num = parseFloat(entry);
                    return isNaN(num) ? '0.00' : num.toFixed(2);
                });
                setMetricData(formattedValues);
                const averageValue = calculateAverage(data.values);
        
                if(label==='EnviVital'){
                updateAvgScore(formattedValues[formattedValues.length - 1]);
                }
               
            } catch (error) {
                console.error(`Error fetching ${metricName} data:`, error);
                setTimestamps([]);
                setMetricData([]);
                // Also notify parent on error
                onEmptyData({
                    metricName,
                    label,
                    borderColor,
                    backgroundColor,
                    error: error.message
                });
            }
        };

        fetchData();
    }, [dataSource, server, startDate, endDate, metricName, updateAvgScore, label, borderColor, backgroundColor]);



    const { condensedTimestamps, condensedMetricData } = getCondensedData(timestamps, metricData);
    const chartData = generateChartData(condensedTimestamps, condensedMetricData, label, borderColor, backgroundColor, metricDataLabels);

    if (!condensedMetricData || condensedMetricData.length === 0) {
        //console.log('No valid data to plot');
        return <p style={{fontSize:'12px',color:'grey'}}>No data available to plot the graph.</p>; // Return null or some fallback UI if there is no valid data to plot
      }


    return <Line key={refreshKey} data={chartData} options={chartOptions(metricName, metricDataLabels, onZoom)} ref={chartRef} />;

};

export default Graph;

