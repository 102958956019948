import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import styles from './Metrics.module.css';

const Metrics = ({ selectedDataSource, services, startTime, endTime, selectedServices, setSelectedServices }) => {
  const [metrics, setMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedMetricData, setSelectedMetricData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metricTypeFilter, setMetricTypeFilter] = useState('all');

  // Chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            family: 'Inter, sans-serif',
            size: 12
          },
          color: '#1F2937'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#1F2937',
        titleFont: {
          family: 'Inter, sans-serif',
          size: 14,
          weight: '600'
        },
        bodyColor: '#6B7280',
        bodyFont: {
          family: 'Inter, sans-serif',
          size: 13
        },
        borderColor: 'rgba(104, 37, 236, 0.1)',
        borderWidth: 1,
        padding: 12,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(104, 37, 236, 0.05)',
          drawBorder: false
        },
        ticks: {
          font: {
            family: 'Inter, sans-serif',
            size: 12
          },
          color: '#6B7280'
        }
      },
      y: {
        grid: {
          color: 'rgba(104, 37, 236, 0.05)',
          drawBorder: false
        },
        ticks: {
          font: {
            family: 'Inter, sans-serif',
            size: 12
          },
          color: '#6B7280'
        }
      }
    }
  };

  // Metric type options
  const metricTypes = [
    { value: 'all', label: 'All Metrics' },
    { value: 'system', label: 'System Metrics' },
    { value: 'jvm', label: 'JVM Metrics' },
    { value: 'db', label: 'Database Metrics' },
    { value: 'queue', label: 'Queue Metrics' },
    { value: 'custom', label: 'Custom Metrics' }
  ];

  useEffect(() => {
    if (selectedDataSource && selectedServices) {
      console.log('Fetching metrics with:', {
        cluster: selectedDataSource,
        service: selectedServices,
        startTime: startTime?.toISOString(),
        endTime: endTime?.toISOString()
      });
      fetchMetrics();
    }
  }, [selectedDataSource, selectedServices, startTime, endTime]);

  const fetchMetrics = async () => {
    setLoading(true);
    try {
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      
      const token = getCookie('token');
      const url = `https://infia.enviseer.com/api/apm/metrics/distinct`;
      
      const payload = {
        cluster: selectedDataSource,
        service_name: selectedServices,
        start_time: startTime?.toISOString(),
        end_time: endTime?.toISOString()
      };

      console.log('Sending metrics request:', payload);
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch metrics: ${response.status} ${errorText}`);
      }
      const data = await response.json();
      console.log('Received metrics data:', data);
      setMetrics(data.metrics || []);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMetricData = async (metricName) => {
    try {
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      
      const token = getCookie('token');
      const url = `https://infia.enviseer.com/api/apm/metrics/timeseries`;
      
      const payload = {
        cluster: selectedDataSource,
        service_name: selectedServices,
        metric_name: metricName,
        start_time: startTime?.toISOString(),
        end_time: endTime?.toISOString()
      };

      console.log('Sending metric data request:', payload);
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch metric data: ${response.status} ${errorText}`);
      }
      const responseData = await response.json();
      console.log('Received metric data:', responseData);

      // Transform the data for the chart
      const data = {
        metric_name: metricName,
        timestamps: responseData.data?.map(item => new Date(item.timestamp).toLocaleString()) || [],
        values: responseData.data?.map(item => item.value) || []
      };

      setSelectedMetricData(data);
    } catch (error) {
      console.error('Error fetching metric data:', error);
    }
  };

  const filteredMetrics = metricTypeFilter === 'all' 
    ? metrics 
    : metrics.filter(metric => metric.toLowerCase().startsWith(metricTypeFilter));

  return (
    <div className={styles.metricsWrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Service Metrics</h2>
        <p className={styles.subtitle}>Performance metrics for {selectedServices || selectedDataSource}</p>
        <div className={styles.controls}>
          <div className={styles.searchBox}>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            <input 
              type="text" 
              placeholder="Search metrics..." 
              className={styles.searchInput}
            />
          </div>
          <select 
            value={metricTypeFilter}
            onChange={(e) => setMetricTypeFilter(e.target.value)}
            className={styles.filterSelect}
          >
            {metricTypes.map(type => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>

          <select 
            value={selectedServices}
            onChange={(e) => setSelectedServices(e.target.value)}
            className={styles.filterSelect}
          >
            {services.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>
      </div>

      <div className={styles.content}>
        {/* Graph Section */}
        {selectedMetric && selectedMetricData && (
          <div className={styles.graphSection}>
            <div className={styles.graphHeader}>
              <h3 className={styles.graphTitle}>
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#6825EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M3 3v18h18"></path>
                  <path d="M18 12l-6-6-6 6"></path>
                </svg>
                {selectedMetric}
              </h3>
              <button
                onClick={() => {
                  setSelectedMetric(null);
                  setSelectedMetricData(null);
                }}
                className={styles.closeButton}
              >
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className={styles.graphContainer}>
              <Line
                data={{
                  labels: selectedMetricData.timestamps,
                  datasets: [{
                    label: selectedMetricData.metric_name,
                    data: selectedMetricData.values,
                    borderColor: '#6825EC',
                    backgroundColor: 'rgba(104, 37, 236, 0.1)',
                    borderWidth: 2,
                    fill: true,
                    tension: 0.4
                  }]
                }}
                options={chartOptions}
              />
            </div>
          </div>
        )}

        {/* Table Section */}
        <div className={styles.tableSection}>
          <table className={styles.metricsTable}>
            <thead>
              <tr>
                <th>Metric Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="2" className={styles.loadingCell}>
                    <div className={styles.loadingIndicator}>
                      <svg className={styles.spinner} viewBox="0 0 50 50">
                        <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                      </svg>
                      <span>Loading metrics...</span>
                    </div>
                  </td>
                </tr>
              ) : filteredMetrics.length > 0 ? (
                filteredMetrics.map((metric, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      if (selectedMetric === metric) {
                        setSelectedMetric(null);
                        setSelectedMetricData(null);
                      } else {
                        setSelectedMetric(metric);
                        fetchMetricData(metric);
                      }
                    }}
                    className={`${styles.metricsRow} ${selectedMetric === metric ? styles.selectedRow : ''}`}
                  >
                    <td>
                      <div className={styles.metricNameCell}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke={selectedMetric === metric ? '#6825EC' : '#6B7280'}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M3 3v18h18"></path>
                          <path d="M18 12l-6-6-6 6"></path>
                        </svg>
                        {metric}
                      </div>
                    </td>
                    <td>
                      <span className={styles.statusBadge}>
                        Active
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className={styles.emptyCell}>
                    <div className={styles.emptyState}>
                      <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M3 3v18h18"></path>
                        <path d="M18 12l-6-6-6 6"></path>
                      </svg>
                      <p>No metrics available</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Metrics; 