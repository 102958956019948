import 'bootstrap-icons/font/bootstrap-icons.css';
import './Sidebar.css';
import Logo from './Logo';
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Sidebar = ({ sidebarOpen, onSelect, toggleSidebar, selectedPage, selectedSubPage }) => {

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const username = getCookie('username');
    const [openInfraDropdown, setOpenInfraDropdown] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState(null);
    const infraRef = useRef(null);
    const [selectedInfraItem, setSelectedInfraItem] = useState(selectedSubPage || null);

    const infraItems = ['Metrics', 'Processes', 'Logs'];

    const handleDropdownClick = (dropdownName) => {
        if (dropdownName === 'infrastructure') {
            setOpenInfraDropdown(!openInfraDropdown);
            setVisibleTooltip(null); // Hide any visible tooltip
        }
    };

    const handleInfraSelect = (item, event) => {
        event.stopPropagation();
        setSelectedInfraItem(item.toLowerCase());
        onSelect('infra', item.toLowerCase());
        setOpenInfraDropdown(false);
    };

    const handleClickOutside = (event) => {
        if (infraRef.current && !infraRef.current.contains(event.target)) {
            setOpenInfraDropdown(false);
        }
    };

    // Close dropdowns when sidebar collapses
    useEffect(() => {
        if (!sidebarOpen) {
            setOpenInfraDropdown(false);
        }
    }, [sidebarOpen]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const tooltips = {
        infrastructure: 'Infra Monitoring',
        analytics: 'Analytics',
        dashboard: 'Dashboard',
        enviDetect: 'EnviDetect',
        logs: 'Logs',
        traces: 'Traces',
        about: 'About Us',
        correlation: 'Correlation',
        alerts: 'Alerts',
        APM: 'APM',
        enviheal: 'EnviHeal',
        itpa: 'IT Process Automation',
        rpa: 'Robotic Process Automation'
    };


    const restrictedUsers = [
        'tarak@rezlive.com', 
        'tarak@tarak.co.in', 
        'vivekkrsingh622@gmail.com',
        'tapas.home@gmail.com',
        'jaal.s@rezlive.com',
        'javed.n@rezlive.com',
        'it@rezlive.com'
    ];
    const email = decodeURIComponent(username);
    const userAccess = restrictedUsers.includes(email)
    ? ['EnviState','alerts'] 
    : ['EnviState', 'alerts', 'logMonitoring', 'EnviDetect', 'Coorelation'];

    return (
        <div>
            <aside id="sidebar" className={`sidebar ${sidebarOpen ? '' : 'collapsed'}`}>
                <div className="sidebar-header">
                    <Logo sidebarOpen={sidebarOpen} />
                </div>
                <div className="sidebar-tile">
                    <ul className="sidebar-nav" id="sidebar-nav">
                        {/* Infrastructure Monitoring Section */}
                        <li
                            className={`nav-item ${selectedPage === 'infra' ? 'active' : ''}`}
                            onMouseEnter={() => !openInfraDropdown && setVisibleTooltip('infrastructure')}
                            onMouseLeave={() => setVisibleTooltip(null)}
                            ref={infraRef}
                        >
                            <div
                                className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                onClick={() => handleDropdownClick('infrastructure')}
                            >
                                {sidebarOpen ? (
                                    <div className="nav-row">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <i className="bi bi-hdd-rack-fill"></i>
                                            <span>Infra Monitoring</span>
                                        </div>
                                        <i
                                            className={`bi ${openInfraDropdown ? 'bi-chevron-up' : 'bi-chevron-down'}`}
                                        ></i>
                                    </div>
                                ) : (
                                    <i className="bi bi-hdd-rack-fill"></i>
                                )}
                            </div>
                            {openInfraDropdown && sidebarOpen && (
                                <div className="dropdown-content">
                                    <ul className="dropdown-list">
                                        {infraItems.map((item, index) => (
                                            <li
                                                key={index}
                                                className={`dropdown-item ${index % 2 === 0 ? 'even' : 'odd'} ${selectedInfraItem === item.toLowerCase() ? 'active' : ''}`}
                                                onClick={(e) => handleInfraSelect(item, e)}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {visibleTooltip === 'infrastructure' && !sidebarOpen && !openInfraDropdown && (
                                <div className="sidebar-custom-tooltip">{tooltips.infrastructure}</div>
                            )}
                            {!sidebarOpen && openInfraDropdown && (
                                <div className="dropdown-content-collapsed">
                                    <ul className="dropdown-list">
                                        {infraItems.map((item, index) => (
                                            <li
                                                key={index}
                                                className={`dropdown-item collapsed ${selectedInfraItem === item.toLowerCase() ? 'active' : ''}`}
                                                onClick={(e) => handleInfraSelect(item, e)}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </li>

                        {/* Conditional Rendering Based on Access */}
                        {userAccess.includes('EnviState') && (
                            <>
                                 {/* Analytics Section */}
                                 <li
                                    className={`nav-item ${selectedPage === 'analytics' ? 'active' : ''}`}
                                    onMouseEnter={() => setVisibleTooltip('analytics')}
                                    onMouseLeave={() => setVisibleTooltip(null)}
                                >
                                    <a
                                        className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSelect('analytics');
                                        }}
                                    >
                                        <i className="bi bi-house-fill"></i>
                                        {sidebarOpen && <span>Metric Monitoring</span>}
                                    </a>
                                    {visibleTooltip === 'analytics' && !sidebarOpen && (
                                        <div className="sidebar-custom-tooltip">
                                            {tooltips.analytics}
                                        </div>
                                    )}
                                </li>

                                {/* Dashboard Section */}
                                <li
                                    className={`nav-item ${selectedPage === 'dashboard' ? 'active' : ''}`}
                                    onMouseEnter={() => setVisibleTooltip('dashboard')}
                                    onMouseLeave={() => setVisibleTooltip(null)}
                                >
                                    <a
                                        className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSelect('dashboard');
                                        }}
                                    >
                                        <i className="bi bi-bar-chart-fill"></i>
                                        {sidebarOpen && <span>Dashboard</span>}
                                    </a>
                                    {visibleTooltip === 'dashboard' && !sidebarOpen && (
                                        <div className="sidebar-custom-tooltip">
                                            {tooltips.dashboard}
                                        </div>
                                    )}
                                </li>
                            </>
                        )}

                        {userAccess.includes('alerts') && (
                            <li
                                className={`nav-item ${selectedPage === 'alerts' ? 'active' : ''}`}
                                onMouseEnter={() => setVisibleTooltip('alerts')}
                                onMouseLeave={() => setVisibleTooltip(null)}
                            >
                                <a
                                    className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSelect('alerts');
                                    }}
                                >
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                    {sidebarOpen && <span>Alerts</span>}
                                </a>
                                {visibleTooltip === 'alerts' && !sidebarOpen && (
                                    <div className="sidebar-custom-tooltip">
                                        {tooltips.alerts}
                                    </div>
                                )}
                            </li>  
                        )}

                        {userAccess.includes('EnviDetect') && (
                            <li
                                className={`nav-item ${selectedPage === 'enviDetect' ? 'active' : ''}`}
                                onMouseEnter={() => setVisibleTooltip('enviDetect')}
                                onMouseLeave={() => setVisibleTooltip(null)}
                            >
                                <a
                                    className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSelect('enviDetect');
                                    }}
                                >
                                    <i className="bi bi-lightbulb-fill"></i>
                                    {sidebarOpen && <span>EnviDetect</span>}
                                </a>
                                {visibleTooltip === 'enviDetect' && !sidebarOpen && (
                                    <div className="sidebar-custom-tooltip">
                                        {tooltips.enviDetect}
                                    </div>
                                )}
                            </li>
                        )}

                        {userAccess.includes('Coorelation') && (
                            <>
                                <li
                                    className={`nav-item ${selectedPage === 'APM' ? 'active' : ''}`}
                                    onMouseEnter={() => setVisibleTooltip('APM')}
                                    onMouseLeave={() => setVisibleTooltip(null)}
                                >
                                    <a
                                        className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSelect('APM');
                                        }}
                                    >
                                        <i className="bi bi-card-heading"></i>
                                        {sidebarOpen && <span>APM</span>}
                                    </a>
                                    {visibleTooltip === 'APM' && !sidebarOpen && (
                                        <div className="sidebar-custom-tooltip">
                                            {tooltips.APM}
                                        </div>
                                    )}
                                </li>
                                <li
                                    className={`nav-item ${selectedPage === 'correlation' ? 'active' : ''}`}
                                    onMouseEnter={() => setVisibleTooltip('correlation')}
                                    onMouseLeave={() => setVisibleTooltip(null)}
                                >
                                    <a
                                        className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSelect('correlation');
                                        }}
                                    >
                                        <i className="bi bi-bezier2"></i>
                                        {sidebarOpen && <span>Correlation</span>}
                                    </a>
                                    {visibleTooltip === 'correlation' && !sidebarOpen && (
                                        <div className="sidebar-custom-tooltip">
                                            {tooltips.correlation}
                                        </div>
                                    )}
                                </li>
                                <li
                                    className={`nav-item ${selectedPage === 'enviheal' ? 'active' : ''}`}
                                    onMouseEnter={() => setVisibleTooltip('enviheal')}
                                    onMouseLeave={() => setVisibleTooltip(null)}
                                >
                                    <a
                                        className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSelect('enviheal');
                                        }}
                                    >
                                        <i className="bi bi-cpu"></i>
                                        {sidebarOpen && <span>EnviHeal</span>}
                                    </a>
                                    {visibleTooltip === 'enviheal' && !sidebarOpen && (
                                        <div className="sidebar-custom-tooltip">
                                            {tooltips.enviheal}
                                        </div>
                                    )}
                                </li>
                            </>
                        )}
                    </ul>
                </div>

                <div className="sidebar-toggle" onClick={toggleSidebar}>
                    <i className={`bi ${sidebarOpen ? 'bi-chevron-left' : 'bi-chevron-right'}`}></i>
                </div>
            </aside>
        </div>
    );
};

export default Sidebar;
