import React, { useState, useEffect, useRef } from 'react';
import './MultiSelectorFilter.css';

const MultiSelectorFilter = ({ 
    label, 
    options, 
    selectedOptions, 
    setSelectedOptions,
    buttonClassName = '',
    dropdownClassName = '',
    optionsClassName = ''
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSelectAll = () => {
        setSelectedOptions(options.map(option => option.value));
    };

    const handleClearAll = () => {
        setSelectedOptions([]);
    };

    const handleOptionToggle = (value) => {
        setSelectedOptions(prev => {
            if (prev.includes(value)) {
                return prev.filter(v => v !== value);
            }
            return [...prev, value];
        });
    };

    return (
        <div className="level-filter-container" ref={dropdownRef}>
            <button 
                className={`level-filter-button ${buttonClassName}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{label}</span>
                {selectedOptions.length > 0 && (
                    <span className="selected-count">{selectedOptions.length}</span>
                )}
            </button>

            {isOpen && (
                <div className={`level-filter-dropdown ${dropdownClassName}`}>
                    <div className="level-filter-header">
                        <div className="filter-action-buttons">
                            <button 
                                className="filter-action-button select-all"
                                onClick={handleSelectAll}
                                title="Select All"
                            >
                                <i className="bi bi-check2-all"></i>
                                Select All
                            </button>
                            {selectedOptions.length > 0 && (
                                <button 
                                    className="filter-action-button clear-all"
                                    onClick={handleClearAll}
                                    title="Clear All"
                                >
                                    <i className="bi bi-x-lg"></i>
                                    Clear All
                                </button>
                            )}
                        </div>
                    </div>
                    <div className={`level-options ${optionsClassName}`}>
                        {options.map(option => (
                            <div key={option.value} className="level-option">
                                <div className="level-option-content">
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions.includes(option.value)}
                                        onChange={() => handleOptionToggle(option.value)}
                                    />
                                    <div className={`level-badge ${option.value.toLowerCase()}`}>
                                        {option.label}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelectorFilter; 