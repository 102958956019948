import React, { useState, useEffect, useCallback, useRef } from 'react';
import ProcessTable from './ProcessTable';
import '../Dropdownnew.css';
import LazyLoad from './LazyLoad';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import CustomDropdown from '../CustomDropdown';
import { formatDateTime } from './utils';
import MultiSelectorFilter from './MultiSelectorFilter';

const InfraProcessesContent = ({ selectedDataSource, selectedServer, refreshKey }) => {
  const [processesList, setProcessesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const fetchProcessData = useCallback(async () => {
    if (!selectedDataSource || !selectedServer) {
      setProcessesList([]);
      return;
    }

    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const token = getCookie('token');
    const url = `https://infia.enviseer.com/api/clusters/${selectedDataSource}/process_monitoring?hostname=${selectedServer}`;
    
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setProcessesList(data || []);
    } catch (error) {
      console.error('Error fetching process data:', error);
      setError('Failed to fetch process data. Please try again later.');
      setProcessesList([]);
    } finally {
      setLoading(false);
    }
  }, [selectedDataSource, selectedServer]);

  useEffect(() => {
    fetchProcessData();
  }, [fetchProcessData, refreshKey]);

  // Count processes by status
  const getStatusCounts = () => {
    if (!processesList.length) return {};
    
    const counts = {};
    processesList.forEach(process => {
      const status = process.process_status || 'unknown';
      counts[status] = (counts[status] || 0) + 1;
    });
    
    return counts;
  };

  const statusCounts = getStatusCounts();
  
  // Get all unique statuses for the filter
  const getUniqueStatuses = () => {
    if (!processesList.length) return [];
    
    const uniqueStatuses = [...new Set(processesList.map(p => p.process_status))];
    return uniqueStatuses.filter(Boolean).map(status => ({
      value: status,
      label: status.charAt(0).toUpperCase() + status.slice(1)
    }));
  };
  
  // Filter processes based on selected statuses
  const getFilteredProcesses = () => {
    if (!selectedStatuses.length) return processesList;
    
    return processesList.filter(process => 
      selectedStatuses.includes(process.process_status)
    );
  };

  return (
    <div className="infra-processes-container" style={{ 
      padding: '20px', 
      height: 'calc(100vh - 150px)',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginTop: '30px',
      background: 'linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%)',
      borderRadius: '12px'
    }}>
      <div className="processes-header" style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 10px'
      }}>
        <h1 style={{ 
          fontSize: '24px', 
          fontWeight: '600', 
          color: '#1b2559',
          margin: 0,
          display: 'flex',
          alignItems: 'center',
          gap: '10px'
        }}>
          Process Monitoring
          {selectedServer && (
            <span style={{ 
              fontSize: '16px', 
              color: '#6825EC', 
              fontWeight: '500',
              background: 'rgba(104, 37, 236, 0.08)',
              padding: '4px 12px',
              borderRadius: '6px',
              display: 'inline-block'
            }}>
              {selectedServer}
            </span>
          )}
        </h1>
        
        <div className="processes-stats" style={{
          display: 'flex',
          gap: '15px'
        }}>
          <div className="stat-item" style={{
            background: 'linear-gradient(135deg, #f2f6ff, #e5eeff)',
            padding: '12px 20px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
            border: '1px solid rgba(104, 37, 236, 0.1)',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            minWidth: '130px',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'default',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 16px rgba(0, 0, 0, 0.12)'
            }
          }} onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.12)';
          }} onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.08)';
          }}>
            <div style={{
              position: 'absolute',
              top: '0',
              right: '0',
              width: '30px',
              height: '30px',
              borderRadius: '0 0 0 15px',
              background: 'rgba(104, 37, 236, 0.08)',
              zIndex: '0'
            }}></div>
            <span style={{ 
              fontSize: '13px', 
              color: '#6B7280', 
              fontWeight: '500',
              position: 'relative',
              zIndex: '1'
            }}>Total Processes</span>
            <span style={{ 
              fontSize: '24px', 
              fontWeight: '700', 
              color: '#1b2559',
              position: 'relative',
              zIndex: '1' 
            }}>
              {processesList.length}
            </span>
          </div>
          
          <div className="stat-item" style={{
            background: 'linear-gradient(135deg, #f0fff4, #dcfce7)',
            padding: '12px 20px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
            border: '1px solid rgba(34, 197, 94, 0.1)',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            minWidth: '130px',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'default'
          }} onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.12)';
          }} onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.08)';
          }}>
            <div style={{
              position: 'absolute',
              top: '0',
              right: '0',
              width: '30px',
              height: '30px',
              borderRadius: '0 0 0 15px',
              background: 'rgba(34, 197, 94, 0.08)',
              zIndex: '0'
            }}></div>
            <span style={{ 
              fontSize: '13px', 
              color: '#6B7280', 
              fontWeight: '500',
              position: 'relative',
              zIndex: '1'
            }}>Running</span>
            <span style={{ 
              fontSize: '24px', 
              fontWeight: '700', 
              color: '#10b981',
              position: 'relative',
              zIndex: '1'
            }}>
              {statusCounts['running'] || 0}
            </span>
          </div>
          
          <div className="stat-item" style={{
            background: 'linear-gradient(135deg, #fffbeb, #fef3c7)',
            padding: '12px 20px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
            border: '1px solid rgba(245, 158, 11, 0.1)',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            minWidth: '130px',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'default'
          }} onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.12)';
          }} onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.08)';
          }}>
            <div style={{
              position: 'absolute',
              top: '0',
              right: '0',
              width: '30px',
              height: '30px',
              borderRadius: '0 0 0 15px',
              background: 'rgba(245, 158, 11, 0.08)',
              zIndex: '0'
            }}></div>
            <span style={{ 
              fontSize: '13px', 
              color: '#6B7280', 
              fontWeight: '500',
              position: 'relative',
              zIndex: '1'
            }}>Sleeping</span>
            <span style={{ 
              fontSize: '24px', 
              fontWeight: '700', 
              color: '#f59e0b',
              position: 'relative',
              zIndex: '1'
            }}>
              {statusCounts['sleeping'] || 0}
            </span>
          </div>
          
          {statusCounts['idle'] !== undefined && (
            <div className="stat-item" style={{
              background: 'linear-gradient(135deg, #fff7ed, #ffedd5)',
              padding: '12px 20px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
              border: '1px solid rgba(234, 88, 12, 0.1)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              minWidth: '130px',
              position: 'relative',
              overflow: 'hidden',
              cursor: 'default'
            }} onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-2px)';
              e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.12)';
            }} onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'translateY(0)';
              e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.08)';
            }}>
              <div style={{
                position: 'absolute',
                top: '0',
                right: '0',
                width: '30px',
                height: '30px',
                borderRadius: '0 0 0 15px',
                background: 'rgba(234, 88, 12, 0.08)',
                zIndex: '0'
              }}></div>
              <span style={{ 
                fontSize: '13px', 
                color: '#6B7280', 
                fontWeight: '500',
                position: 'relative',
                zIndex: '1'
              }}>Idle</span>
              <span style={{ 
                fontSize: '24px', 
                fontWeight: '700', 
                color: '#ea580c',
                position: 'relative',
                zIndex: '1'
              }}>
                {statusCounts['idle'] || 0}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="processes-content" style={{ 
        flex: 1,
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.06)',
        overflow: 'hidden',
        padding: '10px'
      }}>
        {error ? (
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%',
            color: '#EF4444',
            fontSize: '16px',
            flexDirection: 'column',
            gap: '10px'
          }}>
            <span>{error}</span>
            <button 
              onClick={fetchProcessData}
              style={{
                background: '#EF4444',
                color: 'white',
                border: 'none',
                padding: '8px 16px',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '14px'
              }}
            >
              Retry
            </button>
          </div>
        ) : processesList.length === 0 && !loading ? (
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%',
            color: '#6B7280',
            fontSize: '16px',
            flexDirection: 'column',
            gap: '10px'
          }}>
            {selectedDataSource && selectedServer ? (
              <span>No processes found for {selectedServer}</span>
            ) : (
              <span>Please select a data source and server to view processes</span>
            )}
          </div>
        ) : (
          <LazyLoad height="100%">
            <ProcessTable 
              processes={processesList} 
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              loading={loading}
            />
          </LazyLoad>
        )}
      </div>
      
      <style jsx>{`
        @keyframes spin {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

const InfraProcesses = ({ sidebarOpen, onLogout }) => {
  const localDataSource = localStorage.getItem('DataSource') || '';
  const localServer = localStorage.getItem('Server') || '';

  const [dataSources, setDataSources] = useState([]);
  const [servers, setServers] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState(localDataSource || '');
  const [selectedServer, setSelectedServer] = useState(localServer || '');
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchClusters = useCallback(async () => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const token = getCookie('token');
    const username = getCookie('username');
    const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
    
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.clear();
          onLogout();
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
      
      const data = await response.json();
      setDataSources(data);
      
      const storedDataSource = localStorage.getItem('DataSource');
      if (storedDataSource && data.includes(storedDataSource)) {
        setSelectedDataSource(storedDataSource);
      } else if (data.length > 0) {
        setSelectedDataSource(data[0]);
        localStorage.setItem('DataSource', data[0]);
      }

      // Fetch servers only after setting the data source
      fetchServers(storedDataSource || data[0]);
    } catch (error) {
      console.error("Failed to fetch clusters:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [onLogout]);

  const fetchServers = useCallback(async (dataSource) => {
    if (!dataSource) return;
    
    const url = `https://infia.enviseer.com/api/clusters/${dataSource}/hostnames`;
    
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const token = getCookie('token');
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.clear();
          onLogout();
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
      
      const data = await response.json();
      if (data) {
        setServers(data);

        // Check local storage for previously selected server
        const storedServer = localStorage.getItem('Server');
        if (storedServer && data.includes(storedServer)) {
          setSelectedServer(storedServer);
        } else if (data.length > 0) {
          setSelectedServer(data[0]);
          localStorage.setItem('Server', data[0]);
        }
      }
    } catch (error) {
      console.error("Failed to fetch servers:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [onLogout]);

  useEffect(() => {
    fetchClusters();
  }, [fetchClusters]);

  useEffect(() => {
    if (selectedDataSource) {
      fetchServers(selectedDataSource);
    }
  }, [selectedDataSource, fetchServers]);

  const handleDataSourceChange = (event) => {
    const selected = event.target.value;
    setSelectedDataSource(selected);
    localStorage.setItem('DataSource', selected);
    
    fetchServers(selected);
    setRefreshKey(prev => prev + 1);
  };

  const handleServerChange = (event) => {
    const selected = event.target.value;
    setSelectedServer(selected);
    localStorage.setItem('Server', selected);
    setRefreshKey(prevKey => prevKey + 1);
  };

  const handleRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  return (
    <div>
      <div className="dashboard">
        <div className="dropdowns" style={{ width: sidebarOpen ? '90%' : '96%', justifyContent: 'space-between' }}>
          <div className="dropdown-contents">
            <div className="dropdown">
              <CustomDropdown
                label="Cluster"
                options={dataSources.length > 0 ? dataSources.map(source => ({ value: source, label: source })) : []}
                value={selectedDataSource || ''}
                onChange={handleDataSourceChange}
              />
            </div>

            <div className="dropdown">
              <CustomDropdown
                label="Servers"
                options={servers.length > 0 ? servers.map(server => ({ value: server, label: server })) : []}
                value={selectedServer || ''}
                onChange={handleServerChange}
                disabled={servers.length === 0}
              />
            </div>

            <div>
              <button 
                onClick={handleRefresh} 
                className="refresh-button"
                style={{
                  backgroundColor: '#6825EC',
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'none',
                  cursor: 'pointer',
                  boxShadow: '0 4px 12px rgba(104, 37, 236, 0.2)',
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'rotate(45deg)';
                  e.currentTarget.style.backgroundColor = '#5a1ecc';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'rotate(0deg)';
                  e.currentTarget.style.backgroundColor = '#6825EC';
                }}
              >
                <i className="bi bi-arrow-clockwise" style={{ color: 'white', fontSize: '18px' }}></i>
              </button>
            </div>
          </div>
        </div>

        <InfraProcessesContent
          key={refreshKey}
          selectedDataSource={selectedDataSource}
          selectedServer={selectedServer}
          refreshKey={refreshKey}
        />
      </div>
    </div>
  );
};

export default InfraProcesses; 