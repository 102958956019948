import React, { useState } from "react";
import { Undo2, Redo2, RefreshCw } from "lucide-react";

const buttonStyle = {
  padding: "8px",
  borderRadius: "12px",
  backgroundColor: "white",
  border: "1px solid #D1D5DB",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  transition: "background-color 0.2s ease",
  position: "relative",
};

const containerStyle = {
  position: "relative",
  top: "0px",
  left: "16px",
  display: "flex",
  flexDirection: "row",
  gap: "6px",
};

// Tooltip Component (Shown only on hover)
const Tooltip = ({ text, isVisible }) => {
  return (
    isVisible && (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "120%",
          transform: "translateX(-50%)",
          padding: "6px 12px",
          backgroundColor: "white",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          borderRadius: "6px",
          fontSize: "12px",
          color: "#333",
          whiteSpace: "nowrap",
          transition: "opacity 0.2s ease",
        }}
      >
        {text}
      </div>
    )
  );
};

const ZoomControls = ({ handleUndoZoom,  handleResetZoom , zoomHistory}) => {
  const [hoveredButton, setHoveredButton] = useState(null);

  if(zoomHistory&&zoomHistory.length>0){
    return (
    <div style={containerStyle}>
      {/* Undo Zoom Button */}
      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setHoveredButton("undo")}
        onMouseLeave={() => setHoveredButton(null)}
      >
        <button
          onClick={handleUndoZoom}
          style={buttonStyle}
        >
          <Undo2 size={20} color="#6B7280" />
        </button>
        <Tooltip text="Undo zoom" isVisible={hoveredButton === "undo"} />
      </div>



      {/* Reset Zoom Button */}
      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setHoveredButton("reset")}
        onMouseLeave={() => setHoveredButton(null)}
      >
        <button
          onClick={handleResetZoom}
          style={buttonStyle}
        >
          <RefreshCw size={20} color="#6B7280" />
        </button>
        <Tooltip text="Reset zoom" isVisible={hoveredButton === "reset"} />
      </div>
    </div>
  );
  }
  else{
    return;
  }
};

export default ZoomControls;
