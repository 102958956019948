import React, { useState, useCallback, useRef } from 'react';
import ReactFlow, { 
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    ReactFlowProvider,
    Position
} from 'reactflow';
import 'reactflow/dist/style.css';
import './RPA.css';
import CustomNode from './CustomNode';

const nodeTypes = {
    customNode: CustomNode
};

const taskInfo = {
    // Tools
    lassoTool: {
        title: 'Selection Tool',
        description: 'Select and group multiple nodes',
        fields: {}
    },
    globalConnect: {
        title: 'Connection Tool',
        description: 'Create connections between any nodes',
        fields: {}
    },
    // Flow Control
    start: {
        title: 'Start',
        description: 'Starting point of the workflow',
        fields: {
            name: { type: 'text', label: 'Name', required: true }
        }
    },
    flag: {
        title: 'Status Flag',
        description: 'Mark success or failure states',
        fields: {
            type: {
                type: 'select',
                label: 'Status',
                options: ['Success', 'Failure'],
                required: true
            },
            message: { type: 'text', label: 'Message' }
        }
    },
    noop: {
        title: 'No Operation',
        description: 'Placeholder node that performs no action',
        fields: {
            description: { type: 'text', label: 'Description' }
        }
    },
    fork: {
        title: 'Parallel Fork',
        description: 'Split workflow into parallel branches',
        fields: {
            branches: { type: 'number', label: 'Number of Branches', default: '2', required: true }
        }
    },
    join: {
        title: 'Parallel Join',
        description: 'Merge parallel branches',
        fields: {
            type: {
                type: 'select',
                label: 'Join Type',
                options: ['All', 'Any'],
                required: true
            }
        }
    },
    link: {
        title: 'Workflow Link',
        description: 'Link to another workflow',
        fields: {
            workflow: { type: 'text', label: 'Target Workflow', required: true },
            params: { type: 'textarea', label: 'Parameters (JSON)', default: '{}' }
        }
    },
    timedWait: {
        title: 'Delay',
        description: 'Pause workflow execution',
        fields: {
            duration: { type: 'number', label: 'Duration (seconds)', required: true },
            maxWait: { type: 'number', label: 'Maximum Wait (seconds)' }
        }
    },
    // Variables
    editVariable: {
        title: 'Variable Editor',
        description: 'Create or modify workflow variables',
        fields: {
            name: { type: 'text', label: 'Variable Name', required: true },
            value: { type: 'textarea', label: 'Value', required: true },
            type: {
                type: 'select',
                label: 'Type',
                options: ['String', 'Number', 'Boolean', 'JSON'],
                required: true
            }
        }
    },
    // Actions
    script: {
        title: 'Script',
        description: 'Execute custom scripts',
        fields: {
            language: {
                type: 'select',
                label: 'Language',
                options: ['Python', 'JavaScript', 'Shell'],
                required: true
            },
            code: { type: 'textarea', label: 'Code', required: true },
            timeout: { type: 'number', label: 'Timeout (seconds)', default: '30' }
        }
    },
    hostCommand: {
        title: 'System Command',
        description: 'Execute system commands',
        fields: {
            command: { type: 'textarea', label: 'Command', required: true },
            workingDir: { type: 'text', label: 'Working Directory' },
            timeout: { type: 'number', label: 'Timeout (seconds)', default: '30' }
        }
    },
    // Integration
    aiops: {
        title: 'AI Operations',
        description: 'Interact with AI/ML services',
        fields: {
            service: {
                type: 'select',
                label: 'Service',
                options: ['Anomaly Detection', 'Root Cause Analysis', 'Prediction'],
                required: true
            },
            parameters: { type: 'textarea', label: 'Parameters (JSON)', default: '{}', required: true }
        }
    },
    httpRequest: {
        title: 'HTTP Request',
        description: 'Make HTTP/API calls',
        fields: {
            method: {
                type: 'select',
                label: 'Method',
                options: ['GET', 'POST', 'PUT', 'DELETE'],
                required: true
            },
            url: { type: 'text', label: 'URL', required: true },
            headers: { type: 'textarea', label: 'Headers (JSON)', default: '{}' },
            body: { type: 'textarea', label: 'Body (JSON)', default: '{}' }
        }
    },
    // Communication
    email: {
        title: 'Email',
        description: 'Send email notifications',
        fields: {
            to: { type: 'text', label: 'To', required: true },
            subject: { type: 'text', label: 'Subject', required: true },
            body: { type: 'textarea', label: 'Body', required: true },
            attachments: { type: 'text', label: 'Attachments', placeholder: 'Comma-separated file paths' }
        }
    },
    updateTask: {
        title: 'Task Update',
        description: 'Update task status and details',
        fields: {
            taskId: { type: 'text', label: 'Task ID', required: true },
            status: {
                type: 'select',
                label: 'Status',
                options: ['In Progress', 'Completed', 'Failed', 'Pending'],
                required: true
            },
            notes: { type: 'textarea', label: 'Notes' }
        }
    },
    taskLifecycle: {
        title: 'Task Lifecycle',
        description: 'Manage task lifecycle events',
        fields: {
            event: {
                type: 'select',
                label: 'Event',
                options: ['Create', 'Start', 'Complete', 'Cancel', 'Fail'],
                required: true
            },
            details: { type: 'textarea', label: 'Event Details' }
        }
    },
    aif: {
        title: 'AIF Integration',
        description: 'Interact with AIF services',
        fields: {
            operation: { type: 'text', label: 'Operation', required: true },
            parameters: { type: 'textarea', label: 'Parameters (JSON)', default: '{}' }
        }
    },
    phoneCall: {
        title: 'Phone Notification',
        description: 'Make automated phone calls',
        fields: {
            to: { type: 'text', label: 'Phone Number', required: true },
            message: { type: 'textarea', label: 'Message', required: true },
            priority: {
                type: 'select',
                label: 'Priority',
                options: ['Normal', 'High', 'Emergency'],
                default: 'Normal'
            }
        }
    },
    credentials: {
        title: 'Credentials',
        description: 'Retrieve secure credentials',
        fields: {
            service: { type: 'text', label: 'Service Name', required: true },
            account: { type: 'text', label: 'Account', required: true }
        }
    },
    hyperMessage: {
        title: 'HyperA Message',
        description: 'Send messages to HyperA',
        fields: {
            recipient: { type: 'text', label: 'Recipient', required: true },
            message: { type: 'textarea', label: 'Message', required: true },
            type: {
                type: 'select',
                label: 'Message Type',
                options: ['Alert', 'Notification', 'Update'],
                required: true
            }
        }
    }
};

const ServerActionNode = ({ data, selected }) => {
    const [showConfig, setShowConfig] = useState(false);
    const [config, setConfig] = useState(data.config || {});
    const [, setNodes] = useNodes();
    const [, setEdges] = useEdges();

    const handleConfigChange = (field, value) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            [field]: value
        }));
    };

    const handleSave = () => {
        data.config = { ...config };
        setShowConfig(false);
    };

    const handleDelete = useCallback((e) => {
        e.stopPropagation();
        setNodes(nodes => nodes.filter(node => node.id !== data.id));
        setEdges(edges => edges.filter(edge => edge.source !== data.id && edge.target !== data.id));
    }, [data.id, setNodes, setEdges]);

    return (
        <div className={`server-node ${selected ? 'selected' : ''}`}>
            <div className="draggable-handle" />
            <button 
                className="node-delete-btn" 
                onClick={handleDelete}
                title="Delete node"
            >
                <i className="bi bi-trash" />
            </button>
            <div className="node-header" onClick={() => setShowConfig(true)}>
                <div className="node-icon">
                    <i className="bi bi-hdd-rack" />
                </div>
                <div className="node-title">Server Action</div>
                {config.action && (
                    <div className="node-tag">{config.action}</div>
                )}
            </div>
            <div className="node-content">
                {config.target ? (
                    <div>Target: {config.target}</div>
                ) : (
                    <div className="node-placeholder">Click to configure</div>
                )}
            </div>

            {showConfig && (
                <div 
                    className="configuration-backdrop" 
                    onClick={(e) => {
                        if (e.target === e.currentTarget) setShowConfig(false);
                    }}
                >
                    <div 
                        className="configuration-fields" 
                        onClick={e => e.stopPropagation()}
                    >
                        <h3>
                            <i className="bi bi-hdd-rack" />
                            Configure Server Action
                        </h3>
                        
                        <div className="field-group">
                            <label className="field-label required-field">Action</label>
                            <select 
                                className="field-select"
                                value={config.action || ''}
                                onChange={(e) => handleConfigChange('action', e.target.value)}
                                onClick={e => e.stopPropagation()}
                            >
                                <option value="">Select action...</option>
                                <option value="start">Start</option>
                                <option value="stop">Stop</option>
                                <option value="restart">Restart</option>
                                <option value="status">Status</option>
                            </select>
                        </div>

                        <div className="field-group">
                            <label className="field-label required-field">Target Server</label>
                            <input
                                type="text"
                                className="field-input"
                                value={config.target || ''}
                                onChange={(e) => handleConfigChange('target', e.target.value)}
                                onClick={e => e.stopPropagation()}
                                placeholder="Enter server hostname or IP"
                            />
                        </div>

                        <div className="field-group">
                            <label className="field-label">Timeout (seconds)</label>
                            <input
                                type="number"
                                className="field-input"
                                value={config.timeout || '30'}
                                onChange={(e) => handleConfigChange('timeout', e.target.value)}
                                onClick={e => e.stopPropagation()}
                                placeholder="30"
                            />
                            <div className="field-description">Maximum time to wait for response</div>
                        </div>

                        <div className="configuration-actions">
                            <button 
                                className="action-button secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowConfig(false);
                                }}
                            >
                                <i className="bi bi-x-lg" />
                                Cancel
                            </button>
                            <button 
                                className="action-button primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSave();
                                }}
                            >
                                <i className="bi bi-check-lg" />
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const RPA = ({ sidebarOpen, onLogout }) => {
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [showInfoModal, setShowInfoModal] = useState(null);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    );

    const onInit = useCallback(
        (instance) => {
            setReactFlowInstance(instance);
        },
        []
    );

    const onDragStart = (event, item) => {
        event.dataTransfer.setData('application/reactflow', JSON.stringify(item));
        event.dataTransfer.effectAllowed = 'move';
        event.currentTarget.classList.add('dragging');
    };

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
        setIsDraggingOver(true);
    };

    const onDragLeave = () => {
        setIsDraggingOver(false);
    };

    const onDrop = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);

        const data = event.dataTransfer.getData('application/reactflow');
        if (!data || !reactFlowInstance) return;

        const item = JSON.parse(data);
        const position = reactFlowInstance.screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
        });

        const newNode = {
            id: `${item.type}-${Date.now()}`,
            type: 'customNode',
            position,
            data: { 
                label: item.label,
                icon: item.icon,
                tag: item.type,
                config: {}
            },
            sourcePosition: Position.Bottom,
            targetPosition: Position.Top,
            draggable: true,
        };

        setNodes((nds) => nds.concat(newNode));
        setSelectedNode(newNode);
    };

    const onNodeClick = (event, node) => {
        setSelectedNode(node);
    };

    const onDeleteNode = (nodeId) => {
        setNodes((nds) => nds.filter((node) => node.id !== nodeId));
        setEdges((eds) => eds.filter((edge) => 
            edge.source !== nodeId && edge.target !== nodeId
        ));
    };

    const onConfigChange = (nodeId, config) => {
        setNodes((nds) => 
            nds.map((node) => 
                node.id === nodeId 
                    ? { ...node, data: { ...node.data, config } }
                    : node
            )
        );
    };

    const showTaskInfo = (taskType) => {
        setShowInfoModal(taskType);
    };

    return (
            <div className="rpa-container">
            <div className="rpa-workspace">
                <div className="task-palette">
                    <div className="task-palette-header">
                        <h3>Automation Tools</h3>
                        <p>Drag and drop tools to the canvas</p>
                    </div>
                    
                    <div className="task-category">
                        <h4>Tools</h4>
                        <div className="task-grid">
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'lassoTool', label: 'Selection Tool', icon: 'bi-vector-pen' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-vector-pen"></i>
                                    <span>Selection Tool</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('lassoTool')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'globalConnect', label: 'Connection Tool', icon: 'bi-bezier2' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-bezier2"></i>
                                    <span>Connection Tool</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('globalConnect')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="task-category">
                        <h4>Flow Control</h4>
                        <div className="task-grid">
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'start', label: 'Start', icon: 'bi-play-circle-fill' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-play-circle-fill"></i>
                                    <span>Start</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('start')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'flag', label: 'Status Flag', icon: 'bi-flag-fill' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-flag-fill"></i>
                                    <span>Status Flag</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('flag')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'noop', label: 'No Operation', icon: 'bi-slash-circle' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-slash-circle"></i>
                                    <span>No Operation</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('noop')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'fork', label: 'Parallel Fork', icon: 'bi-diagram-3' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-diagram-3"></i>
                                    <span>Parallel Fork</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('fork')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'join', label: 'Parallel Join', icon: 'bi-diagram-2' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-diagram-2"></i>
                                    <span>Parallel Join</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('join')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'link', label: 'Workflow Link', icon: 'bi-link-45deg' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-link-45deg"></i>
                                    <span>Workflow Link</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('link')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'timedWait', label: 'Delay', icon: 'bi-hourglass-split' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-hourglass-split"></i>
                                    <span>Delay</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('timedWait')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="task-category">
                        <h4>Variables</h4>
                        <div className="task-grid">
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'editVariable', label: 'Variable Editor', icon: 'bi-input-cursor-text' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-input-cursor-text"></i>
                                    <span>Variable Editor</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('editVariable')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="task-category">
                        <h4>Actions</h4>
                        <div className="task-grid">
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'script', label: 'Script', icon: 'bi-code-square' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-code-square"></i>
                                    <span>Script</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('script')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'hostCommand', label: 'System Command', icon: 'bi-terminal' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-terminal"></i>
                                    <span>System Command</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('hostCommand')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="task-category">
                        <h4>Integration</h4>
                        <div className="task-grid">
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'aiops', label: 'AI Operations', icon: 'bi-robot' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-robot"></i>
                                    <span>AI Operations</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('aiops')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'httpRequest', label: 'HTTP Request', icon: 'bi-globe' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-globe"></i>
                                    <span>HTTP Request</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('httpRequest')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'aif', label: 'AIF Integration', icon: 'bi-box-arrow-in-right' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-box-arrow-in-right"></i>
                                    <span>AIF Integration</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('aif')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="task-category">
                        <h4>Communication</h4>
                        <div className="task-grid">
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'email', label: 'Email', icon: 'bi-envelope-fill' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-envelope-fill"></i>
                                    <span>Email</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('email')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'phoneCall', label: 'Phone Call', icon: 'bi-telephone-fill' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-telephone-fill"></i>
                                    <span>Phone Call</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('phoneCall')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'hyperMessage', label: 'HyperA Message', icon: 'bi-chat-dots-fill' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-chat-dots-fill"></i>
                                    <span>HyperA Message</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('hyperMessage')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="task-category">
                        <h4>Task Management</h4>
                        <div className="task-grid">
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'updateTask', label: 'Task Update', icon: 'bi-clipboard-check' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-clipboard-check"></i>
                                    <span>Task Update</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('updateTask')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'taskLifecycle', label: 'Task Lifecycle', icon: 'bi-arrow-repeat' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-arrow-repeat"></i>
                                    <span>Task Lifecycle</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('taskLifecycle')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                            <div className="task-item-wrapper">
                                <div
                                    draggable
                                    onDragStart={(e) => onDragStart(e, { type: 'credentials', label: 'Credentials', icon: 'bi-key-fill' })}
                                    className="task-item"
                                >
                                    <i className="bi bi-key-fill"></i>
                                    <span>Credentials</span>
                                </div>
                                <button 
                                    className="info-button"
                                    onClick={() => showTaskInfo('credentials')}
                                >
                                    <i className="bi bi-info-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <ReactFlowProvider>
                    <div 
                        className={`workflow-canvas ${isDraggingOver ? 'drag-over' : ''}`}
                        ref={reactFlowWrapper}
                    >
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            onInit={onInit}
                            nodeTypes={nodeTypes}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            onDragLeave={onDragLeave}
                            onNodeClick={onNodeClick}
                            fitView
                            snapToGrid
                            snapGrid={[15, 15]}
                        >
                            <Background />
                            <Controls />
                        </ReactFlow>
                    </div>
                </ReactFlowProvider>

                {selectedNode && (
                    <div className="node-config-panel">
                        <div className="panel-header">
                            <h3>{taskInfo[selectedNode.data.tag]?.title || selectedNode.data.label}</h3>
                            <button 
                                className="delete-button"
                                onClick={() => onDeleteNode(selectedNode.id)}
                            >
                                <i className="bi bi-trash"></i>
                            </button>
                        </div>
                        <div className="panel-content">
                            {taskInfo[selectedNode.data.tag]?.fields && 
                                Object.entries(taskInfo[selectedNode.data.tag].fields).map(([key, field]) => (
                                    <div key={key} className="form-field">
                                        <label>{field.label}</label>
                                        {field.type === 'select' ? (
                                            <select
                                                value={selectedNode.data.config[key] || field.default || ''}
                                                onChange={(e) => onConfigChange(selectedNode.id, {
                                                    ...selectedNode.data.config,
                                                    [key]: e.target.value
                                                })}
                                                required={field.required}
                                            >
                                                <option value="">Select {field.label}</option>
                                                {field.options.map((opt) => (
                                                    <option key={opt} value={opt}>{opt}</option>
                                                ))}
                                            </select>
                                        ) : field.type === 'textarea' ? (
                                            <textarea
                                                value={selectedNode.data.config[key] || field.default || ''}
                                                onChange={(e) => onConfigChange(selectedNode.id, {
                                                    ...selectedNode.data.config,
                                                    [key]: e.target.value
                                                })}
                                                required={field.required}
                                                placeholder={field.placeholder}
                                            />
                                        ) : (
                                            <input
                                                type={field.type}
                                                value={selectedNode.data.config[key] || field.default || ''}
                                                onChange={(e) => onConfigChange(selectedNode.id, {
                                                    ...selectedNode.data.config,
                                                    [key]: e.target.value
                                                })}
                                                required={field.required}
                                                placeholder={field.placeholder}
                                            />
                                        )}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )}

                {showInfoModal && (
                    <div className="info-modal">
                        <div className="modal-header">
                            <h3>
                                <i className={`bi ${taskInfo[showInfoModal].icon || 'bi-info-circle'}`}></i>
                                {taskInfo[showInfoModal].title}
                            </h3>
                            <button onClick={() => setShowInfoModal(null)}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-section">
                                <p>{taskInfo[showInfoModal].description}</p>
                            </div>
                            
                            {Object.keys(taskInfo[showInfoModal].fields).length > 0 && (
                                <div className="modal-section">
                                    <h4>
                                        <i className="bi bi-list-check"></i>
                                        Configuration Fields
                                    </h4>
                                    <div className="field-list">
                                        {Object.entries(taskInfo[showInfoModal].fields).map(([key, field]) => (
                                            <div key={key} className="field-item">
                                                <label>{field.label}</label>
                                                <span>
                                                    {field.type === 'select' ? (
                                                        'Select from: ' + field.options.join(', ')
                                                    ) : field.type === 'textarea' ? (
                                                        'Multi-line text'
                                                    ) : (
                                                        field.type.charAt(0).toUpperCase() + field.type.slice(1)
                                                    )}
                                                    {field.required && ' (Required)'}
                                                    {field.default && ` (Default: ${field.default})`}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default RPA; 