import React, { useState, useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { formatDateTime } from './utils'; // Ensure you have a formatDateTime function

// Register Chart.js components and custom plugins
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const centerTextPlugin = (selectedOption) => ({
    id: 'centerTextPlugin',
    afterDraw: (chart) => {
        const { ctx, chartArea, data } = chart;
        if (chart.width === 0 || chart.height === 0) {
            return;
        }
        
        const sum = data.datasets[0].data.reduce((acc, value) => acc + value, 0);
        ctx.restore();
        const centerX = chart.width / 2;
        const centerY = 1+chart.height/2;
        const fontSize = chart.height /12;
        let textColor;

switch (selectedOption) {
    case 'Basic Alert':
        textColor = 'rgba(204, 112, 0, 0.8)'; // Example color with opacity for Basic Alert
        break;
    case 'Collective Insight':
        textColor = 'rgba(207, 16, 32, 0.8)'; // Example color with opacity for Collective Insight
        break;
    default:
        textColor = 'rgba(0, 0, 0, 0.8)'; // Default color with opacity
        break;
}

        ctx.font = `bold ${fontSize}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = textColor; // Adjust color as needed
        ctx.fillText(`${sum}`, centerX, centerY);
        ctx.save();
    }
});

const percentageTextPlugin = {
    id: 'percentageTextPlugin',
    afterDatasetsDraw: (chart) => {
        const { ctx, data } = chart;
        const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);

        data.datasets[0].data.forEach((value, index) => {
            const percentage = ((value / total) * 100).toFixed(2);
            const model = chart.getDatasetMeta(0).data[index];
            const { x, y } = model.tooltipPosition();
            const fontSize = chart.height / 30;
            const padding = 4; // Padding around the text
            const borderRadius = 4; // Border radius for rounded corners

            // Set up font for text
            ctx.font = `${fontSize}px Arial`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Calculate text size
            const textWidth = ctx.measureText(`${percentage}%`).width;
            const textHeight = fontSize;

            // Draw background box with rounded corners
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Transparent black background
            ctx.beginPath();
            const xPos = x - textWidth / 2 - padding;
            const yPos = y - textHeight / 2 - padding;
            const boxWidth = textWidth + padding * 2;
            const boxHeight = textHeight + padding * 2;

            // Draw rounded rectangle
            ctx.moveTo(xPos + borderRadius, yPos);
            ctx.lineTo(xPos + boxWidth - borderRadius, yPos);
            ctx.arc(xPos + boxWidth - borderRadius, yPos + borderRadius, borderRadius, 1.5 * Math.PI, 2 * Math.PI);
            ctx.lineTo(xPos + boxWidth, yPos + boxHeight - borderRadius);
            ctx.arc(xPos + boxWidth - borderRadius, yPos + boxHeight - borderRadius, borderRadius, 0, 0.5 * Math.PI);
            ctx.lineTo(xPos + borderRadius, yPos + boxHeight);
            ctx.arc(xPos + borderRadius, yPos + boxHeight - borderRadius, borderRadius, 0.5 * Math.PI, Math.PI);
            ctx.lineTo(xPos, yPos + borderRadius);
            ctx.arc(xPos + borderRadius, yPos + borderRadius, borderRadius, Math.PI, 1.5 * Math.PI);
            ctx.closePath();
            ctx.fill();

            // Draw text
            ctx.fillStyle = '#fff'; // White text color
            ctx.fillText(`${percentage}%`, x, y);
        });
    }
};



const PieChart = ({ dataSource, server, startDate, endDate, updateAnomalyMetric, selectedOption }) => {
    const [anomalyData, setAnomalyData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const formattedStartTime = startDate ? formatDateTime(startDate) : '';
    const formattedEndTime = endDate ? formatDateTime(endDate) : '';
    const [refreshKey, setRefreshKey] = useState(0);
    const chartRef = useRef(null);

    const metricMapping = {
        'cpu.percent.used': { metricName: 'cpu_usage', label: 'CPU' },
        'memory.percent.used': { metricName: 'memory_usage', label: 'Memory' },
        'network_io_bytes_recv': { metricName: 'network_bytes_recv', label: 'Network' },
        'network_io_bytes_sent': { metricName: 'network_bytes_sent', label: 'Network' },
        'disk_usage': { metricName: 'disk_usage', label: 'Disk' },
        'gpu_usage': { metricName: 'gpu_usage', label: 'GPU' }
    };

    const metriclabels= {
        'cpu_usage':{label: 'CPU' }, 
        'memory_usage':{label: 'Memory usage' },'swap_usage':{label: 'Swap Usage' },
        'disk_read_count':{label: 'Read Count' },'disk_write_count':{label: 'Write Count' },'disk_read_bytes':{label: 'Read Bytes' },'disk_write_bytes':{label: 'Write Bytes' },'disk_read_time':{label: 'Read Time' },'disk_write_time':{label: 'Write Time' },
        'network_bytes_sent':{label: 'Bytes sent' },'network_bytes_recv':{label: 'Bytes Recv' },'network_packets_sent':{label: 'Packets Sent' },'network_packets_recv':{label: 'Packets Recv' },'network_errin':{label: 'Errin' },'network_errout':{label: 'Errout' },'network_dropin':{label: 'Dropin' },'network_dropout':{label: 'Dropout' },
    }

    useEffect(() => {
        const fetchAnomalyData = async () => {
            try {
                const mode = selectedOption.label === "Basic Alert" ? 'moderate' : 'strict';
                 function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
                const token = getCookie('token');
    
                const response = await fetch('https://infia.enviseer.com/api/anomaliescount?' + new URLSearchParams({
                    cluster: dataSource,
                    hostname: server,
                    start: formattedStartTime,
                    end: formattedEndTime,
                    mode: mode,
                }), {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                    }
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
                const transformedData = Object.entries(data).map(([key, value]) => ({
                    label: key,
                    count: value
                }));
                console.log("Selected Category Updated2:", selectedCategory);
                if (selectedCategory) {
                    console.log("Selected Category Updated:", selectedCategory);
                    const subcategoryData = aggregateDataBySubCategory(transformedData, selectedCategory);
                    setCategoryData(subcategoryData);
                } else {
                    const aggregatedData = aggregateDataByCategory(transformedData);
                    setAnomalyData(aggregatedData);
                }
            } catch (error) {
                console.error('Error fetching anomaly data:', error);
            }
        };
    
        fetchAnomalyData();
    }, [dataSource, server, formattedStartTime, formattedEndTime, selectedOption, selectedCategory, selectedSubCategory]);
    

    const colors = [
        '#309eff', '#4CB7F5', '#335e82', '#0565b5'
    ];
    const subColors= ['#02f5a4','#07ded3','#f9b9a2','#4ca1a3','#018f77','#228B22',' #FF8C00','#FFBF00'];
    
    const categoryMetrics = {
        'CPU': ['cpu_usage','cpu_wait'],
        'Memory': ['memory_usage', 'swap_usage', 'memory_buffered', 'memory_cached', 'memory_free', 'swap_io_out', 'swap_used'],
        'Disk': ['io_time_ms','disk_io_write_ms','disk_queue_size','disk_reads_bytes', 'disk_writes_bytes','disk_read_count', 'disk_write_count',  'disk_read_time', 'disk_write_time'],
        'Network': ['network_bytes_sent', 'network_bytes_recv', 'network_packets_sent', 'network_packets_recv', 'network_errin', 'network_errout', 'network_dropin', 'network_dropout']
    };

    const aggregateDataByCategory = (data) => {
        const categoryMap = {};
    
        data.forEach(item => {
            const category = Object.keys(categoryMetrics).find(cat => categoryMetrics[cat].includes(item.label));
            if (category) {
                if (categoryMap[category]) {
                    categoryMap[category] += item.count; // Add to existing category count
                } else {
                    categoryMap[category] = item.count; // Initialize new category count
                }
            }
        });
    
        // Convert the map to an array of objects
        return Object.keys(categoryMap).map(category => ({
            label: category,
            count: categoryMap[category]
        }));
    };
    
    const aggregateDataBySubCategory = (data, selectedCategory) => {
        // Check if the selectedCategory is valid
        const metricsForCategory = categoryMetrics[selectedCategory];
        if (!metricsForCategory) {
            return [];
        }
    
        // Filter the data based on the metrics for the selected category
        const filteredData = data.filter(metric => metricsForCategory.includes(metric.label));
    
        // Aggregate the counts for each label
        const aggregatedData = filteredData.reduce((acc, metric) => {
            acc[metric.label] = (acc[metric.label] || 0) + metric.count;
            return acc;
        }, {});
    
        // Convert aggregated data to an array of objects with label and count
        return Object.entries(aggregatedData).map(([label, count]) => ({ label, count }));
    };
    
    const safeAnomalyData = Array.isArray(anomalyData) ? anomalyData : [];
    const safeCategoryData = Array.isArray(categoryData) ? categoryData : [];


    
    const chartData = {
        datasets: selectedCategory ? [
            {
                // Outer Doughnut (Categories)
                data: safeAnomalyData.map(item => item.count),
                backgroundColor: safeAnomalyData.map(item => 
                    item.label === selectedCategory ? colors[safeAnomalyData.indexOf(item)] : `${colors[safeAnomalyData.indexOf(item)]}50`
                ), // Reduce opacity for non-selected categories
                hoverOffset: 4,
                hoverBackgroundColor: safeAnomalyData.map(item => colors[safeAnomalyData.indexOf(item)]),
                labels: safeAnomalyData.map(item => metriclabels[item.label]?.label || item.label), // Labels specific to outer dataset
            },
            {
                // Inner Doughnut (Subcategories)
                data: safeCategoryData.map(item => item.count),
                backgroundColor: safeCategoryData.map(item => 
                    item.label === selectedSubCategory ? subColors[safeCategoryData.indexOf(item)] : `${subColors[safeCategoryData.indexOf(item)]}50`
                ), // Reduce opacity for non-selected subcategories
                hoverOffset: 4,
                hoverBackgroundColor: safeCategoryData.map(item => subColors[safeCategoryData.indexOf(item)]),
                labels: safeCategoryData.map(item => metriclabels[item.label]?.label || item.label), // Labels specific to inner dataset
            }
        ] : [
            {
                // Single dataset when no category is selected
                data: safeAnomalyData.map(item => item.count),
                backgroundColor: colors.slice(0, safeAnomalyData.length),
                hoverOffset: 4,
                hoverBackgroundColor: safeAnomalyData.map(item => colors[safeAnomalyData.indexOf(item)]),
                labels: safeAnomalyData.map(item => metriclabels[item.label]?.label || item.label), // Labels for the dataset
            }
        ]
    };
    
    
    
    const handleClick = (event) => {
    
        if (!chartRef.current) return;
    
        const chart = chartRef.current;
        const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
    
        
        if (elements.length > 0) {
            const index = elements[0].index;
            const datasetIndex = elements[0].datasetIndex;
          if (datasetIndex === 1 && selectedCategory) {
            const item = categoryData[index]; // Inner chart logic (subcategories)
            const metricName = item.label;
            const label = item.label;
            console.log("Inner Chart Clicked: ",metricName, categoryData);
            setSelectedSubCategory(item.label);
            updateAnomalyMetric({metricName, label});
            // Handle inner chart click logic here
            // e.g., update a state or trigger an action based on the subcategory clicked
          }else{
            const item = anomalyData[index];
            const maxanomalyCount = categoryData ? Math.max(...categoryData.map(item2 => item2.count)) : null;

            // Find the first entry with the max anomaly count (if multiple entries, it finds the first one)
            const maxanomalysubcategory = maxanomalyCount ? categoryData.find(item2 => item2.count === maxanomalyCount) : null;

            // Get the label of the max anomaly count
            const maxanomalyLabel = maxanomalysubcategory ? maxanomalysubcategory.label : null;
            if (selectedCategory === item.label) {

                setSelectedCategory('');
              } else {

                setSelectedCategory(item.label);
                
              }
            console.log("KKKKKKKKKKKKK",categoryData,maxanomalyLabel);
                const metricName = Object.values(metricMapping).find(item2 => item2.label === item.label)?.metricName || null;
                const label = item.label;
                if (metricName) {
                    console.log("Selected Metric:", item.label);
                    updateAnomalyMetric({ metricName, label });
                }
            
        }
    }
    };
    return (
        <div style={{ backgroundColor: 'white', paddingTop: '20px', width: '26%', borderRadius: '5px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)', overflowX: 'auto' }}>
            <h3 style={{ marginBottom: '20px', fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Anomalies by Metric</h3>
            <div style={{ display: 'flex', flexDirection:'column',justifyContent: 'center',width:'100%', maxHeight: '320px', zIndex:0, alignItems:'center' }}>
            <Doughnut
                data={chartData}
                onClick={handleClick}
                options={{
                responsive: true,
                plugins: {
                 legend: {
                   position: 'bottom',
                   labels: {
                     boxWidth: 15,
                   }
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                        const datasetIndex = context.datasetIndex;
                        const label = context.dataset.labels[context.dataIndex]; // Use the label corresponding to the dataset
                        return `${label}: ${context.raw}`;
                    }
                  },
               },
                centerTextPlugin: true, // Register the custom plugin
                },
               }}
               plugins={[centerTextPlugin(selectedOption.label), percentageTextPlugin]} // Use the custom plugin here
               ref={chartRef}
            />
             
            </div>{selectedCategory && (
                    <div style={{
                        position: 'relative',
                        textAlign:'center',
                        zIndex: 1, // Ensure it appears above other elements
                        fontWeight: '600',
                        fontSize:'14px',
                        marginBottom:'20px'
                    }}>
                     <h style={{fontWeight:400}}> Type:</h> {selectedCategory}
                    </div>
                )}
        </div>
    );
};

export default PieChart;
