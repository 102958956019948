import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { formatDateTime, convertToIST, getCondensedData } from './utils'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            const label = chart.data.labels[activePoint.index];
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'lightgrey';
            ctx.stroke();
            ctx.restore();

            const labelX = x;
            const labelY = bottomY + 20;
            ctx.fillStyle = 'white';
            ctx.fillRect(labelX - 20, labelY - 10, 40, 20); // Adjust width and height accordingly
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(label.substring(0,5), x, bottomY + 20);
            ctx.fillText(label.substring(6,label.length), x, bottomY + 35);
            ctx.restore();


        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps1, data1, label1, borderColor1, backgroundColor1,timestamps2, data2, label2, pointColor) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
    gradient1.addColorStop(0, '#0dcaf0'); // Light blue start
    gradient1.addColorStop(1, '#e0f2fe'); // Very light blue end


    return {
        labels: timestamps1.map(convertToIST),
        datasets: [
            {
                label: label1,
                data: data1,
                fill: true,
                borderColor: borderColor1,
                backgroundColor: gradient1,
                tension: 0.3,
                pointBorderWidth: 1,
                pointRadius: 2,
                borderWidth: 1,
                pointBackgroundColor: borderColor1,
                pointStyle: 'circle',
                pointHoverRadius: 3,
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                order:1
            },
                {
                label: label2,
                data: data2,
                fill: false,
                tension: 0.2,
                pointBorderWidth: 0.3,
                pointRadius: data2.map(value => value > 0 ? 6 :0),
                pointBackgroundColor: data2.map(value => value > 0 ? pointColor : borderColor1),
                pointBorderColor: data2.map(value => value > 0 ? 'black' : 'transparent'),
                pointStyle: 'circle',
                pointHoverBorderWidth: 1,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                backgroundColor: pointColor,
                borderColor: 'transparent',
                pointHoverRadius: data2.map(value => value > 0 ? 8 : 0),
                order:0,

            }
        ],
    };
};



const fetchClusterMetric = async (metricName, server, start, end) => {
    const formattedStartTime = formatDateTime(start);
    const formattedEndTime = formatDateTime(end);
    const url = `https://infia.enviseer.com/api/clusters/${dataSource}/healthscores?hostname=${server}`;
    console.log(url);
     function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.value);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};

const fetchAggregateData = async (dataSource, server, start, end) => {
     const formattedStartTime = formatDateTime(start);
     const formattedEndTime = formatDateTime(end);
      const url = `https://infia.enviseer.com/api/healthscores/clusterhost?cluster=${dataSource}&hostname=${server}`;
    console.log(url);
      function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }   
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('data', data);
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.value);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};

const LandingGraph = ({ dataSource, servers, startDate, endDate, metricName, labels, updateClusterScore}) => {
   
    const [timestamps, setTimestamps] = useState([]);
    const [metricData, setMetricData] = useState([]);
    const [averageValue, setAverageValue] = useState(0);
    const chartRef = useRef(null);
    
     const calculateAverage = (values) => {
       if (values.length === 0) return 0;
       const sum = values.reduce((acc, curr) => acc + curr, 0);
       return sum / values.length;
    };    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const promises = servers.map(server =>
                    fetchAggregateData(dataSource, server, startDate, endDate)
			
                );
                const results = await Promise.all(promises);
                
                const allTimestamps = results.map(result => result.timestamps);
                const allMetricData = results.map(result => result.values);
                console.log('@@@@@@@@@@@@@@',results, allTimestamps,allMetricData);

		        const serverAverages = allMetricData.map(serverValues => calculateAverage(serverValues));

		        const totalAverage = calculateAverage(serverAverages);

		        setAverageValue(totalAverage.toFixed(2));
                updateClusterScore(averageValue);
                const combinedTimestamps = allTimestamps
                 .filter(timestamps => timestamps && timestamps.length > 0) // Filter out empty arrays
                 .flat() // Combine all non-empty arrays into a single array
                 .filter((value, index, self) => self.indexOf(value) === index);
               // Set the combined timestamps, or handle the case where there are no valid timestamps
               if (combinedTimestamps.length > 0) {
                 setTimestamps(combinedTimestamps);
               } else {
                 // Handle the case where no valid timestamp arrays are available
                 console.log('No valid timestamps found.');
                 // Set an empty array or some other default value
                 setTimestamps([]); // Or another default value or action
               }
               console.log('###########', timestamps)
                // Assuming timestamps are the same for all servers
                //setTimestamps(allTimestamps[0]);
                setMetricData(allMetricData.map(row => row.map(value => parseFloat(value).toFixed(2))));
            } catch (error) {
                console.error(`Error fetching ${metricName} data:`, error);
                setTimestamps([]);
                setMetricData([]);
            }
        };

        if (servers.length > 0) {
            fetchData();
        }
    }, [dataSource, servers, startDate, endDate, metricName, updateClusterScore]);

   const { condensedTimestamps, condensedMetricData } = getCondensedData(timestamps, metricData);
    
   const chartData = {
        labels: condensedTimestamps.map(convertToIST),
        datasets: servers.map((server, index) => ({
            label: servers[index],
            data: condensedMetricData[index] || [],
            tension: 0.2,
            pointBorderWidth: 0.6,
            pointRadius: 1,
            borderWidth: 2,
            borderColor: '#3C0B99',
	    pointStyle: 'circle',
            pointHoverRadius: 4,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 2,
            pointShadowColor: 'rgba(0, 0, 0, 0.5)',
        }))
    };


     const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 0,
                    maxTicksLimit: 7,
                    callback: function(value) {
                        let label = this.getLabelForValue(value);
                        if (label.length > 5) {
                            label = label.substring(5);
                        }
                        return label;
                    }
                },
                title: {
                    display: true,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'EnviVital',
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                        return context.dataset.label + ': ' + context.raw ;
                    },
                },
            },
            verticalLinePlugin: true,
        },
        hover: {
            mode: 'index',
            intersect: false,
        },
    };

   return <Line data={chartData} options={chartOptions} ref={chartRef} />;
};

export default LandingGraph;
